import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { AccountUser, UserSettings } from '@vertice/slices';
import { UserRoleType } from '@vertice/core/src/constants/userRoles';
import useUpdateUser, { UpdateUserResponse } from '@vertice/hooks/src/useUpdateUser';
import useCreateUser, { CreateUserResponse } from '@vertice/hooks/src/useCreateUser';
import { AppTypeContext } from '../contexts/AppTypeContext';
import { getDefaultUserRole } from './utils';
import { RoleSelector } from './RoleSelector';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { zodToHookFormValidator } from '@vertice/core/src/utils/validation/zodToHookFormValidator';
import {
  email as emailSchema,
  phoneNumber as phoneNumberSchema,
} from '@vertice/core/src/utils/validation/sharedSchemas';
import { z } from 'zod';
import { FormTimezoneSelect } from '@vertice/core/src/modules/forms/fields/FormTimezoneSelect/FormTimezoneSelect';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import { removeAllWhitespace } from '@vertice/core/src/utils/strings/removeAllWhitespace';

interface UserFormProps {
  formId: string;
  userData?: AccountUser;
  userSettings?: UserSettings;
  showTimeZone?: boolean;
  onUserSaved?: (contact: AccountUser) => void;
  setRequestInProgress?: (progress: boolean) => void;
}

interface UserFormFormType {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  userRole: string;
}

export interface FormType {
  settings: UserSettings;
  userData: UserFormFormType;
}

const UserFormEntry = createTypedFormEntry<FormType>();

const UserForm: FC<UserFormProps> = ({
  formId,
  userData,
  userSettings,
  showTimeZone,
  onUserSaved,
  setRequestInProgress,
}) => {
  const { t } = useTranslation();
  const { updateUser } = useUpdateUser();
  const { createUser, defaultUserSettings } = useCreateUser();
  const { isIAT } = useContext(AppTypeContext);
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const defaultValues = {
    settings: {
      timeZone: userSettings?.timeZone || '',
    },
    userData: {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      phoneNumber: userData?.phoneNumber || '',
      jobTitle: userData?.accountUserProperties?.jobTitle || '',
      userRole: getDefaultUserRole(userData),
    },
  };

  const methods = useForm<FormType>({ defaultValues, mode: 'all' });
  const { handleSubmit, setValue, watch } = methods;
  const role = watch('userData.userRole');

  const isEditMode = !!userData;

  const setProgress = (progress: boolean) => {
    if (setRequestInProgress) {
      setRequestInProgress(progress);
    }
  };

  const createUserWithData = async (formData: FormType) => {
    const { firstName, lastName, email, phoneNumber, jobTitle, userRole } = formData.userData;

    const userSettingsData = defaultUserSettings as UserSettings;
    if (formData.settings?.timeZone) {
      userSettingsData.timeZone = formData.settings.timeZone;
    }

    return createUser({
      firstName,
      lastName,
      email,
      ...(phoneNumber.trim().length && { phoneNumber: removeAllWhitespace(phoneNumber) }),
      role: userRole,
      jobTitle,
      userSettings: userSettingsData,
    });
  };

  const editUserWithData = async (formData: FormType) => {
    const { firstName, lastName, email, phoneNumber, jobTitle, userRole } = formData.userData;

    if (!userData) {
      return {};
    }

    return updateUser({
      userId: userData.userId,
      firstName,
      lastName,
      email,
      ...(phoneNumber.trim().length && { phoneNumber: removeAllWhitespace(phoneNumber) }),
      role: userRole,
      jobTitle,
      ...(formData.settings?.timeZone && { userSettings: formData.settings }),
    });
  };

  const onSubmit: SubmitHandler<FormType> = async (formData) => {
    const { firstName, lastName, email, phoneNumber, jobTitle } = formData.userData;

    setProgress(true);
    let response;
    if (userData) {
      response = await editUserWithData(formData);
    } else {
      response = await createUserWithData(formData);
    }
    setProgress(false);

    const successResponse = response as CreateUserResponse | UpdateUserResponse;
    if (successResponse?.data && onUserSaved) {
      onUserSaved({
        userId: successResponse.data?.userId!,
        userName: email,
        firstName,
        lastName,
        email,
        phoneNumber,
        accountUserProperties: {
          jobTitle,
        },
      });
    }
  };

  return (
    <Stack direction="row" gap={8}>
      <Stack flex={1}>
        <FormProvider {...methods}>
          <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <FormSection>
              <UserFormEntry
                name="userData.firstName"
                label={t('PREFERENCES.NEW_USER.LABELS.FIRST_NAME')}
                component={FormTextField}
                required
                width={12}
              />
              <UserFormEntry
                name="userData.lastName"
                label={t('PREFERENCES.NEW_USER.LABELS.LAST_NAME')}
                component={FormTextField}
                required
                width={12}
              />
              <UserFormEntry
                name="userData.email"
                label={t('PREFERENCES.NEW_USER.LABELS.EMAIL')}
                component={FormTextField}
                componentProps={{
                  validate: zodToHookFormValidator(emailSchema(t)),
                }}
                required
                disabled={isEditMode}
                width={12}
              />
              <UserFormEntry
                name="userData.phoneNumber"
                label={t('PREFERENCES.NEW_USER.LABELS.PHONE_NUMBER')}
                component={FormTextField}
                componentProps={{
                  validate: zodToHookFormValidator(phoneNumberSchema(t).nullish().or(z.literal(''))),
                }}
                width={12}
              />

              {showTimeZone && (
                <UserFormEntry
                  name="settings.timeZone"
                  label={t('PREFERENCES.NEW_USER.LABELS.TIME_ZONE')}
                  component={FormTimezoneSelect}
                  width={12}
                />
              )}

              <UserFormEntry
                name="userData.jobTitle"
                label={t('PREFERENCES.NEW_USER.LABELS.JOB_TITLE')}
                component={FormTextField}
                required
                width={12}
              />
            </FormSection>
          </form>
        </FormProvider>
      </Stack>

      <Stack flex={1} gap={1}>
        <TextFieldCaption label={t('PREFERENCES.NEW_USER.LABELS.ROLE_TYPE')} required size="XS" />
        <RoleSelector
          role={role as UserRoleType}
          onRoleChanged={(value: UserRoleType) => setValue('userData.userRole', value)}
          disabled={!isIAT && !isUserAdmin && isEditMode}
        />
      </Stack>
    </Stack>
  );
};

export default UserForm;
