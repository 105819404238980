import { useMemo } from 'react';
import useAssumeRoleAuth from './assumeRole/useAssumeRoleAuth';
import useCfaAmplifyAuth from './amplify/useCfaAmplifyAuth';
import { AuthContextData } from '@verticeone/auth/src';
import { createBrowserRouter } from 'react-router-dom';

const useCfaAuth = ({ router }: { router?: ReturnType<typeof createBrowserRouter> }): AuthContextData => {
  const assumeRoleAuth = useAssumeRoleAuth();
  const amplifyAuth = useCfaAmplifyAuth(router);

  return useMemo(() => (assumeRoleAuth.user ? assumeRoleAuth : amplifyAuth), [amplifyAuth, assumeRoleAuth]);
};

export default useCfaAuth;
