import { useTranslation } from 'react-i18next';
import { IntegrationFail } from '@vertice/assets';
import Button from '@verticeone/design-system/src/components/Button';
import { STEPS } from '../../common';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';

export const ConnectionErrorStep = ({ setStep }: WizardStepProps) => {
  const { t } = useTranslation();

  const onBack = () => {
    setStep(STEPS.START);
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationFail />}
          title={t('PREFERENCES.INTEGRATIONS.CONNECTION_ERROR')}
          text={t('PREFERENCES.INTEGRATIONS.SLACK.WIZARD.CONFIGURATION_NOT_RECOGNIZED')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption disabled>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
