import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Text from '@verticeone/design-system/src/components/Text';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { Dialog } from '@verticeone/design-system/src/components/Dialog';

import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowViewer } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { WorkflowVersionRow } from '../types';
import { useCreateWorkflowVersionLabel } from '../utils';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

export type WorkflowVersionSchemaProps = {
  previewRow: WorkflowVersionRow;
  onClose: () => void;
};

export const WorkflowPreviewDialog: FC<WorkflowVersionSchemaProps> = ({ previewRow, onClose }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const createWorkflowVersionLabel = useCreateWorkflowVersionLabel();
  const {
    data: workflowVersionData,
    isLoading,
    isFetching,
  } = useGetWorkflowVersionQuery({ accountId, workflowId: previewRow.id, workflowVersion: previewRow.versionId });

  const workflowVersion = isLoading || isFetching ? undefined : workflowVersionData;

  return (
    <Dialog size="M" fullHeight width={2000} open noPadding onClose={onClose}>
      <Stack padding={6} justifyContent="space-between" direction="row">
        {workflowVersion === undefined ? (
          <Placeholder width="300px" />
        ) : (
          <Stack gap={2} direction="row" alignItems="center">
            <Text variant="heading" size="M">
              {createWorkflowVersionLabel(previewRow)}
            </Text>
            {previewRow.status === 'PENDING' && (
              <ChipButton variant="ghost">{t('ENTITIES.WORKFLOW_VERSION.DRAFT')}</ChipButton>
            )}
            {previewRow.status === 'ACTIVE' && (
              <ChipButton variant="ghost" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}>
                {t('ENTITIES.WORKFLOW_VERSION.LIVE')}
              </ChipButton>
            )}
          </Stack>
        )}
        <IconButton icon={Close} variant="ghost" onClick={onClose} />
      </Stack>
      <Stack height="100vh" width="100%">
        {workflowVersion === undefined ? (
          <Placeholder height="100%" />
        ) : (
          <WorkflowViewer definitions={workflowVersion?.workflowDefinition as Definitions} />
        )}
      </Stack>
    </Dialog>
  );
};
