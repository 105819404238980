import { useLocaleContext } from './LocaleContext';
import { useCallback } from 'react';
import { isNil } from 'lodash';

// WARNING:
// This file is copied from @vertice/core/src/utils/formatting.
// Don't change this file without updating source file as well.
// This file is going to be deleted as soon as we move the formatters into the shared @vertice/utils package.

export interface FormatDateOptions {
  locale?: string;
  formatterOptions?: Intl.DateTimeFormatOptions;
  fallbackValue?: string;
}

export const defaultFormatterOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const dateTimeFormatterOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
};

export const formatDate = (value?: string, options?: FormatDateOptions) => {
  const { locale, formatterOptions, fallbackValue } = options ?? {};
  if (!locale || isNil(value)) {
    return fallbackValue;
  }

  const dateFormatter = new Intl.DateTimeFormat(locale, formatterOptions ?? defaultFormatterOptions);

  return dateFormatter.format(new Date(value));
};

export const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return useCallback(
    (value?: string, options: Omit<FormatDateOptions, 'locale'> = {}) => formatDate(value, { locale, ...options }),
    [locale]
  );
};

export const formatDateRange = (start?: string, end?: string, options?: FormatDateOptions) => {
  const { locale, formatterOptions, fallbackValue } = options ?? {};

  if (!locale || isNil(start) || isNil(end)) {
    return fallbackValue;
  }

  const formatter = new Intl.DateTimeFormat(locale, formatterOptions ?? defaultFormatterOptions);

  return formatter.formatRange(new Date(start), new Date(end));
};

export const useFormatDateRange = () => {
  const { locale } = useLocaleContext();

  return useCallback(
    (start?: string, end?: string, options: Omit<FormatDateOptions, 'locale'> = {}) =>
      formatDateRange(start, end, { locale, ...options }),
    [locale]
  );
};
