import Highcharts from 'highcharts';

export const HighchartsIntervalPoint = () => {
  // Custom series type for displaying low/med/high values using boxplot as a base
  Highcharts.seriesType(
    'intervalpoint',
    'boxplot',
    {
      keys: ['low', 'median', 'high'],
    },
    {
      // Median value is displayed as rectangular point
      // Confidence interval is displayed as line below
      // Inspired by: https://www.highcharts.com/demo/highcharts/advanced-accessible
      // GitHub link: https://github.com/highcharts/highcharts/blob/master/samples/highcharts/demo/advanced-accessible/demo.js
      drawPoints: function () {
        const series = this;
        this.points.forEach((point: any) => {
          let graphicInterval = point.graphicInterval;
          let graphicMedian = point.graphicMedian;
          let graphicTooltipBox = point.graphicTooltipBox; // used to create a box model around the point to show tooltip
          let graphic = point.graphic; // wrapping graphic element (can be used to set opacity on mouseover)
          const verb = graphicTooltipBox ? 'animate' : 'attr';
          const shapeArgs = point.shapeArgs;
          const width = shapeArgs.width;
          const left = Math.floor(shapeArgs.x) + 0.5;
          const centerX = left + Math.round(width / 2) + 0.5;
          const highPlot = Math.floor(point.highPlot) + 0.5;
          // Draw low marker even if 0
          const lowPlot = Math.floor(point.lowPlot) + 0.5 - (point.low === 0 ? 1 : 0);

          const medianPlot = Math.floor(point.medianPlot) + 0.5;
          const medianRectWidth = series.options.custom.pointWidth ?? 10;
          const medianRectHeight = series.options.custom.pointHeight ?? 8;
          const medianRectRadius = series.options.custom.pointRadius ?? 2;
          const medianRectY = medianPlot - medianRectHeight / 2;
          const medianRectX = centerX - medianRectWidth / 2;

          const tooltipBoxPadding = series.options.custom.pointRadius ?? 10;
          const tooltipBoxWidth = medianRectWidth + tooltipBoxPadding;
          const tooltipBoxHeight = Math.max(highPlot, lowPlot) - Math.min(highPlot, lowPlot) + tooltipBoxPadding;
          const tooltipBoxX = centerX - tooltipBoxWidth / 2;
          const tooltipBoxY = Math.min(lowPlot, highPlot) - tooltipBoxPadding / 2;

          if (point.isNull) {
            return;
          }

          if (!graphic) {
            point.graphic = graphic = series.chart.renderer.g('point').add(series.group);
          }

          if (!graphicInterval) {
            point.graphicInterval = graphicInterval = series.chart.renderer.path('interval').add(graphic);
          }

          if (!graphicMedian) {
            point.graphicMedian = graphicMedian = series.chart.renderer
              .rect(medianRectX, medianRectY, medianRectWidth, medianRectHeight, medianRectRadius, medianRectRadius)
              .add(graphic);
          }

          if (!graphicTooltipBox) {
            point.graphicTooltipBox = graphicTooltipBox = series.chart.renderer
              .rect(tooltipBoxX, tooltipBoxY, tooltipBoxWidth, tooltipBoxHeight, 0, 0)
              .add(graphic);
          }

          graphicInterval.attr({
            stroke: series.options.custom.intervalColor ?? point.color,
            'stroke-width': series.options.custom.strokeWidth ?? 2,
            'stroke-linecap': series.options.custom.strokeLinecap ?? 'round',
          });

          graphicMedian.attr({
            fill: point.color,
          });

          graphicTooltipBox.attr({
            fill: 'transparent',
            'stroke-width': 0,
          });

          graphicInterval[verb]({
            d: ['M', centerX, highPlot, 'V', lowPlot],
          });

          graphicMedian[verb]({
            x: medianRectX,
            y: medianRectY,
          });

          graphicTooltipBox[verb]({
            x: tooltipBoxX,
            y: tooltipBoxY,
            width: tooltipBoxWidth,
            height: tooltipBoxHeight,
          });
        });
      },
    }
  );
};
