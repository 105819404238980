export type Timezone = { offsetText: string; value: string; offset: number };

export const timezones: Timezone[] = [
  { offsetText: 'UTC-11:00', value: 'Pacific/Midway', offset: -11 },
  { offsetText: 'UTC-11:00', value: 'Pacific/Niue', offset: -11 },
  { offsetText: 'UTC-11:00', value: 'Pacific/Pago_Pago', offset: -11 },
  { offsetText: 'UTC-10:00', value: 'Pacific/Honolulu', offset: -10 },
  { offsetText: 'UTC-10:00', value: 'Pacific/Johnston', offset: -10 },
  { offsetText: 'UTC-10:00', value: 'Pacific/Rarotonga', offset: -10 },
  { offsetText: 'UTC-10:00', value: 'Pacific/Tahiti', offset: -10 },
  { offsetText: 'UTC-09:00', value: 'America/Anchorage', offset: -8 },
  { offsetText: 'UTC-09:00', value: 'America/Juneau', offset: -8 },
  { offsetText: 'UTC-09:00', value: 'America/Nome', offset: -8 },
  { offsetText: 'UTC-09:00', value: 'America/Sitka', offset: -8 },
  { offsetText: 'UTC-09:00', value: 'America/Yakutat', offset: -8 },
  { offsetText: 'UTC-08:00', value: 'America/Los_Angeles', offset: -8 },
  { offsetText: 'UTC-08:00', value: 'America/Tijuana', offset: -8 },
  { offsetText: 'UTC-08:00', value: 'America/Vancouver', offset: -8 },
  { offsetText: 'UTC-08:00', value: 'PST8PDT', offset: -8 },
  { offsetText: 'UTC-08:00', value: 'America/Santa_Isabel', offset: -7 },
  { offsetText: 'UTC-07:00', value: 'America/Creston', offset: -7 },
  { offsetText: 'UTC-07:00', value: 'America/Dawson', offset: -7 },
  { offsetText: 'UTC-07:00', value: 'America/Dawson_Creek', offset: -7 },
  { offsetText: 'UTC-07:00', value: 'America/Hermosillo', offset: -7 },
  { offsetText: 'UTC-07:00', value: 'America/Phoenix', offset: -7 },
  { offsetText: 'UTC-07:00', value: 'America/Whitehorse', offset: -7 },
  { offsetText: 'UTC-07:00', value: 'America/Chihuahua', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Mazatlan', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Boise', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Cambridge_Bay', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Denver', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Edmonton', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Inuvik', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Ojinaga', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'America/Yellowknife', offset: -6 },
  { offsetText: 'UTC-07:00', value: 'MST7MDT', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Belize', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Costa_Rica', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/El_Salvador', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Guatemala', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Managua', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Tegucigalpa', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'Pacific/Galapagos', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Regina', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Swift_Current', offset: -6 },
  { offsetText: 'UTC-06:00', value: 'America/Chicago', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Indiana/Knox', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Indiana/Tell_City', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Matamoros', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Menominee', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/North_Dakota/Beulah', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/North_Dakota/Center', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/North_Dakota/New_Salem', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Rainy_River', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Rankin_Inlet', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Resolute', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Winnipeg', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'CST6CDT', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Bahia_Banderas', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Cancun', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Merida', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Mexico_City', offset: -5 },
  { offsetText: 'UTC-06:00', value: 'America/Monterrey', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Bogota', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Cayman', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Coral_Harbour', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Eirunepe', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Guayaquil', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Jamaica', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Lima', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Panama', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Rio_Branco', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Detroit', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Havana', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Indiana/Petersburg', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Indiana/Vincennes', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Indiana/Winamac', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Iqaluit', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Kentucky/Monticello', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Louisville', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Montreal', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Nassau', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/New_York', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Nipigon', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Pangnirtung', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Port-au-Prince', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Thunder_Bay', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Toronto', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Indiana/Marengo', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Indiana/Vevay', offset: -5 },
  { offsetText: 'UTC-05:00', value: 'America/Indianapolis', offset: -5 },
  { offsetText: 'UTC-04:30', value: 'America/Caracas', offset: -4.5 },
  { offsetText: 'UTC-04:00', value: 'America/Asuncion', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Campo_Grande', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Cuiaba', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Anguilla', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Antigua', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Aruba', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Barbados', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Blanc-Sablon', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Boa_Vista', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Curacao', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Dominica', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Grand_Turk', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Grenada', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Guadeloupe', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Guyana', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Kralendijk', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/La_Paz', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Lower_Princes', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Manaus', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Marigot', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Martinique', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Montserrat', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Port_of_Spain', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Porto_Velho', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Puerto_Rico', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Santo_Domingo', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/St_Barthelemy', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/St_Kitts', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/St_Lucia', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/St_Thomas', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/St_Vincent', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Tortola', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Santiago', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'Antarctica/Palmer', offset: -4 },
  { offsetText: 'UTC-04:00', value: 'America/Glace_Bay', offset: -3 },
  { offsetText: 'UTC-04:00', value: 'America/Goose_Bay', offset: -3 },
  { offsetText: 'UTC-04:00', value: 'America/Halifax', offset: -3 },
  { offsetText: 'UTC-04:00', value: 'America/Moncton', offset: -3 },
  { offsetText: 'UTC-04:00', value: 'America/Thule', offset: -3 },
  { offsetText: 'UTC-04:00', value: 'Atlantic/Bermuda', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Sao_Paulo', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Argentina/La_Rioja', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Argentina/Rio_Gallegos', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Argentina/Salta', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Argentina/San_Juan', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Argentina/San_Luis', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Argentina/Tucuman', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Argentina/Ushuaia', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Buenos_Aires', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Catamarca', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Cordoba', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Jujuy', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Mendoza', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Araguaina', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Belem', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Cayenne', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Fortaleza', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Maceio', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Paramaribo', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Recife', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Santarem', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'Antarctica/Rothera', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'Atlantic/Stanley', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Godthab', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Montevideo', offset: -3 },
  { offsetText: 'UTC-03:00', value: 'America/Bahia', offset: -3 },
  { offsetText: 'UTC-03:30', value: 'America/St_Johns', offset: -2.5 },
  { offsetText: 'UTC-02:00', value: 'America/Noronha', offset: -2 },
  { offsetText: 'UTC-02:00', value: 'Atlantic/South_Georgia', offset: -2 },
  { offsetText: 'UTC-01:00', value: 'Atlantic/Cape_Verde', offset: -1 },
  { offsetText: 'UTC-01:00', value: 'America/Scoresbysund', offset: 0 },
  { offsetText: 'UTC-01:00', value: 'Atlantic/Azores', offset: 0 },
  { offsetText: 'UTC', value: 'America/Danmarkshavn', offset: 0 },
  { offsetText: 'UTC', value: 'Europe/Isle_of_Man', offset: 0 },
  { offsetText: 'UTC', value: 'Europe/Guernsey', offset: 0 },
  { offsetText: 'UTC', value: 'Europe/Jersey', offset: 0 },
  { offsetText: 'UTC', value: 'Europe/London', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Abidjan', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Accra', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Bamako', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Banjul', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Bissau', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Conakry', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Dakar', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Freetown', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Lome', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Monrovia', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Nouakchott', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Ouagadougou', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Sao_Tome', offset: 0 },
  { offsetText: 'UTC', value: 'Atlantic/Reykjavik', offset: 0 },
  { offsetText: 'UTC', value: 'Atlantic/St_Helena', offset: 0 },
  { offsetText: 'UTC', value: 'Africa/Casablanca', offset: 1 },
  { offsetText: 'UTC', value: 'Africa/El_Aaiun', offset: 1 },
  { offsetText: 'UTC', value: 'Atlantic/Canary', offset: 1 },
  { offsetText: 'UTC', value: 'Atlantic/Faeroe', offset: 1 },
  { offsetText: 'UTC', value: 'Atlantic/Madeira', offset: 1 },
  { offsetText: 'UTC', value: 'Europe/Dublin', offset: 1 },
  { offsetText: 'UTC', value: 'Europe/Lisbon', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Algiers', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Bangui', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Brazzaville', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Douala', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Kinshasa', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Lagos', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Libreville', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Luanda', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Malabo', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Ndjamena', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Niamey', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Porto-Novo', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Tunis', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Africa/Windhoek', offset: 1 },
  { offsetText: 'UTC+01:00', value: 'Arctic/Longyearbyen', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Amsterdam', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Andorra', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Berlin', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Busingen', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Gibraltar', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Luxembourg', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Malta', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Monaco', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Oslo', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Rome', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/San_Marino', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Stockholm', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Vaduz', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Vatican', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Vienna', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Zurich', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Belgrade', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Bratislava', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Budapest', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Ljubljana', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Podgorica', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Prague', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Tirane', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Africa/Ceuta', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Brussels', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Copenhagen', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Madrid', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Paris', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Sarajevo', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Skopje', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Warsaw', offset: 2 },
  { offsetText: 'UTC+01:00', value: 'Europe/Zagreb', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Cairo', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Blantyre', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Bujumbura', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Gaborone', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Harare', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Johannesburg', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Kigali', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Lubumbashi', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Lusaka', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Maputo', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Maseru', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Mbabane', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Africa/Tripoli', offset: 2 },
  { offsetText: 'UTC+02:00', value: 'Asia/Nicosia', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Athens', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Bucharest', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Chisinau', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Asia/Beirut', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Asia/Damascus', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Helsinki', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Kiev', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Mariehamn', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Nicosia', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Riga', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Sofia', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Tallinn', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Uzhgorod', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Vilnius', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Zaporozhye', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Europe/Istanbul', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Asia/Jerusalem', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Asia/Amman', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Asia/Baghdad', offset: 3 },
  { offsetText: 'UTC+02:00', value: 'Europe/Kaliningrad', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Asia/Aden', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Asia/Bahrain', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Asia/Kuwait', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Asia/Qatar', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Asia/Riyadh', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Addis_Ababa', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Asmera', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Dar_es_Salaam', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Djibouti', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Juba', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Kampala', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Khartoum', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Mogadishu', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Africa/Nairobi', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Antarctica/Syowa', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Indian/Antananarivo', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Indian/Comoro', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Indian/Mayotte', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Europe/Kirov', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Europe/Moscow', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Europe/Simferopol', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Europe/Volgograd', offset: 3 },
  { offsetText: 'UTC+03:00', value: 'Europe/Minsk', offset: 3 },
  { offsetText: 'UTC+04:00', value: 'Europe/Astrakhan', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Europe/Samara', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Europe/Ulyanovsk', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Asia/Dubai', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Asia/Muscat', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Indian/Mahe', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Indian/Mauritius', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Indian/Reunion', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Asia/Tbilisi', offset: 4 },
  { offsetText: 'UTC+04:00', value: 'Asia/Yerevan', offset: 4 },
  { offsetText: 'UTC+03:30', value: 'Asia/Tehran', offset: 4.5 },
  { offsetText: 'UTC+04:30', value: 'Asia/Kabul', offset: 4.5 },
  { offsetText: 'UTC+04:00', value: 'Asia/Baku', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Antarctica/Mawson', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Aqtau', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Aqtobe', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Ashgabat', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Dushanbe', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Oral', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Samarkand', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Tashkent', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Indian/Kerguelen', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Indian/Maldives', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Yekaterinburg', offset: 5 },
  { offsetText: 'UTC+05:00', value: 'Asia/Karachi', offset: 5 },
  { offsetText: 'UTC+05:30', value: 'Asia/Kolkata', offset: 5.5 },
  { offsetText: 'UTC+05:30', value: 'Asia/Calcutta', offset: 5.5 },
  { offsetText: 'UTC+05:30', value: 'Asia/Colombo', offset: 5.5 },
  { offsetText: 'UTC+05:45', value: 'Asia/Kathmandu', offset: 5.75 },
  { offsetText: 'UTC+06:00', value: 'Antarctica/Vostok', offset: 6 },
  { offsetText: 'UTC+06:00', value: 'Asia/Almaty', offset: 6 },
  { offsetText: 'UTC+06:00', value: 'Asia/Bishkek', offset: 6 },
  { offsetText: 'UTC+06:00', value: 'Asia/Qyzylorda', offset: 6 },
  { offsetText: 'UTC+06:00', value: 'Asia/Urumqi', offset: 6 },
  { offsetText: 'UTC+06:00', value: 'Indian/Chagos', offset: 6 },
  { offsetText: 'UTC+06:00', value: 'Asia/Dhaka', offset: 6 },
  { offsetText: 'UTC+06:00', value: 'Asia/Thimphu', offset: 6 },
  { offsetText: 'UTC+06:30', value: 'Asia/Rangoon', offset: 6.5 },
  { offsetText: 'UTC+06:30', value: 'Indian/Cocos', offset: 6.5 },
  { offsetText: 'UTC+07:00', value: 'Antarctica/Davis', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Bangkok', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Hovd', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Jakarta', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Phnom_Penh', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Pontianak', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Saigon', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Vientiane', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Indian/Christmas', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Novokuznetsk', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Novosibirsk', offset: 7 },
  { offsetText: 'UTC+07:00', value: 'Asia/Omsk', offset: 7 },
  { offsetText: 'UTC+08:00', value: 'Asia/Hong_Kong', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Macau', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Shanghai', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Krasnoyarsk', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Brunei', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Kuala_Lumpur', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Kuching', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Makassar', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Manila', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Singapore', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Antarctica/Casey', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Australia/Perth', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Taipei', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Choibalsan', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Ulaanbaatar', offset: 8 },
  { offsetText: 'UTC+08:00', value: 'Asia/Irkutsk', offset: 8 },
  { offsetText: 'UTC+09:00', value: 'Asia/Dili', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Asia/Jayapura', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Asia/Tokyo', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Pacific/Palau', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Asia/Pyongyang', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Asia/Seoul', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Asia/Chita', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Asia/Khandyga', offset: 9 },
  { offsetText: 'UTC+09:00', value: 'Asia/Yakutsk', offset: 9 },
  { offsetText: 'UTC+09:30', value: 'Australia/Adelaide', offset: 9.5 },
  { offsetText: 'UTC+09:30', value: 'Australia/Broken_Hill', offset: 9.5 },
  { offsetText: 'UTC+09:30', value: 'Australia/Darwin', offset: 9.5 },
  { offsetText: 'UTC+10:00', value: 'Australia/Brisbane', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Australia/Lindeman', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Australia/Melbourne', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Australia/Sydney', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Antarctica/DumontDUrville', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Pacific/Guam', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Pacific/Port_Moresby', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Pacific/Saipan', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Pacific/Truk', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Australia/Currie', offset: 10 },
  { offsetText: 'UTC+10:00', value: 'Australia/Hobart', offset: 10 },
  { offsetText: 'UTC+11:00', value: 'Antarctica/Macquarie', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Pacific/Efate', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Pacific/Guadalcanal', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Pacific/Kosrae', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Pacific/Noumea', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Pacific/Ponape', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Asia/Sakhalin', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Asia/Ust-Nera', offset: 11 },
  { offsetText: 'UTC+11:00', value: 'Asia/Vladivostok', offset: 11 },
  { offsetText: 'UTC+12:00', value: 'Antarctica/McMurdo', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Auckland', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Funafuti', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Kwajalein', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Majuro', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Nauru', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Tarawa', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Wake', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Wallis', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Pacific/Fiji', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Asia/Anadyr', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Asia/Kamchatka', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Asia/Magadan', offset: 12 },
  { offsetText: 'UTC+12:00', value: 'Asia/Srednekolymsk', offset: 12 },
  { offsetText: 'UTC+13:00', value: 'Pacific/Enderbury', offset: 13 },
  { offsetText: 'UTC+13:00', value: 'Pacific/Fakaofo', offset: 13 },
  { offsetText: 'UTC+13:00', value: 'Pacific/Tongatapu', offset: 13 },
  { offsetText: 'UTC+13:00', value: 'Pacific/Apia', offset: 13 },
];
