import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCallback, useState } from 'react';
import { formatUserRef, parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useCreateRequestMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWaitForRequestReady } from './useWaitForRequestReady';
import { useLoggedUser } from '@verticeone/auth/src';

export type VendorType = 'PREDEFINED' | 'INLINE';

export type Vendor = {
  /** Vendor id. Should be provided if `vendorType` is `PREDEFINED`. */
  id?: string | null;
  /** Vendor name. Should be provided if `vendorType` is `INLINE`. */
  name?: string | null;
  type?: VendorType;
};

export type Product = {
  id?: string;
  name?: string;
  productId?: string;
  annualCost?: number;
  numberOfLicences?: number;
};

export type CreateRequestParams = {
  contractId: string;
  products?: Product[];
  vendor: Vendor;
  source: string;
  department?: string;
};

// Inspired by packages/dashboard/src/pages/IntakeFormWizards/NewPurchaseWizard/Steps/AdditionalRequirements/useCreateNewPurchaseRequest.ts
export const useCreateRenewalRequest = (): [(params: CreateRequestParams) => Promise<string | undefined>, boolean] => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const [inProgress, setInProgress] = useState(false);
  const [createRequestMutation] = useCreateRequestMutation();
  const waitForRequestReady = useWaitForRequestReady();

  const createRequest = useCallback(
    async ({ vendor, contractId, products, source, department }: CreateRequestParams): Promise<string | undefined> => {
      try {
        if (vendor.id === null) {
          // this should never happen as the vendor is always pre-filled
          throw new Error('Vendor is not selected');
        }
        const requestInput: { [p: string]: any } = {
          contractId: contractId,
          products: products,
          vendor: vendor,
        };

        if (department) {
          requestInput.department = department;
        }

        setInProgress(true);

        const createRequestResponse = await createRequestMutation({
          accountId: accountId!,
          createRequest: {
            sourceRef: source,
            serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/renewal/generic/v4`,
            name: `Renewal - ${vendor.name}`,
            input: requestInput,
            owner: formatUserRef(userId),
          },
        });

        const requestReference = 'data' in createRequestResponse ? createRequestResponse.data?.ref : undefined;
        if (requestReference) {
          const requestId = parseRequestRef(requestReference).requestId;
          await waitForRequestReady(accountId, requestId);
          return requestId;
        }
        return undefined;
      } finally {
        setInProgress(false);
      }
    },
    [userId, createRequestMutation, accountId, waitForRequestReady]
  );

  return [createRequest, inProgress];
};
