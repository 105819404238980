import * as z from 'zod';
import { nonEmptyString } from '../../../../../../utils/validation/sharedSchemas';

export const editUserTaskFormSchema = z.object({
  id: z.string().optional(),
  name: nonEmptyString,
  assignees: z.array(z.string()),
});

export type EditUserTaskFormData = z.infer<typeof editUserTaskFormSchema>;
