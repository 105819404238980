import type { StyledComponentProps } from './components/constants';
import getTextVariantStyle, { TextVariants } from '../Text/utils/getTextVariantStyle';
import { isUndefined } from 'lodash';
import { CSSProperties } from '@mui/material/styles/createMixins';

export type TextFieldAreaStylesProps = Pick<StyledComponentProps, 'theme' | '$size' | '$minHeight' | '$autosize'> & {
  $variant?: Exclude<TextVariants, 'code' | 'display'>;
};

export const getTextFieldAreaStyles = ({
  theme,
  $size,
  $minHeight,
  $autosize,
  $variant = 'body-regular',
}: TextFieldAreaStylesProps): CSSProperties => ({
  backgroundColor: 'transparent',
  color: theme.palette.text.color1,
  ...getTextVariantStyle({ variant: $variant, size: $size }),
  minHeight: !isUndefined($minHeight) ? theme.spacing($minHeight) : theme.spacing(30),
  minWidth: theme.spacing(64),
  resize: !$autosize ? 'block' : 'none',
  '&::placeholder': {
    color: theme.palette.text.color3,
    opacity: 1,
  },
});
