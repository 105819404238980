import React, { FunctionComponent, ReactNode, SVGProps } from 'react';
import styled from '@mui/material/styles/styled';
import Text from '../Text';
import Stack from '@mui/material/Stack';
import { SvgIconComponent } from '@mui/icons-material';

export type CardHeaderTitleProps = {
  text: string | ReactNode;
  color?: string;
  icon?: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  after?: React.ReactNode;
  right?: React.ReactNode;
  tag?: string;
};

export const StyledCardHeaderTitleIcon = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.color1,
  padding: 2,
  borderRadius: 4,
}));

const StyledAfterWrapper = styled(Stack)({
  flexShrink: 0,
});

const StyledRightAlignedWrapper = styled(Stack)({
  flexShrink: 0,
});

export const StyledCardHeaderTitle = styled(Text)({
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const CardHeaderTitle: React.FC<CardHeaderTitleProps> = ({
  text,
  color = 'text1',
  icon: Icon,
  after,
  right,
  tag,
  ...otherProps
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" {...otherProps}>
      <Stack direction="row" alignItems="center" {...otherProps}>
        {Icon && (
          <StyledCardHeaderTitleIcon>
            <Icon />
          </StyledCardHeaderTitleIcon>
        )}
        <StyledCardHeaderTitle color={color} variant="body-regular" tag={tag}>
          {text}
        </StyledCardHeaderTitle>
        {after && <StyledAfterWrapper>{after}</StyledAfterWrapper>}
      </Stack>
      {right && <StyledRightAlignedWrapper>{right}</StyledRightAlignedWrapper>}
    </Stack>
  );
};
