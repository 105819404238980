import React from 'react';
import BaseCloudAnalyticsStats from '../../BaseCloudTab/Stats/BaseCloudAnalyticsStats';
import useTagsServicesData from '../Table/useTagsServicesData';

const CloudAccountServicesStats = () => {
  const { data, isLoading } = useTagsServicesData();

  return <BaseCloudAnalyticsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountServicesStats;
