import React from 'react';
import { Url } from '@vertice/slices';
import { isNil } from 'lodash';
import Button from '@verticeone/design-system/src/components/Button';
import { Link } from '@mui/material';

type LinkButtonProps = {
  label?: string;
  url?: Url;
};

export const LinkButton = ({ label, url }: LinkButtonProps) => {
  if (isNil(url)) {
    return null;
  }

  return (
    <Button
      variant="ghost"
      color="neutral"
      size="S"
      component={Link}
      target={'_blank'}
      href={'//' + url}
      rel="noreferrer"
      underline="none"
    >
      {label}
    </Button>
  );
};
