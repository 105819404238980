import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { createBrowserRouter } from 'react-router-dom';

type WithAmplifyProps = {
  children: React.ReactNode;
  router?: ReturnType<typeof createBrowserRouter>;
};

const withAmplify =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (props: WithAmplifyProps) =>
    (
      <Authenticator.Provider>
        <WrappedComponent {...(props as P)} />
      </Authenticator.Provider>
    );

export default withAmplify;
