import { z } from 'zod';
import { formDataProductItemSchema, offerSchema, vendorSchema } from '../shared/schemas';

export const zodSchema = z.object({
  contractCurrency: z.string().optional(),
  vendor: vendorSchema.optional(),
  products: z.array(formDataProductItemSchema).optional(),
  baselineOffer: offerSchema,
  parentAccountId: z.string().nullish().optional(),
});

export type FormData = z.infer<typeof zodSchema>;
