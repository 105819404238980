import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowModel } from '@mui/x-data-grid-pro';
import useAccountServicesUsageData, { TableRow } from '../useAccountServicesUsageData';
import { useCloudAnalytics } from '../../../../CloudAnalyticsContext';
import { getTableHeight } from '../../../BaseCloudTab/Table/utils';
import BaseCloudAnalyticsTable from '../../../BaseCloudTab/Table/BaseCloudAnalyticsTable';

type ReservedInstancesTableProps = {
  linkedAccountId: string;
  productCode: string;
};

const CloudAccountServicesUsageTable = ({ linkedAccountId, productCode }: ReservedInstancesTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE' });
  const {
    getLastNode,
    period: { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd },
  } = useCloudAnalytics();
  const { search, loadingHeight } = getLastNode();
  const { data, ...loadingStatus } = useAccountServicesUsageData({
    linkedAccountId,
    productCode,
    periodOneStart,
    periodOneEnd,
    periodTwoStart,
    periodTwoEnd,
  });
  const filteredData = data?.tableData?.filter((row) =>
    row.usageType?.toLowerCase().includes(search?.toLowerCase() || '')
  );

  const getRowId = (row: GridRowModel<TableRow>) => row.usageType;

  const calculatedHeight = getTableHeight(filteredData);

  return (
    <BaseCloudAnalyticsTable<TableRow>
      height={loadingStatus.isLoading && loadingHeight ? loadingHeight : calculatedHeight}
      data={filteredData || []}
      firstColumn={{
        field: 'usageType',
        headerName: t('COLUMN.USAGE_TYPE'),
      }}
      getRowId={getRowId}
      disableRowSelectionOnClick
      isLoading={loadingStatus.isLoading}
    />
  );
};

export default CloudAccountServicesUsageTable;
