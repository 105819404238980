import { useQuery } from '@tanstack/react-query';
import { useCloudClient } from '../CloudClientProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import {
  ContractStatus,
  type EdpContractListingQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';

export type ContractYear = {
  id: string;
  commitment: number | null;
  discount: number | null;
  endDate: string | null;
  name: string | null;
  startDate: string | null;
};

export type Contract = {
  id: string;
  startDate: string | null;
  endDate: string | null;
  state: 'new' | 'existing';
  years: Array<ContractYear>;
};

export const QUERY_KEY = 'EDPContractListing' as const;

const EDPContractListingQuery = graphql(`
  query EDPContractListing($accountId: String!, $contractStatus: ContractStatus) {
    listEDPContracts(params: { accountId: $accountId, contractStatus: $contractStatus }) {
      ... on EDPContractListing {
        __typename
        items {
          contractId
          costModelContainer {
            id
            costModel {
              startDate
              endDate
              costAllocationSpans {
                startDate
                endDate
                name
                commitment
                discount
              }
            }
          }
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export const useContractListData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  const transformData = (data: EdpContractListingQuery): Array<Contract> | null => {
    if (data.listEDPContracts?.__typename === 'EDPContractListing') {
      return (
        (data.listEDPContracts.items
          ?.map((item) => {
            const costModel = item?.costModelContainer.costModel;

            return {
              id: item?.contractId as string,
              state: 'existing',
              startDate: costModel?.startDate as string,
              endDate: costModel?.endDate as string,
              years:
                costModel?.costAllocationSpans.map((span) => ({
                  id: `${span.startDate}_${span.endDate}`,
                  ...span,
                })) ?? [],
            };
          })
          ?.sort((a, b) => b.startDate.localeCompare(a.startDate)) as Array<Contract>) ?? null
      );
    }
    return null;
  };

  return useQuery({
    queryKey: [QUERY_KEY, accountId],
    queryFn: () =>
      fetchCloudOptimization(EDPContractListingQuery, {
        accountId,
        contractStatus: ContractStatus.All,
      }),
    select: transformData,
    enabled: !!accountId,
  });
};
