import {
  AccountTreeTwoTone,
  CalendarTodayTwoTone,
  TaskTwoTone,
  PersonTwoTone,
  RequestPage,
  RequestPageOutlined,
  Settings,
  Update,
  SvgIconComponent,
  QuizTwoTone,
} from '@mui/icons-material';
import { FC, FunctionComponent, SVGProps } from 'react';
import { SvgIcon, useTheme } from '@mui/material';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { DesignSystemSize } from '@verticeone/design-system/src/types';

const ProductOutlineIcon = () => {
  // copied from Figma
  return (
    <SvgIcon>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.7598 11.1858L22 15.5929L17.7598 20V16.5714C15.3212 16.5631 13.5243 16.06 12.0664 14.9089C11.6993 14.619 11.3618 14.2939 11.0462 13.9371L12.2563 12.0407C12.5916 12.4734 12.9374 12.8321 13.3134 13.129C14.2922 13.9018 15.5961 14.345 17.7598 14.3532V11.1858Z"
          fill="currentColor"
        />
        <path
          d="M0 3.65355C3.47502 3.65355 5.85032 5.79869 7.34297 7.89056L8.5664 5.96402C6.79309 3.70372 4.01871 1.43532 0 1.43532V3.65355Z"
          fill="currentColor"
        />
        <path
          d="M13.5532 4.06162C14.7263 3.53862 16.0976 3.30372 17.7598 3.2981V0L22 4.40711L17.7598 8.81422V5.51635C16.2742 5.522 15.2001 5.73328 14.3622 6.10684C13.5273 6.47907 12.8639 7.04043 12.2406 7.8495C11.6594 8.60383 11.1187 9.56571 10.5117 10.7941C10.0899 11.6477 9.46591 12.7507 8.61013 13.8513C6.85639 16.1068 4.05709 18.4342 0 18.4342V16.216C3.21443 16.216 5.47739 14.3959 6.99881 12.4392C7.73933 11.4868 8.2871 10.5212 8.66143 9.76367C9.28654 8.49858 9.90552 7.37672 10.6233 6.44506C11.4237 5.40622 12.3513 4.5975 13.5532 4.06162Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

type IntelligentWorkflowsIconProps = {
  icon: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  size?: DesignSystemSize;
};

const IntelligentWorkflowsIcon: FC<IntelligentWorkflowsIconProps> = ({ icon: Icon, size = 'M' }) => {
  const { palette } = useTheme();

  return <IconWrapper htmlColor={palette.core.color5} icon={Icon} size={size} />;
};

export const IntelligentWorkflowsProductIcon = () => <IntelligentWorkflowsIcon icon={ProductOutlineIcon} />;

export const GenericWorkflowIcon = () => <IntelligentWorkflowsIcon icon={AccountTreeTwoTone} />;
export const GenericTemplateIcon = () => <IntelligentWorkflowsIcon icon={QuizTwoTone} />;
export const GenericUserIcon = () => <IntelligentWorkflowsIcon icon={PersonTwoTone} />;
export const GenericDateIcon = (props: { size?: DesignSystemSize }) => (
  <IntelligentWorkflowsIcon icon={CalendarTodayTwoTone} {...props} />
);
export const GenericRequestIcon = (props: { size?: DesignSystemSize }) => (
  <IntelligentWorkflowsIcon icon={RequestPage} {...props} />
);
export const GenericMoneyIcon = (props: { size?: DesignSystemSize }) => (
  <IntelligentWorkflowsIcon icon={RequestPageOutlined} {...props} />
);
export const GenericUpdateIcon = (props: { size?: DesignSystemSize }) => (
  <IntelligentWorkflowsIcon icon={Update} {...props} />
);
export const GenericTaskIcon = () => <IntelligentWorkflowsIcon icon={TaskTwoTone} />;
export const GenericServiceIcon = () => <IntelligentWorkflowsIcon icon={Settings} />;
