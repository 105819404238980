import React from 'react';
import { styled } from '@mui/material';

export const StyledNode = styled('div')<React.ComponentProps<'div'> & { $selected?: boolean }>(
  ({ theme: { palette }, $selected }) => ({
    borderColor: $selected ? palette.primary.color2 : palette.core.color6,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    flexDirection: 'column',
    "&[draggable='true']": {
      cursor: 'grab',
    },
    boxShadow: $selected ? `0px 0px 5px ${palette.primary.color2}` : 'none',
  })
);

export const StyledCircleNode = styled(StyledNode)({
  borderRadius: 30,
  width: 60,
  height: 60,
});

export const StyledRectNode = styled(StyledNode)({
  width: 90,
  height: 60,
});

export const StyledDoubleCircleNode = styled(StyledCircleNode)({
  borderStyle: 'double',
  borderWidth: 4,
});
