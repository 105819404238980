import { FieldMapping } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';

export type ExpressionAlias = {
  assignment: string[];
  expressionType: FieldMapping['mappingType'];
  label: string;
};

export const EXPRESSION_ALIASES: Record<string, ExpressionAlias> = {
  WorkflowSettings: {
    assignment: [
      'udf(`urn:verticeone:vertice::services:udf/saas/account/workflow/approvers`, {offer: finalOffer, owner: udf(`urn:verticeone:vertice::services:udf/core/request/owner`, {requestId: requestId})})',
    ],
    expressionType: 'JMESPathExpression',
    label: 'Based on Workflows preferences',
  },
};
