import { VariantProperties } from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import { mapValues } from 'lodash';
import { getTextVariantStyle } from '@verticeone/design-system/src/components/Text';

const convertTextStyleToChartStyle = (style: VariantProperties) => {
  return mapValues(style, (value) => {
    if (typeof value !== 'string') {
      return String(value);
    }

    return value;
  });
};

export const getAxisTitleStyle = () => {
  const textVariantStyle = getTextVariantStyle({
    variant: 'button',
    size: 'S',
  });

  return {
    ...convertTextStyleToChartStyle(textVariantStyle),
    textOverflow: 'ellipsis',
  };
};
