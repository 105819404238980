import { FC } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Dialog } from '@verticeone/design-system/src/components/Dialog';
import Text from '@verticeone/design-system/src/components/Text';
import Divider from '@verticeone/design-system/src/components/Divider';
import IconButton from '@verticeone/design-system/src/components/IconButton';

import { TaskRow } from '../types';
import { TaskModalContent } from './TaskModalContent';
import { TaskIcon } from '@vertice/core/src/modules/intelligentWorkflows/sharedVisualStyle/taskStyle';
import { TaskModalActions } from './TaskModalActions';
import { useGetTaskOverviewQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { TaskContextProvider } from './TaskContext';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';
import { useLoggedUser } from '@verticeone/auth/src';
import { resolveTaskNodeThumbnailConfiguration } from '../../workflowSchema/model/resolveTaskNodeThumbnailConfiguration';

type TaskModalProps = {
  taskRow?: TaskRow;
  onClose: () => void;
};

const MODAL_WIDTH = 680;

export const TaskModal: FC<TaskModalProps> = ({ taskRow, onClose }) => {
  const { t } = useTranslation();
  const { userId } = useLoggedUser();
  const { accountId } = useAccountContext();

  const thumbnail = resolveTaskNodeThumbnailConfiguration(taskRow?.configurations!);

  const { data: taskOverview } = useGetTaskOverviewQuery({ accountId, taskId: taskRow?.id! }, { skip: !taskRow });

  if (taskRow === undefined) {
    return null;
  }

  return (
    <TaskContextProvider task={taskRow} taskOverview={taskOverview}>
      <Dialog open={true} size="S" width={MODAL_WIDTH} fullHeight onClose={onClose}>
        <Stack minHeight={0} maxHeight="100%">
          <Stack
            gap={2}
            paddingTop={2}
            paddingBottom={6}
            paddingX={3}
            direction="row"
            justifyContent="space-between"
            {...testProps('taskModalHeading')}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <TaskIcon
                userId={userId}
                status={taskRow.status}
                type={thumbnail.type}
                id={thumbnail.id}
                size="M"
                assigneeIds={taskRow.assignees.map((a) => a.id)}
              />
              <Text variant="heading" size="S">
                {t('ENTITIES.WORKFLOW_TASK.NAME')}
              </Text>
            </Stack>
            <IconButton size="M" variant="outline" icon={CloseOutlined} onClick={onClose}></IconButton>
          </Stack>
          <Divider />
          <Stack minHeight={0} overflow="auto" gap={6} paddingTop={6}>
            <TaskModalContent />
            <Divider />
            <TaskModalActions key={taskRow.id} />
          </Stack>
        </Stack>
      </Dialog>
    </TaskContextProvider>
  );
};
