import React, { FC } from 'react';
import { Dialog, DialogActions, DialogHeader } from '@verticeone/design-system/src/components/Dialog';
import { DialogContent, Stack } from '@mui/material';
import Button from '@verticeone/design-system/src/components/Button';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { z } from 'zod';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import Grid from '@verticeone/design-system/src/components/Grid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { FormSchemaProvider } from '@vertice/core/src/modules/forms/schema/FormSchemaContext';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import { Request, useUpdateRequestsOwnerMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useSnackbar } from 'notistack';
import { RequestOwnerSelectFormEntry } from '@vertice/core/src/modules/intelligentWorkflows/components/formFields/RequestOwnerSelectFormEntry';
import { COMMON_BUTTON_PROPS } from '../../../constants';
import Divider from '@verticeone/design-system/src/components/Divider';

const TRANSLATIONS_KEY_PREFIX = 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.FORMS.CHANGE_OWNER';

const formSchema = z.object({
  requestOwner: z.string(),
});
type FormData = z.infer<typeof formSchema>;

export const AssignRequestOwnerFormEntry = createTypedFormEntry<FormData>();

type AssignRequestOwnerDialogProps = {
  request: Request;
  isOpened: boolean;
  onClose: () => void;
};

export const AssignRequestOwnerDialog: FC<AssignRequestOwnerDialogProps> = ({ request, isOpened, onClose }) => {
  const { t } = useTranslation(undefined, { keyPrefix: TRANSLATIONS_KEY_PREFIX });
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const [updateRequestOwner] = useUpdateRequestsOwnerMutation();

  // We need to pass an empty error map to zodResolver, because we don't want to show any error messages
  const schemaOptions = { errorMap: () => ({ message: '' }) };
  const formMethods = useForm({
    mode: 'all', // Validate on change, blur, submit
    resolver: zodResolver(formSchema, schemaOptions),
    defaultValues: {
      requestOwner: request.owner || null,
    },
  });
  const goodFormMethods = useGoodFormUtils(formMethods);
  const handleClose = () => {
    onClose();
    formMethods.reset();
  };

  const handleSubmit = async ({ requestOwner }: { requestOwner: string | null }) => {
    try {
      await updateRequestOwner({
        accountId: accountId,
        updateRequestsOwner: {
          owner: requestOwner!,
          requestRefs: [request.ref],
        },
      }).unwrap();
      enqueueSnackbar(t('MESSAGES.SUCCESS'), { variant: 'success' });
      handleClose();
    } catch (e) {
      enqueueSnackbar(t('MESSAGES.FAILURE'), { variant: 'error' });
    }
  };

  return (
    <Dialog size="S" open={isOpened} align="center">
      <DialogHeader>Assign an Owner for this Request</DialogHeader>
      <FormSchemaProvider value={formSchema ?? null}>
        <FormProvider {...formMethods}>
          <form onSubmit={goodFormMethods.handleSubmit(handleSubmit)}>
            <DialogContent>
              <Stack gap={6}>
                <Divider />
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <AssignRequestOwnerFormEntry
                      name="requestOwner"
                      label={t('LABELS.OWNER')}
                      width={12}
                      component={RequestOwnerSelectFormEntry}
                      componentProps={{}}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outline" {...COMMON_BUTTON_PROPS}>
                {t('ACTIONS.CANCEL')}
              </Button>
              <SubmitButton />
            </DialogActions>
          </form>
        </FormProvider>
      </FormSchemaProvider>
    </Dialog>
  );
};

// We keep this as a separate component, because it subscribes to the whole formState and we don't want the root
// useForm to subscribe to it (it would cause re-renders of the whole form)
const SubmitButton = () => {
  const { t } = useTranslation(undefined, { keyPrefix: TRANSLATIONS_KEY_PREFIX });
  const formState = useFormState();
  const submitButtonProps = getPropsForSubmitButton(formState, { disableWhenFormUntouched: false });
  return (
    <Button type="submit" variant="solid" {...COMMON_BUTTON_PROPS} {...submitButtonProps}>
      {t('ACTIONS.SUBMIT')}
    </Button>
  );
};
