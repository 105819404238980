import {
  ActivityHookConfiguration,
  TaskAssignmentConfiguration,
  TaskConfiguration,
  TaskIOMappingConfiguration,
} from '../../definitionsTypes';

type Configuration =
  | TaskConfiguration
  | TaskAssignmentConfiguration
  | ActivityHookConfiguration
  | TaskIOMappingConfiguration;

const isServiceDefinition = (config: Configuration): config is TaskConfiguration => config.kind === 'Vertice:Service';

const isFormDefinition = (config: Configuration): config is TaskConfiguration => config.kind === 'Vertice:Form';

export const findResourceUrnFromConfigurations = (configurations?: Configuration[]) => {
  if (!configurations) return null;

  const formConfig = configurations.find(isFormDefinition);
  const serviceConfig = configurations.find(isServiceDefinition);

  if (formConfig) {
    return formConfig.formUrn as string;
  }

  if (serviceConfig) {
    return serviceConfig.resourceUrn as string;
  }

  return null;
};
