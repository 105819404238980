import { FC, useCallback, useEffect, useState } from 'react';
import {
  NotificationSettingsItem,
  useListSlackChannelsPaginatedQuery,
  useUpdateSlackIntegrationConfigMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { isValidIntegrationResponse } from '../../../common';
import { ChannelSettingsFormData, ChannelSetupRenewal } from './ChannelSetupRenewal';
import { Box, styled } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { useTranslation } from 'react-i18next';
import { ChannelSetupCreation } from './ChannelSetupCreation';

const CardSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.global.color.coolGray[10].main,
  textTransform: 'uppercase',
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
}));

type ChannelSetupWrapperProps = {
  notificationSettings?: { [p: string]: NotificationSettingsItem[] };
};

export type SaveSettingsData = {
  renewal?: ChannelSettingsFormData;
  creation?: ChannelSettingsFormData;
};

export type SaveSettingsDataSections = keyof SaveSettingsData;

export const DEFAULT_CHANNEL_RENEWAL_SETTINGS = {
  channelId: '',
  timeThreshold: 'P3M',
};
export const DEFAULT_CHANNEL_CREATION_SETTINGS = {
  channelId: '',
};

export const ChannelSetupWrapper: FC<ChannelSetupWrapperProps> = ({ notificationSettings }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const [loadingSection, setLoadingSection] = useState<SaveSettingsDataSections>();

  const [saveNotificationSettings] = useUpdateSlackIntegrationConfigMutation();

  const { data: slackChannelsData, isLoading: isLoadingSlackChannels } = useListSlackChannelsPaginatedQuery({
    accountId,
  });

  const [channelRenewalSettings, setChannelRenewalSettings] = useState<NotificationSettingsItem>();
  const [channelCreationSettings, setChannelCreationSettings] = useState<NotificationSettingsItem>();

  useEffect(() => {
    if (notificationSettings?.contractRenewal) {
      setChannelRenewalSettings(notificationSettings?.contractRenewal?.[0]);
    }
    if (notificationSettings?.contractCreation) {
      setChannelCreationSettings(notificationSettings?.contractCreation?.[0]);
    }
  }, [notificationSettings]);

  const onSave = useCallback(
    async (formData: SaveSettingsData) => {
      setLoadingSection(Object.keys(formData)[0] as SaveSettingsDataSections);

      const renewal = formData.renewal
        ? formData.renewal.channelId
          ? formData.renewal
          : undefined
        : channelRenewalSettings;

      const creation = formData.creation
        ? formData.creation.channelId
          ? formData.creation
          : undefined
        : channelCreationSettings;

      const response = await saveNotificationSettings({
        accountId: accountId,
        body: {
          notificationSettings: {
            contractRenewal: renewal
              ? [
                  {
                    channelId: renewal.channelId,
                    timeThreshold: renewal.timeThreshold,
                  },
                ]
              : [],
            contractCreation: creation
              ? [
                  {
                    channelId: creation.channelId,
                    timeThreshold: creation.timeThreshold,
                  },
                ]
              : [],
          },
        },
      });

      if (isValidIntegrationResponse(response) && response.data?.notificationSettings) {
        const settingsResponse = response.data?.notificationSettings;
        // Update only data which were posted
        if (formData.renewal && settingsResponse.contractRenewal?.[0]) {
          setChannelRenewalSettings(settingsResponse.contractRenewal?.[0]);
        }
        if (formData.creation && settingsResponse.contractCreation?.[0]) {
          setChannelCreationSettings(settingsResponse.contractCreation?.[0]);
        }
      }
      setLoadingSection(undefined);
    },
    [accountId, saveNotificationSettings, channelCreationSettings, channelRenewalSettings]
  );

  return (
    <>
      <CardSection>
        <Text color="text2" variant="label" size="XS">
          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNEL_RENEWAL.SECTION_RENEWAL.TITLE')}
        </Text>
      </CardSection>
      <ChannelSetupRenewal
        channels={slackChannelsData?.items || []}
        channelSettings={channelRenewalSettings || DEFAULT_CHANNEL_RENEWAL_SETTINGS}
        onSave={onSave}
        savingSection={loadingSection}
        loadingChannels={isLoadingSlackChannels}
      />
      <CardSection>
        <Text color="text2" variant="label" size="XS">
          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNEL_RENEWAL.SECTION_CREATION.TITLE')}
        </Text>
      </CardSection>
      <ChannelSetupCreation
        channels={slackChannelsData?.items || []}
        channelSettings={channelCreationSettings || DEFAULT_CHANNEL_CREATION_SETTINGS}
        onSave={onSave}
        savingSection={loadingSection}
        loadingChannels={isLoadingSlackChannels}
      />
    </>
  );
};
