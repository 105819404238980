import { FC } from 'react';
import { Box, useTheme } from '@mui/material';

import styled from '@mui/material/styles/styled';

import Text from '../../Text';
import { DesignSystemSize } from '../../../types';
import { baseSizes } from '../../../guidelines/Sizing/sizings';
import { VerticeVIcon } from '../../../assets';

const LogoWrapper = styled('div')<{ size: DesignSystemSize }>(({ size }) => ({
  display: 'inline-block',
  [`& svg`]: {
    position: 'relative',
    top: `calc(${baseSizes[size]} / 10)`,
    width: baseSizes[size],
    height: baseSizes[size],
  },
}));

type TextMentionProps = {
  label: string;
  managed?: boolean;
  size?: DesignSystemSize;
  logoSize?: DesignSystemSize;
};
const TextMention: FC<TextMentionProps> = ({ label, managed, size = 'S', logoSize = 'XS' }) => {
  const { palette } = useTheme();

  return (
    <Box display="inline">
      {managed && (
        <LogoWrapper size={logoSize}>
          <VerticeVIcon />
        </LogoWrapper>
      )}
      <Text size={size} whiteSpace="break-spaces" variant="body-bold" color={palette.secondary.color1}>
        {label}
      </Text>
    </Box>
  );
};

export default TextMention;
