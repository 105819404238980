import { SortOutlined as SortOutlinedIcon } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import IconWrapper from '../../IconWrapper/IconWrapper';
import { useDataGridContext } from '../DataGridContext';

export const DescendingIcon = () => {
  const { palette } = useTheme();
  const { headerSize } = useDataGridContext();

  return <IconWrapper icon={SortOutlinedIcon} size={headerSize} htmlColor={palette.neutral.color1} />;
};
