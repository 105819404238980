import React, { useCallback, useEffect, useState } from 'react';
import type { MultiValue, SingleValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { range } from 'lodash';
import { useLazyAnalyticsTagValuesQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

import FilterItem from './FilterItem';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';
import { TableRow } from './useTagsData';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getTableData } from '../../../../../../modules/cloud/utils/graphDataUtils';

type Option = {
  label: string;
  value: string;
  count?: number;
};

type FilterProps = {
  data?: TableRow[];
};

const LIMIT = 1000;

const Filter = ({ data }: FilterProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.TAGS.FILTER' });
  const { accountId } = useAccountContext();
  const { filter, setFilter, period } = useCloudAnalytics();
  const [getTagValues] = useLazyAnalyticsTagValuesQuery();
  const [tagValuesToSelect, setTagValuesToSelect] = useState<{
    isLoading: boolean;
    values: Array<string>;
  }>({ isLoading: false, values: [] });

  const selectedTag = filter.tagName as string;
  const tagNames = data!.map(({ tag_name, tag_count }) => ({ label: tag_name, value: tag_name, count: tag_count }));

  const getTagValuesByTagName = useCallback(
    async (tagName: string, tagCount: number): Promise<Array<string>> => {
      return new Promise(async (resolve) => {
        const promises = await Promise.all(
          range(Math.ceil(tagCount / LIMIT)).map((offset) =>
            getTagValues({
              accountId,
              ...period,
              tagName,
              offset: `${offset * LIMIT}::integer`,
              limit: `${LIMIT}::integer`,
              responseLimit: LIMIT,
            })
          )
        );

        if (
          promises
            .map((res) => res?.data?.athenaViewQuery?.__typename)
            .some((typename) => typename === 'DeferredQueryResult')
        ) {
          setTimeout(() => {
            resolve(getTagValuesByTagName(tagName, tagCount));
          }, 3000);
        } else {
          const values: Array<string> = promises
            .map((res) => {
              if (res?.data?.athenaViewQuery?.__typename === 'DataTableResult' && res.data.athenaViewQuery.table) {
                return getTableData(res.data.athenaViewQuery.table);
              }
              return [];
            })
            .flat()
            .map((tag: any) => tag.tag_value);

          resolve(values);
        }
      });
    },
    [accountId, getTagValues, period]
  );

  const setFilterData = useCallback(
    (tagName: string, tagCount: number) => {
      setFilter('tagName', tagName);
      setFilter('tagCount', tagCount);
      setFilter('tagValues', []);
      setTagValuesToSelect({ isLoading: true, values: [] });
      void getTagValuesByTagName(tagName, tagCount).then((values) =>
        setTagValuesToSelect({
          isLoading: false,
          values,
        })
      );
    },
    [getTagValuesByTagName, setFilter]
  );

  useEffect(() => {
    if (!selectedTag && tagNames.length > 0) {
      setFilterData(tagNames[0].value, tagNames[0].count);
    }
  }, [selectedTag, setFilterData, tagNames]);

  return (
    <Stack direction="row" gap={4} alignItems="center">
      <FilterItem
        id="tag"
        minWidth={200}
        label={t('GROUP_BY.LABEL')}
        placeholder={t('GROUP_BY.PLACEHOLDER')}
        isMulti={false}
        options={tagNames}
        selectedItem={selectedTag}
        onChange={(option: SingleValue<Option>) => {
          setFilterData(option?.value as string, option?.count as number);
        }}
      />
      <FilterItem<true>
        id="tagValues"
        minWidth={200}
        label={t('FILTER.LABEL')}
        placeholder={!selectedTag ? t('FILTER.NA') : t('FILTER.PLACEHOLDER')}
        isDisabled={!selectedTag}
        isLoading={tagValuesToSelect.isLoading}
        options={tagValuesToSelect.values.map((tag) => ({ label: tag, value: tag }))}
        isMulti={true}
        selectedItem={filter.tagValues as Array<string>}
        onChange={(option: MultiValue<Option>) => {
          setFilter(
            'tagValues',
            option?.map((item) => item.value)
          );
        }}
      />
    </Stack>
  );
};

export default Filter;
