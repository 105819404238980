import { ProductBenchmarkHistogramItem, RangePricingBenchmark, TierPricingBenchmark } from '@vertice/slices';
import { SeriesWordcloudOptions } from 'highcharts';
import { keyBy } from 'lodash';
import { categories } from './constants';

export const getNumerosityValuesForAllCategories = (data: ProductBenchmarkHistogramItem[]) => {
  const dataByLabel = keyBy(data, 'label');
  const relevantCategories = getRelevantCategories(data);
  return relevantCategories.map((category) => dataByLabel[category]?.value ?? null);
};

export const getBenchmarkingValuesForAllCategories = (ranges: RangePricingBenchmark[], multiplier = 1) => {
  const rangeMap = keyBy(ranges, 'label');
  const relevantCategories = getRelevantCategories(ranges);
  return relevantCategories.map((category) => {
    const range = rangeMap[category];
    if (range) {
      return [range.priceLow * multiplier, range.priceMedian * multiplier, range.priceHigh * multiplier];
    }

    return [null, null, null];
  });
};

export const getSeries = (tiers: TierPricingBenchmark[], availableColors: string[], multiplier = 1) => {
  return tiers.map(
    (tier, index) =>
      ({
        name: tier.label,
        color: availableColors.length ? availableColors[index % availableColors.length] : undefined,
        data: getBenchmarkingValuesForAllCategories(tier.ranges, multiplier),
      } as any as SeriesWordcloudOptions)
  );
};

const getRelevantCategories = (data: ProductBenchmarkHistogramItem[] | RangePricingBenchmark[]): string[] => {
  const responseCategories = data.map((x) => x.label);
  return categories.filter((value) => responseCategories.includes(value));
};
