import { NavigationNode, useCloudAnalytics } from '../../../../CloudAnalyticsContext';

import React from 'react';
import CloudAccountServicesGraph from './CloudAccountServicesGraph';
import CloudAccountServicesUsageGraph from './CloudAccountServicesUsageGraph';
import CloudAccountsGraph from './CloudAccountsGraph';
import { useParams } from 'react-router-dom';

const GraphByLastNode = () => {
  const { node, getLastNode } = useCloudAnalytics();
  const lastNode = getLastNode();
  const { activeTab } = useParams();
  const activeTabId = activeTab as keyof NavigationNode;

  switch (lastNode.type) {
    case 'account':
      return <CloudAccountServicesGraph linkedAccountId={lastNode.id} />;
    case 'service':
      return (
        <CloudAccountServicesUsageGraph
          linkedAccountId={node[activeTabId]?.children?.id || ''}
          productCode={lastNode.id}
        />
      );
    default:
      return <CloudAccountsGraph />;
  }
};

export default GraphByLastNode;
