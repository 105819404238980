import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { SlackConnection, SlackConnectionStatus } from '../../../common';

const getStatusColor = (status?: string) => {
  if (status === SlackConnectionStatus.ACTIVE) {
    return 'success';
  }
  return 'error';
};

const getStatusText = (t: TFunction, status?: string) => {
  if (status === SlackConnectionStatus.ACTIVE) {
    return t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.LINKED');
  }
  return t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.NOT_LINKED');
};

export const StatusCell = ({ value: connectionStatus }: GridRenderCellParams<SlackConnection>) => {
  const { t } = useTranslation();

  return (
    <ChipButton variant="ghost" size="S" color={getStatusColor(connectionStatus)} isActive={false}>
      {getStatusText(t, connectionStatus)}
    </ChipButton>
  );
};
