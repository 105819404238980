import { z } from 'zod';
import { t } from 'i18next';

import { SecurityQuestionAnswerSchema } from '../shared/schemas';
import { email, nonEmptyString } from '../../../../../../../utils/validation/sharedSchemas';

export const zodSchema = z.object({
  vendorWebsite: nonEmptyString,
  vendorContact: z.object({
    name: nonEmptyString,
    email: email(t),
  }),
  dataSharedWithVendor: SecurityQuestionAnswerSchema,
  dataSharingDetails: z.object({
    customerPiiData: SecurityQuestionAnswerSchema,
    customerAccountAuthData: SecurityQuestionAnswerSchema,
    employeeContacts: SecurityQuestionAnswerSchema,
    employeeSocialSecurityNumbers: SecurityQuestionAnswerSchema,
    employeeBankAccounts: SecurityQuestionAnswerSchema,
    acquisitionPlans: SecurityQuestionAnswerSchema,
    sourceCode: SecurityQuestionAnswerSchema,
    publishedResearch: SecurityQuestionAnswerSchema,
    pressReleases: SecurityQuestionAnswerSchema,
    accountingData: SecurityQuestionAnswerSchema,
    customerCreditCardData: SecurityQuestionAnswerSchema,
    customerMedicalRecords: SecurityQuestionAnswerSchema,
  }),
  additionalIntegration: SecurityQuestionAnswerSchema,
  additionalNotes: z.string().optional(),
});

export type FormData = z.infer<typeof zodSchema>;
