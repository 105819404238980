import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel';
import { DesignSystemSize } from '../../types';

export const sortingOrder: GridSortDirection[] = ['desc', 'asc'];

export const HEADER_SIZES: Record<
  DesignSystemSize,
  {
    size: DesignSystemSize;
    gap: string;
  }
> = {
  XL: {
    size: 'S',
    gap: '10px',
  },
  L: {
    size: 'S',
    gap: '9px',
  },
  M: {
    size: 'XS',
    gap: '8px',
  },
  S: {
    size: 'XS',
    gap: '7px',
  },
  XS: {
    size: 'XXS',
    gap: '6px',
  },
  XXS: {
    size: 'XXS',
    gap: '5px',
  },
};
