import { useCallback } from 'react';
import { isNil } from 'lodash';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';

const useFormatContractCurrency = (contract: Contract) => {
  const currency = contract.parts.contractual?.financial?.baseCurrency || DEFAULT_CURRENCY;
  const formatCurrency = useFormatCurrency();

  return useCallback(
    (value?: string | number) => {
      const numericValue = typeof value === 'string' ? parseFloat(value) : value;
      if (isNil(numericValue)) {
        return undefined;
      }

      return formatCurrency(numericValue, { currency, maximumFractionDigits: 0 });
    },
    [currency, formatCurrency]
  );
};

export default useFormatContractCurrency;
