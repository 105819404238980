import { FC } from 'react';
import { Stack, styled, css } from '@mui/material';
import { Link } from 'react-router-dom';

import { createServiceIdentifier } from '@vertice/core/src/hooks/workflows/refUtils';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Resource } from './types';
import { ServiceCardContent } from './ServiceCardContent';

export const cardCssProps = css(({ theme }) => ({
  display: 'flex',
  padding: 8,
  border: '1px solid',
  borderColor: theme.palette.core.color3,
  borderRadius: theme.spacing(3),
  textDecoration: 'none',
  color: 'unset',
  flexBasis: 362,
  flexGrow: 0,
  flexShrink: 0,
}));

const StyledLinkCard = styled(Link)(() => cardCssProps);
export const StyledStackCard = styled(Stack)(() => cardCssProps);

type ServiceCardProps = {
  service: Resource;
  inactive?: boolean;
};

export const ServiceCard: FC<ServiceCardProps> = ({ service, inactive }) => {
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  if (inactive) {
    return (
      <StyledStackCard>
        <ServiceCardContent service={service} inactive />
      </StyledStackCard>
    );
  }

  return (
    <StyledLinkCard
      to={generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
        serviceId: createServiceIdentifier(service.urn),
      })}
    >
      <ServiceCardContent service={service} />
    </StyledLinkCard>
  );
};
