import { PredefinedWidgetDef } from '../shared/types';
import { BaselineOfferSection } from './sections/BaselineOfferSection';
import { OffersSection } from './sections/OffersSection';
import { FinalOfferSection } from './sections/FinalOfferSection';

export const baselineOfferWidgetDef: PredefinedWidgetDef = {
  urnSuffix: /^widget\/saas\/offers\/baseline\/v\d+$/,
  component: BaselineOfferSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.BASELINE_OFFER',
  renderPredicate: (widgetData) => !!widgetData?.baselineOffer,
};

export const negotiatedOffersWidgetDef: PredefinedWidgetDef = {
  urnSuffix: /^widget\/saas\/offers\/negotiation\/v\d+$/,
  component: OffersSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.OFFERS',
  renderPredicate: (widgetData) => !!widgetData?.negotiationOffers && !!widgetData?.baselineOffer,
};

export const finalOfferWidgetDef: PredefinedWidgetDef = {
  urnSuffix: /^widget\/saas\/offers\/final\/v\d+$/,
  component: FinalOfferSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.FINAL_OFFER',
  renderPredicate: (widgetData) => !!widgetData?.finalOffer && !!widgetData?.baselineOffer,
};
