import { PredefinedFormDef } from '../shared/types';
import { SecurityApprovalForm } from './SecurityApprovalForm';
import { zodSchema, FormData } from './schema';
import { getAssessmentDefaultValues } from '../SecurityAssessmentForm/formDef';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/securityReview\/approval\/v\d+$/,
  component: SecurityApprovalForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue, taskContext) => ({
    securityApproved: getValue('securityApproved'),
    approvalNotes: getValue('approvalNotes'),
    ...getAssessmentDefaultValues(getValue, taskContext),
  }),
};
