import { useTheme } from '@mui/material';

const ConfidenceIntervalLegendIcon = () => {
  const { palette } = useTheme();

  return (
    <svg height="16" width="2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="lineAB" d="M 1 0 V 16" stroke={palette.core.color4} strokeWidth={2} strokeLinecap="round" />
    </svg>
  );
};

export default ConfidenceIntervalLegendIcon;
