import { Definitions, ProcessDefinition, TaskDefinition } from '../definitionsTypes';
import { isProcessDefinition } from '../pocWorkflowSchema';

export const updateTaskInProcessDefinition = (definitions: Definitions, task: TaskDefinition): Definitions => {
  const processDefinition = definitions.definitions.find(isProcessDefinition);

  if (!processDefinition) {
    throw new Error('No process definition found');
  }

  const newTasks: TaskDefinition[] = (processDefinition.process.tasks ?? []).map((t) => {
    if (t.task.id === task.task.id) {
      return task;
    }

    return t;
  });

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...processDefinition.process,
      tasks: newTasks,
    },
  };

  return {
    ...definitions,
    definitions: [...definitions.definitions.filter((d) => !isProcessDefinition(d)), newProcessDefinition],
  };
};
