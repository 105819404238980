import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { TabContext, TabList } from '@mui/lab';
import { IconButton, Menu, MenuItem, Stack, styled, Tab } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ConfirmationDialog } from '@vertice/components';
import { DataSourceGroup } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import Text from '@verticeone/design-system/src/components/Text';
import { isNotNil } from '@vertice/core/src/utils/validation/isNotNil';
import { useLastFetchedData } from '../hooks/useLastFetchedData';
import DiscoverySourcesCell from '../components/DiscoverySourcesCell';
import { LastFetchedInfo } from '../components/LastFetchedInfo';
import { useApplication } from './hooks/useApplication';
import { ApplicationLayout, UsersLayout } from './layouts';
import { Tabs } from './utils';
import { UsageContext } from '../Applications/UsageContext';

const StyledTabList = styled(TabList)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(8),

  '&::after': {
    display: 'flex',
    content: '""',
    borderBottom: `1px solid ${theme.palette.core.color3}`,
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },

  '.MuiTabs-indicator': {
    zIndex: 1,
  },
}));

const Usage = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const [showHideConfirmation, setShowHideConfirmation] = useState(false);
  const { application, isSuccess, isFetching, isHidden, toggleStatus, refetch } = useApplication(applicationId);

  const { activeTab, setActiveTab } = useContext(UsageContext);

  const switchApplicationStatus = (skipConfirmation: boolean) => {
    setShowHideConfirmation(!skipConfirmation);

    if (skipConfirmation) {
      void toggleStatus().then(() => refetch());
    }
  };

  useEffect(() => {
    if (!application && isSuccess) {
      navigate(routes.PAGE_NOT_FOUND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, isSuccess]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isLoading: isLoadingLastFetchedData } = useLastFetchedData(DataSourceGroup.Usage);
  const accessLogs = data?.getAlarms?.lastFetchedAccessLogs;
  const hasAccessLogs = accessLogs && accessLogs.length > 0;
  const providers = application?.sources?.map((source) => source.id).filter(isNotNil);

  if (!applicationId) {
    return null;
  }

  return (
    <Stack direction="column" sx={{ width: 1 }} p={10}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
        <Stack direction="column" gap={2}>
          <Stack direction="row">
            <Breadcrumbs variant="slash" size="S">
              <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
              <BreadcrumbLink component={Link} to={routes.APPLICATIONS.ABSOLUTE_PATH}>
                {t('SAAS.NAVBAR.APPLICATIONS')}
              </BreadcrumbLink>
              <BreadcrumbLink>{application?.name ?? ''}</BreadcrumbLink>
            </Breadcrumbs>
          </Stack>
          <Stack direction="row" gap={2}>
            <Text variant="heading" size="M">
              {t('APPLICATIONS.USAGE.SUB_HEADER')}
            </Text>
            <Text variant="heading" size="M">
              {application?.name ?? ''}
            </Text>
          </Stack>
          <Stack direction="row" gap={2}>
            <Text variant="heading" size="S">
              {application && t('APPLICATIONS.USAGE.SOURCE.SOURCE', { count: application?.sources?.length ?? 0 })}
            </Text>
            <DiscoverySourcesCell sources={application?.sources} size={24} />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={4}>
          {!isFetching && !isLoadingLastFetchedData && hasAccessLogs && (
            <LastFetchedInfo
              accessLogs={accessLogs}
              measureReportsGeneratedAt={application?.measureReports?.generatedAt}
              alarmsPerDay={data?.getAlarms?.alarmsPerDay}
              providers={providers}
            />
          )}
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          {application && !isFetching && (
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  switchApplicationStatus(isHidden);
                }}
              >
                {isHidden ? t('APPLICATIONS.USAGE.UNHIDE_FROM_LIST') : t('APPLICATIONS.USAGE.HIDE_FROM_LIST')}
              </MenuItem>
            </Menu>
          )}
        </Stack>
      </Stack>
      <TabContext value={activeTab}>
        <StyledTabList onChange={(event: SyntheticEvent, newValue: Tabs) => setActiveTab(newValue)}>
          <Tab label={t('APPLICATIONS.USAGE.APPLICATION')} value={t(Tabs.APPLICATIONS)} iconPosition="start" />
          <Tab label={t('APPLICATIONS.USAGE.USERS')} value={t(Tabs.USERS)} iconPosition="start" />
        </StyledTabList>

        {activeTab === Tabs.APPLICATIONS && <ApplicationLayout />}
        {activeTab === Tabs.USERS && <UsersLayout />}
      </TabContext>
      <ConfirmationDialog
        isOpen={showHideConfirmation}
        secondaryButtonAction={() => setShowHideConfirmation(false)}
        secondaryButtonText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CANCEL_BUTTON')}
        primaryButtonAction={() => switchApplicationStatus(true)}
        headerText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.HEADER')}
        bodyPrimaryText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CONTENT', {
          application: application?.name,
        })}
        primaryButtonText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CONFIRM_BUTTON')}
      />
    </Stack>
  );
};

export default Usage;
