import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import CssBaseline from '@verticeone/design-system/src/components/CssBaseline';
import type { Theme } from '@mui/material/styles/createTheme';
import type { CSSObject } from '@mui/styled-engine';
import Sidebar from './Sidebar/components/Sidebar';
import Navbar from './Sidebar/Navbar';
import LayoutWrapper, { useMainLayout } from './LayoutWrapper';
import { CfaAccountContextProvider } from './CfaAccountContext';
import { CustomerSuccessContextWrapper } from '@vertice/core/src/contexts/CustomerSuccessContext';
import AssumeRoleWarningBanner from '../../modules/auth/assumeRole/AssumeRoleWarningBanner';
import { NotificationWebSocketContextProvider } from '@vertice/core/src/contexts/NotificationWebSocketContext/NotificationWebSocketContext';

const sidebarLaptopWidth = 240;
const sidebarMobileWidth = 88;

type NavProps = {
  theme: Theme;
  $width: number;
};

type MainProps = {
  theme: Theme;
  $width: number;
  $overrides?: CSSObject;
};

const Nav = styled(Box)<NavProps>(({ theme, $width }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '999',
  flexShrink: '0',
  height: '100vh',
  maxHeight: '100vh',
  backgroundColor: theme.palette.core.bg,
  transition: 'width 0.3s ease-in-out',
  width: $width,
  boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '20' }),

  '.MuiDrawer-paper': {
    position: 'relative',
  },
}));

const Main = styled(Box)<MainProps>(({ theme, $width }) => ({
  display: 'flex',
  flex: '1 1',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
  transition: 'margin 0.3s ease-in-out',
  backgroundColor: theme.palette.core.color1,
  marginLeft: `${$width}px`,
  width: `calc(100% - ${$width}px)`,
}));

const LayoutContent = () => {
  const { isOpen, isSmall } = useMainLayout();
  const width = useMemo(() => (isOpen ? sidebarLaptopWidth : sidebarMobileWidth), [isOpen]);

  return (
    <>
      <CssBaseline />
      <CustomerSuccessContextWrapper>
        <Nav component="aside" aria-label="navigation" $width={width}>
          <Sidebar>
            <Navbar />
          </Sidebar>
        </Nav>
        <Main component="main" $width={isSmall ? sidebarMobileWidth : width}>
          <AssumeRoleWarningBanner />
          <Outlet />
        </Main>
      </CustomerSuccessContextWrapper>
    </>
  );
};

const MainLayout = () => (
  <CfaAccountContextProvider>
    <NotificationWebSocketContextProvider>
      <LayoutWrapper>
        <LayoutContent />
      </LayoutWrapper>
    </NotificationWebSocketContextProvider>
  </CfaAccountContextProvider>
);

export default MainLayout;
