import React from 'react';
import { Stack } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';
import { useClickOutside } from '@vertice/dashboard/src/layouts/Main/Sidebar/useClickOutside';

import CollapseButton from './CollapseButton';
import Logo from './Logo';

type SidebarProps = {
  children: React.ReactNode;
};

const StyledDrawer = styled(Drawer)({
  height: '100vh',
  overflowX: 'hidden',
  transition: 'width 300ms',
  '& > *': {
    backgroundColor: 'transparent',
    border: 'none',
  },
});

const Sidebar = ({ children }: SidebarProps) => {
  useClickOutside();
  const { isOpen, toggle } = useMainLayout();

  return (
    <>
      <CollapseButton />
      <StyledDrawer open={isOpen} variant="permanent" onClose={toggle}>
        <Logo />
        <Stack justifyContent="space-between" height="100%" overflow={'hidden'}>
          {children}
        </Stack>
      </StyledDrawer>
    </>
  );
};

export default Sidebar;
