import dayjs from 'dayjs';
import keepOnlyLocalDatePart from '@vertice/core/src/utils/dates/keepOnlyLocalDatePart';

export const SOONEST_TARGET_SIGN_DATE = keepOnlyLocalDatePart(dayjs().startOf('day'))!;

export enum SecurityScore {
  NONE = 'NONE',
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}
