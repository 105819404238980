import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Service } from './types';
import { useRouteNavigate } from '@verticeone/router/src';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { ExtractableRoute } from '@verticeone/router/src/routes/utils';

type InstanceTypePosition = {
  instanceFamily: number;
  size: number;
};

export type Tab = {
  id: Service;
  component?: () => ReactNode;
  instanceTypePosition: InstanceTypePosition;
};

interface CloudInventoryContextProps {
  showExpired: boolean;
  setShowExpired: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: Tab;
  setActiveTab: (product: Service) => void;
  tabs: Tab[];
}

const initialTab: Tab = {
  id: 'ec2',
  instanceTypePosition: { instanceFamily: 0, size: 1 },
};

const CloudInventoryContext = createContext<CloudInventoryContextProps>({
  showExpired: false,
  setShowExpired: () => {},
  activeTab: initialTab,
  setActiveTab: () => {},
  tabs: [],
});

interface CloudInventoryProviderProps {
  children: React.ReactNode;
  tabs: Tab[];
  routeConfig: {
    path: string;
    route: ExtractableRoute;
  };
}

export const getInstanceFamily = (type?: string, position: InstanceTypePosition = { instanceFamily: 0, size: 1 }) =>
  type?.split('.')?.[position.instanceFamily];

export const getInstanceSize = (type?: string, position: InstanceTypePosition = { instanceFamily: 0, size: 1 }) =>
  type?.split('.')?.[position.size];

export const CloudInventoryProvider = ({ children, tabs, routeConfig }: CloudInventoryProviderProps) => {
  const [showExpired, setShowExpired] = useState(false);
  const { navigate } = useRouteNavigate();
  const { product } = useParams<{ product: Service }>();
  const activeTabId = product?.toLowerCase();

  if (tabs.length === 0) {
    return <Navigate replace={true} to={ROUTES.CLOUD_RIO} />;
  }

  if (!activeTabId || !tabs.some((tab) => tab.id === activeTabId)) {
    return (
      <Navigate
        replace={true}
        to={generatePath(routeConfig?.path || '', { activeTab: 'inventory', product: tabs[0]?.id })}
      />
    );
  }

  const setActiveTab = (tabId: Service) => {
    if (routeConfig?.route) {
      navigate(routeConfig.route, { product: tabId });
    }
  };

  return (
    <CloudInventoryContext.Provider
      value={{
        showExpired,
        setShowExpired,
        activeTab: tabs.find((tab) => tab.id === activeTabId) || tabs[0],
        setActiveTab,
        tabs,
      }}
    >
      {children}
    </CloudInventoryContext.Provider>
  );
};

export const useCloudInventory = (): CloudInventoryContextProps => useContext(CloudInventoryContext);
