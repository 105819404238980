import { contractWorkflowsV2Api as api } from '../../api/contractWorkflowsV2API';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    offerCostModel: build.mutation<OfferCostModelApiResponse, OfferCostModelApiArg>({
      query: (queryArg) => ({ url: `/offer/costModel`, method: 'POST', body: queryArg.body }),
    }),
    getAccountConfig: build.query<GetAccountConfigApiResponse, GetAccountConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/config` }),
    }),
    setAccountConfig: build.mutation<SetAccountConfigApiResponse, SetAccountConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/config`, method: 'PUT', body: queryArg.body }),
    }),
    getDefaultWorkflow: build.query<GetDefaultWorkflowApiResponse, GetDefaultWorkflowApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/requestTypes/${queryArg.requestType}/config` }),
    }),
    setDefaultWorkflow: build.mutation<SetDefaultWorkflowApiResponse, SetDefaultWorkflowApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requestTypes/${queryArg.requestType}/config`,
        method: 'PUT',
        body: queryArg.requestTypeConfig,
      }),
    }),
    distributeDefaultWorkflow: build.mutation<DistributeDefaultWorkflowApiResponse, DistributeDefaultWorkflowApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requestTypes/${queryArg.requestType}/config/distribute`,
        method: 'POST',
      }),
    }),
    getRequest: build.query<GetRequestApiResponse, GetRequestApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}` }),
    }),
    getRequestJournal: build.query<GetRequestJournalApiResponse, GetRequestJournalApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/journal`,
        params: { maxResults: queryArg.maxResults, nextToken: queryArg.nextToken },
      }),
    }),
    listRequests: build.query<ListRequestsApiResponse, ListRequestsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    createRequest: build.mutation<CreateRequestApiResponse, CreateRequestApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        method: 'POST',
        body: queryArg.createRequest,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    terminateRequest: build.mutation<TerminateRequestApiResponse, TerminateRequestApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/terminate`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as contractWorkflowsV2ApiCodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type OfferCostModelApiResponse = /** status 200 Offer instance response */ CostModel[];
export type OfferCostModelApiArg = {
  /** Offer cost model request */
  body: any[];
};
export type GetAccountConfigApiResponse = /** status 200 Service config response */ {
  config?: AccountConfiguration;
};
export type GetAccountConfigApiArg = {
  accountId: string;
};
export type SetAccountConfigApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type SetAccountConfigApiArg = {
  accountId: string;
  /** Service config request */
  body: {
    config?: AccountConfiguration;
  };
};
export type GetDefaultWorkflowApiResponse = /** status 200 Request config response */ RequestTypeConfig;
export type GetDefaultWorkflowApiArg = {
  accountId: string;
  requestType: RequestType;
};
export type SetDefaultWorkflowApiResponse = /** status 200 Empty Response */ object;
export type SetDefaultWorkflowApiArg = {
  accountId: string;
  requestType: RequestType;
  /** Request type config request */
  requestTypeConfig: RequestTypeConfig;
};
export type DistributeDefaultWorkflowApiResponse = /** status 200 Empty Response */ object;
export type DistributeDefaultWorkflowApiArg = {
  accountId: string;
  requestType: RequestType;
};
export type GetRequestApiResponse = /** status 200 Request detail response */ {
  request?: Request;
};
export type GetRequestApiArg = {
  accountId: string;
  requestId: string;
};
export type GetRequestJournalApiResponse = /** status 200 Request journal response */ {
  items: JournalItem[];
  nextToken?: PaginationToken;
};
export type GetRequestJournalApiArg = {
  accountId: string;
  requestId: string;
  maxResults?: number;
  nextToken?: PaginationToken;
};
export type ListRequestsApiResponse = /** status 200 Request list response */ {
  items: Request[];
  nextToken?: PaginationToken;
};
export type ListRequestsApiArg = {
  accountId: string;
  nextToken?: PaginationToken;
  maxResults?: number;
};
export type CreateRequestApiResponse = /** status 201 Object reference urn */ {
  ref: string;
};
export type CreateRequestApiArg = {
  accountId: string;
  nextToken?: PaginationToken;
  maxResults?: number;
  /** Request create request */
  createRequest: CreateRequest;
};
export type TerminateRequestApiResponse = /** status 200 Empty Response */ object;
export type TerminateRequestApiArg = {
  accountId: string;
  requestId: string;
};
export type CostModel = {
  id: string;
  class: string;
  version: string;
  model: {
    annual_cost: number;
    contract_length?: number | null;
  };
};
export type Error = {
  /** Error code, should be unique */
  errorCode: string;
  /** Human readable error message, not with i18n */
  errorMessage?: string;
  errorParameters?: {
    [key: string]: any;
  };
};
export type AllPowerUsersApprovalConfig = {
  type: 'AllPowerUsersApprovalConfig';
};
export type SingleApproverApprovalConfig = {
  type: 'SingleApproverApprovalConfig';
  /** Approver user id */
  approver: string;
};
export type ThresholdBasedApprovalConfig = {
  type: 'ThresholdBasedApprovalConfig';
  approvers: {
    userId: string;
    threshold?: number;
  }[];
};
export type AccountConfiguration = {
  approvalConfiguration?: AllPowerUsersApprovalConfig | SingleApproverApprovalConfig | ThresholdBasedApprovalConfig;
};
export type RequestTypeConfig = {
  /** Default workflow id for request type. */
  workflowId?: string | null;
};
export type RequestType = 'PURCHASE' | 'RENEWAL';
export type CommonRequestInput = {
  /** Contract id */
  contractId?: string;
};
export type VendorType = 'PREDEFINED' | 'INLINE';
export type PurchaseRequestInput = {
  vendorType: VendorType;
  /** Vendor id. Should be provided if `vendorType` is `PREDEFINED`. */
  vendorId?: string | null;
  /** Vendor name. Should be provided if `vendorType` is `INLINE`. */
  vendorName?: string | null;
  [key: string]: any;
};
export type RenewalRequestInput = {
  parentContractId: string;
  [key: string]: any;
};
export type RequestContext = {
  stage?: string | null;
  [key: string]: any;
};
export type DateTime = string;
export type Request = {
  ref: string;
  name: string;
  /** Name of underlying workflow. */
  workflowName?: string | null;
  /** Id of underlying workflow instance. */
  workflowInstanceId?: string | null;
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'CANCELLED' | 'TERMINATED';
  type: RequestType;
  input: CommonRequestInput & (PurchaseRequestInput | RenewalRequestInput);
  context?: RequestContext;
  createdAt: DateTime;
  createdBy: string | null;
  closedAt?: DateTime;
};
export type JournalItem = {
  actor: string;
  intents: string[];
  delta: object;
  timestamp: DateTime;
};
export type PaginationToken = string | null;
export type CreateRequest = {
  type: RequestType;
  name: string;
  input: PurchaseRequestInput | RenewalRequestInput;
  configurations: {
    [key: string]: any;
  }[];
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useOfferCostModelMutation,
  useGetAccountConfigQuery,
  useLazyGetAccountConfigQuery,
  useSetAccountConfigMutation,
  useGetDefaultWorkflowQuery,
  useLazyGetDefaultWorkflowQuery,
  useSetDefaultWorkflowMutation,
  useDistributeDefaultWorkflowMutation,
  useGetRequestQuery,
  useLazyGetRequestQuery,
  useGetRequestJournalQuery,
  useLazyGetRequestJournalQuery,
  useListRequestsQuery,
  useLazyListRequestsQuery,
  useCreateRequestMutation,
  useTerminateRequestMutation,
} = injectedRtkApi;
