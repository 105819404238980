import { Box, Stack, styled } from '@mui/material';
import { Product } from '@vertice/slices';
import Card from '@verticeone/design-system/src/components/Card';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import Text, { EllipsisText } from '@verticeone/design-system/src/components/Text';
import Tooltip from '@verticeone/design-system/src/components/Tooltip/Tooltip';
import { createRef, useCallback, useEffect, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/src';

const StyledLink = styled(Link)(() => {
  return {
    display: 'flex',
    textDecoration: 'unset',
    color: 'unset',
    height: '100%',
    width: '100%',
  };
});

const DescriptionText = ({ children }: { children: string }) => {
  const textElementRef = createRef<HTMLElement>();
  const [disableHoverListener, setDisableHoverListener] = useState(true);

  const checkTextLength = useCallback(() => {
    const scrollHeight = textElementRef.current?.scrollHeight;
    const clientHeight = textElementRef.current?.clientHeight;
    const textOverflow = scrollHeight && clientHeight && scrollHeight > clientHeight;
    setDisableHoverListener(!textOverflow);
  }, [textElementRef]);

  // check text lenght on first render and add resize listener
  useEffect(() => {
    checkTextLength();
    window.addEventListener('resize', checkTextLength);

    return () => {
      window.removeEventListener('resize', checkTextLength);
    };
  }, [checkTextLength]);

  return (
    <Tooltip size="S" disableHoverListener={disableHoverListener} content={children}>
      <EllipsisText ref={textElementRef} variant="body-regular" size="S" color="text2" lineClamp={4}>
        {children}
      </EllipsisText>
    </Tooltip>
  );
};

export type SingleProductCardProps = {
  vendorId: string;
  product: Product;
};

const SingleProductCard = ({ vendorId, product }: SingleProductCardProps) => {
  const routes = useRoutes();

  return (
    <Card height="100%">
      <StyledLink
        to={generatePath(routes.VENDORS.VENDOR.PRODUCT.ABSOLUTE_PATH, { id: vendorId, productId: product.id ?? null })}
      >
        <Stack gap={4} p={4}>
          <Stack gap={2} flexGrow={1} maxWidth={1022}>
            <Text variant="body-bold" size="M">
              {product.name}
            </Text>
            <DescriptionText>{product.description ?? '-'}</DescriptionText>
          </Stack>
          <Box alignSelf="flex-start">
            {product.category && (
              <ChipButton variant="ghost" color="primary" size="XS" isActive={false}>
                {product.category}
              </ChipButton>
            )}
          </Box>
        </Stack>
      </StyledLink>
    </Card>
  );
};

export default SingleProductCard;
