import { PredefinedFormDef } from '../shared/types';
import { SimpleStepOnForm } from './SimpleStepOnForm';
import { FormData, zodSchema } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/core\/step-on\/simple\/v\d+$/,
  component: SimpleStepOnForm,
  schema: zodSchema,
  getDefaultValues: (getValue) => ({
    requiredAction: getValue('requiredAction'),
    requiredActionCompleted: getValue('requiredActionCompleted'),
  }),
  // we don't want to send anything as result of this form
  transformOutput: () => ({}),
};
