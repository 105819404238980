import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Select from '@verticeone/design-system/src/components/Select';
import { MultiValue } from 'react-select';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { Filter, RenewalStatus } from '../types';

type RenewalStatusFilterOption = { value: RenewalStatus; label: string };

export const RenewalStatusFilter: FC = () => {
  const { t } = useTranslation();
  const { filterValues, setFilterValue } = useContext(ContractListContext);
  const setLastRenewalStates = setFilterValue(Filter.RENEWAL_STATUS);

  const options: RenewalStatusFilterOption[] = [
    {
      value: RenewalStatus.NOT_YET_RENEWED,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.NOT_YET_RENEWED'),
    },
    {
      value: RenewalStatus.NOT_YET_RENEWED_DEADLINE_90_DAYS,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.NOT_YET_RENEWED_90_DAYS'),
    },
    {
      value: RenewalStatus.RENEWAL_IN_PROGRESS,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.RENEWAL_IN_PROGRESS'),
    },
    {
      value: RenewalStatus.RENEWAL_WITH_VERTICE_COMPLETED,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.RENEWAL_WITH_VERTICE_COMPLETED'),
    },
    {
      value: RenewalStatus.RENEWAL_OUTSIDE_VERTICE_COMPLETED,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.RENEWAL_OUTSIDE_VERTICE_COMPLETED'),
    },
    {
      value: RenewalStatus.PLANNED_FOR_EXPIRATION,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.PLANNED_FOR_EXPIRATION'),
    },
  ];

  const onChange = (selection: MultiValue<RenewalStatusFilterOption>) =>
    setLastRenewalStates(selection.length ? selection.map((option) => option.value) : undefined);

  return (
    <Stack minWidth={240}>
      <Select<RenewalStatusFilterOption, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={options}
        value={options?.filter(({ value }) => filterValues?.[Filter.RENEWAL_STATUS]?.includes(value))}
        placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.RENEWAL_STATUS')}
        onChange={onChange}
        testId={'renewal-status-filter'}
      />
    </Stack>
  );
};
