import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { BaseWorkflowRenderer, WorkflowRendererProps } from './BaseWorkflowRenderer';
import { useLoggedUser } from '@verticeone/auth/src';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { WorkflowRendererContextProvider } from './WorkflowRendererContext';
import { useRequestTasks } from '../../request/RequestDetailPage/useRequestTasks';
import { TaskModal } from '../../task/TaskModal/TaskModal';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { Definitions } from '../../definitionsTypes';

type WorkflowRendererFullProps = PropsWithChildren<
  WorkflowRendererProps & {
    requestId: string;
    allowVerticeServiceNavigation?: boolean;
    isEditor?: boolean;
    workflowDefinitions?: Definitions;
  }
>;

const WorkflowRenderer: FC<WorkflowRendererFullProps> = ({
  children,
  model,
  workflowDefinitions,
  requestId,
  isEditor = false,
  allowVerticeServiceNavigation,
}) => {
  const { usersById } = useRelevantUsersById();
  const { userId: loggedUserId } = useLoggedUser();
  const { accountId } = useAccountContext();
  const { allTasks } = useRequestTasks(requestId);

  const [modalTaskId, setModalTaskId] = useState<string | undefined>(undefined);
  const onOpenTask = (taskId: string) => {
    setModalTaskId(taskId);
  };
  const modalTask = useMemo(() => {
    return allTasks.find((row) => modalTaskId && row.id.includes(modalTaskId));
  }, [allTasks, modalTaskId]);

  return (
    <>
      <WorkflowRendererContextProvider
        value={{
          usersById,
          isEditor,
          loggedUserId,
          accountId,
          onOpenTask,
          allowVerticeServiceNavigation,
          workflowDefinitions,
        }}
      >
        <BaseWorkflowRenderer model={model}>{children}</BaseWorkflowRenderer>
      </WorkflowRendererContextProvider>
      <TaskModal onClose={() => setModalTaskId(undefined)} taskRow={modalTask} />
    </>
  );
};

export default WorkflowRenderer;
