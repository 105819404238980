import { SeriesOptionsType } from 'highcharts';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetProductBenchmarkContractLengthStatisticsQuery } from '@vertice/slices';
import { useMemo } from 'react';
import { categories } from './constants';
import { getValuesForAllCategories } from './utils';

export type UseGetMostCommonContractLengthDataProps = {
  vendorId: string;
  productId: string;
};

export type MostCommonContractLengthData = {
  median?: number;
  series: SeriesOptionsType[];
  categories: string[];
};

export type UseGetMostCommonContractLengthDataReturn = {
  data?: MostCommonContractLengthData;
  isLoading: boolean;
};

const useGetMostCommonContractLengthData = ({
  vendorId,
  productId,
}: UseGetMostCommonContractLengthDataProps): UseGetMostCommonContractLengthDataReturn => {
  const { accountId } = useAccountContext();
  const { data, isLoading } = useGetProductBenchmarkContractLengthStatisticsQuery({
    accountId,
    vendorId,
    productId,
  });

  const processedData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const series: SeriesOptionsType[] = data?.contractLengthHistogram
      ? [
          {
            type: 'column',
            name: 'length',
            data: getValuesForAllCategories(data?.contractLengthHistogram),
          },
        ]
      : [];

    return {
      median: data.contractLengthMedian,
      series,
      categories,
    };
  }, [data]);

  return {
    data: processedData,
    isLoading: isLoading || !processedData,
  };
};

export default useGetMostCommonContractLengthData;
