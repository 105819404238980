import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { FormControl } from '@mui/material';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { useTaskFormContext } from '../TaskFormContext';

type CheckItemFormEntryProps<FormDataType extends FieldValues> = {
  name: FieldPath<FormDataType>;
  label: string;
};

export const CheckItemFormEntry = <FormDataType extends FieldValues>({
  name,
  label,
}: CheckItemFormEntryProps<FormDataType>) => {
  const { readOnly } = useTaskFormContext();

  return (
    <FormControl
      variant="outlined"
      sx={{
        justifyContent: 'center',
        flex: 1,
        paddingTop: 8,
        paddingLeft: 4,
      }}
    >
      <FormChecklistItemField
        name={name}
        label={label}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        disabled={readOnly}
        sx={{
          paddingLeft: 4,
        }}
      />
    </FormControl>
  );
};
