import { useTranslation } from 'react-i18next';
import { Offer } from '../Offer';
import { useOfferItemMapping } from '../helpers/itemMapping';
import { WidgetProps } from '../../shared/types';

export const FinalOfferSection = ({ widgetData }: WidgetProps) => {
  const offer = widgetData?.finalOffer;
  const baselineOffer = widgetData?.baselineOffer;
  const { t } = useTranslation();
  const { vendor, products, concessions } = useOfferItemMapping(offer);

  const title = `${t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.FINAL_OFFER_TITLE')}: ${offer.name}`;

  return (
    <Offer
      title={title}
      vendor={vendor}
      products={products}
      concessions={concessions}
      offer={offer}
      type="final"
      baselineOffer={baselineOffer}
    />
  );
};
