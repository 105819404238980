export enum Placeholders {
  id = '__id__',
  display = '__display__',
}

export type UserSuggestion = {
  id: string;
  label: string;
  managed?: boolean;
};

export type Suggestion = {
  result: UserSuggestion;
  querySequenceStart: number;
  querySequenceEnd: number;
};

export type Segment = {
  label: string;
  key: string;
  index: number;
  indexInPlaintext: number;
} & (
  | {
      type: 'text';
    }
  | {
      markup: string;
      type: 'mention';
    }
);

export type Mentions = {
  segments: Segment[];
  plainText: string;
  rawLength: number;
};
