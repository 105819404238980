import React from 'react';
import { DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { useTheme } from '@mui/material';
import GaugeLabel from './GaugeLabel';
import { GaugeWrapper } from './GaugeWrapper';

export type DonutProps = TestProps & {
  value: number;
  maxValue?: number;
  color?: DesignSystemColor;
  size?: number;
  width?: number;
  borderColor?: string;
  borderWidth?: number;
  label?: string;
  labelSize?: DesignSystemSize;
  labelColor?: string;
};

const Donut = ({
  value,
  maxValue = 100,
  color = 'primary',
  size = 42,
  width = 4,
  borderColor,
  borderWidth = 1,
  label,
  labelSize = 'M',
  labelColor,
  testId,
}: DonutProps) => {
  const { palette } = useTheme();

  const radius = (size - width - 2 * borderWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const normalizedValue = Math.round((value * circumference) / maxValue);
  const remainingValue = circumference - normalizedValue;
  const offset = circumference / 4;

  return (
    <GaugeWrapper size={size} radius={radius} testId={testId} component="donut-gauge">
      <circle
        className="donut-border"
        fill="transparent"
        stroke={borderColor ?? palette.core.bg}
        strokeWidth={width + 2 * borderWidth}
      />
      <circle className="donut-ring" fill="transparent" stroke={palette[color].color3} strokeWidth={width} />
      {!!value && (
        <circle
          className="donut-segment-border"
          fill="transparent"
          stroke={borderColor ?? palette.core.bg}
          strokeWidth={width}
          strokeDasharray={`${normalizedValue + 2 * borderWidth} ${remainingValue - 2 * borderWidth}`}
          strokeDashoffset={offset + borderWidth}
        />
      )}
      {!!value && (
        <circle
          className="donut-segment"
          fill="transparent"
          stroke={palette[color].color2}
          strokeWidth={width}
          strokeDasharray={`${normalizedValue} ${remainingValue}`}
          strokeDashoffset={offset}
        />
      )}
      <GaugeLabel label={label ?? value} color={labelColor ?? palette[color].color1} size={labelSize} />
    </GaugeWrapper>
  );
};

export default Donut;
