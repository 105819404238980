import React, { useContext } from 'react';
import Stack from '@mui/material/Stack';
import Select, { SimpleOption } from '@verticeone/design-system/src/components/Select';
import { MultiValue } from 'react-select';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { Filter } from '../types';

type GenericMultiselectFilterProps = {
  name: Filter;
  placeholder?: string;
  testId?: string;
  options: SimpleOption[];
  minWidth?: number;
};

export const GenericMultiselectFilter = ({
  name,
  placeholder,
  testId,
  options,
  minWidth = 240,
}: GenericMultiselectFilterProps) => {
  const { filterValues, setFilterValue } = useContext(ContractListContext);
  const setLastValue = setFilterValue(name);

  const onChange = (selection: MultiValue<SimpleOption>) =>
    setLastValue(selection.length ? selection.map((option) => option.value) : undefined);

  return (
    <Stack minWidth={minWidth}>
      <Select<SimpleOption, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={options}
        value={options?.filter(({ value }) => {
          const selectedValue = filterValues?.[name];
          return selectedValue ? (selectedValue as string[]).includes(value) : undefined;
        })}
        placeholder={placeholder}
        onChange={onChange}
        testId={testId}
      />
    </Stack>
  );
};
