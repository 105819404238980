import { FieldPath, FieldValues } from 'react-hook-form';
import FormConcessionsField from '@vertice/core/src/modules/forms/fields/FormConcessionsField';
import { useTaskFormContext } from '../TaskFormContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

type OfferFormConcessionsListProps<FormDataType extends FieldValues> = {
  name: FieldPath<FormDataType>;
  currency: string;
};

export const OfferFormConcessionsList = <FormDataType extends FieldValues>({
  name,
  currency,
}: OfferFormConcessionsListProps<FormDataType>) => {
  const { readOnly } = useTaskFormContext();

  return (
    <FormConcessionsField
      name={name}
      id={name}
      editable={!readOnly}
      currency={currency}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
    />
  );
};
