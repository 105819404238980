import React, { FC } from 'react';
import { InitiateRequestBetaDialog } from '@vertice/dashboard/src/modules/intelligentWorkflows/request/InitiateRequestBetaDialog/InitiateRequestBetaDialog';
import { generatePath } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/src';
import { useCreatePurchaseRequest } from './useCreatePurchaseRequest';

type IWPurchaseRequestBetaModalProps = {
  vendorId: string;
  vendorName?: string;
  isOpened: boolean;
  onClose: () => void;
};

// Interim modal for beta customers of intelligent workflows
export const InitiatePurchaseRequestBetaDialog: FC<IWPurchaseRequestBetaModalProps> = ({
  isOpened,
  onClose,
  vendorId,
  vendorName,
}) => {
  const routes = useRoutes();
  const {
    createPurchaseRequest,
    isLoading: isCreatingIWPurchaseRequest,
    legacyIntakeForm,
  } = useCreatePurchaseRequest();

  const { isLoading: isIntakeFormLoading } = legacyIntakeForm;

  const handleCreateIWPurchaseRequestClick = async () => {
    await createPurchaseRequest(vendorId, vendorName);
  };

  return (
    <InitiateRequestBetaDialog
      isOpened={isOpened}
      onClose={onClose}
      isLoading={isCreatingIWPurchaseRequest}
      isDisabled={isIntakeFormLoading}
      requestType="PURCHASE"
      legacyRequestLinkRoute={generatePath(routes.VENDORS.VENDOR.NEW_PURCHASE.ABSOLUTE_PATH, { id: vendorId })}
      onIWRequestClick={handleCreateIWPurchaseRequestClick}
    />
  );
};
