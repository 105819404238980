import { Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import Text from '@verticeone/design-system/src/components/Text';
import { UserIdentity } from '@vertice/slices';
import { getConnectedUser, SlackConnection, SlackConnectionStatus } from '../../../common';

type VerticeUserCellProps = { connectedUsers?: UserIdentity[] } & GridRenderCellParams<SlackConnection>;

export const VerticeUserCell = ({
  connectedUsers,
  row: { userId, userName, firstName, lastName },
}: VerticeUserCellProps) => {
  const isUserConnected = getConnectedUser(userId, connectedUsers)?.status === SlackConnectionStatus.ACTIVE;

  return (
    <Stack>
      <Text variant="body-regular" size="M" color="text1">
        {userName}
      </Text>
      <Text variant="caption" size="XS" color={isUserConnected ? 'text3' : 'error1'}>
        {`${firstName} ${lastName}`}
      </Text>
    </Stack>
  );
};
