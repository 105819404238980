import { useOnSelectionChange, useReactFlow, type OnSelectionChangeFunc, useStoreApi, Node } from '@xyflow/react';
import { useCallback, useState } from 'react';
import { DRAWER_WIDTH, TRANSITION_DURATION } from './constants';
import isEqual from 'lodash/isEqual';

type UseWorkflowViewerStateProps = {
  onNodeSelected: (nodeId?: string) => void;
  checkChangeSelectionAllowed: () => Promise<boolean>;
};

export const useWorkflowViewerState = ({
  onNodeSelected,
  checkChangeSelectionAllowed,
}: UseWorkflowViewerStateProps) => {
  const flow = useReactFlow();
  const flowApi = useStoreApi();

  const [selectedNodes, setSelectedNodes] = useState<Node[]>([]);

  const handleSelectionChange: OnSelectionChangeFunc = useCallback(
    async ({ nodes: changedNodes }) => {
      if (isEqual(changedNodes, selectedNodes)) {
        return;
      }
      const changeAllowed = await checkChangeSelectionAllowed();

      // if change is not allowed then reset to last selection
      if (!changeAllowed) {
        flowApi.setState((s) => {
          s.resetSelectedElements();
          s.addSelectedNodes(selectedNodes.map((node) => node.id));
          return s;
        });
        return;
      }

      setSelectedNodes(changedNodes);

      const node = changedNodes[0];
      if (!node?.id) {
        return onNodeSelected(undefined);
      }

      onNodeSelected(node.id);

      // center and zoom the selected node
      const targetZoom = Math.max(flow.getZoom(), 0.7);
      const x = node.position.x + (node?.measured?.width ?? 0) / 2 + DRAWER_WIDTH / 2 / targetZoom;
      const y = node.position.y + (node?.measured?.height ?? 0) / 2;
      void flow.setCenter(x, y, { zoom: targetZoom, duration: TRANSITION_DURATION });
    },
    [checkChangeSelectionAllowed, flow, flowApi, onNodeSelected, selectedNodes]
  );

  const unselectNodes = useCallback(() => {
    flow.setNodes((n) => n.map((node) => ({ ...node, selected: false })));
  }, [flow]);

  useOnSelectionChange({
    onChange: handleSelectionChange,
  });

  return {
    unselectNodes,
  };
};
