import {
  FlowDefinition,
  Process,
  ProcessDefinition,
  TaskConfiguration,
} from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import {
  WorkflowModel,
  WorkflowStartNode,
  WorkflowEndNode,
  WorkflowTaskNode,
  WorkflowEdge,
  WorkflowGatewayNode,
  WorkflowState,
} from './types';
import { resolveTaskNodeType } from './resolveTaskNodeType';
import { resolveTaskNodeThumbnailConfiguration } from './resolveTaskNodeThumbnailConfiguration';
import { findResourceUrnFromConfigurations } from './configurationUtils';
import { ServicesThumbnails } from '../WorkflowViewer/useServicesThumbnails';

const convertStartNodes = (startNodes: Process['start'], state?: WorkflowState): WorkflowStartNode[] =>
  startNodes.map(({ start }) => ({
    id: start.id,
    name: start.name,
    kind: 'start',
    parentRequest: state?.parentRequest,
  }));

const convertEndNodes = (endNodes: Process['end']): WorkflowEndNode[] =>
  endNodes.map(({ end }) => ({
    id: end.id,
    name: end.name,
    kind: 'end',
  }));

const convertTaskNodes = (
  taskNodes: Process['tasks'],
  state?: WorkflowState,
  serviceCatalogItems?: ServicesThumbnails
): WorkflowTaskNode[] =>
  taskNodes?.map(({ task }) => {
    const resourceUrn = findResourceUrnFromConfigurations(task.configurations);
    const resourceId = resourceUrn?.slice(resourceUrn.indexOf('/') + 1);
    const serviceThumbnail = resourceId ? serviceCatalogItems?.[resourceId] : null;

    const taskThumbnail = resolveTaskNodeThumbnailConfiguration(task.configurations as TaskConfiguration[]);
    const isTaskThumbnailFallback = taskThumbnail.id === '' || taskThumbnail.type === '';

    return {
      id: task.id,
      name: task.name,
      kind: 'task',
      state: state?.tasks[task.id],
      type: resolveTaskNodeType(state?.tasks[task.id], task.configurations as TaskConfiguration[]),
      requestRoute: state?.requestRoute,
      thumbnail: isTaskThumbnailFallback && serviceThumbnail ? serviceThumbnail : taskThumbnail,
    };
  }) || [];

const convertGatewayNodes = (gatewayNodes: Process['gateways']): WorkflowGatewayNode[] =>
  gatewayNodes?.map(({ gateway }) => ({
    id: gateway.id,
    name: gateway.name,
    kind: 'gateway',
  })) || [];

const convertEdges = (flow?: FlowDefinition): WorkflowEdge[] => {
  return flow?.flow.edges?.map(({ edge }) => edge) || [];
};

type ProcessDefinitionToModel = {
  processDefinition: ProcessDefinition;
  workflowState?: WorkflowState;
  servicesThumbnails?: ServicesThumbnails;
};

export const processDefinitionToModel = ({
  processDefinition,
  workflowState,
  servicesThumbnails,
}: ProcessDefinitionToModel): WorkflowModel => {
  const processDefinitionKind: ProcessDefinition['kind'] = 'ProcessEngine:ProcessDefinition';

  if (processDefinition.kind !== processDefinitionKind) {
    throw new Error(`Invalid process definition kind: ${processDefinition.kind}`);
  }

  return {
    nodes: [
      ...convertStartNodes(processDefinition.process.start, workflowState),
      ...convertEndNodes(processDefinition.process.end),
      ...convertTaskNodes(processDefinition.process.tasks, workflowState, servicesThumbnails),
      ...convertGatewayNodes(processDefinition.process.gateways),
    ],
    edges: convertEdges(processDefinition.process.flow),
  };
};
