import { range } from 'lodash';

export const getTickPositions = (spend: number, target: number): number[] => {
  const maxSteps = 12;
  const stepsArray = [
    { limit: 550000, step: 100000 },
    { limit: 2000000, step: 250000 },
    { limit: 10000000, step: 500000 },
    { limit: 50000000, step: 1000000 },
    { limit: Infinity, step: 10000000 },
  ];

  const maxTickPosition = Math.max(spend, target);

  const step = stepsArray.find(({ limit }) => maxTickPosition < limit)!.step;
  const end = Math.ceil(maxTickPosition / step) * step;

  let positions = range(0, end + step, step);

  // Adjust step size if positions exceed maxSteps
  if (positions.length > maxSteps) {
    const newStep = Math.ceil(maxTickPosition / maxSteps);
    const newEnd = Math.ceil(maxTickPosition / newStep) * newStep;
    positions = range(0, newEnd + newStep, newStep);
  }

  // Find the closest point to target
  const closestPoint = positions.reduce((prev, curr) =>
    Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
  );

  // Remove the closest point
  positions = positions.filter((pos) => pos !== closestPoint);

  if (!positions.includes(target)) {
    positions.push(target);
    positions.sort((a, b) => a - b);
  }

  return positions;
};
