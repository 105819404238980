import type { Theme } from '@mui/material/styles';
import type { ChangeEventHandler } from 'react';
import type { DesignSystemColor, DesignSystemSize, DesignSystemVariant } from '../../../types';
import { TextareaAutosize } from '@mui/material';
import { TextVariants } from '../../Text/utils';

export type BaseTextFieldAreaProps<T extends React.ElementType = 'textarea'> = {
  value?: string;
  label?: string;
  onChange?: ChangeEventHandler<any>;
  textLimit?: number;
  size: DesignSystemSize;
  variant: Exclude<DesignSystemVariant, 'plain'>;
  textVariant?: Exclude<TextVariants, 'code' | 'display'>;
  color: DesignSystemColor;
  minHeight?: number;
  disabled?: boolean;
  hasError?: boolean;
  autosize?: boolean;
} & Omit<React.ComponentPropsWithoutRef<T>, 'children' | 'onChange' | 'value'>;

export type TextFieldAreaProps =
  | (BaseTextFieldAreaProps<'textarea'> & { autosize?: false })
  | (BaseTextFieldAreaProps<typeof TextareaAutosize> & { autosize: true });

export type StyledComponentProps = {
  theme: Theme;
  $size: DesignSystemSize;
  $disabled: boolean;
  $hasError: boolean;
  $hasLabel: boolean;
  $minHeight?: number;
  $autosize?: boolean;
  $color: DesignSystemColor;
};

export const areaPadding: Record<DesignSystemSize, number> = {
  XXS: 5,
  XS: 6,
  S: 7,
  M: 8,
  L: 9,
  XL: 10,
};

export const labelSizes: Record<DesignSystemSize, DesignSystemSize> = {
  XXS: 'XXS',
  XS: 'XXS',
  S: 'XS',
  M: 'XS',
  L: 'S',
  XL: 'S',
};

export const labelContentGap: Record<DesignSystemSize, number> = {
  XXS: 0.5,
  XS: 0.5,
  S: 0.5,
  M: 1,
  L: 1,
  XL: 1,
};
