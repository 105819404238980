import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { LoadableComponent } from '@verticeone/design-system/src/components/Loader';
import { AWS_BRAND_COLOR } from '../../../../../modules/cloud/constants';
import { useEligibleSpendData } from './useEligibleSpendData';
import BreakdownChart from '../BreakdownChart';
import { ELIGIBILITY_THRESHOLD } from '../../constants';

const Layout = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_SPEND_BREAKDOWN' });
  const { palette } = useTheme();
  const { data, isFetching } = useEligibleSpendData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('TITLE')} tag="h2" />
        <CardHeaderDescription>{t('DESCRIPTION_INACTIVE')}</CardHeaderDescription>
      </CardHeader>
      <Box bgcolor={palette.core.color1} py={6}>
        <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
          {data && (
            <BreakdownChart
              data={data}
              thresholdValue={ELIGIBILITY_THRESHOLD}
              thresholdLabel={t('GRAPH.ELIGIBILITY_THRESHOLD')}
            />
          )}
        </LoadableComponent>
      </Box>
    </Card>
  );
};

export default Layout;
