import { useEffect, RefObject, useCallback, useState } from 'react';

type BaseUseScrollElementProps = {
  tolerance?: number;
};

export type UseScrollElement<T extends HTMLElement> = BaseUseScrollElementProps &
  (
    | {
        element: RefObject<T>;
        container: RefObject<T>;
        condition: boolean;
      }
    | {
        element?: undefined;
        container: RefObject<T>;
        condition: boolean;
      }
  );

/**
 * useScrollElement
 * @description This hook can be used to either scroll to the end of a container when a condition is met
 * or to scroll to a specific element within a container when a condition is met.
 * @param UseScrollElement<T> { container, element, condition }
 * @returns isScrolledToBottom: boolean, scrollToBottom: () => void
 */
export const useScrollElement = <T extends HTMLElement>({
  container,
  element,
  condition,
  tolerance = 0,
}: UseScrollElement<T>) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  const scrollToBottom = useCallback(
    (behavior?: ScrollBehavior) => {
      if (!container.current) return;

      const commentsContainerHeight = container.current.clientHeight;
      const commentsContainerScrollHeight = container.current.scrollHeight;

      if (element?.current) {
        const offset = element?.current.offsetTop;

        container.current.scroll({
          behavior,
          top: offset,
        });
      } else {
        container.current.scroll({
          behavior,
          top: commentsContainerScrollHeight - commentsContainerHeight,
        });
      }
    },
    [container, element]
  );

  useEffect(() => {
    if (!condition) return;

    scrollToBottom();
  }, [scrollToBottom, condition]);

  useEffect(() => {
    const originalRef = container.current;

    if (!container.current) return;

    const handleScroll = () => {
      if (!container.current) return;

      setScrollTop(container.current.scrollTop + container.current.clientHeight);
      setScrollHeight(container.current.scrollHeight);
    };

    container.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!originalRef) return;
      originalRef.removeEventListener('scroll', handleScroll);
    };
    // INFO: Keep condition variable here to attach the listener properly, else it fails to attach when loading
    // TODO: Find a more consistent way to attach event listeners on refs that might be null
  }, [container, element, condition]);

  return {
    isScrolledToBottom: scrollTop + tolerance >= scrollHeight && scrollHeight !== 0,
    scrollToBottom,
  };
};
