import { useListUsersQuery, UserInfo } from '@vertice/slices';
import { keyBy } from 'lodash';

export type VerticeUsersByIdReturn = Record<string, UserInfo>;

export type AccountUsersByIdReturn = {
  usersById: VerticeUsersByIdReturn;
  isLoadingUsers: boolean;
};

export const useVerticeUsersById = (options?: { skip: boolean }): AccountUsersByIdReturn => {
  const { data: usersData, isLoading: isLoadingUsers } = useListUsersQuery(
    { filters: 'userGroup:account-manager' },
    { skip: options?.skip }
  );
  const usersById = keyBy(usersData, 'userId');
  return {
    usersById,
    isLoadingUsers,
  };
};
