import React, { MouseEvent } from 'react';
import Card, {
  CardHeader,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import Tab from '@verticeone/design-system/src/components/Tab';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import ReservedInstancesGraph from '../shared/ReservedInstancesGraph/ReservedInstancesGraph';
import EC2CoverageTable from '../shared/ReservedInstancesTable/EC2CoverageTable';
import { CalendarTodayOutlined } from '@mui/icons-material';
import TabsBottomLineWrapper from '@verticeone/design-system/src/components/Tabs/TabsBottomLineWrapper';
import { AWS_BRAND_COLOR } from '../../constants';
import { useCloudContext } from '../../CloudContext';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import RioBanner from '../shared/RioBanner/RioBanner';
import useEC2CoverageData from './useEC2CoverageData';

enum ReservedInstancesCardTab {
  PERCENTAGE_COVERAGE = 'percentage_coverage',
}

type EC2CoverageCardProps = {
  testCode: 'VT-12' | 'VT-18';
  headerLabel: string;
};

const EC2CoverageCard = (props: EC2CoverageCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useEC2CoverageData(props.testCode);
  const { subFeatures } = useCloudContext();
  const navigate = useNavigate();
  const isSprioEnabled = subFeatures.sprio && subFeatures.sprioEC2RI;
  const isRioBannerVisible = (subFeatures.rio || isSprioEnabled) && props.testCode === 'VT-18';
  const getTitleAndDescription = () => {
    if (props.testCode === 'VT-12') {
      return {
        title: t('EC2_SAVINGS_PLAN_COVERAGE.TITLE'),
        description: t('EC2_SAVINGS_PLAN_COVERAGE.DESCRIPTION'),
      };
    }

    return {
      title: t('RESERVED_INSTANCES.TITLE'),
      description: t(`RESERVED_INSTANCES.DESCRIPTION`, { type: 'EC2' }),
    };
  };

  const { title, description } = getTitleAndDescription();

  const handleClick = (evt: MouseEvent) => {
    const route = isSprioEnabled ? ROUTES.CLOUD_RIO_RESERVED_INSTANCES_DETAIL : ROUTES.CLOUD_RIO_TAB_DETAIL;
    const path = generatePath(route, { activeTab: 'inventory', product: 'ec2' });
    if (evt.ctrlKey || evt.metaKey) {
      // Open in new tab
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <Card>
        <CardHeader size="S" noDivider>
          <CardHeaderTitle text={title} tag="h2" />
          <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 3 })} icon={CalendarTodayOutlined} />
          <CardHeaderDescription>{description}</CardHeaderDescription>
        </CardHeader>
        <TabsBottomLineWrapper paddingX={6}>
          <Tabs variant="outlined" value={ReservedInstancesCardTab.PERCENTAGE_COVERAGE} color={AWS_BRAND_COLOR}>
            <Tab
              value={ReservedInstancesCardTab.PERCENTAGE_COVERAGE}
              label={t('RESERVED_INSTANCES.PERCENTAGE_COVERAGE')}
            />
          </Tabs>
        </TabsBottomLineWrapper>
        <Box bgcolor={palette.core.color1}>
          <LoadableContentWrapper {...loadingStatus}>
            <Stack gap={6}>
              <ReservedInstancesGraph target={data?.target!} values={data?.values!} />
              <EC2CoverageTable data={data?.groupedByRegion!} headerLabel={props.headerLabel} />
            </Stack>
          </LoadableContentWrapper>
        </Box>
      </Card>
      {isRioBannerVisible ? <RioBanner onClick={handleClick} /> : null}
    </>
  );
};

export default EC2CoverageCard;
