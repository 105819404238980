import React, { FC } from 'react';
import { styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import { NodeControls } from './NodeControls';
import { End } from '../../definitionsTypes';
import { StyledCircleNode } from './Shared';

export const StyledEndNode = styled(StyledCircleNode)(({ theme: { palette } }) => ({
  backgroundColor: palette.error.color4,
  borderWidth: 3,
}));

export const EndNodeComponent: FC<NodeProps<Node<End>>> = (props) => (
  <NodeControls {...props} content={<StyledEndNode $selected={props.selected}>{props.data.name}</StyledEndNode>} />
);
