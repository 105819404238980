import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';
import { LegacyVendorInfoCard, VendorInfoCard } from '../cards/VendorInfoCard';
import { LegacyVendorPricingCard, VendorPricingCard } from '../cards/VendorPricingCard';
import ProductsPricingCard from '../cards/ProductsPricingCard';
import VendorDiligenceInsights from '@vertice/core/src/modules/vendor/VendorDiligenceInsights/VendorDiligenceInsights';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useGetVendorByIdQuery } from '@vertice/slices';
import useBenchmarking from '../hooks/useBenchmarking';
import { isNil } from 'lodash';

type AboutVendorSectionProps = {
  vendorId: string;
};

const LegacyAboutVendorSection = ({ vendorId }: AboutVendorSectionProps) => {
  const { data: vendor } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });
  if (!vendor) {
    return null;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <LegacyVendorInfoCard
          description={vendor.description}
          yearFounded={vendor.yearFounded}
          hq={vendor.hq}
          website={vendor.website}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LegacyVendorPricingCard
          simplicity={vendor.simplicityRating}
          transparency={vendor.transparencyRating}
          parity={vendor.parityRating}
        />
      </Grid>
    </Grid>
  );
};

const AboutVendorSection = ({ vendorId }: AboutVendorSectionProps) => {
  const { data: vendor } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });
  const showPricingCard =
    !isNil(vendor?.simplicityRating) && !isNil(vendor?.transparencyRating) && !isNil(vendor?.parityRating);

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
      <Box minWidth={0} flexGrow={1}>
        <VendorInfoCard vendorId={vendorId} />
      </Box>
      {showPricingCard && (
        <Box flexBasis={{ xs: 1, md: 370 }}>
          <VendorPricingCard
            simplicity={vendor.simplicityRating}
            transparency={vendor.transparencyRating}
            parity={vendor.parityRating}
          />
        </Box>
      )}
    </Stack>
  );
};

const OverviewTab = () => {
  const { id: vendorId } = useParams();
  const { accountId } = useAccountContext();

  const { isEnabled } = useFeatures();
  const isVendorLegalEnabled = isEnabled(FEATURES.VENDOR_LEGAL);
  const { benchmarkingStage0 } = useBenchmarking();

  if (!vendorId) {
    return null;
  }
  return (
    <Stack gap={4} p={6} pt={8}>
      {benchmarkingStage0 ? (
        <AboutVendorSection vendorId={vendorId} />
      ) : (
        <LegacyAboutVendorSection vendorId={vendorId} />
      )}
      <ProductsPricingCard vendorId={vendorId} />
      {isVendorLegalEnabled && <VendorDiligenceInsights accountId={accountId} vendorId={vendorId} />}
    </Stack>
  );
};

export default OverviewTab;
