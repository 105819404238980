import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';
import { useClickOutside } from '@vertice/dashboard/src/layouts/Main/Sidebar/useClickOutside';
import AccountManagerCard from '@vertice/dashboard/src/components/AccountManagerCard/AccountManagerCard';

import NavigationGroup from './components/NavigationGroup';
import NavigationItem from './components/NavigationItem';

import LogoutIcon from '@mui/icons-material/LogoutTwoTone';
import SupportIcon from '@mui/icons-material/HelpTwoTone';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloudNavbarItems from '@vertice/dashboard/src/modules/cloud/components/CloudNavbarItems';
import SaasNavbarItems from '@vertice/dashboard/src/modules/saas/components/SaasNavbarItems';
import WorkflowsNavbarItems from '@vertice/dashboard/src/modules/intelligentWorkflows/components/WorkflowsNavbarItems';
import { amplifyConfig } from '@vertice/dashboard/src/config';
import useAmplifyConfigs from '../../../hooks/useAmplifyConfigs';
import { useAuthentication } from '@verticeone/auth/src';

import { useCustomerSuccessWidget } from '@vertice/core/src/contexts/CustomerSuccessContext';
import { useRoutes } from '@verticeone/router/src';
import { useRef, useState } from 'react';

const { KNOWLEDGE_BASE } = ROUTES;

const ShadowBase = styled('div')<{ $visible: boolean }>(({ theme, $visible }) => ({
  position: 'sticky',
  zIndex: 1,
  minHeight: theme.spacing(1),
  opacity: $visible ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out',
  width: '100%',
  pointerEvents: 'none',
}));

const ShadowTop = styled(ShadowBase)(({ theme }) => ({
  background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)',
  top: 0,
  marginBottom: theme.spacing(-1),
}));

const ShadowBottom = styled(ShadowBase)(({ theme }) => ({
  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)',
  bottom: 0,
  marginTop: theme.spacing(-1),
}));

const Sidebar = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  useClickOutside();
  const { t } = useTranslation();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const routes = useRoutes();
  const { isOpen } = useMainLayout();
  const { signOut } = useAuthentication();
  const { clear: removeAmplifyConfigsFromStorage } = useAmplifyConfigs(amplifyConfig);

  const { zendeskEnabled, showWidget } = useCustomerSuccessWidget();

  const logOut = () => {
    removeAmplifyConfigsFromStorage();
    void signOut();
  };

  const onScrollHandler = (event: React.WheelEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
    setScrollHeight(event.currentTarget.scrollHeight);
    setClientHeight(event.currentTarget.clientHeight);
  };

  const getVisibleSides = (): { top: boolean; bottom: boolean } => {
    if (!isOpen) return { top: false, bottom: false };

    const isBottom = clientHeight === scrollHeight - scrollTop;
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

    return {
      top: (isBottom || isBetween) && !(isTop && isBottom),
      bottom: (isTop || isBetween) && !(isTop && isBottom),
    };
  };

  return (
    <Stack position={'relative'} overflow={'hidden'} justifyContent={'space-between'} height={'100%'}>
      <Stack overflow={'auto'} ref={sidebarRef} onScroll={onScrollHandler}>
        <ShadowTop $visible={getVisibleSides().top} />
        <Stack margin={4} gap={4}>
          <SaasNavbarItems />
          <WorkflowsNavbarItems />
          <CloudNavbarItems />
        </Stack>
        <ShadowBottom $visible={getVisibleSides().bottom} />
      </Stack>
      <Stack gap={4} position={'sticky'} bottom={0} margin={4}>
        {isOpen && <AccountManagerCard />}
        <NavigationGroup>
          {zendeskEnabled && <NavigationItem onClick={showWidget} icon={SupportIcon} label={t('SIDEBAR.HELP')} />}
          <NavigationItem to={KNOWLEDGE_BASE} icon={MenuBookIcon} label={t('SIDEBAR.KNOWLEDGE_BASE')} />
          {/* prettier-ignore */}
          <NavigationItem to={routes.PREFERENCES.ABSOLUTE_PATH} match={[routes.PREFERENCES.TAB.ABSOLUTE_PATH]} icon={SettingsIcon} label={t('SIDEBAR.PREFERENCES')} />
          <NavigationItem onClick={logOut} icon={LogoutIcon} label={t('AUTH.LOG_OUT')} />
        </NavigationGroup>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
