import { Box, Portal } from '@mui/material';
import React, { FC, useEffect, useRef } from 'react';
import Text from '../../Text';
import { OverlayContent, OverlayContentProps } from './OverlayContnet';
import { StrokeOverlayWidth } from './Content';

type HighlighterProps = OverlayContentProps & {
  cursorRef: React.RefObject<HTMLSpanElement>;
  inputRef: HTMLTextAreaElement | null;
};

const Highlighter: FC<HighlighterProps> = ({ inputRef, size, variant, ...props }) => {
  const highlighterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onScroll = () => {
      if (!inputRef || !highlighterRef.current) {
        return;
      }

      highlighterRef.current.scrollLeft = inputRef.scrollLeft;
      highlighterRef.current.scrollTop = inputRef.scrollTop;
    };

    inputRef?.addEventListener('scroll', onScroll);
    return () => inputRef?.removeEventListener('scroll', onScroll);
  }, [inputRef, highlighterRef]);

  return (
    <Portal container={inputRef?.parentElement || null}>
      <Text
        variant={variant}
        size={size}
        ref={highlighterRef}
        sx={{
          ...getOverlayPosition(inputRef),
          position: 'absolute',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          overscrollBehavior: 'none',
          pointerEvents: 'none',
        }}
      >
        <OverlayContent {...props} size={size} variant={variant} />
        <Box visibility="hidden">.</Box>
      </Text>
    </Portal>
  );
};

export default Highlighter;

const getOverlayPosition = (input?: HTMLTextAreaElement | null) => {
  if (!input) {
    return {};
  }

  const computedStyle = getComputedStyle(input);

  return {
    left: `${
      input.offsetLeft +
      parseFloat(computedStyle.paddingLeft) +
      parseFloat(computedStyle.borderLeft) -
      parseFloat(computedStyle.marginLeft) -
      StrokeOverlayWidth / 2
    }px`,
    width: `${input.clientWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight)}px`,

    top: `${
      input.offsetTop +
      parseFloat(computedStyle.borderTop) -
      parseFloat(computedStyle.marginTop) -
      StrokeOverlayWidth / 2
    }px`,
    paddingTop: `${0 + parseFloat(computedStyle.paddingTop)}px`,
    paddingBottom: `${0 + parseFloat(computedStyle.paddingBottom)}px`,
    marginTop: `${0 + parseFloat(computedStyle.marginTop)}px`,
    marginBottom: `${0 + parseFloat(computedStyle.marginBottom)}px`,

    height: `${input.clientHeight}px`,
  };
};
