import React, { FC, ReactNode, createContext, useContext, useState } from 'react';

export type TaskFormContextType = {
  readOnly: boolean;
  isProcessing: boolean;
  setProcessing?: (v: boolean) => void;
};

const TaskFormContext = createContext<TaskFormContextType>({
  readOnly: true,
  isProcessing: false,
});

const useTaskFormContext = () => {
  return useContext(TaskFormContext);
};

const TaskFormContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isProcessing, setProcessing] = useState(false);
  return (
    <TaskFormContext.Provider value={{ readOnly: false, isProcessing, setProcessing }}>
      {children}
    </TaskFormContext.Provider>
  );
};

export { useTaskFormContext, TaskFormContextProvider };
