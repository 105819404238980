import useAmplifyAuth from '@verticeone/auth/src/authentication/amplify/useAmplifyAuth';
import useAmplifyConfigs from '../../../hooks/useAmplifyConfigs';
import { amplifyConfig } from '../../../config';
import { useEffect, useMemo } from 'react';
import { Hub } from 'aws-amplify';
import { AuthContextData } from '@verticeone/auth/src';
import AuthUser from '@verticeone/auth/src/authentication/AuthUser';
import { useGetAccountsQuery, useGetAccountUserQuery } from '@vertice/slices';
import { createBrowserRouter } from 'react-router-dom';

let alreadyConfigured = false;

/** Takes the generic core AmplifyAuth and bakes-in the CFA-specific configuration. */
const useCfaAmplifyAuth = (router?: ReturnType<typeof createBrowserRouter>): AuthContextData => {
  const amplifyAuth = useAmplifyAuth();

  const { configure, clear } = useAmplifyConfigs(amplifyConfig);

  useEffect(() => {
    // If you accidentally use this hook multiple times, we don't want to configure Amplify multiple times.
    if (alreadyConfigured) {
      return;
    }
    configure();
    const listener = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          document.location.reload();
          break;
        case 'tokenRefresh_failure':
          document.location.reload();
          break;
        case 'signOut':
          clear();
          break;
        case 'customOAuthState':
          {
            const state = JSON.parse(data.payload.data);
            if (state.from) {
              void router?.navigate(state.from, { replace: true });
            }
          }
          break;
        default:
        // do nothing
      }
    });

    alreadyConfigured = true;

    return listener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  // Enhance the user object with the accountId

  // As of 2024-04-18, accountId IS NOT included in Amplify JWT tokens -> you need to obtain it with a separate API request
  const { data: accountsList } = useGetAccountsQuery({}, { skip: !amplifyAuth.user, refetchOnMountOrArgChange: false });

  // Use the first account available
  const accountId = accountsList?.[0].accountId;

  const { data: account } = useGetAccountUserQuery(
    { accountId: accountId!, userId: amplifyAuth.user?.userId! },
    { skip: !accountId || !amplifyAuth.user?.userId, refetchOnMountOrArgChange: false }
  );

  const userWithAccountId = useMemo(
    (): AuthUser | undefined =>
      amplifyAuth.user && accountId && account
        ? {
            ...amplifyAuth.user,

            // CFA-specific stuff
            accountId,
            roles: account.userRoles ?? [],
          }
        : undefined,
    [amplifyAuth.user, accountId, account]
  );

  return { ...amplifyAuth, user: userWithAccountId };
};

export default useCfaAmplifyAuth;
