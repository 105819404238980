import React from 'react';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';
import BaseCloudAnalyticsStats from '../../BaseCloudTab/Stats/BaseCloudAnalyticsStats';
import useAccountServicesUsageData from '../Table/useAccountServicesUsageData';

type CloudAccountServicesUsageStatsProps = {
  linkedAccountId: string;
  productCode: string;
};

const CloudAccountServicesUsageStats = ({ linkedAccountId, productCode }: CloudAccountServicesUsageStatsProps) => {
  const { period } = useCloudAnalytics();
  const { data, isLoading } = useAccountServicesUsageData({
    linkedAccountId,
    productCode,
    ...period,
  });
  return <BaseCloudAnalyticsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountServicesUsageStats;
