import { ReactNode } from 'react';
import AuthProvider from '@verticeone/auth/src';
import withAmplify from '@verticeone/auth/src/authentication/amplify/withAmplify';
import useCfaAuth from './useCfaAuth';
import { API_URLS } from '@vertice/slices';
import { createBrowserRouter } from 'react-router-dom';

type CfaAuthContextProviderProps = {
  router?: ReturnType<typeof createBrowserRouter>;
  children: ReactNode;
};

export const CfaAuthContextProvider = withAmplify<CfaAuthContextProviderProps>(
  ({ children, router }: CfaAuthContextProviderProps) => {
    const authContextData = useCfaAuth({ router });

    return (
      <AuthProvider authServiceBaseUrl={API_URLS.BASE_URLS.AUTH_SERVICE} authentication={authContextData}>
        {children}
      </AuthProvider>
    );
  }
);
