import React from 'react';
import ProductsListBase, { ProductListProps as ProductsListBaseProps } from './ProductList';
import WithActions from './WithActions';
import VendorProductsContextProvider from './VendorProductsContextProvider';
import { ProductItem } from './types';
import { DesignSystemColor } from '@verticeone/design-system/src/types';
import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { Product } from '@vertice/slices';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { asProductItem, useProductCollumns } from './useProductCollumns';

interface ProductsListProps<T extends ProductItem>
  extends Omit<ProductsBaseListProps<T>, 'extraColumns' | 'getProductItem' | 'columnVisibilityModel'> {
  showAnnualCost?: boolean;
  showLicenseType?: boolean;
}

export const ProductsList = ({ showAnnualCost, showLicenseType, ...props }: ProductsListProps<ProductItem>) => {
  const extraColumns = useProductCollumns();

  return (
    <ProductsBaseList
      {...props}
      extraColumns={extraColumns}
      getProductItem={asProductItem}
      columnVisibilityModel={{
        annualCost: !!showAnnualCost,
        licenseType: !!showLicenseType,
      }}
    />
  );
};

interface ProductsBaseListProps<T extends ProductItem> extends ProductsListBaseProps<T> {
  extraColumns: GridColDef<T>[];
  getProductItem: (r: GridRowModel, p: Product[]) => T;
  columnVisibilityModel?: GridColumnVisibilityModel;

  withActions?: boolean;
  excludeDeleteAction?: boolean;
  allowSavingFor?: Array<string>;
  currency?: string;
  vendorId?: string;
  selectedProducts: T[];
  setSelectedProducts: (newProducts: T[]) => void;
  color?: DesignSystemColor;
}

export const ProductsBaseList = <T extends ProductItem>({
  extraColumns,
  getProductItem,

  withActions,
  excludeDeleteAction,
  allowSavingFor,
  currency,
  vendorId,
  selectedProducts,
  setSelectedProducts,
  color = 'primary',
  dataGridExtraProps,
  ...rest
}: ProductsBaseListProps<T>) => {
  const listBase = (
    <ProductsListBase
      selectedProducts={selectedProducts}
      readOnlyMode={!withActions}
      dataGridExtraProps={{ color, ...dataGridExtraProps }}
      extraColumns={extraColumns}
      {...rest}
    />
  );
  return (
    <VendorProductsContextProvider currency={currency} vendorId={vendorId} selectedProducts={selectedProducts}>
      {withActions ? (
        <WithActions
          {...{
            excludeDeleteAction,
            allowSavingFor,
            selectedProducts,
            setSelectedProducts,
            getProductItem,
            extraColumns,
          }}
        >
          {listBase}
        </WithActions>
      ) : (
        listBase
      )}
    </VendorProductsContextProvider>
  );
};
