import { omit } from 'lodash';
import { type NavigateOptions, useNavigate } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import type { ExtractRouteParams, PickRequiredParams } from '../routes/utils';
import type { ExtractableRoute } from '../routes/utils';
import { useCallback } from 'react';

type CustomNavigateOptions<S extends Record<string, string>, T extends boolean> = {
  relative?: T;
  search?: S | null;
} & Omit<NavigateOptions, 'relative' | 'search'>;

type CustomGeneratePathOptions = {
  relative?: boolean;
};

const useRouteNavigate = () => {
  const navigate = useNavigate();

  /**
   *    const Route = {
   *      ABSOLUTE_PATH: '/:absolute/path',
   *      PATH: '/path',
   *    } as const;
   *
   *    ----- VALID -----
   *    navigateTo(Route, null, { relative: true, search: { b: 'value' } });
   *    navigateTo(Route, null, { relative: true });
   *    navigateTo(Route, { absolute: 'value' }, { relative: false, search: { b: 'value' } });
   *    navigateTo(Route, { absolute: 'value' }, { relative: false });
   *    navigateTo(Route, { absolute: 'value' });
   *    navigateTo(Route, {}, { relative: true });
   *
   *    ----- INVALID -----
   *    navigateTo(Route, { absolute: 'value' }, { relative: false, search: 'wrong_string' });    // TS2322: Type 'string' is not assignable to type 'Record  | null | undefined'.
   *    navigateTo(Route, null);                                                                  // TS2345: Argument of type 'null' is not assignable to parameter of type '{ absolute: string | number | boolean; }'.
   *    navigateTo(Route, {});                                                                    // TS2345: Argument of type '{}' is not assignable to parameter of type '{ absolute: string | number | boolean; }'.
   *    navigateTo(Route);                                                                        // TS2554: Expected 2-3 arguments, but got 1.
   */
  const navigateTo = useCallback(
    <S extends Record<string, any>, R extends ExtractableRoute, RelativePath extends boolean = false>(
      route: R,
      ...args: PickRequiredParams<ExtractRouteParams<R, RelativePath>> extends Record<string, never>
        ? [params?: ExtractRouteParams<R, RelativePath> | null, options?: CustomNavigateOptions<S, RelativePath>]
        : [params: ExtractRouteParams<R, RelativePath>, options?: CustomNavigateOptions<S, RelativePath>]
    ): void => {
      const [params, options] = args;
      const opt: NavigateOptions = {
        ...(omit(options, ['search', 'relative']) ?? {}),
        ...(options?.relative ? { relative: 'route' } : {}),
      };

      const path = !!options?.relative ? route.PATH : route.ABSOLUTE_PATH;
      const generatedPath = generatePath(path, {
        ...route.defaultParams,
        ...params,
      });
      const query = options?.search ? new URLSearchParams(options.search) : null;

      navigate([generatedPath, query].filter((item) => item !== null).join('?'), opt);
    },
    [navigate]
  );

  const generatePathForRoute = useCallback(
    <R extends ExtractableRoute, RelativePath extends boolean = false>(
      route: R,
      ...args: PickRequiredParams<ExtractRouteParams<R, RelativePath>> extends Record<string, never>
        ? [params?: ExtractRouteParams<R, RelativePath> | null, options?: CustomGeneratePathOptions]
        : [params: ExtractRouteParams<R, RelativePath>, options?: CustomGeneratePathOptions]
    ): string => {
      const [params, options] = args;
      const path = options?.relative ? route.PATH : route.ABSOLUTE_PATH;

      return generatePath(path, {
        ...route.defaultParams,
        ...params,
      });
    },
    []
  );

  return { navigate: navigateTo, generatePath, generatePathForRoute };
};

export default useRouteNavigate;
