import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useMemo } from 'react';
import { isVerticeWorkflowHandler, parseServiceHandlerRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { WorkflowVersionRow } from './types';
import { useTranslation } from 'react-i18next';

export const useIsDefaultWorkflow = (workflowVersion?: WorkflowVersion, service?: ServiceCatalogResource) => {
  return useMemo(() => {
    if (workflowVersion !== undefined && service !== undefined) {
      const parsedServiceHandler = parseServiceHandlerRef(service.definition.ServiceProvider?.HandlerUrn);
      if (isVerticeWorkflowHandler(parsedServiceHandler)) {
        return parsedServiceHandler.workflowId === workflowVersion.id;
      }
    }
    return false;
  }, [workflowVersion, service]);
};

/**
 * Deployed workflow versions have versionName "v<number>"
 */
export const isDeployedVersion = (row: WorkflowVersionRow) =>
  row?.versionName && row.versionName.match(/^v\d+$/) !== null;

export const useCreateWorkflowVersionLabel = () => {
  const { t } = useTranslation();

  const localizeVersionName = useLocalizeVersionName();
  return (row: WorkflowVersionRow) =>
    t('ENTITIES.WORKFLOW_VERSION.LABEL', {
      workflowName: row.workflowName,
      versionName: localizeVersionName(row.versionName),
    });
};

export const useLocalizeVersionName = () => {
  const { t } = useTranslation();

  return (versionName?: string) => versionName?.replace(/^v/, () => t('ENTITIES.WORKFLOW_VERSION.NAME') + ' ');
};
