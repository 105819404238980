import {
  EdpValidationError,
  ErroredQueryResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const useValidationErrorHandler = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { enqueueSnackbar } = useSnackbar();

  const showError = (message: string, description?: string) => {
    enqueueSnackbar({ variant: 'error', message, description });
  };

  return (validateContractModel?: EdpValidationError | ErroredQueryResult | null) => {
    if (validateContractModel?.__typename === 'EDPValidationError') {
      const spanCodes = ['SPAN_START_OVERLAPS', 'SPAN_NOT_ADJACENT', 'SPAN_END_OVERLAPS'];
      const context = validateContractModel.context ? JSON.parse(validateContractModel.context) : null;
      const { code, fieldName } = validateContractModel;

      if (spanCodes.includes(code)) {
        showError(
          t(`ERROR.${code}`, {
            current: context?.current,
            previous: context?.previous,
          })
        );
      } else {
        const term = context?.name || context?.current;
        const field = t(`ERROR.${fieldName.toUpperCase()}`);
        const message = t('ERROR.ERROR_IN', { term });
        const description = t(`ERROR.${code}`, { term, field });

        showError(message, description);
      }
      return true;
    }

    if (validateContractModel?.__typename === 'ErroredQueryResult') {
      showError(validateContractModel.error);
      return true;
    }

    return false;
  };
};

export default useValidationErrorHandler;
