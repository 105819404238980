import BaseCloudAnalyticsGraph from '../../../BaseCloudTab/Graph/BaseCloudAnalyticsGraph';
import React from 'react';
import useTagsServicesGraphData from '../useTagsServicesGraphData';

const CloudTagsServicesGraphData = () => {
  const { data: graphData, ...loadingStatus } = useTagsServicesGraphData();

  return <BaseCloudAnalyticsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudTagsServicesGraphData;
