import { Offer } from '../Offer';
import { useOfferItemMapping } from '../helpers/itemMapping';
import { WidgetProps } from '../../shared/types';

export const BaselineOfferSection = ({ widgetData }: WidgetProps) => {
  const offer = widgetData?.baselineOffer;
  const { vendor, products, concessions } = useOfferItemMapping(offer);

  return (
    <Offer
      title={offer.name}
      vendor={vendor}
      products={products}
      concessions={concessions}
      offer={offer}
      type="baseline"
    />
  );
};
