import { PostAuthBatchRequest } from '../requests/postAuthBatch';
import { MutationFunction, UseMutationOptions, useMutation } from '@tanstack/react-query';
import { postAuthBatch } from '../requests/postAuthBatch';
import { useAuthentication } from '../../authentication/AuthenticationContext';

export const getPostAuthBatchMutationOptions = <TError = Error, TContext = unknown>(
  { accountId, baseUrl }: { accountId: string; baseUrl: string },
  options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof postAuthBatch>>, TError, PostAuthBatchRequest, TContext>;
    request?: RequestInit;
    authenticationFunction?: () => Promise<string>;
  }
) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAuthBatch>>, PostAuthBatchRequest> = (props) => {
    return postAuthBatch(props, accountId, baseUrl, requestOptions, options?.authenticationFunction);
  };

  return { mutationFn, ...mutationOptions };
};

export const usePostAuthBatch = <TError = Error, TContext = unknown>(
  accountId: string,
  baseUrl: string,
  options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof postAuthBatch>>, TError, PostAuthBatchRequest, TContext>;
    request?: RequestInit;
  }
) => {
  const { getJwtToken } = useAuthentication();

  const mutationOptions = getPostAuthBatchMutationOptions(
    { accountId, baseUrl },
    {
      request: options?.request ?? {},
      mutation: options?.mutation ?? {},
      authenticationFunction: getJwtToken,
    }
  );

  return useMutation({ ...mutationOptions });
};
