import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Accordion, { AccordionDetails, AccordionSummary } from '@verticeone/design-system/src/components/Accordion';
import { Comments } from '@verticeone/design-system/src/components/Comments';
import { useConversations } from '../../useConversations';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';
import { useLoggedUser } from '@verticeone/auth/src';

type ConversationProps = {
  requestId: string;
};

export const ConversationSection: FC<ConversationProps> = ({ requestId }) => {
  const { userId } = useLoggedUser();
  const { usersById, isLoadingUsers } = useConversationUsers();
  const { cursor, messages, isMessagePending, isLoading, sendMessage, updateCursor } = useConversations(requestId);
  const { t } = useTranslation();

  return (
    <UsersContextProvider>
      <Accordion size="XS" variant="label" defaultExpanded>
        <AccordionSummary title={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.COMMENTS')} />
        <AccordionDetails>
          <Stack height={800}>
            <Comments
              comments={messages}
              users={usersById}
              currentUser={userId}
              cursor={cursor}
              onSendComment={sendMessage}
              onUpdateCursor={updateCursor}
              isLoading={isLoading || isLoadingUsers}
              isSending={isMessagePending}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </UsersContextProvider>
  );
};
