import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import { useWatch } from 'react-hook-form';

import { SecurityScoreNone, SecurityScoreLow, SecurityScoreMedium, SecurityScoreHigh } from '@vertice/assets';
import Text from '@verticeone/design-system/src/components/Text';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import Accordion, { AccordionDetails, AccordionSummary } from '@verticeone/design-system/src/components/Accordion';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import Divider from '@verticeone/design-system/src/components/Divider';

import { useRequestContext } from '../../../../../request/RequestDetailPage/RequestContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import FormToggleButtonField from '../../../../../../forms/fields/FormToggleButtonField';
import { TaskFormEntry } from '../shared/formFields/TaskFormEntry';
import { SecurityQuestionnaireOverview } from '../shared/formSections/SecurityQuestionnaireOverview';
import { SecurityScore } from '../shared/constants';
import { FormData } from './schema';

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

const getSecurityScoreIcon = (score?: SecurityScore) => {
  if (score === SecurityScore.LOW) return SecurityScoreLow;
  if (score === SecurityScore.MODERATE) return SecurityScoreMedium;
  if (score === SecurityScore.HIGH) return SecurityScoreHigh;
  return SecurityScoreNone;
};

export const SecurityApprovalForm = () => {
  const { t } = useTranslation();
  const { parentRequest } = useRequestContext();
  const taskData = useWatch<FormData>();

  return (
    <Stack gap={4}>
      <Text variant="caption" size="S" color="text1">
        {t('INTAKE_FORM.SECURITY_APPROVAL.HEADER', { vendorName: parentRequest?.input.vendor.name })}
      </Text>

      <TaskFormEntry<FormData, typeof FormToggleButtonField>
        name="securityApproved"
        required={true}
        component={FormToggleButtonField}
        componentProps={{
          compact: true,
          options: [
            {
              title: t('INTAKE_FORM.SECURITY_APPROVAL.APPROVED'),
              value: ApprovalStatus.APPROVED,
              color: 'success',
              isActive: taskData.securityApproved,
            },
            {
              title: t('INTAKE_FORM.SECURITY_APPROVAL.REJECTED'),
              value: ApprovalStatus.REJECTED,
              color: 'error',
              isActive: !taskData.securityApproved,
            },
          ],
        }}
      />

      <TaskFormEntry<FormData, typeof FormTextField>
        name="approvalNotes"
        label={t('INTAKE_FORM.SECURITY_APPROVAL.APPROVAL_NOTES')}
        component={FormTextField}
        width={12}
        componentProps={{
          multiline: true,
          rows: 4,
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
      />

      <Accordion variant="solid" size="L" defaultExpanded={true}>
        <AccordionSummary
          title={
            <Text variant="label" size="S" color="text1">
              {t('INTAKE_FORM.SECURITY_APPROVAL.DETAILS')}
            </Text>
          }
        />
        <AccordionDetails>
          <Stack gap={6}>
            {taskData.securityScore !== SecurityScore.NONE && (
              <>
                <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
                  <IconWrapper icon={SecurityIcon} />
                  <Text variant="heading" size="XS" color="text1">
                    {t('INTAKE_FORM.SECURITY_APPROVAL.SCORE_TITLE')}
                  </Text>
                </Stack>
                <Box alignSelf="center">
                  <IconWrapper icon={getSecurityScoreIcon(taskData.securityScore)} />
                </Box>
                <Divider />
              </>
            )}
            <SecurityQuestionnaireOverview />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
