import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { TimezoneSelect } from '@vertice/core/src/components/TimezoneSelect';

export type FormTimezoneSelectProps<FormDataType extends FieldValues> = {
  id?: string;
  name: FieldPath<FormDataType>;
  required?: boolean;
};

export const FormTimezoneSelect = <FormDataType extends FieldValues = never>({
  name,
  required,
  ...otherProps
}: FormTimezoneSelectProps<FormDataType>) => {
  const {
    field: { ref, value, onChange, onBlur, disabled: formDisabled },
    fieldState: { invalid, error },
  } = useController<FormDataType>({ name, rules: { required } });

  return (
    <TimezoneSelect
      ref={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isDisabled={formDisabled}
      error={invalid}
      helperText={invalid && error?.message}
      isClearable
      {...otherProps}
    />
  );
};
