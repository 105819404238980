import React, { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';

import LargeValue from '@vertice/core/src/modules/saas/contract/components/LargeValue';
import useFormatContractCurrency from './useFormatContractCurrency';
import {
  getAnnualScopeReductionSavings,
  getScopeReductionSavings,
} from '@vertice/core/src/modules/saas/savings/computed';
import { PropsWithContract } from '../types';

export const BreakdownScopeReductionSavings: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency(contract);
  const id = useId();

  const annualScopeReductionSavings = getAnnualScopeReductionSavings(contract);
  const scopeReductionSavings = getScopeReductionSavings(contract);

  return (
    <Stack gap={1} flex={1}>
      <TextFieldCaption
        htmlFor={id}
        label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION')}
        size="XS"
        tooltip={{
          title: t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION'),
          content: t('ENTITIES.CONTRACT.TOOLTIPS.SCOPE_REDUCTION'),
        }}
      />
      <LargeValue
        id={id}
        size="S"
        primaryValue={formatContractCurrency(annualScopeReductionSavings)}
        primaryTestId="annualScopeReductionSavingsValue"
        secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(scopeReductionSavings) })}
        secondaryTestId="scopeReductionSavingsValue"
      />
    </Stack>
  );
};
