import { AccountUser, UserInfo } from '@vertice/slices';
import { useCallback, useMemo } from 'react';
import { UserOption } from '../../user/UserSelect/types';
import { parseUserRef } from '../../../hooks/workflows/refUtils';
import { isNotNil } from '../../../utils/validation/isNotNil';
import safePick from '../../../utils/objects/safePick';
import { useRelevantUsersById } from '../../../hooks/useRelevantUsersById';

const accountUserToSelectUser = (user: AccountUser | UserInfo): UserOption => ({
  id: user.userId,
  email: user.email ?? '',
  ...safePick(user, 'firstName', 'middleName', 'lastName'),
});

const useGetAssignableUsers = () => {
  const { usersById, isLoadingUsers } = useRelevantUsersById();

  const resolveUsersFromRefs = useCallback(
    (usersRefs: string[]): UserOption[] =>
      usersRefs
        .map((userRef) => {
          const { userId } = parseUserRef(userRef);
          const maybeUser = usersById[userId];
          return maybeUser ? accountUserToSelectUser(maybeUser) : null;
        })
        .filter(isNotNil),
    [usersById]
  );

  return useMemo(
    () => ({
      assignableUsers: Object.values(usersById ?? {}).map(accountUserToSelectUser),
      isLoadingAssignableUsers: isLoadingUsers,
      resolveUsersFromRefs,
    }),
    [usersById, resolveUsersFromRefs, isLoadingUsers]
  );
};

export default useGetAssignableUsers;
