import { useTheme } from '@mui/material/styles';
import { TaskColorVariant } from '../workflowSchema/WorkflowRenderer/NodeComponents/TaskNode/types';

export type UseIconColorsReturn = {
  iconColor: string;
  iconBgColor: string;
};

export const useIconColors = (variant: TaskColorVariant, inverted: boolean): UseIconColorsReturn => {
  const { palette } = useTheme();

  switch (variant) {
    case 'error':
      return inverted
        ? { iconColor: palette.error.color4, iconBgColor: palette.error.hover.color2 }
        : { iconColor: palette.error.color2, iconBgColor: palette.error.color4 };
    case 'primary':
    case 'secondary':
    case 'neutral':
    case 'success':
    default:
      return inverted
        ? { iconColor: palette[variant].color4, iconBgColor: palette[variant].color2 }
        : { iconColor: palette[variant].color2, iconBgColor: palette[variant].color4 };
  }
};
