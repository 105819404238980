import { useCallback } from 'react';
import { CommentMetadata, Content, Mention, MentionUser } from '../Comment/types';
import { useTranslation } from 'react-i18next';
import i18n from '../../translate';
import { getFullName } from '../../utils/formatting/user';

export const getNewCommentsCount = (comments: CommentMetadata[], cursor: string) =>
  comments.reduce(
    (acc, comment) => (new Date(comment.timestamp) > new Date(cursor) && !comment.isCommentAuthor ? acc + 1 : acc),
    0
  );

export const getFirstNewCommentIndex = (comments: CommentMetadata[], cursor: string) =>
  comments.findIndex((comment) => new Date(comment.timestamp) > new Date(cursor!) && !comment.isCommentAuthor);

export const MENTION_PATTERN = /<@(?:[a-z]|[0-9]|-)+>/g;
export const MENTION_PATTERN_GROUP = /<@((?:[a-z]|[0-9]|-)+)>/g;

export const useCommentContent = (userId: string | undefined, users: { [x: string]: MentionUser } | undefined = {}) => {
  const userMention = useMention(userId);

  return useCallback(
    (content: string): Content[] => {
      const mentions = content
        .match(MENTION_PATTERN)
        ?.map((mention) => userMention(users[mention.substring(2, mention.length - 1)]));
      if (!mentions) {
        return [content];
      }

      return content.split(MENTION_PATTERN).flatMap((segment, index) => {
        return index < mentions.length ? [segment, mentions[index]] : [segment];
      });
    },
    [users, userMention]
  );
};

export const useMention = (userId: string | undefined) => {
  const { t } = useTranslation(undefined, { i18n });

  return useCallback(
    (user: MentionUser | undefined, prefix: string | undefined = '@'): Mention => {
      if (!user) {
        return { label: `${prefix}${t('COMMENTS.UNKNOWN_AUTHOR')}`, managed: false };
      }

      return {
        label:
          `${prefix}${getFullName(user)}` + (user.userId === userId ? ` (${t('COMMENTS.IS_COMMENT_AUTHOR')})` : ''),
        managed: user.verticeUser,
      };
    },
    [userId, t]
  );
};
