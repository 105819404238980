import { ListOfDepartments } from '@vertice/core/src/modules/departments/types';
import {
  ListTagAssignmentsApiArg,
  ListTagAssignmentsApiResponse,
  TagAssignment,
  useLazyListTagAssignmentsQuery,
} from '@vertice/slices';
import { useMemo } from 'react';
import { getOwnedDepartmentIdsFromTagAssignments } from '@vertice/core/src/modules/saas/contract/utils/tagUtils';
import { getUserRef } from '@vertice/core/src/modules/saas/contract/utils/refUtils';
import { useFetchPaginated } from '@vertice/core/src/hooks/useFetchPaginated';
import { useAccountDepartments } from '@vertice/core/src/hooks/useAccountDepartments';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export type UseUserOwnerDepartmentsProps = {
  userId: string;
  skip?: boolean;
};

export type UseUserOwnerDepartmentsResult = { departments: ListOfDepartments | undefined; isLoading: boolean };

export const useUserOwnerDepartments = ({
  userId,
  skip = false,
}: UseUserOwnerDepartmentsProps): UseUserOwnerDepartmentsResult => {
  const { accountId } = useAccountContext();
  const { isUserAdmin, isUserPowerUser, isLoading: isUserAccountRolesLoading } = useLoggedUserAccountRoles();

  const { data: allDepartments, isLoading: isAllDepartmentsLoading } = useAccountDepartments({ skip: skip });

  const [listTagAssignments] = useLazyListTagAssignmentsQuery();

  const { items: userTagAssignments, isLoading: isLoadingTagAssignments } = useFetchPaginated<
    ListTagAssignmentsApiResponse,
    ListTagAssignmentsApiArg,
    TagAssignment
  >({
    fetchFn: listTagAssignments,
    getItemsFn: (data) => data.tagAssignments,
    getNextTokenFn: (data) => data.nextToken,
    fetchArgs: {
      accountId: accountId!,
      ref: getUserRef(userId),
      maxResults: 500,
    },
  });

  const availableDepartmentIDs = getOwnedDepartmentIdsFromTagAssignments(userTagAssignments);

  return useMemo(() => {
    const isLoading = isAllDepartmentsLoading || isUserAccountRolesLoading || isLoadingTagAssignments;
    if (isLoading) {
      return { isLoading, departments: undefined };
    }

    return {
      isLoading,
      departments:
        isUserAdmin || isUserPowerUser
          ? allDepartments
          : allDepartments?.filter((department) => availableDepartmentIDs?.includes(department.departmentId)),
    };
  }, [
    isAllDepartmentsLoading,
    isUserAccountRolesLoading,
    isLoadingTagAssignments,
    isUserAdmin,
    isUserPowerUser,
    allDepartments,
    availableDepartmentIDs,
  ]);
};
