import React from 'react';
import NavigationItem from '../../../../layouts/Main/Sidebar/components/NavigationItem';
import DashboardIcon from '@mui/icons-material/DashboardTwoTone';
import ScienceIcon from '@mui/icons-material/ScienceTwoTone';
import AnalyticsIcon from '@mui/icons-material/AnalyticsTwoTone';
import InterestsIcon from '@mui/icons-material/InterestsTwoTone';
import InsightsIcon from '@mui/icons-material/InsightsOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuoteOutlined';
import NavigationGroup from '../../../../layouts/Main/Sidebar/components/NavigationGroup';
import useCloudPermissions from '../../hooks/useCloudPermissions';
import useCloudFeature, { CloudSubFeature } from '../../hooks/useCloudFeature';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { useTranslation } from 'react-i18next';
import { AWS_BRAND_COLOR } from '../../constants';
import { ReactComponent as NavbarIcon } from './navbarIcon.svg';
import { useMainLayout } from '../../../../layouts/Main/LayoutWrapper';
import { generatePath } from 'react-router-dom';
import NavigationDropdown from '../../../../layouts/Main/Sidebar/components/NavigationDropdown';

const {
  CLOUD_OVERVIEW,
  CLOUD_ANALYTICS,
  CLOUD_ANALYTICS_TAB,
  CLOUD_INSIGHTS_BASE,
  CLOUD_EDP,
  CLOUD_INSIGHTS_TAB,
  CLOUD_OPTIMIZATION,
  CLOUD_OPTIMIZATION_RECOMMENDATION,
  CLOUD_OPTIMIZATION_RECOMMENDATION_CODE,
  CLOUD_RIO_TAB,
  CLOUD_RIO_TAB_DETAIL,
  CLOUD_RIO,
  CLOUD_RIO_SAVINGS_PLANS,
  CLOUD_RIO_SAVINGS_PLANS_DETAIL,
  CLOUD_RIO_RESERVED_INSTANCES,
  CLOUD_RIO_RESERVED_INSTANCES_DETAIL,
  CLOUD_RIO_TRANSACTIONS,
} = ROUTES;

const CloudNavbarItems = () => {
  const { t } = useTranslation();
  const cloudFeature = useCloudFeature();
  const cloudPermissions = useCloudPermissions();
  const { isOpen: isNavbarExpanded } = useMainLayout();

  const isCcoGroupVisible =
    !cloudFeature.isLoading &&
    cloudFeature.data.isMainFeatureEnabled &&
    !cloudPermissions.isLoading &&
    cloudPermissions.data.canUserAccessCCO;

  if (!isCcoGroupVisible) {
    return null;
  }

  const isFullVersion =
    !cloudFeature.isLoading &&
    cloudFeature.data.isMainFeatureEnabled &&
    !cloudFeature.data.subFeatures.activeSpendAudit;

  const isFeatureEnabled = (featureKey: CloudSubFeature) =>
    !cloudFeature.isLoading && cloudFeature.data.isMainFeatureEnabled && cloudFeature.data.subFeatures[featureKey];

  const isRIOEnabled = isFeatureEnabled(CloudSubFeature.RIO);
  const isSprioEnabled = isFeatureEnabled(CloudSubFeature.SPRIO);
  const isSprioComputeSPEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_COMPUTE_SP);
  const isSprioEC2SPEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_EC2_SP);
  const isSprioSagemakerSPEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_SAGEMAKER_SP);
  const isSprioSPEnabled = isSprioComputeSPEnabled || isSprioEC2SPEnabled || isSprioSagemakerSPEnabled;
  const isSprioEC2RIEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_EC2_RI);
  const isSprioRDSRIEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_RDS_RI);
  const isSprioOpenSearchRIEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_OPENSEARCH_RI);
  const isSprioElasticacheRIEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_ELASTICACHE_RI);
  const isSprioRedshiftRIEnabled = isFeatureEnabled(CloudSubFeature.SPRIO_REDSHIFT_RI);

  const isSprioRIEnabled =
    isSprioEC2RIEnabled ||
    isSprioRDSRIEnabled ||
    isSprioOpenSearchRIEnabled ||
    isSprioElasticacheRIEnabled ||
    isSprioRedshiftRIEnabled;
  const isAnalyticsEnabled = isFeatureEnabled(CloudSubFeature.ANALYTICS);
  const isEdpEnabled = isFeatureEnabled(CloudSubFeature.ACTIVE_EDP);

  return (
    <NavigationGroup
      isVisible={isCcoGroupVisible}
      label={isNavbarExpanded ? t('CLOUD.NAVBAR.CLOUD_COST_OPTIMIZATION') : t('CLOUD.NAVBAR.CLOUD')}
      icon={NavbarIcon}
    >
      <NavigationItem
        to={CLOUD_OVERVIEW}
        icon={DashboardIcon}
        label={t(isFullVersion ? 'CLOUD.NAVBAR.OVERVIEW' : 'CLOUD.NAVBAR.SPEND_AUDIT')}
        color={AWS_BRAND_COLOR}
      />
      {isAnalyticsEnabled && (
        <NavigationItem
          to={CLOUD_ANALYTICS}
          match={[CLOUD_ANALYTICS_TAB]}
          icon={AnalyticsIcon}
          label={t('CLOUD.NAVBAR.ANALYTICS')}
          color={AWS_BRAND_COLOR}
        />
      )}
      <NavigationItem
        to={CLOUD_INSIGHTS_BASE}
        match={[CLOUD_INSIGHTS_TAB]}
        icon={ScienceIcon}
        label={t('CLOUD.NAVBAR.INSIGHTS')}
        color={AWS_BRAND_COLOR}
      />
      {isEdpEnabled && (
        <NavigationItem to={CLOUD_EDP} icon={RequestQuoteIcon} label={t('CLOUD.NAVBAR.EDP')} color={AWS_BRAND_COLOR} />
      )}
      <NavigationItem
        to={CLOUD_OPTIMIZATION}
        match={[CLOUD_OPTIMIZATION_RECOMMENDATION, CLOUD_OPTIMIZATION_RECOMMENDATION_CODE]}
        icon={InterestsIcon}
        label={t('CLOUD.NAVBAR.OPTIMIZATION')}
        color={AWS_BRAND_COLOR}
      />
      {isRIOEnabled && !isSprioEnabled && (
        <NavigationItem
          to={generatePath(CLOUD_RIO_TAB, { activeTab: 'optimize' })}
          match={[CLOUD_RIO_TAB_DETAIL]}
          icon={InsightsIcon}
          label={t('CLOUD.NAVBAR.RIO.MAIN')}
          color={AWS_BRAND_COLOR}
        />
      )}
      {isRIOEnabled && isSprioEnabled && (
        <NavigationDropdown
          to={CLOUD_RIO}
          icon={InsightsIcon}
          label={t('CLOUD.NAVBAR.RIO_NEW.MAIN')}
          color={AWS_BRAND_COLOR}
        >
          {isSprioSPEnabled && (
            <NavigationItem
              to={generatePath(CLOUD_RIO_SAVINGS_PLANS, { activeTab: 'optimize' })}
              match={[CLOUD_RIO_SAVINGS_PLANS, CLOUD_RIO_SAVINGS_PLANS_DETAIL]}
              label={t('CLOUD.NAVBAR.RIO_NEW.SAVINGS_PLANS')}
              color={AWS_BRAND_COLOR}
            />
          )}
          {isSprioRIEnabled && (
            <NavigationItem
              to={generatePath(CLOUD_RIO_RESERVED_INSTANCES, { activeTab: 'optimize' })}
              match={[CLOUD_RIO_RESERVED_INSTANCES, CLOUD_RIO_RESERVED_INSTANCES_DETAIL]}
              label={t('CLOUD.NAVBAR.RIO_NEW.RESERVED_INSTANCES')}
              color={AWS_BRAND_COLOR}
            />
          )}
          {(isSprioSPEnabled || isSprioRIEnabled) && (
            <NavigationItem
              to={CLOUD_RIO_TRANSACTIONS}
              label={t('CLOUD.NAVBAR.RIO_NEW.TRANSACTIONS')}
              color={AWS_BRAND_COLOR}
            />
          )}
        </NavigationDropdown>
      )}
    </NavigationGroup>
  );
};

export default CloudNavbarItems;
