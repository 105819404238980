import { Children, isValidElement, PropsWithChildren, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';

import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import Button from '@verticeone/design-system/src/components/Button';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import { WizardStepProps } from '../../components/Wizard';
import { INTEGRATIONS_ROUTE, Step } from '../common';
import { useActivateSlackIntegration } from './hooks/useActivateSlackIntegration';
import { useDeactivateSlackIntegration } from './hooks/useDeactivateSlackIntegration';
import { DisconnectSlackDialog } from './DisconnectSlackDialog';

type IntegrationSetupProps = {
  activeStep: Step;
  setStep: (step: Step) => void;
} & PropsWithChildren;

export const IntegrationSetup = ({ activeStep, setStep, children }: IntegrationSetupProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);
  const handleSetIsDisconnectDialogOpened = (value: boolean) => {
    setIsDisconnectDialogOpened(value);
  };

  const { activateSlackIntegration, isLoadingActivateSlackIntegration, isConnectionInitiated } =
    useActivateSlackIntegration(setStep);
  const { deactivateSlackIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress } =
    useDeactivateSlackIntegration(handleSetIsDisconnectDialogOpened);

  const stepContent = Children.toArray(children).find((child) =>
    isValidElement<WizardStepProps>(child) ? child.props.stepValue === activeStep : false
  );

  return (
    <Stack flex={1} bgcolor={palette.core.color1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={4}
        p={6}
        borderBottom={`1px solid ${palette.core.color3}`}
      >
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink component={Link} to={ROUTES.PREFERENCES}>
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_PREFERENCES')}
          </BreadcrumbLink>
          <BreadcrumbLink component={Link} to={INTEGRATIONS_ROUTE}>
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_INTEGRATIONS')}
          </BreadcrumbLink>
          <BreadcrumbLink disabled>
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_SLACK')}
          </BreadcrumbLink>
        </Breadcrumbs>
        {activeStep === Step.START && (
          <Button
            variant="solid"
            size="M"
            color="primary"
            onClick={activateSlackIntegration}
            isLoading={isLoadingActivateSlackIntegration || isConnectionInitiated}
          >
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CONNECT_BUTTON')}
          </Button>
        )}
        {activeStep === Step.CONNECTION && (
          <>
            <Button variant="solid" size="M" color="neutral" onClick={() => setIsDisconnectDialogOpened(true)}>
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT')}
            </Button>
            <DisconnectSlackDialog
              isOpened={isDisconnectDialogOpened}
              isLoading={isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress}
              onSubmit={deactivateSlackIntegration}
              onClose={() => setIsDisconnectDialogOpened(false)}
            />
          </>
        )}
      </Stack>
      <Stack p={6}>{stepContent}</Stack>
    </Stack>
  );
};
