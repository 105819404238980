import { useTranslation } from 'react-i18next';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import React, { useState } from 'react';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import Button from '@verticeone/design-system/src/components/Button';
import { Link } from 'react-router-dom';
import { InitiatePurchaseRequestBetaDialog } from './InitiatePurchaseRequestBetaDialog';
import { useCreatePurchaseRequest } from './useCreatePurchaseRequest';

export const RequestPurchaseButton = ({ vendorId, vendorName }: { vendorId: string; vendorName?: string }) => {
  const { t } = useTranslation();
  const { isEnabled, getFeature } = useFeatures();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [purchaseBetaModalOpened, setPurchaseBetaModalOpened] = useState(false);
  const handleClosePurchaseBetaModal = () => setPurchaseBetaModalOpened(false);

  const { createPurchaseRequest, isLoading: isCreatingIWPurchaseRequest } = useCreatePurchaseRequest();

  const handleCreateIWPurchaseRequestClick = async () => {
    const IWFeature = getFeature(FEATURES.INTELLIGENT_WORKFLOWS);
    if (IWFeature?.properties?.beta === true) {
      setPurchaseBetaModalOpened(true);
      return;
    }

    await createPurchaseRequest(vendorId, vendorName);
  };

  return (
    <>
      {isEnabled(FEATURES.INTELLIGENT_WORKFLOWS) ? (
        <Button
          variant="solid"
          color="primary"
          disabled={isCreatingIWPurchaseRequest}
          isLoading={isCreatingIWPurchaseRequest}
          onClick={handleCreateIWPurchaseRequestClick}
        >
          {t('VENDOR.REQUEST')}
        </Button>
      ) : (
        <Button
          component={Link}
          to={generatePath(routes.VENDORS.VENDOR.NEW_PURCHASE.ABSOLUTE_PATH, { id: vendorId })}
          variant="solid"
          color="primary"
        >
          {t('VENDOR.REQUEST')}
        </Button>
      )}
      <InitiatePurchaseRequestBetaDialog
        isOpened={purchaseBetaModalOpened}
        onClose={handleClosePurchaseBetaModal}
        vendorId={vendorId}
        vendorName={vendorName}
      />
    </>
  );
};
