// WARNING:
// This file is copied from @vertice/core/src/utils/formatting.
// Don't change this file without updating source file as well.
// This file is going to be deleted as soon as we move the formatters into the shared @vertice/utils package.

export const getFullName = (user?: { firstName?: string; middleName?: string; lastName?: string }): string => {
  if (!user) {
    return '';
  }
  return [user.firstName, user.middleName, user.lastName].filter(Boolean).join(' ');
};
