import React from 'react';
import { useLoggedUser } from '@verticeone/auth/src';
import UserInformation from './UserInformation/UserInformation';
import { Box, Stack } from '@mui/material';
import { SlackIntegration } from './SlackIntegration/SlackIntegration';
import { useTranslation } from 'react-i18next';
import NotificationsPreferences from '@vertice/core/src/modules/notifications/NotificationsPreferences';
import { AuthType, useAuthentication } from '@verticeone/auth/src';
import Card, {
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import Alert from '@verticeone/design-system/src/components/Alert';
import { InfoOutlined } from '@mui/icons-material';

const UserContent = () => {
  const { userId } = useLoggedUser();
  const { t } = useTranslation();

  return (
    <Stack px={10} gap={4}>
      <UserInformation />

      <Card>
        <CardHeader size="S">
          <CardHeaderTitle tag="h1" text={t('PREFERENCES.EMAIL_NOTIFICATIONS.USER.TITLE')} />
          <CardHeaderDescription>
            <Box>{t('PREFERENCES.EMAIL_NOTIFICATIONS.USER.DESCRIPTION')}</Box>
          </CardHeaderDescription>
        </CardHeader>
        <NotificationsPreferences userId={userId} noBorder />
      </Card>

      <SlackIntegration />
    </Stack>
  );
};

const User = () => {
  const { t } = useTranslation();
  const { type: authType } = useAuthentication();

  return authType === AuthType.ASSUME_ROLE ? (
    <Stack px={10}>
      <Alert
        icon={InfoOutlined}
        color="info"
        variant="ghost"
        subtitle={t('PREFERENCES.USER.USER_SETTINGS_NOT_AVAILABLE_IN_ASSUME_ROLE')}
      />
    </Stack>
  ) : (
    <UserContent />
  );
};

export default User;
