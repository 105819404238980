import React, { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';

import LargeValue from '@vertice/core/src/modules/saas/contract/components/LargeValue';
import useFormatContractCurrency from './useFormatContractCurrency';
import { getAnnualSavingsVsInitial, getSavingsVsInitial } from '@vertice/core/src/modules/saas/savings/computed';
import { PropsWithContract } from '../types';

export const BreakdownSavingsVsInitial: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency(contract);
  const id = useId();

  const annualSavingsVsInitial = getAnnualSavingsVsInitial(contract);
  const savingsVsInitial = getSavingsVsInitial(contract);

  return (
    <Stack gap={1} flex={1.5}>
      <TextFieldCaption
        htmlFor={id}
        label={t('ENTITIES.CONTRACT.LABELS.SAVINGS_VS_INITIAL')}
        size="XS"
        tooltip={{
          title: t('ENTITIES.CONTRACT.LABELS.SAVINGS_VS_INITIAL'),
          content: t('ENTITIES.CONTRACT.TOOLTIPS.SAVINGS_VS_INITIAL'),
        }}
      />
      <LargeValue
        id={id}
        size="S"
        primaryValue={formatContractCurrency(annualSavingsVsInitial)}
        primaryTestId={'annualSavingsVsInitialValue'}
        secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(savingsVsInitial) })}
        secondaryTestId={'savingsVsInitialValue'}
      />
    </Stack>
  );
};
