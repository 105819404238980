import { styled } from '@mui/material';
import { testProps } from '../../utils/testProperties';

type StyledSvgProps = {
  $size: number;
  $radius: number;
};

const StyledSvg = styled('svg')<StyledSvgProps>(({ theme, $size, $radius }) => ({
  width: `${$size}px`,
  height: `${$size}px`,

  circle: {
    cx: $size / 2,
    cy: $size / 2,
    r: $radius,
  },
}));

type GaugeWrapperProps = {
  size: number;
  radius: number;
  testId?: string;
  component: string;
  children: React.ReactNode;
};

export const GaugeWrapper = ({ size, radius, testId, component, children }: GaugeWrapperProps) => {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" $size={size} $radius={radius} {...testProps(testId, component)}>
      {children}
    </StyledSvg>
  );
};
