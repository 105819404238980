import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { API_URLS } from './constants';
import ApiMiddlewareExtras from './types/ApiMiddlewareExtras';

type ExtraOptions = {
  unauthorized?: boolean;
};

export const getPrepareHeadersFn =
  (extraOptions: ExtraOptions = {}) =>
  async (headers: Headers, { extra }: { extra: unknown }): Promise<Headers> => {
    const { getJwtToken } = extra as ApiMiddlewareExtras;
    const jwtToken = extraOptions.unauthorized ? undefined : await getJwtToken();
    if (jwtToken) {
      headers.set('Authorization', `Bearer ${jwtToken}`);
    }
    // Optional: Add header with Trace ID provided by Vertice Test Automation
    const verticeTraceId = localStorage.getItem('verticeTraceId');
    if (verticeTraceId) {
      headers.set('X-Amzn-Trace-Id', `Root=${verticeTraceId}`);
    }
    return headers;
  };

const prepareBaseQuery: (baseUrl?: string) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  (baseUrl) => async (args, api, extraOptions: ExtraOptions) =>
    fetchBaseQuery({
      baseUrl: baseUrl || API_URLS.BASE_URLS.MAIN,
      prepareHeaders: getPrepareHeadersFn(extraOptions),
    })(args, api, extraOptions);

export default prepareBaseQuery;
