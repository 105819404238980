import { appendErrors, FieldError, ResolverOptions, ResolverResult } from 'react-hook-form';
import { z, ZodError, ZodIssue } from 'zod';
import { toNestErrors } from '@hookform/resolvers';
import {
  ContractFormData,
  FetchedContract,
  FieldRule,
  FormPlusComputedFieldPaths,
  FieldRuleGetterContext,
  FormSupportedMode,
  FetchedDataTypeFromMode,
  ResolvedFieldRulesPerField,
} from '../types';
import { map, get as lodashGet } from 'lodash';
import flatten from 'lodash/flatten';

export type Resolver = <T extends FetchedContract | undefined>(
  fieldsRules: ResolvedFieldRulesPerField,
  fetchedContract: T,
  options?: {
    zodSchemaOptions?: Partial<z.ParseParams>;
    /**
     * @default async
     */
    mode?: 'async' | 'sync';

    /** Prints all Zod errors to console. */
    debugErrors?: boolean;
  }
) => <TContext>(
  values: ContractFormData,
  context: TContext | undefined,
  options: ResolverOptions<ContractFormData>
) => Promise<ResolverResult<ContractFormData>>;

const isZodError = (error: any): error is ZodError => error.errors != null;

const parseErrorSchema = (zodErrors: z.ZodIssue[], validateAllFieldCriteria: boolean) => {
  const errors: Record<string, FieldError> = {};
  for (; zodErrors.length; ) {
    const error = zodErrors[0];
    const { code, message, path: pathParts } = error;
    const path = pathParts.join('.');

    if (!errors[path]) {
      if ('unionErrors' in error) {
        const unionError = error.unionErrors[0].errors[0];

        errors[path] = {
          message: unionError.message,
          type: unionError.code,
        };
      } else {
        errors[path] = { message, type: code };
      }
    }

    if ('unionErrors' in error) {
      error.unionErrors.forEach((unionError) => unionError.errors.forEach((e) => zodErrors.push(e)));
    }

    if (validateAllFieldCriteria) {
      const types = errors[path].types;
      const messages = types && types[error.code];

      errors[path] = appendErrors(
        path,
        validateAllFieldCriteria,
        errors,
        code,
        messages ? ([] as string[]).concat(messages as string[], error.message) : error.message
      ) as FieldError;
    }

    zodErrors.shift();
  }

  return errors;
};

export const contractFieldsRulesResolver =
  <Mode extends FormSupportedMode, T extends FetchedDataTypeFromMode<Mode>>(
    getFieldRules: (context: FieldRuleGetterContext<Mode>) => ResolvedFieldRulesPerField,
    fetchedContract: T,
    {
      zodSchemaOptions,
      mode,
      debugErrors,
    }: {
      zodSchemaOptions?: Partial<z.ParseParams>;
      /**
       * @default async
       */
      mode?: 'async' | 'sync';

      /** Prints all Zod errors to console. */
      debugErrors?: boolean;
    } = {}
  ) =>
  async <TContext>(values: ContractFormData, _: TContext | undefined, options: ResolverOptions<ContractFormData>) => {
    const fieldRules = getFieldRules({
      ...(fetchedContract ?? {}),
      formData: values,
    } as FieldRuleGetterContext<Mode>);

    const errors = flatten(
      await Promise.all(
        map(fieldRules, async (fieldRule: FieldRule | undefined, pathRaw: string): Promise<ZodIssue[]> => {
          const path = pathRaw as FormPlusComputedFieldPaths;
          if (!fieldRule || fieldRule[0] !== 'WRITABLE') return [];
          const fieldZodSchema = fieldRule[1];
          try {
            // we don't care about the parsing result
            await fieldZodSchema[mode === 'sync' ? 'parse' : 'parseAsync'](lodashGet(values, path), zodSchemaOptions);
            return [];
          } catch (error: any) {
            if (isZodError(error)) {
              return error.errors.map((e) => ({ ...e, path: [...path.split('.'), ...e.path] }));
            }
            throw error;
          }
        })
      )
    );

    if (debugErrors) {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(errors, null, 2));
    }

    return {
      errors: toNestErrors(
        parseErrorSchema(errors, !options.shouldUseNativeValidation && options.criteriaMode === 'all'),
        options
      ),
      values,
    };
  };
