import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';
import { useCallback } from 'react';
import { getFullName as getFullNameFn } from '../utils/formatting/user';
import { useRelevantUsersById, UsersByIdReturn } from '../hooks/useRelevantUsersById';

export type UsersContextType = {
  usersById: UsersByIdReturn;
  getFullName: (userId: string) => string;
};

const { UsersContextProvider: RawUsersContextProvider, useUsersContext } = createRequiredContext<
  UsersContextType,
  'Users'
>('Users');

const UsersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { usersById } = useRelevantUsersById({ skip: false });

  const getFullName = useCallback(
    (userId: string) => {
      const user = usersById[userId];
      return getFullNameFn(user);
    },
    [usersById]
  );

  return <RawUsersContextProvider value={{ usersById, getFullName }}>{children}</RawUsersContextProvider>;
};

export { useUsersContext, UsersContextProvider };
