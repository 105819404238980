import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Card from '@verticeone/design-system/src/components/Card';
import Text from '@verticeone/design-system/src/components/Text';
import { Box, Stack, useTheme } from '@mui/material';
import { ThumbUpTwoTone, ThumbDownTwoTone } from '@mui/icons-material';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';

type BulletListProps = {
  icon: ReactNode;
  texts: string[];
};

const BulletList = ({ icon, texts }: BulletListProps) => {
  return texts?.map((text, index) => (
    <Stack gap={2} key={index}>
      <Stack direction="row" gap={1.75} alignItems="flex-start">
        <Box my={0.375}>{icon}</Box>
        <Text variant="body-regular" size="S" color="text2">
          {text}
        </Text>
      </Stack>
    </Stack>
  ));
};

const EffectiveBenchmarkingCard = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Card minWidth={374} height="100%" p={6} gap={6}>
      <Stack gap={0.5}>
        <Text variant="caption" size="XS" color="text2">
          {t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.TITLE')}
        </Text>
        <Text variant="body-regular" size="S">
          <Trans i18nKey="PRODUCT.EFFECTIVE_BENCHMARKING_CARD.DESCRIPTION" />
        </Text>
      </Stack>
      <Stack gap={3}>
        <Text variant="heading" size="XS">
          {t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.OUR_RECOMMENDATIONS')}
        </Text>
        <BulletList
          icon={
            <Box p={0.5} borderRadius={1} bgcolor={palette.success.color4}>
              <IconWrapper size="XXS" color="success" icon={ThumbUpTwoTone} />
            </Box>
          }
          texts={[
            t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.RECOMMENDATION_1'),
            t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.RECOMMENDATION_2'),
            t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.RECOMMENDATION_3'),
          ]}
        />
      </Stack>
      <Stack gap={3}>
        <Text variant="heading" size="XS">
          {t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.KNOWN_ISSUES')}
        </Text>
        <BulletList
          icon={
            <Box p={0.5} borderRadius={1} bgcolor={palette.error.color4}>
              <IconWrapper size="XXS" color="error" icon={ThumbDownTwoTone} />
            </Box>
          }
          texts={[t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.ISSUE_1')]}
        />
      </Stack>
      <Text variant="body-regular" size="XS">
        {t('PRODUCT.EFFECTIVE_BENCHMARKING_CARD.ISSUE_STATEMENT')}
      </Text>
    </Card>
  );
};

export default EffectiveBenchmarkingCard;
