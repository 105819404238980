import React, { useCallback } from 'react';
import { FieldPathByValue, FieldValues, useFormContext } from 'react-hook-form';

import { OfferProduct, OfferProducts, Offer, joinFormPath, useWatchNested } from '../schemas';
import { useTaskFormContext } from '../TaskFormContext';
import { ProductsBaseList } from '../../../../../../../saas/contract/components/ProductList';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { Product } from '@vertice/slices';
import { asProductItem } from '../../../../../../../saas/contract/components/ProductList/useProductCollumns';
import { useOfferProductCollumns } from '../../../useOfferProductCollumns';

type OfferFormProductListProps<
  V extends FieldValues,
  P extends FieldPathByValue<V, Offer> = FieldPathByValue<V, Offer>
> = {
  name: P;
};

export const OfferFormProductList = <T extends FieldValues>({ name: offerName }: OfferFormProductListProps<T>) => {
  const { setValue } = useFormContext<T>();
  const { readOnly } = useTaskFormContext();

  const products = useWatchNested({ offerName, fieldName: 'products' });
  const vendor = useWatchNested({ offerName, fieldName: 'vendor' });
  const baseCurrency = useWatchNested({ offerName, fieldName: 'baseCurrency' });
  const extraColumns = useOfferProductCollumns();

  const setProducts = useCallback(
    (productsToSet: OfferProducts) =>
      setValue(joinFormPath<T>(offerName, 'products'), productsToSet as any, { shouldValidate: true }),
    [setValue, offerName]
  );

  return (
    <ProductsBaseList<OfferProduct>
      color="neutral"
      extraColumns={extraColumns}
      getProductItem={asOfferProductItem}
      withActions={!readOnly}
      readOnlyMode={readOnly}
      vendorId={vendor?.id}
      currency={baseCurrency}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
    />
  );
};

export const asOfferProductItem = (newRow: GridRowModel, vendorProducts: Product[]): OfferProduct => {
  const hasSpan =
    newRow.allocationSpan?.start_date || newRow.allocationSpan?.end_date || newRow.allocationSpan?.rollingFrequency;

  return {
    ...asProductItem(newRow, vendorProducts),
    licenseCost: newRow.licenseCost,
    allocationSpan: hasSpan
      ? {
          start_date: newRow.allocationSpan?.start_date,
          end_date: newRow.allocationSpan?.end_date,
          rolling_frequency: newRow.allocationSpan?.rollingFrequency,
        }
      : undefined,
  };
};
