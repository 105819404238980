import React from 'react';
import { Stack } from '@mui/material';

import { Dropzone, ErrorMessageList, FileList, Fileupload } from '@verticeone/design-system/src/components/Fileupload';
import Loader from '@verticeone/design-system/src/components/Loader';

import {
  acceptedTypes,
  customErrorCodes,
  maxFileBytesSize,
  validator,
} from '@vertice/core/src/modules/saas/contract/components/DocumentUpload/utils';
import useRequestDocuments from '../../useRequestDocuments';
import { useRequestContext } from '../../RequestContext';

export const DocumentsSection = () => {
  const { requestId } = useRequestContext();
  const { files, isFetching, uploadFiles, deleteFile, downloadFile, allowDeletion } = useRequestDocuments(requestId);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Stack>
      <Fileupload
        multiple
        acceptedTypes={acceptedTypes}
        maxFileSize={maxFileBytesSize}
        files={files}
        onAddFiles={uploadFiles}
        onRemoveFiles={deleteFile}
        validator={validator}
        customErrorCodes={customErrorCodes}
      >
        <Stack gap={4}>
          <FileList
            size="M"
            hideErrorFiles
            dynamicFileNameLength
            onFileNameClick={downloadFile}
            hiddenColumns={allowDeletion ? [] : ['remove']}
          />
          <Stack height={210} alignItems="center">
            <Dropzone size="M" />
          </Stack>
          <ErrorMessageList size="S" />
        </Stack>
      </Fileupload>
    </Stack>
  );
};
