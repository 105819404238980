import React, { FC } from 'react';
import { styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import { NodeControls } from './NodeControls';
import { Event } from '../../definitionsTypes';
import { StyledDoubleCircleNode } from './Shared';
import {
  WarningOutlined,
  EmailOutlined,
  AccessTimeOutlined,
  PodcastsOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper/IconWrapper';

export const StyledEventNode = styled(StyledDoubleCircleNode)(({ theme: { palette } }) => ({
  backgroundColor: palette.info.color4,
}));

const TypeIconMap: Record<Event['eventType'], SvgIconComponent> = {
  Error: WarningOutlined,
  Message: EmailOutlined,
  Signal: PodcastsOutlined,
  Timer: AccessTimeOutlined,
};

export const EventNodeComponent: FC<NodeProps<Node<Event>>> = (props) => (
  <NodeControls
    {...props}
    content={
      <StyledEventNode $selected={props.selected}>
        <IconWrapper size="XL" icon={TypeIconMap[props.data.eventType]} />
        {props.data.name}
      </StyledEventNode>
    }
  />
);
