import { Mentions, Placeholders, Segment } from './types';

export const makeMentionsMarkup = (markup: string, id: string, display?: string) => {
  return markup.replace(Placeholders.id, id).replace(Placeholders.display, display || id);
};

export const mentionSegments = (value: string, regex: RegExp, displayTransform: (id: string) => string): Mentions => {
  const segments: Segment[] = [];

  const mentionSegment = (markup: string, index: number, indexInPlaintext: number, id: string, label: string) =>
    segments.push({
      type: 'mention',
      key: `${index}-${id}`,
      index,
      indexInPlaintext,
      label,
      markup,
    });
  const textSegment = (label: string, index: number, indexInPlaintext: number) =>
    segments.push({
      type: 'text',
      key: `${index}-${indexInPlaintext}`,
      index,
      indexInPlaintext,
      label,
    });

  let start = 0;
  let currentPlainTextIndex = 0;

  // detect all mention markup occurrences in the value and iterate the matches
  while (true) {
    const match = regex.exec(value);
    if (match === null) {
      break;
    }

    const id = match[1];
    const display = displayTransform(id);

    const substr = value.substring(start, match.index);
    textSegment(substr, start, currentPlainTextIndex);
    currentPlainTextIndex += substr.length;

    mentionSegment(match[0], match.index, currentPlainTextIndex, id, display);
    currentPlainTextIndex += display.length;
    start = regex.lastIndex;
  }

  if (start < value.length) {
    textSegment(value.substring(start), start, currentPlainTextIndex);
  }

  return {
    segments,
    plainText: getPlainText(segments),
    rawLength: value.length,
  };
};

export function getPlainText(segments: Segment[]) {
  return segments.map(({ label }) => label).join('');
}

export function mapPlainTextIndex(
  segments: Segment[],
  indexInPlainText: number,
  inMarkupCorrection: 'START' | 'END' | 'NULL' = 'START',
  fallback: number
): number | null | undefined {
  if (typeof indexInPlainText !== 'number') {
    return indexInPlainText;
  }

  let result: number | undefined | null = undefined;

  for (const segment of segments) {
    if (result !== undefined) {
      continue;
    }

    const { label, index, indexInPlaintext } = segment;
    if (segment.type === 'text' && indexInPlaintext + label.length >= indexInPlainText) {
      // found the corresponding position in the current plain text range
      result = index + indexInPlainText - indexInPlaintext;
    } else if (segment.type === 'mention' && indexInPlaintext + label.length > indexInPlainText) {
      if (inMarkupCorrection === 'NULL') {
        result = null;
      } else {
        result = index + (inMarkupCorrection === 'END' ? segment.markup.length : 0);
      }
    }
  }

  // when a mention is at the end of the value and we want to get the cursor position
  // at the end of the string, result is undefined
  return result === undefined ? fallback : result;
}

export function applyChangeToValue(
  value: string,
  rawLength: number,
  plainTextValue: string,
  oldPlainTextValue: string,
  selectionStartBefore: number | null,
  selectionEndBefore: number | null,
  selectionEndAfter: number,
  segments: Segment[],
  regex: RegExp,
  displayTransform: (id: string) => string
) {
  const lengthDelta = oldPlainTextValue.length - plainTextValue.length;
  if (selectionStartBefore === null) {
    selectionStartBefore = selectionEndAfter + lengthDelta;
  }

  if (selectionEndBefore === null) {
    selectionEndBefore = selectionStartBefore;
  }

  // Fixes an issue with replacing combined characters for complex input. Eg like acented letters on OSX
  if (
    selectionStartBefore === selectionEndBefore &&
    selectionEndBefore === selectionEndAfter &&
    oldPlainTextValue.length === plainTextValue.length
  ) {
    selectionStartBefore = selectionStartBefore - 1;
  }

  // extract the insertion from the new plain text value
  let insert = plainTextValue.slice(selectionStartBefore, selectionEndAfter);

  // handling for Backspace key with no range selection
  let spliceStart = Math.min(selectionStartBefore, selectionEndAfter);

  let spliceEnd = selectionEndBefore;
  if (selectionStartBefore === selectionEndAfter) {
    // handling for Delete key with no range selection
    spliceEnd = Math.max(selectionEndBefore, selectionStartBefore + lengthDelta);
  }

  let mappedSpliceStart = mapPlainTextIndex(segments, spliceStart, 'START', rawLength);
  let mappedSpliceEnd = mapPlainTextIndex(segments, spliceEnd, 'END', rawLength);

  const controlSpliceStart = mapPlainTextIndex(segments, spliceStart, 'NULL', rawLength);
  const controlSpliceEnd = mapPlainTextIndex(segments, spliceEnd, 'NULL', rawLength);
  const willRemoveMention = controlSpliceStart === null || controlSpliceEnd === null;

  let newValue = spliceString(value, mappedSpliceStart || 0, mappedSpliceEnd || 0, insert);
  const { plainText: controlPlainTextValue } = mentionSegments(newValue, regex, displayTransform);

  if (!willRemoveMention) {
    // test for auto-completion changes
    if (controlPlainTextValue !== plainTextValue) {
      // some auto-correction is going on

      // find start of diff
      spliceStart = 0;
      while (plainTextValue[spliceStart] === controlPlainTextValue[spliceStart]) spliceStart++;

      // extract auto-corrected insertion
      insert = plainTextValue.slice(spliceStart, selectionEndAfter);

      // find index of the unchanged remainder
      spliceEnd = oldPlainTextValue.lastIndexOf(plainTextValue.substring(selectionEndAfter));

      // re-map the corrected indices
      mappedSpliceStart = mapPlainTextIndex(segments, spliceStart, 'START', rawLength);
      mappedSpliceEnd = mapPlainTextIndex(segments, spliceEnd, 'END', rawLength);
      newValue = spliceString(value, mappedSpliceStart || 0, mappedSpliceEnd || 0, insert);
    }
  }

  return { value: newValue, plainText: controlPlainTextValue };
}

export function findStartOfMentionInPlainText(segments: Segment[], indexInPlainText: number): number | undefined {
  let result: number | undefined = undefined;

  for (const segment of segments) {
    if (segment.type === 'text') {
      continue;
    }
    const { label, indexInPlaintext: mentionPlainTextIndex } = segment;
    if (mentionPlainTextIndex <= indexInPlainText && mentionPlainTextIndex + label.length > indexInPlainText) {
      result = mentionPlainTextIndex;
    }
  }
  return result;
}

export function spliceString(str: string, start: number, end: number, insert: string): string {
  return str.substring(0, start) + insert + str.substring(end);
}
