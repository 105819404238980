import React, { FC } from 'react';
import { PropsWithContract } from '../types';
import { isRollingContract } from '../../../../../../saas/contract/computed';
import { RollingFrequency } from './RollingFrequency';
import { Stack } from '@mui/material';
import { StartDate } from './StartDate';
import { NextRollDate } from './NextRollDate';
import { SignDate } from './SignDate';
import { RenewalDate } from './RenewalDate';
import { AutoRenewalDate } from './AutoRenewalDate';
import { ContractLength } from './ContractLength';
import { Reseller } from './Reseller';

export const Lifecycle: FC<PropsWithContract> = ({ contract }) => {
  const isRolling = isRollingContract(contract);

  return isRolling ? (
    <Stack direction="row" padding={4} flexWrap="wrap" rowGap={6}>
      <RollingFrequency contract={contract} />
      <StartDate contract={contract} />
      <NextRollDate contract={contract} />
      <SignDate contract={contract} />
    </Stack>
  ) : (
    <Stack direction="row" padding={4} flexWrap="wrap" rowGap={6}>
      <RollingFrequency contract={contract} />
      <StartDate contract={contract} />
      <RenewalDate contract={contract} />
      <SignDate contract={contract} />
      <AutoRenewalDate contract={contract} />
      <ContractLength contract={contract} />
      <Reseller contract={contract} />
    </Stack>
  );
};
