import { Offer } from '../../../../../task/TaskModal/TaskModalForm/predefinedForms/shared/schemas';
import { OfferType } from '../types';
import { useTranslation } from 'react-i18next';

type UseOfferFooter = {
  offer: Offer;
  baselineOffer?: Offer | null;
  type: OfferType;
};

type UseOfferFooterValuesReturn = {
  title: string;
  cost: number;
  savings?: {
    baseSavings: number;
    concessionSavings: number;
  };
};

export const useOfferFooterValues = ({ offer, baselineOffer, type }: UseOfferFooter) => {
  const { t } = useTranslation();

  const getOfferFooterValues = () => {
    let footer: UseOfferFooterValuesReturn = { title: '', cost: 0 };

    if (offer.overrideEffectiveCost && offer.effectiveCosts) {
      footer.title = t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.TOTAL_COST');
      footer.cost = offer.effectiveCosts;
    } else {
      footer.title = t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_SERVICES_COST');
      footer.cost = offer.computedCosts ?? 0;
    }

    if (baselineOffer) {
      switch (type) {
        case 'enumerated':
        case 'final':
          const baseOfferCost =
            (baselineOffer.overrideEffectiveCost ? baselineOffer.effectiveCosts : baselineOffer.computedCosts) ?? 0;
          const offerCost = (offer.overrideEffectiveCost ? offer.effectiveCosts : offer.computedCosts) ?? 0;

          const baseSavings = baseOfferCost - offerCost;
          const concessionSavings = offer.concessions.reduce(
            (acc, concession) => (acc += concession.status === 'REALISED' ? concession.amount : 0),
            0
          );

          footer.savings = {
            baseSavings,
            concessionSavings,
          };
          break;
        default:
          break;
      }
    }

    return footer;
  };

  return { getOfferFooterValues };
};
