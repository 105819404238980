import React from 'react';
import { Stack } from '@mui/material';
import Card from '@verticeone/design-system/src/components/Card';
import Divider from '@verticeone/design-system/src/components/Divider';
import TableLayout from '../BaseCloudTab/Table/TableLayout';
import TableBreadcrumbs from '../BaseCloudTab/Table/TableBreadcrumbs';
import TableByLastNode from './Table/components/TableByLastNode';
import GraphByLastNode from './Graph/components/GraphByLastNode';
import StatsByLastNode from './Stats/StatsByLastNode';
import Filter from './Filter/Filter';
import useTagsData from './Filter/useTagsData';
import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';
import { useCloudAnalytics } from '../../CloudAnalyticsContext';
import Alert from '@verticeone/design-system/src/components/Alert';
import { ErrorOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const CloudTagsTab = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.TAGS' });
  const { period } = useCloudAnalytics();
  const { data, isLoading, error } = useTagsData({
    ...period,
  });

  return (
    <LoadableContentWrapper error={error} isEmpty={false} isLoading={isLoading}>
      <Stack gap={4}>
        <TableBreadcrumbs />
        <Filter data={data} />
        {!data || data.length === 0 ? (
          <Alert
            color="warning"
            icon={ErrorOutline}
            size="M"
            title={t('NO_TAG_DATA_ALERT.TITLE')}
            subtitle={t('NO_TAG_DATA_ALERT.SUBTITLE')}
            testId="no-tag-data-alert-test-id"
            variant="ghost"
          />
        ) : (
          <Card>
            <StatsByLastNode />
            <Divider />
            <Stack>
              <GraphByLastNode />
              <Divider />
              <TableLayout>
                <TableByLastNode />
              </TableLayout>
            </Stack>
          </Card>
        )}
      </Stack>
    </LoadableContentWrapper>
  );
};

export default CloudTagsTab;
