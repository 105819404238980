import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowModel } from '@mui/x-data-grid-pro';
import useTagsServicesUsageData, { TableRow } from '../useTagsServicesUsageData';
import { useCloudAnalytics } from '../../../../CloudAnalyticsContext';
import { getTableHeight } from '../../../BaseCloudTab/Table/utils';
import BaseCloudAnalyticsTable from '../../../BaseCloudTab/Table/BaseCloudAnalyticsTable';

type ReservedInstancesTableProps = {
  productCode: string;
};

const CloudTagsServicesUsageTable = ({ productCode }: ReservedInstancesTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE' });
  const { getLastNode } = useCloudAnalytics();
  const { search, loadingHeight } = getLastNode();
  const { data, ...loadingStatus } = useTagsServicesUsageData({
    productCode,
  });
  const filteredData = data?.tableData?.filter((row) =>
    row.usageType?.toLowerCase().includes(search?.toLowerCase() || '')
  );

  const getRowId = (row: GridRowModel<TableRow>) => row.usageType;

  const calculatedHeight = getTableHeight(filteredData);

  return (
    <BaseCloudAnalyticsTable<TableRow>
      height={loadingStatus.isLoading && loadingHeight ? loadingHeight : calculatedHeight}
      data={filteredData || []}
      firstColumn={{
        field: 'usageType',
        headerName: t('COLUMN.USAGE_TYPE'),
      }}
      getRowId={getRowId}
      disableRowSelectionOnClick
      isLoading={loadingStatus.isLoading}
    />
  );
};

export default CloudTagsServicesUsageTable;
