import {
  CloudInventoryProvider,
  Tab,
} from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/useCloudInventory';
import React from 'react';

import CloudInventoryContent from './CloudInventoryContent';
import Ec2RIUtilizationCard from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/Ec2/Ec2RIUtilizationCard';
import RdsRIUtilizationCard from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/Rds/RdsRIUtilizationCard';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import { useRoutes } from '@verticeone/router/src';

const CloudInventoryTab = () => {
  const routes = useRoutes();

  const tabs: Tab[] = [
    {
      id: 'ec2',
      instanceTypePosition: { instanceFamily: 0, size: 1 },
      component: Ec2RIUtilizationCard,
    },
    {
      id: 'rds',
      instanceTypePosition: { instanceFamily: 1, size: 2 },
      component: RdsRIUtilizationCard,
    },
  ];

  const routeConfig = {
    path: ROUTES.CLOUD_RIO_TAB_DETAIL,
    route: routes.CLOUD.RIO.INVENTORY.DETAIL,
  };

  return (
    <CloudInventoryProvider tabs={tabs} routeConfig={routeConfig}>
      <CloudInventoryContent />
    </CloudInventoryProvider>
  );
};

export default CloudInventoryTab;
