import { Links } from '@vertice/slices';
import { isNil } from 'lodash';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import React from 'react';
import { LinkButton } from './LinkButton';
import { removeProtocolFromUrl } from '@vertice/core/src/utils/strings/removeProtocolFromUrl';

type LinkSectionProps = {
  title: string;
  links?: Links;
};

export const LinkSection = ({ title, links }: LinkSectionProps) => {
  if (isNil(links) || links.length === 0) return null;

  return (
    <Stack gap={2}>
      <Text variant="caption" size="XS" color="text2">
        {title}
      </Text>
      {links.map(({ label, link }, index) => (
        <LinkButton key={index} label={label} url={removeProtocolFromUrl(link)} />
      ))}
    </Stack>
  );
};
