import { PricingRates } from './types';
import { DesignSystemColor } from '@verticeone/design-system/src/types';

const RATING_MAX = 5;
const SCORE_MAX = 100;

const rescaleRatingToScore = (value?: number) => {
  return value ? Math.round((value * SCORE_MAX) / RATING_MAX) : 0;
};

const calculateOverallScore = ({ simplicity = 0, transparency = 0, parity = 0 }: PricingRates) => {
  const averageRating = (Number(simplicity) + Number(transparency) + Number(parity)) / 3;
  return rescaleRatingToScore(averageRating);
};

export const getPricingScoreValues = (rates: PricingRates) => ({
  simplicityScore: rescaleRatingToScore(rates.simplicity),
  transparencyScore: rescaleRatingToScore(rates.transparency),
  parityScore: rescaleRatingToScore(rates.parity),
  overallScore: calculateOverallScore(rates),
});

export const getChipColorBasedOnValue = (value: number): DesignSystemColor => {
  return value < 34 ? 'error' : value < 67 ? 'warning' : 'success';
};
