import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import Text from '@verticeone/design-system/src/components/Text';

type FieldProps = {
  labelTranslationKey: string;
  value?: string;
};

export const Field: FC<FieldProps> = ({ labelTranslationKey, value }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={0} flexBasis="33%">
      <TextFieldCaption label={t(labelTranslationKey)} size="XS" />
      <Text variant="body-regular" color="text1">
        {value ?? '-'}
      </Text>
    </Stack>
  );
};
