import { useTranslation } from 'react-i18next';
import { useRequests } from './useRequests';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import NoData from '@verticeone/design-system/src/components/NoData';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import Tab from '@verticeone/design-system/src/components/Tab';
import { RequestRow } from './types';
import { GridRowClassNameParams, GridRowHeightParams, GridRowModel } from '@mui/x-data-grid-pro';
import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import TabsBottomLineWrapper from '@verticeone/design-system/src/components/Tabs/TabsBottomLineWrapper';
import React, { useCallback } from 'react';
import { Stack, styled } from '@mui/material';
import { useGroupingColumn, useRequestsColumns } from './useRequestsColumns';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';

const StyledDataGrid = styled(DataGrid<RequestRow>)(({ theme }) => ({
  '& .MuiDataGrid-columnHeader:first-child': {
    paddingLeft: 86,
  },
  '& .MuiDataGrid-cell:first-child': {
    paddingLeft: 4,
  },
  '& .child-row': {
    backgroundColor: theme.palette.core.color1,
    '&, &:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.core.color1,
    },
  },
}));

export const RequestsListPage = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState('active');
  const showClosed = activeTab === 'closed';
  const { requests, requestIds, isLoadingRequests } = useRequests(showClosed);
  const columns = useRequestsColumns(showClosed);
  const groupingColumn = useGroupingColumn();

  const rowIdGetter = (row: GridRowModel<RequestRow>) => row.requestId;
  const getTreeDataPath = useCallback(
    ({ parentId: parent, requestId }: GridRowModel<RequestRow>) =>
      parent && requestIds.has(parent) ? [parent, requestId] : [requestId],
    [requestIds]
  );

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<RequestRow>) => {
      return row.parentId && requestIds.has(row.parentId) ? 'child-row' : '';
    },
    [requestIds]
  );
  const getRowHeight = useCallback(
    ({ model }: GridRowHeightParams) => {
      return model.parentId && requestIds.has(model.parentId) ? 60 : 74;
    },
    [requestIds]
  );

  return (
    <IntelligentWorkflowsLayout heading={t('INTELLIGENT_WORKFLOWS.NAVBAR.REQUESTS')}>
      <Stack gap={8}>
        <TabsBottomLineWrapper paddingX={4}>
          <Tabs
            variant="outlined"
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
            scrollButtons="auto"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            <Tab value="active" label={t(`INTELLIGENT_WORKFLOWS.REQUESTS_LIST.ACTIVE_TAB`)} />
            <Tab value="closed" label={t(`INTELLIGENT_WORKFLOWS.REQUESTS_LIST.CLOSED_TAB`)} />
          </Tabs>
        </TabsBottomLineWrapper>
        <UsersContextProvider>
          <StyledDataGrid
            treeData
            defaultGroupingExpansionDepth={2}
            getRowClassName={getRowClassName}
            rows={requests}
            columns={columns}
            getRowId={rowIdGetter}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColumn}
            sortingMode="client"
            disableRowSelectionOnClick
            rowSelection={false}
            noRowsOverlayHeight={200}
            getRowHeight={getRowHeight}
            slots={{
              noRowsOverlay: () => <NoData header={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.NO_REQUESTS')} />,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 50 } },
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
            color="neutral"
            loadingStyle="skeleton"
            loading={isLoadingRequests}
          />
        </UsersContextProvider>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
