import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ActionMeta, MultiValue } from 'react-select';
import { Stack } from '@mui/material';
import FormSelectField2 from '../../../../../forms/fields/FormSelectField2';
import { UsersByIdReturn, useAccountUsersById } from '../../../../../../hooks/useAccountUsersById';
import { getFullName } from '../../../../../../utils/formatting/user';
import TextField from '@verticeone/design-system/src/components/TextField';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import { EditUserTaskFormData } from './schema';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';

type Option = {
  readonly value: string;
  readonly label: string;
};

type GroupOption = {
  label: string;
  options: Option[];
  singleOption: boolean;
};

const useAssignmentOptions = (users: UsersByIdReturn): GroupOption[] => {
  const { t } = useTranslation();
  return useMemo(() => {
    const fixedOptions: Option[] = [
      { value: 'REQUEST_OWNER', label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.REQUEST_OWNER') },
      { value: 'WORKFLOWS_CONFIG', label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.WORKFLOWS_CONFIG') },
    ];

    const userOptions: Option[] = Object.values(users).map((user) => ({
      value: user.userId,
      label: getFullName(user),
    }));

    return [
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.DYNAMIC_USERS_GROUP'),
        options: fixedOptions,
        singleOption: true,
      },
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.STATIC_USERS_GROUP'),
        options: userOptions,
        singleOption: false,
      },
    ];
  }, [t, users]);
};

type EditUserTaskFormProps = {
  onDirty: () => void;
};

export const EditUserTaskForm: FC<EditUserTaskFormProps> = ({ onDirty }) => {
  const { t } = useTranslation();
  const { register, setValue, formState } = useFormContext<EditUserTaskFormData>();

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  const { usersById } = useAccountUsersById();

  const assignmentOptions = useAssignmentOptions(usersById);

  // make sure that only options from one select group are applies - either dynamic users or ordinary users
  // at the same time if a group is marked as "singleOption" only single option from the group can be selected
  const handleAssignmentChange = (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    const currentGroup = assignmentOptions.find((group) => group.options.includes(actionMeta.option!));

    if (!currentGroup) {
      setValue(
        'assignees',
        newValue.map((val) => val.value),
        { shouldDirty: true }
      );
      return;
    }
    const groupValues = currentGroup.options.map((option) => option.value);

    const newAssignees =
      currentGroup.singleOption && actionMeta.option !== undefined
        ? [actionMeta.option?.value]
        : newValue.filter((val) => groupValues.includes(val.value)).map((val) => val.value);

    setValue('assignees', newAssignees, { shouldDirty: true });
  };

  return (
    <Stack gap={4} direction={'column'} width={'100%'}>
      <Stack gap={1}>
        <TextFieldCaption label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.NAME')} size="XS" />
        <TextField {...register('name')} variant="outlined" size="S" fullWidth autoComplete="off" />
      </Stack>
      <Stack gap={1}>
        <TextFieldCaption label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.ASSIGNEES')} size="XS" />
        <FormSelectField2<EditUserTaskFormData, Option, true, GroupOption>
          isMulti
          id="assignees"
          name="assignees"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size="S"
          maxMultiChips={1}
          onChange={handleAssignmentChange}
          options={assignmentOptions}
          unboxValue={(value) => value.map((val) => val.value)}
          boxValue={(value) =>
            assignmentOptions
              .map((v) => v.options)
              .flat()
              .filter((val) => (value as string[]).includes(val.value))
          }
        />
      </Stack>
    </Stack>
  );
};
