import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListWorkflowsRequestsPaginatedQuery } from '@vertice/slices/src/api/enhancedBffeWorkflowsAPI';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { isRequestRef, isSaasRenewalServiceRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useNotificationWebSocketSubscription } from '@vertice/core/src/contexts/NotificationWebSocketContext';

export const useContractRenewalRequests = (contract: Contract) => {
  const { accountId, isFeatureEnabled } = useAccountContext();
  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  const {
    data: requests,
    isLoading,
    refetch: refetchLinkedRequests,
  } = useListWorkflowsRequestsPaginatedQuery(
    {
      accountId,
      contractId: contract.record.contractId,
    },
    { skip: !isIWEnabled }
  );

  useNotificationWebSocketSubscription({
    filter: isRequestRef,
    callback: refetchLinkedRequests,
  });

  const linkedRequests = requests?.items.filter((request) => isSaasRenewalServiceRef(request.serviceRef)) || [];

  return {
    linkedRequests,
    isLoadingLinkedRequests: isLoading,
  };
};
