import React, { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { NodeControls } from './NodeControls';
import { WorkflowGatewayNode } from '../../model/types';
import { rgba } from 'polished';
import Text from '@verticeone/design-system/src/components/Text';

const StyledGatewayNode = styled(Stack)(({ theme: { palette } }) => ({
  width: 34,
  height: 34,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  span: {
    color: palette.text.color2,
    position: 'relative',
    top: 30,
    width: 100,
  },
  zIndex: 1,
  // diamond shape
  '&:before': {
    content: '""',
    position: 'absolute',
    border: 'none',
    inset: '12%',
    transform: 'rotateZ(45deg)',
    background: palette.secondary.color4,
    zIndex: -1,
  },
  // gradient border in diamond shape
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: '12%',
    padding: '2px',
    background: `linear-gradient(135deg, ${rgba(palette.secondary.color4, 0.9)} 0%, ${rgba(
      palette.text.color1,
      0.1
    )} 100%)`,
    transform: 'rotateZ(45deg) scale(1.1)',
    zIndex: -2,
  },
}));

export const GatewayNodeComponent: FC<NodeProps<Node<WorkflowGatewayNode>>> = (props) => (
  <NodeControls
    {...props}
    content={
      <StyledGatewayNode>
        <Text variant="heading" size="XS">
          {props.data.name}
        </Text>
      </StyledGatewayNode>
    }
  />
);
