import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridProProps, GridCellParams, GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import AddItemFooter from './AddItemFooter';
import NoRows from './NoRows';
import { ConcessionItem, ConcessionTypeType } from './types';
import TypeCellEditable from './cells/TypeCellEditable';
import DescriptionCellEditable from './cells/DescriptionCellEditable';
import AmountCellEditable from './cells/AmountCellEditable';
import AmountCellReadOnly from './cells/AmountCellReadOnly';
import TypeCellReadOnly from './cells/TypeCellReadOnly';
import useConcessions from './useConcessions';
import StatusCell from './cells/StatusCell';
import { DesignSystemColor } from '@verticeone/design-system/src/types';

export interface ConcessionsListProps {
  extraColumns?: GridColDef[];
  dataGridExtraProps?: Partial<DataGridProProps>;
  readOnlyMode?: boolean;
  selectedConcessions: ConcessionItem[];
  setSelectedConcessions: (newProducts: ConcessionItem[]) => void;
  color?: DesignSystemColor;
}

const ConcessionsList: FC<ConcessionsListProps> = ({
  extraColumns = [],
  dataGridExtraProps = {},
  readOnlyMode,
  selectedConcessions,
  setSelectedConcessions,
  color,
}) => {
  const { t } = useTranslation();

  const { updateConcession } = useConcessions({ selectedConcessions, setSelectedConcessions });
  const statusCell = (params: GridCellParams) => (
    <StatusCell
      color={color}
      {...params}
      onStatusChange={(newValue) => {
        updateConcession({
          ...params.row,
          status: newValue,
        });
      }}
    />
  );

  const typeCell = (params: GridCellParams<any, ConcessionTypeType>) => <TypeCellReadOnly {...params} />;
  const editableTypeCell = (params: GridCellParams<any, ConcessionTypeType>) => <TypeCellEditable {...params} />;

  const editableDescriptionCell = (params: GridCellParams<any, string>) => <DescriptionCellEditable {...params} />;
  const amountCell = (params: GridCellParams<any, number>) => <AmountCellReadOnly {...params} />;
  const editableAmountCell = (params: GridCellParams<any, number>) => <AmountCellEditable {...params} />;

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: t('CONCESSIONS_LIST.COLUMN.REALIZED'),
      renderCell: statusCell,
      renderEditCell: statusCell,
      disableColumnMenu: true,
      editable: !readOnlyMode,
      sortable: false,
      width: 100,
      align: 'center',
    },
    {
      field: 'type',
      headerName: t('CONCESSIONS_LIST.COLUMN.TYPE'),
      renderCell: typeCell,
      renderEditCell: editableTypeCell,
      disableColumnMenu: true,
      editable: !readOnlyMode,
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('CONCESSIONS_LIST.COLUMN.DESCRIPTION'),
      renderEditCell: editableDescriptionCell,
      disableColumnMenu: true,
      editable: !readOnlyMode,
      flex: 1,
    },
    {
      field: 'amount',
      headerName: t('CONCESSIONS_LIST.COLUMN.VALUE'),
      renderCell: amountCell,
      renderEditCell: editableAmountCell,
      disableColumnMenu: true,
      editable: !readOnlyMode,
      maxWidth: 250,
      flex: 1,
    },
  ];

  const columnsToUse = [...columns, ...extraColumns];
  const getRowId = (row: GridRowModel) => row.id ?? `${row.type}-${row.description}-${row.amount}`;

  return (
    <DataGrid
      rows={selectedConcessions || []}
      columns={columnsToUse}
      getRowId={getRowId}
      slots={{
        footer: (args) => <AddItemFooter color={color} {...args} />,
        noRowsOverlay: NoRows,
      }}
      sortingMode="client"
      isCellEditable={() => false}
      disableRowSelectionOnClick
      hideFooter
      columnHeaderHeight={48}
      autoHeight={true}
      color={color}
      {...dataGridExtraProps}
    />
  );
};

export default ConcessionsList;
