import BaseCloudAnalyticsGraph from '../../../BaseCloudTab/Graph/BaseCloudAnalyticsGraph';
import React from 'react';
import useAccountsGraphData from '../useAccountsGraphData';

const CloudAccountsGraph = () => {
  const { data: graphData, ...loadingStatus } = useAccountsGraphData();

  return <BaseCloudAnalyticsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudAccountsGraph;
