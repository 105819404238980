import React from 'react';
import { Box, styled, useTheme } from '@mui/material';
import DataGrid, { GridColDef } from '@verticeone/design-system/src/components/DataGrid';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import Text from '@verticeone/design-system/src/components/Text';
import CurrencyCell from '@vertice/dashboard/src/modules/cloud/cards/shared/ReservedInstancesTable/Cells/CurrencyCell';
import PercentageCell from '@vertice/dashboard/src/modules/cloud/cards/shared/ReservedInstancesTable/Cells/PercentageCell';
import { useTranslation } from 'react-i18next';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';
import { gridClasses, GridRowModel } from '@mui/x-data-grid-pro';
import { useDateRangeFormatter } from '../utils';
import GridHeaderCellWithSubtitle from './ExpandableHeader';

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.core.bg,
}));

const GREY_CELL_CLASS = 'grey-cell';

type BaseCloudAccountTableProps<T> = {
  data: T[];
  height: string;
  onRowClick?: (row: GridRowModel) => void;
  getRowId: (row: T) => string;
  firstColumn: {
    field: string;
    headerName: string;
  };
  disableRowSelectionOnClick?: boolean;
  isLoading?: boolean;
};

const BaseCloudAnalyticsTable = <T extends object>({
  data,
  firstColumn,
  height,
  getRowId,
  onRowClick,
  disableRowSelectionOnClick,
  isLoading,
}: BaseCloudAccountTableProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE' });
  const { palette } = useTheme();
  const {
    period: { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd },
  } = useCloudAnalytics();
  const formatDateRange = useDateRangeFormatter();
  const [expandCurrentPeriod, setExpandCurrentPeriod] = React.useState(false);
  const [expandPreviousPeriod, setExpandPreviousPeriod] = React.useState(false);

  const columnVisibilityModel = React.useMemo(() => {
    return {
      charges: expandCurrentPeriod,
      credit: expandCurrentPeriod,
      tax: expandCurrentPeriod,
      previousCharges: expandPreviousPeriod,
      previousCredit: expandPreviousPeriod,
      previousTax: expandPreviousPeriod,
    };
  }, [expandCurrentPeriod, expandPreviousPeriod]);

  const createExpandableColumnDefinition = ({
    field,
    headerName,
    dateRangeText,
    isExpanded,
    toggleExpand,
  }: {
    field: string;
    headerName: string;
    dateRangeText?: string;
    isExpanded: boolean;
    toggleExpand: () => void;
  }): GridColDef<T> => ({
    field,
    headerName,
    renderHeader: (params) => (
      <GridHeaderCellWithSubtitle
        {...params}
        subtitle={dateRangeText}
        expandable={{ isExpanded, toggle: toggleExpand }}
      />
    ),
    renderCell: ({ value }) => <CurrencyCell value={value} maximumFractionDigits={2} />,
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
    editable: false,
    minWidth: 240,
    flex: 1,
  });

  const createGreyColumnDefinition = ({
    field,
    headerName,
    minWidth,
  }: {
    field: string;
    headerName: string;
    minWidth?: number;
  }): GridColDef<T> => ({
    field,
    headerName,
    renderCell: ({ value }) => <CurrencyCell value={value} maximumFractionDigits={2} />,
    disableColumnMenu: true,
    cellClassName: GREY_CELL_CLASS,
    headerClassName: GREY_CELL_CLASS,
    align: 'right',
    headerAlign: 'right',
    editable: false,
    minWidth: minWidth || 160,
    flex: 1,
  });

  const columns: GridColDef<T>[] = [
    {
      field: firstColumn.field,
      headerName: firstColumn.headerName,
      cellClassName: onRowClick ? 'pointer' : '',
      renderCell: ({ value }) => (
        <Text variant="body-regular" size="M">
          {value}
        </Text>
      ),
      disableColumnMenu: true,
      editable: false,
      minWidth: 220,
      flex: 1,
    },
    createExpandableColumnDefinition({
      field: 'cost',
      headerName: t('COLUMN.COSTS'),
      dateRangeText: formatDateRange(periodOneStart, periodOneEnd),
      isExpanded: expandCurrentPeriod,
      toggleExpand: () => setExpandCurrentPeriod((prev) => !prev),
    }),
    createGreyColumnDefinition({ field: 'charges', headerName: t('COLUMN.CHARGES') }),
    createGreyColumnDefinition({ field: 'credit', headerName: t('COLUMN.CREDITS') }),
    createGreyColumnDefinition({ field: 'tax', headerName: t('COLUMN.TAXES') }),
    createExpandableColumnDefinition({
      field: 'previousCost',
      headerName: t('COLUMN.PREVIOUS_COSTS'),
      dateRangeText: formatDateRange(periodTwoStart, periodTwoEnd),
      isExpanded: expandPreviousPeriod,
      toggleExpand: () => setExpandPreviousPeriod((prev) => !prev),
    }),
    createGreyColumnDefinition({
      field: 'previousCharges',
      headerName: t('COLUMN.CHARGES'),
      minWidth: 220,
    }),
    createGreyColumnDefinition({ field: 'previousCredit', headerName: t('COLUMN.CREDITS') }),
    createGreyColumnDefinition({ field: 'previousTax', headerName: t('COLUMN.TAXES') }),
    {
      field: 'difference',
      headerName: t('COLUMN.CHANGE_PERCENTAGE'),
      renderCell: (params) => {
        const value = Number.isFinite(params.value) ? params.value : null;
        return (
          <PercentageCell
            value={value}
            customNullPlaceholder="-"
            formatOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2, signDisplay: 'exceptZero' }}
          />
        );
      },
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      editable: false,
      minWidth: 220,
      flex: 1,
    },
  ];

  return (
    <StyledBox height={height}>
      <DataGrid<T>
        noBorder
        noBorderRadius
        rows={data}
        size="XL"
        headerSize="M"
        loading={isLoading}
        loadingRowCount={10}
        loadingStyle="skeleton"
        columns={columns}
        getRowId={getRowId}
        onRowClick={onRowClick}
        initialState={{
          sorting: {
            sortModel: [{ field: 'cost', sort: 'desc' }],
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        sortingMode="client"
        hideFooter
        color={AWS_BRAND_COLOR}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        sx={{
          [`.${GREY_CELL_CLASS}`]: {
            backgroundColor: `${palette.input.color2} !important`,
          },
          [`.${GREY_CELL_CLASS}.${gridClasses.cell}.${gridClasses.withBorderColor}`]: {
            borderColor: palette.core.color3,
          },
        }}
      />
    </StyledBox>
  );
};

export default BaseCloudAnalyticsTable;
