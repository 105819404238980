import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { useCloudClient } from '../CloudClientProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { type EdpSavingsCheckQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';
import { type MonitoringValueCheckResult } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

type DataColumns =
  | 'aws_cost'
  | 'aws_edp_discount'
  | 'aws_edp_discounted_spend'
  | 'marketplace_cost'
  | 'marketplace_edp_discount'
  | 'marketplace_edp_discounted_spend'
  | 'total_cost'
  | 'total_edp_discount'
  | 'total_edp_discounted_spend';

const EDPSavingsQuery = graphql(`
  query EDPSavingsCheck($accountId: String!, $startDate: AWSDate!, $checkCode: String!) {
    checkQuery(params: { accountId: $accountId, startDate: $startDate, code: $checkCode }) {
      ... on CheckResult {
        __typename
        result {
          values {
            dateEnd
            dateStart
            checkResult {
              __typename
              columns
              data
              dataTypes
            }
          }
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

const parseData = (data: EdpSavingsCheckQuery) => {
  if (data?.checkQuery?.__typename === 'CheckResult') {
    return (
      data.checkQuery.result
        ?.map((item) => item!.values)
        .flat()
        .map((item) => ({
          dateEnd: item!.dateEnd,
          dateStart: item!.dateStart,
          data: getTableData(item!.checkResult as MonitoringValueCheckResult)?.[0] as Record<DataColumns, number>,
        })) ?? []
    );
  }

  return [];
};

export const useSavingsData = () => {
  const { accountId } = useAccountContext();
  const startDate = useMemo(() => dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'), []);
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['EDPSavings'],
    queryFn: () =>
      fetchCloudOptimization(EDPSavingsQuery, {
        accountId,
        startDate,
        checkCode: 'TOTAL_COST_DISCOUNT',
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.checkQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => parseData(data).sort((a, b) => a.dateStart.localeCompare(b.dateStart)),
  });
};

export type SavingsData = ReturnType<typeof parseData>;
