import {
  RequestContextData,
  RequestContextProvider,
} from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/RequestContext';
import { RequestDetailPage as RequestDetailPageContent } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/RequestDetailPage';
import { useParams } from 'react-router-dom';
import { useRequestDetails } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/useRequestDetails';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { widgetDef as vendorDiligenceWidgetDef } from './widgets/vendorDiligence/widgetDef';
import { getDataForWidget } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/widgets/shared/utils';
import { LINKED_CONTRACT_WIDGET_URN_PATTERN } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/widgets/LinkedContracts/widgetDef';

export const RequestDetailPage = () => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();
  const params = useParams() as { requestId: string };
  const { request, parentRequest, isLoading, workflowVersion, isExecutedByWorkflow } = useRequestDetails(
    params.requestId
  );

  const parentRequestRef = parentRequest ? parseRequestRef(parentRequest.ref) : undefined;
  const parentRequestPath = parentRequestRef
    ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, { requestId: parentRequestRef.requestId })
    : undefined;

  const contractId = request
    ? getDataForWidget(request, LINKED_CONTRACT_WIDGET_URN_PATTERN)?.contractId ?? request?.verticeReserved?.contractId
    : undefined;
  const linkedContractPath = contractId
    ? generatePathForRoute(routes.CONTRACTS.DETAIL, {
        contractId,
      })
    : undefined;

  const requestContext: RequestContextData = {
    requestId: params.requestId,
    request,
    parentRequest,
    parentRequestPath,
    linkedContractPath,
    isLoading,
    workflowVersion,
    isExecutedByWorkflow,
    additionalWidgets: [vendorDiligenceWidgetDef],
  };

  return (
    <RequestContextProvider value={requestContext}>
      <RequestDetailPageContent />
    </RequestContextProvider>
  );
};
