import { useCallback, useMemo, useState } from 'react';
import { Suggestion, UserSuggestion } from './types';

export const useSuggestionSelection = (
  listRef: HTMLUListElement | null,
  suggestions: Suggestion[],
  onBlur: () => void,
  onSelect: (d: UserSuggestion, s: number, e: number) => void
) => {
  const [focusIndex, setFocusIndex] = useState(0);

  const changeFocusIndex = useCallback(
    (direction: 'next' | 'prev', suggestionsLen: number) =>
      setFocusIndex((index) => {
        const delta = direction === 'next' ? 1 : -1;
        const nextIndex = (suggestionsLen + index + delta) % suggestionsLen;

        if (listRef) {
          const scrollTop = listRef.scrollTop;

          let { top, bottom } = listRef.children[nextIndex].getBoundingClientRect();
          const { top: topContainer } = listRef.getBoundingClientRect();
          top = top - topContainer + scrollTop;
          bottom = bottom - topContainer + scrollTop;

          if (top < scrollTop) {
            listRef.scrollTop = top;
          } else if (bottom > listRef.offsetHeight + scrollTop) {
            listRef.scrollTop = bottom - listRef.offsetHeight;
          }
        }

        return nextIndex;
      }),
    [listRef]
  );

  const clear = useCallback(() => {
    setFocusIndex(0);
    onBlur();
  }, [onBlur]);

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      const suggestionLen = suggestions?.length || 0;

      if (ev.key === 'Escape') {
        clear();
      } else if (ev.key === 'ArrowDown') {
        changeFocusIndex('next', suggestionLen);
      } else if (ev.key === 'ArrowUp') {
        changeFocusIndex('prev', suggestionLen);
      } else if (['Enter', 'Tab'].includes(ev.key)) {
      } else {
        return;
      }

      ev.preventDefault();
      ev.stopPropagation();
    },
    [changeFocusIndex, clear, suggestions]
  );

  const onKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      if (['Enter', 'Tab'].includes(ev.key)) {
        const { result, querySequenceStart, querySequenceEnd } = suggestions[focusIndex];
        onSelect(result, querySequenceStart, querySequenceEnd);

        ev.preventDefault();
        ev.stopPropagation();
      }
    },
    [focusIndex, onSelect, suggestions]
  );

  return useMemo(
    () => ({
      focusIndex,
      onKeyDown,
      onKeyUp,
      clear,
    }),
    [focusIndex, onKeyDown, onKeyUp, clear]
  );
};
