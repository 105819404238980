import sleep from '@vertice/core/src/utils/async/sleep';
import { useLazyGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useMemo } from 'react';

export const useWaitForRequestReady = (delay = 2000, maxAttempts = 20) => {
  const [getRequest] = useLazyGetRequestQuery();

  return useMemo(
    () => async (accountId: string, requestId: string) => {
      let requestReady = false;
      let attempts = 0;
      do {
        await sleep(delay);
        requestReady = await getRequest({ accountId, requestId })
          .unwrap()
          .then((response) => response.request?.status === 'ACTIVE' && !!response.request.executionRef);
        attempts++;
      } while (!requestReady && attempts < maxAttempts);
      return requestReady;
    },
    [delay, getRequest, maxAttempts]
  );
};
