import { useMemo } from 'react';
import { ResourceThumbnail, useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { TasksThumbnail } from '../../definitionsTypes';

const convertToTasksThumbnail = (serviceThumbnail: ResourceThumbnail): TasksThumbnail => {
  return {
    id: serviceThumbnail.Id,
    type: serviceThumbnail.Type,
  };
};

export type ServicesThumbnails = Record<string, TasksThumbnail>;

export const useServicesThumbnails = (): ServicesThumbnails => {
  const { accountId } = useAccountContext();
  const { data: serviceCatalog } = useListServiceCatalogQuery({ accountId });

  const items = useMemo(() => {
    if (!serviceCatalog?.items) {
      return {};
    }

    return serviceCatalog.items.reduce((acc, service) => {
      const resourceId = service.urn.slice(service.urn.indexOf('/') + 1);

      if (service.urn && service.thumbnail) {
        acc[resourceId] = convertToTasksThumbnail(service.thumbnail);
      }
      return acc;
    }, {} as Record<string, TasksThumbnail>);
  }, [serviceCatalog]);

  return items;
};
