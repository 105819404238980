export type AuthRequestProps<ID extends string = string> = {
  id: ID;
  object: string;
  action: string;
};

export type AuthResponseProps = {
  id: string;
  eft: boolean | undefined;
};

export type ReducerItem<ID extends string = string> = {
  isLoading: boolean;
  isAllowed: boolean;
  metadata: AuthRequestProps<ID>;
};

type ReducerAction =
  | { type: 'ADD_POLICIES'; payload: AuthRequestProps | Array<AuthRequestProps> }
  | { type: 'VALIDATE_POLICIES'; payload: AuthResponseProps | Array<AuthResponseProps> };

export const authRequestDataToItem = <ID extends string>(item: AuthRequestProps<ID>): ReducerItem<ID> => ({
  isAllowed: false,
  isLoading: true,
  metadata: item,
});

export const authorizerReducer = (state: Array<ReducerItem>, action: ReducerAction): Array<ReducerItem> => {
  switch (action.type) {
    case 'ADD_POLICIES': {
      // Check if all items are already in the state to avoid rerendering
      if ([action.payload].flat().every((item) => state.some(({ metadata }) => metadata.id === item.id))) {
        return state;
      }

      return [
        ...state,
        ...[action.payload]
          .flat()
          .map(authRequestDataToItem)
          .filter((newItem) => !state.some((item) => item.metadata.id === newItem.metadata.id)),
      ];
    }
    case 'VALIDATE_POLICIES':
      const idx = [action.payload].flat().map((item) => item.id);

      return [
        ...(state
          .filter((item) => idx.some((id) => id === item.metadata.id))
          .map<ReducerItem | undefined>((item) => {
            const newItem = [action.payload].flat().find(({ id }) => id === item.metadata.id);

            return newItem ? { ...item, isAllowed: !!newItem.eft, isLoading: false } : undefined;
          })
          .filter((item) => !!item) as Array<ReducerItem>),
      ];
    default:
      return state;
  }
};
