import Text from '@verticeone/design-system/src/components/Text';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnnualCostCell,
  EditableAnnualCostCell,
  EditableLicensesCell,
} from '../../../../saas/contract/components/ProductList/cells';
import DatePickerCell from '../../../../saas/contract/components/ProductList/cells/DatePickerCell';
import { DateCell } from '../../../../saas/contract/components/ContractList/Cells/DateCell';
import { GridColDef } from '@mui/x-data-grid-pro';
import { OfferProduct } from './predefinedForms/shared/schemas';

export const useOfferProductCollumns = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE' });

  return useMemo(
    (): GridColDef<OfferProduct>[] => [
      {
        field: 'licenseCost',
        headerName: t('LICENSE_COST'),
        renderCell: (params) => <Text variant="caption">{params.row.licenseCost ?? ''}</Text>,
        renderEditCell: (params) => <EditableAnnualCostCell {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value: licenseCost }) => {
          const { numberOfLicences, annualCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences,
            licenseCost,
            annualCost: numberOfLicences && licenseCost ? numberOfLicences * licenseCost : annualCost,
          };
        },
        editable: true,
        flex: 1,
      },
      {
        field: 'numberOfLicences',
        headerName: t('LICENSES'),
        renderCell: (params) => <Text variant="caption">{params.row.numberOfLicences ?? ''}</Text>,
        renderEditCell: (params) => <EditableLicensesCell {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value: numberOfLicences }) => {
          const { licenseCost, annualCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences,
            licenseCost,
            annualCost: numberOfLicences && licenseCost ? numberOfLicences * licenseCost : annualCost,
          };
        },
        editable: true,
        flex: 0.5,
      },
      {
        field: 'annualCost',
        headerName: t('TOTAL_TERM_COST'),
        renderCell: (params) => <AnnualCostCell {...params} />,
        renderEditCell: (params) => <EditableAnnualCostCell {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value }) => {
          const { numberOfLicences, licenseCost, annualCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences: value && licenseCost && !numberOfLicences ? value / licenseCost : numberOfLicences,
            licenseCost: value && numberOfLicences && !licenseCost ? value / numberOfLicences : licenseCost,
            annualCost: !licenseCost || !numberOfLicences ? value : annualCost,
          };
        },
        editable: true,
        flex: 1,
      },
      {
        field: 'startDate',
        headerName: t('START_DATE'),
        valueGetter: ({ row }) => row.allocationSpan?.start_date,
        valueSetter: ({ row, value }) => ({
          ...row,
          allocationSpan: { ...row.allocationSpan, start_date: value },
        }),
        renderCell: ({ value }) => (value ? <DateCell date={value} /> : null),
        renderEditCell: (params) => <DatePickerCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1,
      },
      {
        field: 'endDate',
        headerName: t('END_DATE'),
        valueGetter: ({ row }) => row.allocationSpan?.end_date,
        valueSetter: ({ row, value }) => ({
          ...row,
          allocationSpan: { ...row.allocationSpan, end_date: value } as OfferProduct['allocationSpan'],
        }),
        renderCell: ({ value }) => (value ? <DateCell date={value} /> : null),
        renderEditCell: (params) => <DatePickerCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1,
      },
    ],
    [t]
  );
};
