import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { FilterView } from '../components/ContractList/types';
import useFilterConfig from './useFilterConfig';
import { Filter, FilterValues } from '../components/ContractList/Filters/types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { getDefaultSortModel } from '../components/ContractList/functions';

type ContractListState = {
  lastActiveFilterView?: FilterView;
  setLastActiveFilterView: (filterView: FilterView) => void;
  filterValues?: FilterValues;
  setFilterValue: (filter: Filter) => (value: any) => void;
  availableFilters?: Filter[];
  activeFilters?: Filter[];
  setFiltersEnabled: (filtersToChange: Filter[], enabled: boolean) => void;
  setFiltersVisible: (filtersToChange: Filter[]) => void;
  sortModel?: GridSortModel;
  setSortModel: (sortModel?: GridSortModel) => void;
  paginationModel?: GridPaginationModel;
  setPaginationModel: (paginationModel?: GridPaginationModel) => void;
};

const defaultContractListFilterState: ContractListState = {
  setLastActiveFilterView: () => null,
  setFilterValue: () => () => null,
  setFiltersEnabled: () => null,
  setFiltersVisible: () => null,
  setSortModel: () => null,
  setPaginationModel: () => null,
};

export const ContractListContext = React.createContext<ContractListState>(defaultContractListFilterState);

/* A wrapper to be used in Router to allow state sharing between Contract pages - filter from list needs to be preserved when going to detail and back */
const ContractListContextWrapper = ({ children }: PropsWithChildren) => {
  const [lastActiveFilterView, setLastActiveFilterView] = React.useState<FilterView>();
  const [filterValues, setFilterValues] = React.useState<FilterValues>();
  const [sortModel, setSortModel] = React.useState<GridSortModel | undefined>();
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel | undefined>();

  const { availableFilters, activeFilters, setFiltersEnabled, setFiltersVisible } = useFilterConfig();

  const setFilterValue = useCallback(
    (filterName: Filter) => (value: any) => {
      setFilterValues((oldValues) => ({
        ...(oldValues ?? {}),
        [filterName]: value,
      }));
    },
    [setFilterValues]
  );

  useEffect(() => {
    if (lastActiveFilterView) {
      // We set the default sort model for respective view whenever the view is changed
      setSortModel(getDefaultSortModel(lastActiveFilterView));
    }
  }, [lastActiveFilterView]);

  return (
    <ContractListContext.Provider
      value={{
        lastActiveFilterView,
        setLastActiveFilterView,
        filterValues,
        setFilterValue,
        availableFilters,
        activeFilters,
        setFiltersEnabled,
        setFiltersVisible,
        sortModel,
        setSortModel,
        paginationModel,
        setPaginationModel,
      }}
    >
      {children}
    </ContractListContext.Provider>
  );
};

export default ContractListContextWrapper;
