import React from 'react';
import { GridValidRowModel } from '@mui/x-data-grid';
import DataGridSkeleton from './DataGridSkeleton';
import DataGridBase from './DataGridBase';
import { DataGridProps } from './types';
import { DataGridContextProvider } from './DataGridContext';

const DataGrid = <R extends GridValidRowModel>({ loadingStyle = 'loader', loading, ...props }: DataGridProps<R>) => {
  const size = props.size ?? 'M';
  const headerSize = props.headerSize ?? size;

  const providerContext = {
    size,
    headerSize,
    color: props.color ?? 'primary',
    testId: props.testId,
  };

  if (loading && loadingStyle === 'skeleton') {
    return (
      <DataGridContextProvider value={{ ...providerContext, component: 'data-grid-skeleton' }}>
        <DataGridSkeleton {...props} />
      </DataGridContextProvider>
    );
  }

  return (
    <DataGridContextProvider value={{ ...providerContext, component: 'data-grid' }}>
      <DataGridBase loading={loading} {...props} />
    </DataGridContextProvider>
  );
};

export default DataGrid;
