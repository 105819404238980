import { PredefinedFormDef } from '../shared/types';
import { SecurityQuestionnaireForm } from './SecurityQuestionnaireForm';
import { zodSchema, FormData } from './schema';

export const getQuestionnaireDefaultValues: Required<PredefinedFormDef<FormData>>['getDefaultValues'] = (
  getValue,
  taskContext
) => {
  return {
    vendorWebsite: getValue('vendorWebsite'),
    vendorContact: { name: getValue('vendorContact.name', ''), email: getValue('vendorContact.email', '') },
    dataSharedWithVendor: getValue('dataSharedWithVendor'),
    dataSharingDetails: {
      customerPiiData: getValue('dataSharingDetails.customerPiiData'),
      customerAccountAuthData: getValue('dataSharingDetails.customerAccountAuthData'),
      employeeContacts: getValue('dataSharingDetails.employeeContacts'),
      employeeSocialSecurityNumbers: getValue('dataSharingDetails.employeeSocialSecurityNumbers'),
      employeeBankAccounts: getValue('dataSharingDetails.employeeBankAccounts'),
      acquisitionPlans: getValue('dataSharingDetails.acquisitionPlans'),
      sourceCode: getValue('dataSharingDetails.sourceCode'),
      publishedResearch: getValue('dataSharingDetails.publishedResearch'),
      pressReleases: getValue('dataSharingDetails.pressReleases'),
      accountingData: getValue('dataSharingDetails.accountingData'),
      customerCreditCardData: getValue('dataSharingDetails.customerCreditCardData'),
      customerMedicalRecords: getValue('dataSharingDetails.customerMedicalRecords'),
    },
    additionalIntegration: getValue('additionalIntegration'),
    additionalNotes: getValue('additionalNotes'),
  };
};

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/securityReview\/questionnaire\/v\d+$/,
  component: SecurityQuestionnaireForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue, taskContext) => ({
    ...getQuestionnaireDefaultValues(getValue, taskContext),
  }),
};
