import React, { FC } from 'react';

import { PropsWithContract } from '../types';
import { Field } from './Field';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';

export const AutoRenewalDate: FC<PropsWithContract> = ({ contract }) => {
  const renewalDate = contract.parts.contractual?.lifecycle?.autoRenewalDeadline;
  const formatDate = useFormatDate();
  return (
    <Field
      labelTranslationKey="ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE"
      value={renewalDate ? formatDate(renewalDate) : undefined}
    />
  );
};
