import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@mui/icons-material';

import IconButton from '@verticeone/design-system/src/components/IconButton';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { WorkflowVersionRow } from '../types';
import { createServiceIdentifier } from '@vertice/core/src/hooks/workflows/refUtils';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

type EditButtonProps = {
  row: WorkflowVersionRow;
};

export const EditButton: FC<EditButtonProps> = ({ row }) => {
  const { t } = useTranslation();

  const routes = useRoutes();
  const { navigate } = useRouteNavigate();

  const goToEditor = () => {
    navigate(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL.VERSION.EDITOR, {
      workflowId: row.id,
      versionId: row.versionId,
      serviceId: createServiceIdentifier(row.serviceRef!),
    });
  };

  return (
    <Tooltip title={t('ENTITIES.WORKFLOW_VERSION.ACTIONS.EDIT')} minified size="S">
      <IconButton
        icon={EditOutlined}
        variant="outline"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        onClick={goToEditor}
      />
    </Tooltip>
  );
};
