import React, { FC } from 'react';
import { styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { NodeControls } from './NodeControls';
import { Gateway } from '../../definitionsTypes';
import { StyledNode } from './Shared';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper/IconWrapper';
import { CloseOutlined, MergeOutlined, AltRouteOutlined, SvgIconComponent } from '@mui/icons-material';

export const StyledGatewayNode = styled(StyledNode)<{ $selected: boolean }>(({ theme, $selected }) => ({
  width: 80,
  height: 80,
  position: 'relative',
  borderStyle: 'none',
  boxShadow: 'none',
  zIndex: 1,
  '&:before': {
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'inherit',
    content: '""',
    inset: '12%',
    transform: 'rotateZ(45deg)',
    boxShadow: $selected ? `0px 0px 5px ${theme.palette.primary.color2}` : 'none',
    background: theme.palette.core.color3,
    zIndex: -1 /* to be below the parent element */,
  },
}));

const TypeIconMap: Record<Gateway['gatewayType'], SvgIconComponent> = {
  Exclusive: CloseOutlined,
  Fork: AltRouteOutlined,
  Join: MergeOutlined,
};

export const GatewayNodeComponent: FC<NodeProps<Node<Gateway>>> = (props) => (
  <NodeControls
    {...props}
    content={
      <StyledGatewayNode $selected={props.selected ?? false}>
        <IconWrapper size="XL" icon={TypeIconMap[props.data.gatewayType]} />
        {props.data.name}
      </StyledGatewayNode>
    }
  />
);
