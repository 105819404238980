import React, { PropsWithChildren } from 'react';
import { useLocation, Navigate, useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@vertice/components';
import useAssumeRoleAuth from '../modules/auth/assumeRole/useAssumeRoleAuth';

const RequireAuth = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { route } = useAuthenticator((context) => [context.route]);
  const { user: assumeRoleUser } = useAssumeRoleAuth();

  if (assumeRoleUser) {
    return children;
  }

  if (route === 'idle' || !route) {
    return <Loader />;
  }
  if (route !== 'authenticated') {
    const isSamlError = searchParams.get('error_description')?.startsWith('Invalid SAML response received');
    return isSamlError ? (
      <Navigate to="/single-sign-on" state={{ from: location, error: true }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  return children;
};

export default RequireAuth;
