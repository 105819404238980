import React, { FC } from 'react';

import { PropsWithContract } from '../types';
import { Field } from './Field';

export const Reseller: FC<PropsWithContract> = ({ contract }) => {
  const resellerName = contract.parts.contractual?.vendor?.resellerName;
  const fromReseller = contract.parts.contractual?.vendor?.purchasedFromReseller;
  if (!resellerName || !fromReseller) {
    return null;
  }
  return <Field labelTranslationKey="ENTITIES.CONTRACT.LABELS.FROM_RESELLER" value={resellerName} />;
};
