import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PropsWithContract } from '../types';
import { Field } from './Field';

export const RollingFrequency: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const rollFrequency = contract.parts.contractual?.lifecycle?.rollFrequency;
  return (
    <Field
      labelTranslationKey="ENTITIES.CONTRACT.LABELS.ROLLING_FREQUENCY"
      value={rollFrequency ? t(`ENTITIES.CONTRACT.LABELS.FREQUENCY_${rollFrequency}`) : undefined}
    />
  );
};
