import React from 'react';
import { FormControl, Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { FormData } from './schema';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../shared/TaskFormContext';
import { useTranslation } from 'react-i18next';

export const SimpleStepOnForm = () => {
  const { t } = useTranslation();
  const requiredAction = useWatch<FormData, 'requiredAction'>({ name: 'requiredAction' });
  const { readOnly } = useTaskFormContext();

  return (
    <Stack gap={6}>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="requiredActionCompleted"
          label={requiredAction}
          description={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.STEP_ON.DESCRIPTION')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size="S"
          disabled={readOnly}
        />
      </FormControl>
    </Stack>
  );
};
