import { ComponentType, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, Stack } from '@mui/material';
import { Company } from '@vertice/components';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import toConstantCase from '@vertice/core/src/utils/strings/toConstantCase';
import { PREFERENCES_PAGES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import TabsBottomLineWrapper from '@verticeone/design-system/src/components/Tabs/TabsBottomLineWrapper';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import Tab from '@verticeone/design-system/src/components/Tab';
import Text from '@verticeone/design-system/src/components/Text';
import Loader from '@verticeone/design-system/src/components/Loader';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import Workflows from './Workflows/Workflows';
import User from './User/User';
import Directory from './Directory/Directory';
import Integrations from './Integrations/Integrations';
import Customization from './Customization/Customization';
import './Preferences.scss';

type PreferencesProps = {
  page: string;
};

type TabDef = {
  path: string;
  component: ComponentType;
};

const getTabLabelKey = (tabId: string) => `PREFERENCES.${toConstantCase(tabId)}.TAB`;

export const Preferences = ({ page }: PreferencesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isEnabled: isFeatureEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const someIntegrationEnabled =
    !isFeaturesLoading &&
    (isFeatureEnabled(FEATURES.SSO) ||
      isFeatureEnabled(FEATURES.USAGE_ANALYTICS) ||
      isFeatureEnabled(FEATURES.INTEGRATIONS));

  const customizationFeatureEnabled = !isFeaturesLoading && isFeatureEnabled(FEATURES.CONTRACTS_CUSTOMIZATION);

  const availableTabs = useMemo(
    (): TabDef[] => [
      ...(isUserAdmin ? [{ path: PREFERENCES_PAGES.COMPANY, component: Company }] : []),
      ...(isUserAdmin ? [{ path: PREFERENCES_PAGES.DIRECTORY, component: Directory }] : []),
      ...(isUserAdmin && someIntegrationEnabled
        ? [{ path: PREFERENCES_PAGES.INTEGRATIONS, component: Integrations }]
        : []),
      ...(isUserAdmin ? [{ path: PREFERENCES_PAGES.WORKFLOWS, component: Workflows }] : []),
      { path: PREFERENCES_PAGES.USER, component: User },
      ...(isUserAdmin && customizationFeatureEnabled
        ? [{ path: PREFERENCES_PAGES.CUSTOMIZATION, component: Customization }]
        : []),
    ],
    [customizationFeatureEnabled, isUserAdmin, someIntegrationEnabled]
  );
  const activeTab = availableTabs.find(({ path }) => path === page ?? '');

  useEffect(() => {
    if (!activeTab && availableTabs.length > 0) {
      navigate(`${ROUTES.PREFERENCES}/${availableTabs[0].path}`, { replace: true });
    }
  }, [activeTab, navigate, availableTabs]);

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('PREFERENCES.TITLE')}</BreadcrumbLink>
          <BreadcrumbLink disabled>
            {activeTab ? t(getTabLabelKey(activeTab.path)) : <Skeleton width="6em" />}
          </BreadcrumbLink>
        </Breadcrumbs>
        <Text variant="heading" size="M" color="text1" tag="h1">
          {t('PREFERENCES.TITLE')}
        </Text>
      </Stack>
      {activeTab ? (
        <>
          {availableTabs.length > 1 && (
            <TabsBottomLineWrapper paddingX={10}>
              <Tabs
                variant="outlined"
                value={activeTab.path}
                onChange={(_, value) => navigate(`../${value}`, { relative: 'path' })}
              >
                {availableTabs.map(({ path }) => (
                  <Tab key={path} value={path} label={t(getTabLabelKey(path))} />
                ))}
              </Tabs>
            </TabsBottomLineWrapper>
          )}
          <activeTab.component />
        </>
      ) : (
        <Loader />
      )}
    </Stack>
  );
};
