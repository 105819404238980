import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '../../translate';
import Text from '../Text';
import Grid from '../Grid';
import Tooltip from '../Tooltip';
import { CommentMetadata } from './types';
import useFormattedTimeAgo from '../../utils/formatting/useFormattedTimeAgo';
import { dateTimeFormatterOptions, useFormatDate } from '../../utils/formatting/date';

export type CommentHeaderProps = Omit<CommentMetadata, 'content'> & {
  isBranded: boolean;
};

const CommentHeader: FC<CommentHeaderProps> = ({
  personName,
  personRole,
  timestamp,
  isBranded,
  isCommentAuthor = false,
}: CommentHeaderProps) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation(undefined, { i18n });
  const { getFormattedTimeAgo } = useFormattedTimeAgo();

  const defaultColor = isCommentAuthor ? 'secondary1' : 'text1';
  const textColor = isBranded ? 'primary1' : defaultColor;

  const secondaryTextStyle = {
    opacity: 0.6,
  };

  return (
    <Stack>
      <Grid container gap={1} alignItems="center">
        <Text variant="body-bold" size="S" color={textColor}>
          {personName} {isCommentAuthor ? `(${t('COMMENTS.IS_COMMENT_AUTHOR')})` : ''}
        </Text>
        <Text size="S" variant="body-bold" color={textColor} style={secondaryTextStyle}>
          •
        </Text>
        <Tooltip minified size="S" title={formatDate(timestamp, { formatterOptions: dateTimeFormatterOptions })}>
          <Text variant="body-regular" size="XS" color={textColor} style={secondaryTextStyle}>
            {getFormattedTimeAgo(new Date(timestamp))}
          </Text>
        </Tooltip>
      </Grid>
      {personRole && (
        <Text variant="body-regular" size="XS" color={textColor} style={secondaryTextStyle}>
          {personRole}
        </Text>
      )}
    </Stack>
  );
};

export default CommentHeader;
