import {
  ActivityHookConfiguration,
  TaskAssignmentConfiguration,
  TaskConfiguration,
  TaskDefinition,
  TaskIOMappingConfiguration,
} from '../definitionsTypes';

export const isAssignmentConfiguration = (
  configuration:
    | TaskConfiguration
    | ActivityHookConfiguration
    | TaskIOMappingConfiguration
    | TaskAssignmentConfiguration
): configuration is TaskAssignmentConfiguration => {
  return configuration.kind === 'Tasks:Assignment';
};

export const getTaskAssignmentConfiguration = (task: TaskDefinition): TaskAssignmentConfiguration | undefined => {
  const assignment = task.task.configurations?.find(isAssignmentConfiguration);

  if (!assignment) return undefined;

  return assignment;
};

export const updateTaskDefinition = (
  taskDefinition: TaskDefinition,
  { name, assignmentConfiguration }: { name: string; assignmentConfiguration: TaskAssignmentConfiguration }
): TaskDefinition => {
  const newConfigurations = [
    ...(taskDefinition.task.configurations ?? []).filter((v) => !isAssignmentConfiguration(v)),
    assignmentConfiguration,
  ];

  return {
    ...taskDefinition,
    task: {
      ...taskDefinition.task,
      name,
      configurations: newConfigurations,
    },
  };
};
