export type AuthRequest = {
  id: string;
  obj: string;
  act: string;
};

export type AuthResponse = {
  eft: boolean;
  id: string;
};

export type PostAuthBatchResponse = {
  responses: AuthResponse[];
};

export type PostAuthBatchRequest = {
  requests: AuthRequest[];
};

const postAuthBatchUrl = (baseUrl: string, accountId: string) => `${baseUrl}/accounts/${accountId}/auth/batch`;

const postAuthBatchAuthorization = async (getToken: () => Promise<string>) => {
  return {
    Authorization: `Bearer ${await getToken()}`,
  };
};

export const postAuthBatch = async (
  body: PostAuthBatchRequest,
  accountId: string,
  baseUrl: string,
  options?: RequestInit,
  authenticator?: () => Promise<string>
): Promise<PostAuthBatchResponse> => {
  const { headers, ...restOptions } = options ?? {};

  return fetch(postAuthBatchUrl(baseUrl, accountId), {
    ...restOptions,
    headers: {
      ...(headers ?? {}),
      ...(authenticator ? await postAuthBatchAuthorization(authenticator) : {}),
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  }).then((resp) => resp.clone().json() as Promise<PostAuthBatchResponse>);
};
