import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { VerticeVIcon } from '@vertice/assets';

import Text from '@verticeone/design-system/src/components/Text';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { AppBadge, MessageWrapper } from './ConnectionStep';
import { MessageButton, VerticalLine } from './SlackPreviewCommon';

export const SlackPreviewCreation = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <MessageWrapper>
      <IconWrapper icon={VerticeVIcon} />
      <Stack flex={1} gap={4}>
        <Box>
          <Stack direction="row" gap={1} alignItems="center">
            <Text variant="body-bold" size="XS" color="text1">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_VERTICE')}
            </Text>
            <AppBadge>
              <Text variant="body-regular" size="XXS" color="text2">
                {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_APP')}
              </Text>
            </AppBadge>
            <Text variant="body-regular" size="XS" color="text2">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_TIME')}
            </Text>
          </Stack>
          <Text variant="body-bold" size="XS" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_CREATION_HEAD_TITLE')}
          </Text>
        </Box>
        <Stack direction="row" alignItems="center">
          <VerticalLine />
          <IconWrapper icon={DescriptionOutlinedIcon} size="L" />
          <Text variant="body-bold" size="XS" color="secondary1">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_RENEWAL_CONTRACT')}
          </Text>
        </Stack>
        <Text
          variant="body-regular"
          size="XS"
          color="text2"
          sx={{ borderBottom: `1px solid ${palette.core.color3}`, paddingBottom: 4, whiteSpace: 'pre-line' }}
        >
          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_CREATION_DESCRIPTION')}
        </Text>
        <MessageButton>
          <Text variant="body-regular" size="XS" color="text1">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_RENEWAL_VIEW_CONTRACT')}
          </Text>
        </MessageButton>
      </Stack>
    </MessageWrapper>
  );
};
