import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import {
  monthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import extractTooltipContent, {
  TooltipItem,
} from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { ifExpression } from '@vertice/core/src/utils/ifExpression';
import { CumulativeSumChartData } from './useCumulativeSumChartData';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';

type SavingsGraphProps = {
  data: CumulativeSumChartData;
};

const CumulativeSumChart = ({ data }: SavingsGraphProps) => {
  const [chart, setChartRef] = useChartRef();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_SPEND_TREND' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyStackedColumnHover = useStackedColumnHover();
  const coloredValues = data.values as unknown as SeriesOptionsWithData[];

  const series = useMemo(() => {
    const values = coloredValues.map((item) => {
      return {
        ...item,
        ...ifExpression(item.id === 'commitment', {
          name: t('TOTAL_COMMITMENT'),
          lineWidth: 2,
          zIndex: 1,
          color: palette.core.color6,
          dashStyle: 'Dash',
          states: {
            hover: { color: palette.core.color6 },
            inactive: { color: palette.core.color6 },
          },
        }),
        ...ifExpression(item.id === 'forecast', {
          name: t('FORECAST_LABEL'),
          borderWidth: 4,
          borderColor: palette.visualization.divergent.tertiary['-10'],
          color: palette.visualization.divergent.tertiary['-10'],
          states: {
            hover: {
              color: palette.visualization.divergent.tertiary['-50'],
              borderColor: palette.visualization.divergent.tertiary['-50'],
            },
            inactive: {
              color: palette.visualization.divergent.tertiary['-10'],
              borderColor: palette.visualization.divergent.tertiary['-10'],
            },
          },
        }),
        ...ifExpression(item.id === 'cumulativeSpendBefore', {
          name: t('CUMULATIVE_SPEND_BEFORE_LABEL'),
          borderWidth: 4,
          borderColor: palette.visualization.divergent.secondary['-30'],
          border: 0,
          color: palette.visualization.divergent.secondary['-30'],
          states: {
            hover: {
              color: palette.visualization.divergent.secondary['-40'],
              borderColor: palette.visualization.divergent.secondary['-40'],
            },
            inactive: {
              color: palette.visualization.divergent.secondary['-30'],
              borderColor: palette.visualization.divergent.secondary['-30'],
            },
          },
        }),
        ...ifExpression(item.id === 'cumulativeSpendForecast', {
          name: t('CUMULATIVE_SPEND_FORECAST_LABEL'),
          borderWidth: 4,
          borderColor: palette.visualization.divergent.secondary['-10'],
          border: 0,
          color: palette.visualization.divergent.secondary['-10'],
          states: {
            hover: {
              color: palette.visualization.divergent.secondary['-20'],
              borderColor: palette.visualization.divergent.secondary['-20'],
            },
            inactive: {
              color: palette.visualization.divergent.secondary['-10'],
              borderColor: palette.visualization.divergent.secondary['-10'],
            },
          },
        }),
        ...ifExpression(item.id === 'actualSpend', {
          name: t('ACTUAL_SPEND_LABEL'),
          borderWidth: 4,
          borderColor: palette.visualization.divergent.secondary['-40'],
          color: palette.visualization.divergent.secondary['-40'],
          states: {
            hover: {
              color: palette.visualization.divergent.secondary['-50'],
              borderColor: palette.visualization.divergent.secondary['-50'],
            },
            inactive: {
              color: palette.visualization.divergent.secondary['-40'],
              borderColor: palette.visualization.divergent.secondary['-40'],
            },
          },
        }),
      };
    });

    return [
      ...(values.filter((item) => item.type === 'line') || []),
      ...getSeriesWithBorderRadius(values.filter((item: SeriesOptionsWithData) => item.type === 'column') || [], 4),
    ] as SeriesOptionsType[];
  }, [
    coloredValues,
    palette.core.color6,
    palette.visualization.divergent.secondary,
    palette.visualization.divergent.tertiary,
    t,
  ]);

  const options = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStackedColumnHover,
        applyStyledHighcharts,
        mergeOptions({
          chart: { type: 'column' },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: {
                    enabled: false,
                  },
                },
              },
            },
          },
          yAxis: {
            labels: { formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY, 0, 2, true) },
            maxPadding: 0,
            plotLines: [
              {
                value: data.commitment,
                width: 0,
                zIndex: 10,
                label: {
                  style: {
                    fontSize: '14px',
                  },
                  text: t('TOTAL_COMMITMENT'),
                  align: 'left',
                  y: 16,
                  x: 140,
                },
              },
            ],
          },
          xAxis: {
            categories: data.months,
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true },
          series,
        }),
      ]),
    [
      applyXAxisOffset,
      applyStackedColumnHover,
      applyStyledHighcharts,
      data.commitment,
      data.months,
      palette,
      locale,
      t,
      series,
    ]
  );

  const legendItems = useMemo<LegendItemData[]>(() => {
    return extractLegendItemsFromSeries(options, data.usedCategories).filter((item) => item.id !== 'commitment');
  }, [options, data.usedCategories]);

  const tooltipSort = sortTooltipPointsFunction(data.usedCategories, true);

  const tooltipExtractor = extractTooltipContent(tooltipSort, (v) => (v ? formatCurrency(v) : ''));

  return (
    <Stack gap={1.5} {...testProps('cumulativeSumOfSpend', 'chart')}>
      {legendItems.length > 0 && (
        <Stack direction="row" alignSelf="flex-end" px={6} spacing={4}>
          <Legend items={legendItems} />
        </Stack>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          const COMMITMENT_LABEL = t('TOTAL_COMMITMENT');
          const CUMULATIVE_SPEND_BEFORE_LABEL = t('CUMULATIVE_SPEND_BEFORE_LABEL');
          const CUMULATIVE_SPEND_FORECAST_LABEL = t('CUMULATIVE_SPEND_FORECAST_LABEL');
          const ACTUAL_SPEND_LABEL = t('ACTUAL_SPEND_LABEL');
          const FORECAST_LABEL = t('FORECAST_LABEL');

          const tooltipContent = tooltipExtractor(context);
          const forecast = context.points?.find((point) => point.series.userOptions.name === FORECAST_LABEL)?.y ?? 0;
          const dynamicItems = tooltipContent.items?.filter((item) => item.name !== COMMITMENT_LABEL);
          const cumulativeSpendBefore =
            context.points?.find((point) => point.series.userOptions.name === CUMULATIVE_SPEND_BEFORE_LABEL)?.y ?? 0;
          const cumulativeSpendForecast =
            context.points?.find((point) => point.series.userOptions.name === CUMULATIVE_SPEND_FORECAST_LABEL)?.y ?? 0;
          const actualSpend =
            context.points?.find((point) => point.series.userOptions.name === ACTUAL_SPEND_LABEL)?.y ?? 0;
          const commitment = tooltipContent.items?.find((item) => item.name === COMMITMENT_LABEL);
          const totalSpend =
            Number(actualSpend) + Number(forecast) + Number(cumulativeSpendBefore) + Number(cumulativeSpendForecast);

          if (!totalSpend && !commitment && !dynamicItems?.length) {
            return <></>;
          }

          return (
            <TooltipWrapper>
              {dynamicItems?.map((item: TooltipItem, index: number) => (
                <TooltipSeriesValuePair
                  seriesColor={
                    item.name === FORECAST_LABEL ? palette.visualization.divergent.tertiary['-50'] : item.color
                  }
                  seriesName={item.name}
                  value={item.value}
                  key={item.name || index}
                  borderColor={item.name === COMMITMENT_LABEL ? palette.core.color5 : undefined}
                />
              ))}
              {totalSpend ? (
                <TooltipTotal label={t('TOOLTIP.TOTAL_SPEND')} value={formatCurrency(totalSpend).toString()} />
              ) : null}
              {commitment ? (
                <>
                  <TooltipSeriesValuePair seriesName={commitment.name} value={commitment.value} key={commitment.name} />
                </>
              ) : null}
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default CumulativeSumChart;
