import { ZodOptional, ZodTypeAny, ZodNullable, ZodDefault, ZodObject, ZodEffects, ZodUnion, ZodArray } from 'zod';

export const setRequiredTo = <T extends ZodTypeAny>(
  schema: T,
  isRequired: boolean
): ZodOptional<ZodNullable<T>> | T => {
  if (!isRequired) {
    return schema.nullish();
  }

  while (schema instanceof ZodOptional || schema instanceof ZodNullable) {
    schema = schema.unwrap();
  }
  return schema;
};

export const makeRequired = <T extends ZodTypeAny>(schema: T): ZodOptional<ZodNullable<T>> | T => {
  return setRequiredTo(schema, true);
};

export const isNestedFieldOptional = (schema: ZodTypeAny, path: string): boolean => {
  const keys = path.split('.').reverse();
  let currentSchema: ZodTypeAny | undefined = schema;

  while (keys.length) {
    if (currentSchema instanceof ZodEffects) {
      // eslint-disable-next-line no-underscore-dangle
      currentSchema = currentSchema._def.schema;
      continue;
    }

    const key = keys.pop() as string;
    if (currentSchema instanceof ZodOptional) {
      return true;
    } else if (currentSchema instanceof ZodObject) {
      currentSchema = currentSchema.shape[key];
    } else if (currentSchema instanceof ZodArray) {
      currentSchema = currentSchema.element;
    } else {
      return false;
    }
  }

  if (currentSchema instanceof ZodUnion) {
    // Quite specific solution... we're ignoring the other branch...
    currentSchema = currentSchema.options[0];
  }

  return currentSchema instanceof ZodOptional || currentSchema instanceof ZodDefault;
};
