import { TaskConfiguration } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { TasksThumbnail } from '../../definitionsTypes';

export const resolveTaskNodeThumbnailConfiguration = (configurations: TaskConfiguration[]): TasksThumbnail => {
  const hasThumbnail = (configurations ?? []).some((config) => config.kind === 'UI:Thumbnail');
  const thumbnailConfiguration = (configurations ?? []).find((config) => config.kind === 'UI:Thumbnail');

  return hasThumbnail && thumbnailConfiguration
    ? (thumbnailConfiguration.thumbnail as TasksThumbnail)
    : ({
        id: '',
        type: '',
      } as TasksThumbnail);
};
