import { SeriesOptionsType, SeriesWordcloudOptions } from 'highcharts';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetProductPricingBenchmarksQuery } from '@vertice/slices';
import { useMemo } from 'react';
import { getNumerosityValuesForAllCategories, getSeries } from './utils';
import { useTheme } from '@mui/material';
import { categories } from './constants';

export enum BenchmarkingPeriods {
  MONTHLY = 1,
  ANNUALLY = 12,
}

export type UseGetBenchmarkDataProps = {
  vendorId: string;
  productId: string;
  period: BenchmarkingPeriods;
};

export type BenchmarkData = {
  series: SeriesWordcloudOptions[];
  numerosity: SeriesOptionsType[];
  categories: string[];
};

export type UseGetBenchmarkDataReturn = {
  data?: BenchmarkData;
  isLoading: boolean;
};

const useGetBenchmarkData = ({ vendorId, productId, period }: UseGetBenchmarkDataProps): UseGetBenchmarkDataReturn => {
  const { palette } = useTheme();
  const { accountId } = useAccountContext();
  const { data, isLoading } = useGetProductPricingBenchmarksQuery({
    accountId,
    vendorId,
    productId,
  });

  const availableColors = useMemo(
    () => [
      palette.visualization.divergent.primary['-50'],
      palette.visualization.divergent.secondary['-50'],
      palette.visualization.divergent.tertiary['-50'],
      palette.visualization.divergent.primary['+50'],
      palette.visualization.divergent.secondary['+50'],
      palette.visualization.divergent.tertiary['+50'],
    ],
    [palette]
  );

  const processedData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const multiplier: number = period;

    const series = data?.tiers ? getSeries(data.tiers, availableColors, multiplier) : [];
    const numerosity: SeriesOptionsType[] = data?.numerosity
      ? [
          {
            type: 'column',
            name: 'numerosity',
            data: getNumerosityValuesForAllCategories(data?.numerosity),
          },
        ]
      : [];

    return {
      series,
      numerosity,
      categories,
    };
  }, [data, period, availableColors]);

  return {
    data: processedData,
    isLoading: isLoading || !processedData,
  };
};

export default useGetBenchmarkData;
