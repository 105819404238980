import { MenuList } from '@mui/material';
import React, { forwardRef } from 'react';
import { Suggestion, UserSuggestion } from '../types';
import { StyledMenuItem } from '../../Menu/MenuItem';
import styled from '@mui/material/styles/styled';
import TextMention from './TextMention';

const StyledMenuList = styled(MenuList)({
  width: 300,
  maxHeight: 300,
  overflow: 'auto',
  border: `solid`,
  borderWidth: 1,
  borderRadius: 2,
});

type SuggestionListProps = {
  focusIndex: number;
  suggestions: Suggestion[];
  onBlur: () => void;
  onSelect: (d: UserSuggestion, s: number, e: number) => void;
};
const SuggestionList = forwardRef<HTMLUListElement, SuggestionListProps>(
  ({ focusIndex, suggestions, onBlur, onSelect }, ref) => {
    return (
      <StyledMenuList ref={ref}>
        {suggestions.map(({ result, querySequenceStart, querySequenceEnd }, index) => (
          <StyledMenuItem
            key={result.id}
            selected={index === focusIndex}
            onClick={() => {
              onSelect(result, querySequenceStart, querySequenceEnd);
              onBlur();
            }}
            $color="secondary"
          >
            <TextMention {...result} />
          </StyledMenuItem>
        ))}
      </StyledMenuList>
    );
  }
);

export default SuggestionList;
