import BaseCloudAnalyticsGraph from '../../../BaseCloudTab/Graph/BaseCloudAnalyticsGraph';
import React from 'react';
import useAccountServicesGraphData from '../useAccountServicesGraphData';

type CloudAccountsServicesGraphProps = {
  linkedAccountId: string;
};

const CloudAccountServicesGraph = ({ linkedAccountId }: CloudAccountsServicesGraphProps) => {
  const { data: graphData, ...loadingStatus } = useAccountServicesGraphData({
    linkedAccountId,
  });

  return <BaseCloudAnalyticsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudAccountServicesGraph;
