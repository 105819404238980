import React from 'react';
import { Stack } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import Text from '../Text/Text';
import type { DesignSystemColor } from '../../types';
import Tooltip, { TooltipInfoTrigger } from '../Tooltip';

export type BaseStatBarItemProps = {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
  color?: DesignSystemColor;
  isInGroup?: boolean;
  orientation?: 'horizontal' | 'vertical';
  tooltip?: {
    title: string;
    description: string;
  };
};

export type StatBarItemProps = Omit<BaseStatBarItemProps, 'isInGroup'>;

const Orientation = {
  horizontal: 'row',
  vertical: 'column',
} as const;

const Align = {
  horizontal: 'center',
  vertical: 'flex-start',
} as const;

const StatBarItem = ({
  isInGroup = false,
  orientation = 'vertical',
  color,
  value,
  title,
  tooltip,
}: BaseStatBarItemProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      p={!!isInGroup ? 2 : 4}
      direction={Orientation[orientation]}
      bgcolor={color ? palette[color].color2 : palette.core.bg}
      alignItems={Align[orientation]}
      flex="1 0 0"
      alignSelf="stretch"
    >
      <Stack direction="row" gap={1}>
        <Text variant="caption" size="M" color={color ? `${color}3` : 'text2'}>
          {title}
        </Text>
        {!!tooltip && (
          <Tooltip {...tooltip} size="S">
            <TooltipInfoTrigger sx={{ color: color ? palette[color].color3 : palette.text.color2 }} />
          </Tooltip>
        )}
      </Stack>
      <Text variant="heading" size="M" color={color ? 'text5' : 'text1'}>
        {value}
      </Text>
    </Stack>
  );
};

export default StatBarItem as React.FC<StatBarItemProps>;
