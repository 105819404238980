import React, { FC } from 'react';
import { styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import { NodeControls } from './NodeControls';
import { Start } from '../../definitionsTypes';
import { StyledCircleNode } from './Shared';

export const StyledStartNode = styled(StyledCircleNode)(({ theme: { palette } }) => ({
  backgroundColor: palette.success.color4,
}));

export const StartNodeComponent: FC<NodeProps<Node<Start>>> = (props) => (
  <NodeControls {...props} content={<StyledStartNode $selected={props.selected}>{props.data.name}</StyledStartNode>} />
);
