import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';

const NotImplemented = () => {
  return (
    <Stack>
      <Text variant="body-regular">Not Implemented</Text>
    </Stack>
  );
};

export default NotImplemented;
