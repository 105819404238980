import { Grid } from '@mui/material';
import Card from '@verticeone/design-system/src/components/Card';
import Text from '@verticeone/design-system/src/components/Text';
import { TotalSavingsCard } from '../../modules/saas/dashboard/cards/TotalSavingsCard';
import { ContractCountsCard } from '../../modules/saas/dashboard/cards/ContractCountsCard';
import { SpendCard } from '../../modules/saas/dashboard/cards/SpendCard';
import { DepartmentsCard } from '../../modules/saas/dashboard/cards/DepartmentsCard';
import CustomerTaskBar from '../../modules/saas/dashboard/cards/CustomerTaskBar';
import { Loader } from '@vertice/components';
import React, { useEffect } from 'react';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import Calendar from './components/Calendar';
import Contracts from './components/Contracts';
import { useTranslation } from 'react-i18next';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import DashboardContextProvider, { useDashboardContext } from './DashboardContextProvider';

import CardGroup from './components/CardGroup';

const useRedirectToContractForInsufficientRoles = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { isUserAdmin, isUserPowerUser, isLoading: isUserAccountRolesLoading } = useLoggedUserAccountRoles();

  useEffect(() => {
    if (!isUserAccountRolesLoading && !(isUserAdmin || isUserPowerUser)) {
      navigate(routes.CONTRACTS);
    }
  }, [isUserAdmin, isUserAccountRolesLoading, isUserPowerUser, navigate, routes.CONTRACTS]);
  return isUserAccountRolesLoading;
};

const DashboardStatsContent = () => {
  const { allowCategoryChange } = useDashboardContext();

  if (allowCategoryChange) {
    return (
      <>
        <Grid item xs={10} lg={2}>
          <TotalSavingsCard sx={{ height: '100%' }} />
        </Grid>
        <Grid item xs={10} lg={8}>
          <CardGroup>
            <ContractCountsCard sx={{ height: 264, flexBasis: '25%' }} />
            <SpendCard sx={{ height: 264, flexBasis: '25%' }} />
            <DepartmentsCard sx={{ height: 264, flexBasis: '50%' }} />
          </CardGroup>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={10} md={10} lg={2}>
        <TotalSavingsCard sx={{ height: '100%' }} />
      </Grid>
      <Grid item xs={10} md={5} lg={2}>
        <ContractCountsCard sx={{ height: '100%' }} />
      </Grid>
      <Grid item xs={10} md={5} lg={2}>
        <SpendCard sx={{ height: '100%' }} />
      </Grid>
      <Grid item xs={10} md={10} lg={4}>
        <DepartmentsCard sx={{ height: '100%' }} />
      </Grid>
    </>
  );
};

const Dashboard = () => {
  const { t } = useTranslation();

  const loading = useRedirectToContractForInsufficientRoles();
  if (loading) {
    return <Loader />;
  }

  return (
    // We use 10 column grid, because
    //  - main content (left part) = 3/5 of container
    //  - smallest card takes 1/2 of the main content on narrow screen (3/5 * 1/2 = 3/10 of container)
    <DashboardContextProvider>
      <Grid container p={10} columns={10} spacing={4}>
        <DashboardStatsContent />
        <Grid item xs={10} md={10} lg={10}>
          <CustomerTaskBar />
        </Grid>
        <Grid item xs={10} md={10} lg={10}>
          <Card justifyContent="center" spacing={6} paddingTop={6}>
            <Text size="S" variant="heading" paddingX={6}>
              {t('DASHBOARD.PIPELINE')}
            </Text>
            <Calendar />
            <Contracts />
          </Card>
        </Grid>
      </Grid>
    </DashboardContextProvider>
  );
};

export default Dashboard;
