import { createApi } from '@reduxjs/toolkit/query/react';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const notifWebsocketsAPI = createApi({
  reducerPath: 'notifWebsocketsAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.NOTIF_WEBSOCKETS),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
