import { DesignSystemColor } from '@verticeone/design-system/src/types';

export const INTELLIGENT_WORKFLOWS_BRAND_COLOR: 'secondary' = 'secondary' satisfies DesignSystemColor;

export const SERVICE_CATALOG_SERVICE_KIND = 'Vertice/ServiceCatalog/Service/ServiceDefinition';

export const COMMON_BUTTON_PROPS = {
  fullWidth: true,
  color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
  size: 'S' as const,
};

// This account id is used for the internal workflow admin account which is used for managing internal workflows
// and their distribution to customer accounts
export const WORKFLOW_ADMIN_ACCOUNT_ID = 'VERTICE_WORKFLOW_ADMINISTRATION';

export const TASK_REF_SUFFIX = 'vertice-workflows:task';
export const REQUEST_REF_SUFFIX = 'vertice-services:request';
