import { useCallback } from 'react';
import { useAccountContext } from '../../../contexts/AccountContext';
import {
  useDeployWorkflowVersionMutation,
  useDeployWorkflowMutation,
  useStoreWorkflowDefinitionMutation,
  useRedeployWorkflowVersionMutation,
  DeployWorkflow,
} from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { Definitions } from '../definitionsTypes';
import { isProcessDefinition } from '../pocWorkflowSchema';

type SaveWorkflowParams = {
  definitions?: Definitions;
  workflowId?: string;
  workflowVersion?: string;
  serviceRef?: string;
  customVersionName?: string;
};

export const useSaveWorkflow = () => {
  const { accountId } = useAccountContext();

  const [storeWorkflow, { isLoading: isStoringWorkflowDefinition }] = useStoreWorkflowDefinitionMutation();
  const [deployWorkflow, { isLoading: isDeployingWorkflow }] = useDeployWorkflowMutation();
  const [deployWorkflowVersion, { isLoading: isDeployingWorkflowVersion }] = useDeployWorkflowVersionMutation();
  const [redeployWorkflowVersion, { isLoading: isRedeployingWorkflowVersion }] = useRedeployWorkflowVersionMutation();

  const isSavingWorkflow =
    isStoringWorkflowDefinition || isDeployingWorkflow || isDeployingWorkflowVersion || isRedeployingWorkflowVersion;

  const saveWorkflow = useCallback(
    // creates a new workflow if no workflowId is provided
    // deploys new version of an existing workflow if workflowId is provided
    // redeploy the same version of an existing workflow if workflowId and workflowVersion are provided
    (
      { definitions, workflowId, workflowVersion, serviceRef, customVersionName }: SaveWorkflowParams,
      onSuccess?: () => void,
      onError?: () => void
    ) => {
      if (definitions) {
        void (async () => {
          const processName = definitions.definitions.filter(isProcessDefinition).reduce((acc, def) => {
            return def.process.name || acc;
          }, '');
          const currentDefinitionsStr = JSON.stringify(definitions);
          const currentDefinitionsBytes = new TextEncoder().encode(currentDefinitionsStr);
          const currentDefinitionsBlob = new Blob([currentDefinitionsBytes], {
            type: 'application/json;charset=utf-8',
          });
          const fileName = `workflow-definitions-${new Date().getTime()}`;
          const storeResponse = await storeWorkflow({ accountId, body: currentDefinitionsBlob, fileName });
          // response contains the FileRef which is passed down to the deployWorkflow mutation
          if ('data' in storeResponse && storeResponse.data.ref !== undefined) {
            const fileRef = storeResponse.data.ref;
            const deployWorkflowSpec: DeployWorkflow = {
              ref: fileRef,
              name: processName,
              versionName: customVersionName,
              serviceRef,
            };
            let deployResult: { error: any } | { data: any };
            if (workflowId) {
              if (workflowVersion) {
                deployResult = await redeployWorkflowVersion({
                  accountId,
                  workflowId,
                  workflowVersion,
                  deployWorkflow: deployWorkflowSpec,
                });
              } else
                deployResult = await deployWorkflowVersion({
                  accountId,
                  workflowId,
                  deployWorkflow: deployWorkflowSpec,
                });
            } else {
              deployResult = await deployWorkflow({ accountId, deployWorkflow: deployWorkflowSpec });
            }
            if ('data' in deployResult) {
              onSuccess?.();
            } else {
              onError?.();
            }
          } else {
            onError?.();
          }
        })();
      }
    },
    [accountId, deployWorkflow, deployWorkflowVersion, redeployWorkflowVersion, storeWorkflow]
  );

  return { isSavingWorkflow, saveWorkflow };
};
