import { notifWebsocketsAPI as api } from '../../api/notifWebsocketsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateNotificationOtp: build.mutation<GenerateNotificationOtpApiResponse, GenerateNotificationOtpApiArg>({
      query: (queryArg) => ({ url: `/notif/${queryArg.accountId}/authorize/otp`, method: 'POST', body: queryArg.body }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as notifWebsocketsAPICodegen };
export type GenerateNotificationOtpApiResponse =
  /** status 200 Generate notification OTP response */ CommandResultGenerateNotificationOtp;
export type GenerateNotificationOtpApiArg = {
  accountId: string;
  /** Generate notification OTP request */
  body: object;
};
export type CommandResultGenerateNotificationOtp = {
  ticket?: string;
  ticketTTL?: number;
  purpose?: string;
  protocol?: string;
  scope?: string;
};
export const { useGenerateNotificationOtpMutation } = injectedRtkApi;
