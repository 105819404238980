import React, { FC } from 'react';
import { SimplifiedStage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DesignSystemColor, DesignSystemSize } from '@verticeone/design-system/src/types';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { useTranslation } from 'react-i18next';
import assertExhausted from '../../../../utils/assertExhausted';

type ContractStageChipProps = {
  stage?: SimplifiedStage;
  size?: DesignSystemSize;
};

const getContractStageColor = (stage: NonNullable<SimplifiedStage>): DesignSystemColor => {
  switch (stage) {
    case 'LIVE':
      return 'primary';
    case 'WORKFLOW_IN_PROGRESS':
    case 'EXPIRED_NATURAL':
    case 'EXPIRED_PLANNED':
    case 'EXPIRED_UNINTENTIONAL':
    case 'TERMINATED':
    case 'CANCELLED_FIXED_TERM':
    case 'CANCELLED_REQUEST':
    case 'DELETED':
      return 'neutral';
    case 'DRAFT':
    case 'NOT_YET_LIVE':
      return 'neutral';
    default:
      assertExhausted(stage);
      return 'neutral';
  }
};

export const ContractStageChip: FC<ContractStageChipProps> = ({ stage, size = 'M' }) => {
  const { t } = useTranslation();

  if (!stage) {
    return null;
  }

  return (
    <ChipButton color={getContractStageColor(stage)} size={size} variant="ghost" isActive={false}>
      {t(`ENTITIES.CONTRACT.STAGES_ON_DETAIL.${stage}`)}
    </ChipButton>
  );
};
