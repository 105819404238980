import React from 'react';
import { Stack } from '@mui/material';
import { EllipsisText } from '@verticeone/design-system/src/components/Text';
import { TaskIcon } from '@vertice/core/src/modules/intelligentWorkflows/sharedVisualStyle/taskStyle';
import { TaskRow } from '../../types';
import { useLoggedUser } from '@verticeone/auth/src';
import { resolveTaskNodeThumbnailConfiguration } from '../../../workflowSchema/model/resolveTaskNodeThumbnailConfiguration';

export const TaskName = ({ taskRow }: { taskRow: TaskRow }) => {
  const { userId } = useLoggedUser();

  const thumbnail = resolveTaskNodeThumbnailConfiguration(taskRow.configurations!);

  return (
    <Stack direction="row" gap={4} alignItems="center" minWidth={0}>
      <TaskIcon
        userId={userId}
        status={taskRow.status}
        type={thumbnail.type}
        id={thumbnail.id}
        assigneeIds={taskRow.assignees.map((a) => a.id)}
      />
      <EllipsisText variant="heading" size="S" title={taskRow.name}>
        {taskRow.name}
      </EllipsisText>
    </Stack>
  );
};
