import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';
import Text from '@verticeone/design-system/src/components/Text';
import { valueComparator } from './utils';
import { AnnualCostCell, EditableAnnualCostCell, EditableLicensesCell } from './cells';
import { ProductItem } from './types';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { Product } from '@vertice/slices';

export const useProductCollumns = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE' });

  return useMemo(
    (): GridColDef<ProductItem>[] => [
      {
        field: 'annualCost',
        headerName: t('ANNUAL_COST'),
        renderCell: (params) => <AnnualCostCell {...params} />,
        renderEditCell: (params) => <EditableAnnualCostCell {...params} />,
        disableColumnMenu: true,
        sortComparator: valueComparator,
        editable: true,
        flex: 1.3,
      },
      {
        field: 'numberOfLicences',
        headerName: t('LICENSES'),
        renderCell: (params) => <Text variant="caption">{params.row.numberOfLicences ?? ''}</Text>,
        renderEditCell: (params) => <EditableLicensesCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1,
      },
    ],
    [t]
  );
};

export const asProductItem = (newRow: GridRowModel, vendorProducts: Product[]): ProductItem => {
  return {
    id: newRow.id,
    productId: newRow.productId,
    annualCost: newRow.annualCost,
    numberOfLicences: newRow.numberOfLicences,
    licenseType: newRow.licenseType
      ? {
          id: newRow.licenseType.id,
          label: newRow.licenseType.label,
        }
      : null,
    name: vendorProducts.find((prod) => prod.id === newRow.productId)?.name || '',
  };
};
