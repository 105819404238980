import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { EllipsisText, TextProps } from '@verticeone/design-system/src/components/Text';
import Button, { ButtonProps } from '@verticeone/design-system/src/components/Button';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export type ExpandableTextProps = {
  children?: React.ReactNode;
  defaultLineClamp?: number;
  textProps?: TextProps;
  buttonProps?: ButtonProps;
};

export const ExpandableText = ({ children, defaultLineClamp = 3, textProps, buttonProps }: ExpandableTextProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CORE.COMPONENTS.EXPANDABLE_TEXT' });
  const textElementRef = createRef<HTMLElement>();
  const maxLineClamp = 1000;

  const [showMoreButtonVisible, setShowMoreButtonVisible] = useState(false);
  const [lineClamp, setLineClamp] = useState(defaultLineClamp);
  const textExpanded = lineClamp === maxLineClamp;

  const checkTextLength = useCallback(() => {
    const scrollHeight = textElementRef.current?.scrollHeight;
    const clientHeight = textElementRef.current?.clientHeight;
    const textOverflow = scrollHeight && clientHeight && scrollHeight > clientHeight;
    setShowMoreButtonVisible(!!textOverflow);
  }, [textElementRef]);

  // check text lenght on first render and add resize listener
  useEffect(() => {
    checkTextLength();
    window.addEventListener('resize', checkTextLength);

    return () => {
      window.removeEventListener('resize', checkTextLength);
    };
  }, [checkTextLength]);

  const toggleLineClamp = () => {
    setLineClamp((oldLineClamp) => (oldLineClamp === maxLineClamp ? defaultLineClamp : maxLineClamp));
  };

  return (
    <Stack gap={2} alignItems="flex-start">
      <EllipsisText ref={textElementRef} variant="body-regular" size="S" lineClamp={lineClamp} {...textProps}>
        {children}
      </EllipsisText>
      {(showMoreButtonVisible || textExpanded) && (
        <Button
          variant="plain"
          color="primary"
          size="XS"
          endIcon={<IconWrapper size="XS" icon={textExpanded ? ExpandLess : ExpandMore} />}
          onClick={toggleLineClamp}
          sx={{ padding: '0', '&:hover': { padding: 0 } }}
          {...buttonProps}
        >
          {textExpanded ? t('SHOW_LESS') : t('SHOW_MORE')}
        </Button>
      )}
    </Stack>
  );
};
