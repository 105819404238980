import React, { Dispatch, SetStateAction, useState } from 'react';
import { Stack } from '@mui/material';
import { addMinutes } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogActions,
  DialogText,
} from '@verticeone/design-system/src/components/Dialog';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useBuyReservationMutation } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import Accordion from '@verticeone/design-system/src/components/Accordion';
import AccordionSummary from '@verticeone/design-system/src/components/Accordion/AccordionSummary';
import AccordionDetails from '@verticeone/design-system/src/components/Accordion/AccordionDetails';

import { useModalData } from './useModalData';
import { useInstanceList } from '../../../../providers/InstanceListProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type BuyRIModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  effectiveDate: Date;
  executeImmediately: boolean;
};

type RowProps = {
  label: string;
  value: string | number;
};

const Row = ({ label, value }: RowProps) => (
  <Stack direction="row" justifyContent="space-between">
    <Text variant="body-regular">{label}</Text>
    <Text variant="body-bold">{value}</Text>
  </Stack>
);

type SummaryProps = {
  executionDate: string;
};

const Summary = ({ executionDate }: SummaryProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.BUY_MODAL' });
  const formatCurrency = useFormatCurrency();
  const data = useModalData();

  // Calculate the total costs
  const totalRiCost = data.details.reduce((total, detail) => total + detail.riCost, 0);
  const totalUpfrontCost = data.details.reduce((total, detail) => total + detail.upfrontCost, 0);
  const totalExpectedSavings = data.details.reduce((total, detail) => total + detail.expectedSavings, 0);

  return (
    <Stack sx={{ overflow: 'auto' }}>
      <DialogContent>
        <Row label={t('ITEMS.PRODUCT')} value={`${data.product} ${data.region}`} />
        <Row label={t('ITEMS.EXECUTION_DATE')} value={executionDate} />
        <Stack sx={{ margin: '0 -14px' }} gap={2}>
          {data.details.map((detail, index) => (
            <Accordion size="S" variant="outlined" key={detail.reservedInstancesOfferingId}>
              <AccordionSummary
                title={<Text variant="body-bold">{`${data.instanceFamily} ${data.instanceType} ${detail.term}`}</Text>}
              />
              <AccordionDetails>
                <Stack gap={2}>
                  <Row label={t('ITEMS.QUANTITY')} value={detail.quantity} />
                  <Row label={t('ITEMS.MONTHLY_COST')} value={formatCurrency(detail.monthlyCost)} />
                  <Row label={t('ITEMS.UPFRONT_COST')} value={formatCurrency(detail.upfrontCost)} />
                  <Row label={t('ITEMS.EXPECTED_SAVINGS')} value={formatCurrency(detail.expectedSavings)} />
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        <Row label={t('ITEMS.TOTAL_RI_COST')} value={formatCurrency(totalRiCost)} />
        <Row label={t('ITEMS.TOTAL_UPFRONT_COST')} value={formatCurrency(totalUpfrontCost)} />
        <Row label={t('ITEMS.TOTAL_EXPECTED_SAVINGS')} value={formatCurrency(totalExpectedSavings)} />
      </DialogContent>
    </Stack>
  );
};

const BuyRIModal = ({ open, setOpen, effectiveDate, executeImmediately }: BuyRIModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.BUY_MODAL' });
  const { product } = useInstanceList();
  const data = useModalData();
  const { accountId } = useAccountContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buyReservation] = useBuyReservationMutation();
  const executionDate = executeImmediately ? t('VALUES.IMMEDIATELY') : effectiveDate?.toLocaleDateString();

  const handleConfirm = () => {
    setIsSubmitting(true);

    const purchaseExecutionTime = executeImmediately ? addMinutes(new Date(), +30) : effectiveDate;

    buyReservation({
      accountId: accountId!,
      purchases: data.details.map((detail) => ({
        service: product,
        region: data.region,
        instanceCount: detail.quantity,
        offeringId: detail.reservedInstancesOfferingId,
        purchaseExecutionTime,
      })),
    })
      .then((buyReservationData) => {
        if (
          'data' in buyReservationData &&
          buyReservationData.data.buyReservation?.__typename === 'ErroredQueryResult'
        ) {
          enqueueSnackbar(t('SNACKBAR.ERROR.TITLE'), {
            variant: 'error',
            description: `${t('SNACKBAR.ERROR.MESSAGE')} ${buyReservationData.data.buyReservation?.error}`,
          });
        } else {
          enqueueSnackbar(t('SNACKBAR.SUCCESS.TITLE'), {
            variant: 'success',
            description: t('SNACKBAR.SUCCESS.MESSAGE', { date: executionDate }),
          });
        }
        setIsSubmitting(false);
        setOpen(false);
      })
      .catch(() => {
        enqueueSnackbar(t('SNACKBAR.ERROR.TITLE'), {
          variant: 'error',
          description: t('SNACKBAR.ERROR.DESCRIPTION'),
        });
        setIsSubmitting(false);
        setOpen(false);
      });
  };

  return (
    <Dialog open={open} size="M">
      <DialogHeader>{t('TITLE')}</DialogHeader>
      <Stack paddingX={6} paddingTop={6}>
        <DialogText variant="body-regular">
          <Trans
            i18nKey="CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.BUY_MODAL.DESCRIPTION"
            components={{ b: <Text variant="body-bold" /> }}
          />
        </DialogText>
      </Stack>
      <Summary executionDate={executionDate} />
      <DialogActions>
        <Button testId="dismiss" variant="outline" color="tertiary" onClick={() => setOpen(false)}>
          {t('FOOTER.DISMISS')}
        </Button>
        <Button
          testId="confirm"
          variant="solid"
          color="tertiary"
          onClick={handleConfirm}
          disabled={!effectiveDate}
          isLoading={isSubmitting}
        >
          {t('FOOTER.CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyRIModal;
