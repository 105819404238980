import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import Highcharts from 'highcharts';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { getAxisTitleStyle } from '../shared/utils';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { BenchmarkData } from './useGetBenchmarkData';

export type NumerosityGraphProps = {
  data: BenchmarkData;
};

const axisTitleStyle = getAxisTitleStyle();

const NumerosityGraph = ({ data }: NumerosityGraphProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const applyStyledHighcharts = useStyledHighcharts();
  const applyXAxisOffset = useXAxisOffset(100, 16);

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        (opts) =>
          mergeOptions(
            {
              chart: {
                type: 'column',
                height: 151,
                spacingTop: 0,
                spacingLeft: -27,
                spacingBottom: 6,
              },
              tooltip: { enabled: false },
              plotOptions: {
                series: {
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                },
                column: {
                  pointPadding: 0.03,
                  groupPadding: 0,
                },
              },
              xAxis: {
                labels: {
                  y: 33,
                },
                categories: data.categories,
                title: {
                  text: t('PRODUCT.PRICING_BENCHMARK_CARD.X_AXIS_TITLE'),
                  y: 6,
                  style: {
                    color: palette.text.color2,
                    ...axisTitleStyle,
                  },
                },
              },
              yAxis: {
                labels: {
                  enabled: false,
                },
                gridLineWidth: 0,
                title: {
                  text: t('PRODUCT.PRICING_BENCHMARK_CARD.Y_AXIS_NUMEROSITY_TITLE'),
                  align: 'high',
                  y: 6,
                  x: 43,
                  style: {
                    color: palette.text.color2,
                    ...axisTitleStyle,
                    width: 200,
                  },
                },
              },
              series: data.numerosity.map((serie) => ({
                ...serie,
                color: palette.core.color3,
                borderRadiusTopLeft: 8,
                borderRadiusTopRight: 8,
              })),
            },
            opts
          ),
      ]),
    [t, applyXAxisOffset, applyStyledHighcharts, palette, data]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default NumerosityGraph;
