import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import VendorContractsCard from '../cards/VendorContractsCard';

const ContractsTab = () => {
  const { id } = useParams();
  const vendorId = id!;

  return (
    <Box p={6} pt={8}>
      <VendorContractsCard vendorId={vendorId} />
    </Box>
  );
};

export default ContractsTab;
