import React, { ChangeEvent } from 'react';
import { Stack } from '@mui/material';
import { LegendItemData } from './types';
import ColorSquare from '../ColorSquare';
import Text from '@verticeone/design-system/src/components/Text';
import Checkbox from '@verticeone/design-system/src/components/Checkbox';
import { DesignSystemColor } from '@verticeone/design-system/src/types';

type LegendItemProps = LegendItemData & {
  selected?: boolean;
  onSelectionChange?: (selected: boolean) => void;
  checkboxColor?: DesignSystemColor;
};

const LegendItem = ({
  checkboxColor = 'primary',
  color = 'transparent',
  outlined,
  custom,
  label,
  value,
  selected,
  onSelectionChange,
}: LegendItemProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onSelectionChange) {
      onSelectionChange(event.target.checked);
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap="7px">
      {onSelectionChange && (
        <Checkbox size="XXS" checked={selected} onChange={onChange} sx={{ p: 0 }} color={checkboxColor} />
      )}
      {custom ? custom : <ColorSquare color={color} outlined={outlined} />}
      <Text variant="body-regular" size="S" sx={{ flex: 1, wordBreak: 'break-word' }}>
        {label}
      </Text>
      <Text variant="body-bold" size="S">
        {value}
      </Text>
    </Stack>
  );
};

export default LegendItem;
