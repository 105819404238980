import Card, { CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { CardProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useLoggedUser } from '@verticeone/auth/src';
import {
  ListTasksForUserApiArg,
  ListTasksForUserApiResponse,
  Task,
  useLazyListTasksForUserQuery,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useFetchPaginated } from '@vertice/core/src/hooks/useFetchPaginated';
import { useRoutes } from '@verticeone/router/src';
import { useNavigate } from 'react-router-dom';
import Button from '@verticeone/design-system/src/components/Button';
import { ContractListContext } from '@vertice/core/src/modules/saas/contract/contexts/ContractListContext';
import { FC, useContext } from 'react';
import { PREDEFINED_VIEW_AWAITING_APPROVAL } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import Text from '@verticeone/design-system/src/components/Text';
import { useFeatureEnabled } from '@vertice/admin-panel/src/layouts/Sidebar/Navigation';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useTasks } from '@vertice/core/src/modules/intelligentWorkflows/task/TasksListPage/useTasks';

type CustomerTaskBarProps = CardProps;

const CustomerTaskBar = (props: CustomerTaskBarProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const navigate = useNavigate();
  const { setLastActiveFilterView } = useContext(ContractListContext);
  const isFeatureEnabled = useFeatureEnabled();

  const inteligentWorkflowsEnabled = isFeatureEnabled([FEATURES.INTELLIGENT_WORKFLOWS]);

  return (
    <Card {...props}>
      <CardHeader noDivider={true} size="S">
        <CardHeaderTitle text={t('DASHBOARD.CUSTOMER_TASKS.TITLE')} />
        <CardHeaderActions>
          {inteligentWorkflowsEnabled ? (
            <TaskInfo
              onClick={() => {
                setLastActiveFilterView(PREDEFINED_VIEW_AWAITING_APPROVAL);
                navigate(routes.INTELLIGENT_WORKFLOWS.TASKS.ABSOLUTE_PATH);
              }}
            />
          ) : (
            <SaaSTaskInfo
              onClick={() => {
                setLastActiveFilterView(PREDEFINED_VIEW_AWAITING_APPROVAL);
                navigate(routes.CONTRACTS.PATH);
              }}
            />
          )}
        </CardHeaderActions>
      </CardHeader>
    </Card>
  );
};

export default CustomerTaskBar;

type TaskInfoProps = {
  onClick: () => void;
};

const TaskInfo: FC<TaskInfoProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const { filteredTaskRows: tasks, isLoading } = useTasks(true, false, '');

  if (isLoading) {
    return null;
  }

  if (!tasks?.length) {
    return (
      <Text size="M" variant="body-regular" color="text3">
        {t('DASHBOARD.CUSTOMER_TASKS.NO_TASKS_INFO')}
      </Text>
    );
  }

  return (
    <Button color="primary" direction="horizontal" size="M" variant="solid" onClick={onClick}>
      {t('DASHBOARD.CUSTOMER_TASKS.PENDING', { count: tasks?.length })}
    </Button>
  );
};

const SaaSTaskInfo: FC<TaskInfoProps> = ({ onClick }) => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { t } = useTranslation();
  const [getTasks] = useLazyListTasksForUserQuery();

  const {
    items: tasks,
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks,
  } = useFetchPaginated<ListTasksForUserApiResponse, ListTasksForUserApiArg, Task>({
    fetchFn: getTasks,
    getItemsFn: (data) => data.items || [],
    getNextTokenFn: (data) => data.nextToken,
    fetchArgs: { accountId, userId },
    preferCache: false,
  });

  if (isLoadingTasks || isFetchingTasks) {
    return null;
  }

  if (!tasks?.length) {
    return (
      <Text size="M" variant="body-regular" color="text3">
        {t('DASHBOARD.CUSTOMER_TASKS.NO_TASKS_INFO')}
      </Text>
    );
  }

  return (
    <Button color="primary" direction="horizontal" size="M" variant="solid" onClick={onClick}>
      {t('DASHBOARD.CUSTOMER_TASKS.AWAITING_APPROVAL', { count: tasks?.length })}
    </Button>
  );
};
