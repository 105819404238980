import { ConnectionState, ExpectedMessage, Subscriber } from './types';

export const SHOULD_CONNECT_STATES = Array<ConnectionState>('init', 'disconnected');

export const parseWebSocketIncomingMessage = (message: MessageEvent) => {
  try {
    const messageData: ExpectedMessage = JSON.parse(message.data);

    return messageData;
  } catch (e) {
    console.error(e);
  }
};

export const createWSConnection = (
  url: string,
  ticket: string,
  handlers: {
    onOpen?: () => void;
    onClose?: () => void;
    onMessage?: (message: MessageEvent) => void;
  }
) => {
  const ws = new WebSocket(url, ['authorization', ticket]);

  ws.onopen = handlers.onOpen ?? null;
  ws.onclose = handlers.onClose ?? null;
  ws.onmessage = handlers.onMessage ?? null;

  return ws;
};

export const notifySubscribers = (subscribers: Array<Subscriber>, message: ExpectedMessage) =>
  subscribers.forEach(({ callback }) => callback(message));
