import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  AccountUser,
  IntegrationConnectionStatus,
  Oauth2ConnectionStatus,
  SlackUser,
  UpdateSlackIntegrationConfigApiResponse,
  UserIdentity,
} from '@vertice/slices';
import { INTEGRATION_ROUTES, ROUTES } from '@vertice/utils/src/constants/cfa/constants';

export const SLACK_PROVIDER_ID = 'Provider:Slack';
export const VERTICE_PROVIDER_ID = 'Provider:Vertice';
export const SLACK_DATA_SOURCE_CODES = ['SLACK_USER_BASE', 'VERTICE_USER_BASE'];
export const SLACK_ENTITY_TYPE = 'CUSTOMER/USER_SLACK';
export const SLACK_WIZARD_ROUTE = `${ROUTES.PREFERENCES}/${INTEGRATION_ROUTES.BASE_PATH}/${INTEGRATION_ROUTES.SLACK_SETUP}`;
export const INTEGRATIONS_ROUTE = `${ROUTES.PREFERENCES}/${ROUTES.INTEGRATIONS}`;

export const STEPS = {
  START: 'start',
  DATA_FIDELITY: 'data_fidelity',
  SELECT_DATA_SOURCE: 'select_data_source',
  ALLOW_ACCESS: 'allow_access',
  CONNECTION: 'connection',
  CHECK_CONNECTION: 'check_connection',
  CONNECTION_ERROR: 'connection_error',
  CONNECTION_SUCCESS: 'connection_success',
};

export enum Step {
  START = 'start',
  CONNECTION = 'connection',
}

export type WizardV2StepProps = {
  stepValue: Step;
};

export enum SlackConnectionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'ADMIN_PENDING',
  REVOKING = 'REVOKING',
}

type ValidIntegrationResponse = {
  data: Oauth2ConnectionStatus | IntegrationConnectionStatus | UpdateSlackIntegrationConfigApiResponse;
};
type ErrorResponse = { error: FetchBaseQueryError | SerializedError };
export const isValidIntegrationResponse = (
  response: ValidIntegrationResponse | ErrorResponse
): response is ValidIntegrationResponse => {
  return (response as ValidIntegrationResponse).data !== undefined;
};

type ValidConnectionResponse = { data: object };
export const isValidConnectionResponse = (
  response: ValidConnectionResponse | ErrorResponse
): response is ValidConnectionResponse => {
  return (response as ValidConnectionResponse).data !== undefined;
};

export type SlackConnection = AccountUser & { slackUsers: SlackUser[] };
export type SlackConnectionFilter = Record<'all' | 'notLinked', boolean>;
export const EMPTY_FILTER: SlackConnectionFilter = {
  notLinked: false,
  all: false,
};
export const VERTICE_USER_FIELD = 'verticeUser';
export const SLACK_USER_FIELD = 'slackUser';

export const getConnectedUser = (verticeUserId: string, connectedUsers?: UserIdentity[]) => {
  return connectedUsers?.find((user) => user.userId === verticeUserId);
};
