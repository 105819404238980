import { ProductItem } from './types';

type UseProductsProps<T extends ProductItem> = {
  selectedProducts?: T[];
  setSelectedProducts: (newProducts: T[]) => void;
};

const useSelectedProducts = <T extends ProductItem>({ selectedProducts, setSelectedProducts }: UseProductsProps<T>) => {
  const updateProduct = (updatedProduct: ProductItem) => {
    if (!selectedProducts) {
      return;
    }

    setSelectedProducts(
      selectedProducts?.reduce((acc: T[], item: T) => {
        if (item.id === updatedProduct.id) {
          acc.push({
            ...item,
            ...updatedProduct,
          });
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
    );
  };

  const addProduct = (newProduct: T) => {
    if (!selectedProducts) {
      return;
    }

    setSelectedProducts([...selectedProducts, newProduct]);
  };

  const removeProduct = (id: string) => {
    if (!selectedProducts) {
      return;
    }

    setSelectedProducts(
      selectedProducts?.reduce((acc: T[], item) => {
        if (item.id !== id) {
          acc.push(item);
        }
        return acc;
      }, [])
    );
  };

  return {
    addProduct,
    updateProduct,
    removeProduct,
  };
};

export default useSelectedProducts;
