import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider as DatePickerLocalizationProvider } from '@mui/x-date-pickers-pro';
import { translations } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import { AppTypeContext } from '@vertice/components/src/contexts/AppTypeContext';
import { ThemeProvider } from '@verticeone/design-system/src/theme/ThemeProvider';
import RouteContextProvider from './router/RouteContext';
import Router, { CreateRouter } from './router/Router';
import '@vertice/i18n';

import '@aws-amplify/ui-react/styles.css';
import '@verticeone/design-system/src/theme/muiOverrides.scss';
import '@vertice/assets/styles/common.scss';
import '@vertice/assets/styles/amplify.scss';
import './App.scss';
import { LocaleContextProvider, useLocale } from '@vertice/core/src/contexts/LocaleContext';
import { CfaAuthContextProvider } from './modules/auth/CfaAuthContextProvider';
import { I18n } from 'aws-amplify';
import enTranslation from '@vertice/i18n/src/i18n/en.json';
import { createBrowserRouter } from 'react-router-dom';

I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabularies({
  en: {
    'Sign in': enTranslation.AUTH.SIGN_IN,
    Email: enTranslation.AUTH.EMAIL,
    Password: enTranslation.AUTH.PASSWORD,
    'Forgot your password?': enTranslation.AUTH.FORGOT_PASSWORD,
    'Reset your password': enTranslation.AUTH.RESET_PASSWORD,
    'Reset your Password': enTranslation.AUTH.RESET_PASSWORD,
    'Send Code': enTranslation.AUTH.SEND_CODE,
    'Send code': enTranslation.AUTH.SEND_CODE,
    'Back to Sign In': enTranslation.AUTH.BACK_TO_LOGIN,
    'Enter your email': enTranslation.AUTH.EMAIL,
    'Confirm Password': enTranslation.AUTH.CONFIRM_PASSWORD,
    'New password': enTranslation.AUTH.NEW_PASSWORD,
    Code: enTranslation.AUTH.CODE,
    Submit: enTranslation.AUTH.SUBMIT,
  },
});

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });
dayjs.extend(duration);

function App() {
  const localeContext = useLocale();
  const router = createBrowserRouter(CreateRouter());

  return (
    <LocaleContextProvider value={localeContext}>
      <ThemeProvider locale={localeContext.locale}>
        <AppTypeContext.Provider value={{ isIAT: false }}>
          <DatePickerLocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              fieldMonthPlaceholder: ({ format }) => format,
            }}
          >
            <StyledEngineProvider injectFirst>
              <CfaAuthContextProvider router={router}>
                <RouteContextProvider>
                  <Router router={router} />
                </RouteContextProvider>
              </CfaAuthContextProvider>
            </StyledEngineProvider>
          </DatePickerLocalizationProvider>
        </AppTypeContext.Provider>
      </ThemeProvider>
    </LocaleContextProvider>
  );
}

export default App;
