import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { subYears, startOfMonth, format } from 'date-fns';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import type { TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudInsightsEDP/CloudClientProvider';
import { DATE_FORMAT, getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

type TableData = Record<
  'aws_infra_spend' | 'eligible_marketplace_spend' | 'commitment' | 'total_edp_eligible_spend',
  number
>;

const EDPTotalEligibleQuery = graphql(`
  query EDPTotalEligibleSpend($accountId: String!, $startDate: AWSDate!, $checkCode: String!) {
    athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }
    ) {
      __typename
      ... on DataTableResult {
        table(columns: ["aws_infra_spend", "eligible_marketplace_spend", "commitment", "total_edp_eligible_spend"]) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
    checkQuery(params: { accountId: $accountId, startDate: $startDate, code: $checkCode }) {
      ... on CheckResult {
        __typename
        hashcode
        result {
          time
          values {
            code
            dateEnd
            dateStart
            checkResult {
              columns
              data
              dataTypes
            }
          }
        }
      }
      ... on DeferredQueryResult {
        __typename
        hashcode
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export const useEDPTotalEligibleSpend = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();
  const startDate = useMemo(() => format(subYears(startOfMonth(new Date()), 1), DATE_FORMAT), []);

  return useQuery({
    queryKey: ['EDPTotalEligibleSpend'],
    queryFn: () =>
      fetchCloudOptimization(EDPTotalEligibleQuery, {
        accountId,
        startDate,
        checkCode: 'TOTAL_COST_DISCOUNT',
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      if (data.athenaViewQuery?.__typename !== 'DataTableResult') {
        return null;
      }
      if (data.checkQuery?.__typename !== 'CheckResult') {
        return null;
      }

      const row = getTableData(data.athenaViewQuery.table as TableType)?.[0] as TableData;
      const months =
        data.checkQuery.result
          ?.flatMap((item) => item?.values)
          .map((item) => getTableData(item?.checkResult as TableType) as Array<{ total_edp_discount: number }>)
          .flat() ?? [];

      return {
        spendInTerm: row.aws_infra_spend + row.eligible_marketplace_spend,
        totalSpend: row.commitment,
        avgMonthlySaving: months.reduce((acc, item) => acc + item.total_edp_discount, 0) / months.length,
      };
    },
  });
};
