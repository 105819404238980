import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useGetServiceCatalogResources } from './useGetServiceCatalogResources';

export const useGetRequestService = (request?: Request) => {
  const { resources, isLoading } = useGetServiceCatalogResources({
    resourcesFilter: (item) => item.kind === 'Vertice/ServiceCatalog/Service/ServiceDefinition',
  });

  // find the service by the generated identifier from the URL
  return {
    isLoading,
    service: resources?.find((item) => item.urn === request?.serviceRef),
  };
};
