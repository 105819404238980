import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Request as LegacyRequest } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isRequestRef, parseRequestRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { RequestRow } from './types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListServiceRequestsPaginatedQuery } from '@vertice/slices';
import { useCallback, useMemo } from 'react';
import { useListRequestsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { getDataForWidget } from '../RequestDetailPage/widgets/shared/utils';
import { DETAILS_WIDGET_URN_PATTERN } from '../RequestDetailPage/widgets/Details/widgetDef';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';

export const addRequestsRows = (items: Request[], closed: boolean): RequestRow[] => {
  return items
    .filter((request) => {
      return (
        Array<Request['status']>('COMPLETED', 'CANCELLED', 'TERMINATED', 'FAILED').includes(request.status) === closed
      );
    })
    .map((request) => {
      const { requestId } = parseRequestRef(request.ref);
      const parentRequestId = request.parentRequestRef
        ? parseRequestRef(request.parentRequestRef).requestId
        : undefined;
      const vendor = getDataForWidget(request, DETAILS_WIDGET_URN_PATTERN)?.vendor ?? request.verticeReserved?.vendor;

      return {
        requestId: requestId,
        requestName: request.name,
        requestType: request.serviceName,
        createdAt: request.createdAt,
        lastActivityAt: request.updatedAt,
        closedAt: request.closedAt,
        status: request.status,
        parentId: parentRequestId,
        vendor: vendor,
        ownerId: request.owner ? parseUserRef(request.owner).userId : undefined,
      };
    });
};

const addLegacyRequestRows = (items: LegacyRequest[], isClosed: boolean) =>
  items
    .filter((request) => {
      return (
        Array<LegacyRequest['status']>('COMPLETED', 'CANCELLED', 'TERMINATED').includes(request.status) === isClosed
      );
    })
    .map((request) => {
      const { requestId } = parseRequestRef(request.ref);
      const vendor = request.vendor;

      return {
        requestId: request.contractId || requestId,
        requestName: request.name,
        createdAt: request.createdAt,
        lastActivityAt: request.lastActivityAt,
        closedAt: request.closedAt,
        status: request.status,
        isLegacy: true,
        vendor,
      };
    });

export const useRequests = (closed: boolean) => {
  const { accountId } = useAccountContext();
  const { data: requests, isLoading: isLoadingRequests, refetch } = useListServiceRequestsPaginatedQuery({ accountId });
  const {
    data: legacyRequests,
    isLoading: isLoadingLegacyRequests,
    refetch: refetchLegacyRequests,
  } = useListRequestsQuery({ accountId });

  const isLoading = useMemo(
    () => isLoadingRequests || isLoadingLegacyRequests,
    [isLoadingRequests, isLoadingLegacyRequests]
  );
  const refetchAll = useCallback(async () => {
    await Promise.all([refetch(), refetchLegacyRequests()]);
  }, [refetch, refetchLegacyRequests]);

  useNotificationWebSocketSubscription({
    filter: isRequestRef,
    callback: () => {
      void refetchAll();
    },
  });

  const { rows, ids } = useMemo(() => {
    if (!requests?.items || !legacyRequests?.items) {
      return { rows: [], ids: new Set() };
    }

    const legacyRequestItems = addLegacyRequestRows(legacyRequests.items, closed);
    const requestItems = addRequestsRows(requests.items, closed);
    const requestRows: RequestRow[] = [...requestItems, ...legacyRequestItems];
    const requestIds = new Set(requestRows.map((row) => row.requestId));

    return { rows: requestRows, ids: requestIds };
  }, [closed, requests?.items, legacyRequests?.items]);

  return {
    requests: rows,
    requestIds: ids,
    isLoadingRequests: isLoading,
    refetch: refetchAll,
  };
};
