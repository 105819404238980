import React from 'react';
import useAccountsData from '../Table/useAccountsData';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';
import BaseCloudAnalyticsStats from '../../BaseCloudTab/Stats/BaseCloudAnalyticsStats';

const CloudAccountsTable = () => {
  const { period } = useCloudAnalytics();
  const { data, isLoading } = useAccountsData(period);
  return <BaseCloudAnalyticsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountsTable;
