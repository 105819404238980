import { z } from 'zod';
import { offerSchema } from '../shared/schemas';

export type SelectionStatus = 'REPEAT' | 'DECLINE' | 'ACCEPTED';
export const SELECTION_STATUS_OPTIONS: [SelectionStatus, ...SelectionStatus[]] = ['REPEAT', 'DECLINE', 'ACCEPTED'];

export const zodSchema = z
  .object({
    negotiationOffers: z.object({
      instances: z.array(offerSchema),
    }),
    baselineOffer: offerSchema,

    finalOffer: offerSchema.optional(),
    status: z.enum(SELECTION_STATUS_OPTIONS),
  })
  .refine(
    ({ finalOffer, status }) => (finalOffer && status === 'ACCEPTED') || status === 'REPEAT' || status === 'DECLINE',
    { path: ['status'] }
  );

export type FormData = z.infer<typeof zodSchema>;
