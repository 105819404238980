import Button from '@verticeone/design-system/src/components/Button';
import { TaskStatus } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';
import { TaskColorVariant } from './types';

type TaskViewButtonProps = {
  taskId: string;
  assigneeIds?: string[];
  colorVariant: TaskColorVariant;
  status?: TaskStatus;
};

export const TaskViewButton = ({ taskId, assigneeIds, colorVariant, status }: TaskViewButtonProps) => {
  const { t } = useTranslation();

  const { loggedUserId, onOpenTask } = useWorkflowRendererContext();
  const isAssigned = assigneeIds?.includes(loggedUserId);

  // Only show the button if the task is assigned to the current user and is active or failed task
  if (!isAssigned || !Array<TaskColorVariant>('error', 'secondary').includes(colorVariant) || !status) {
    return null;
  }

  return (
    <Stack marginTop={2}>
      <Button onClick={() => onOpenTask?.(taskId)} variant="ghost" size="XS" color={colorVariant}>
        {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.VIEW_TASK')}
      </Button>
    </Stack>
  );
};
