import BaseCloudAnalyticsGraph from '../../../BaseCloudTab//Graph/BaseCloudAnalyticsGraph';
import React from 'react';
import useAccountServicesUsageGraphData from '../useAccountServicesUsageGraphData';

type CloudAccountsServicesGraphProps = {
  linkedAccountId: string;
  productCode: string;
};

const CloudAccountServicesUsageGraph = ({ linkedAccountId, productCode }: CloudAccountsServicesGraphProps) => {
  const { data: graphData, ...loadingStatus } = useAccountServicesUsageGraphData({
    linkedAccountId,
    productCode,
  });

  return <BaseCloudAnalyticsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudAccountServicesUsageGraph;
