import { Offer } from '../../../../../task/TaskModal/TaskModalForm/predefinedForms/shared/schemas';
import { EnumeratedOfferSection } from './EnumeratedOffersSection';
import { Stack } from '@mui/material';
import { WidgetProps } from '../../shared/types';

export const OffersSection = ({ widgetData }: WidgetProps) => {
  const offers = widgetData?.negotiationOffers;
  const baselineOffer = widgetData?.baselineOffer;

  return (
    <Stack gap={4}>
      {(offers?.instances ?? []).map((offer: Offer, index: number) => (
        <EnumeratedOfferSection
          key={[offer.id, index].join()}
          index={index + 1}
          offer={offer}
          baselineOffer={baselineOffer}
        />
      ))}
    </Stack>
  );
};
