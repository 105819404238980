import { useTranslation } from 'react-i18next';
import { FieldPath, useController } from 'react-hook-form';
import { RadioGroup, Stack, SxProps } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import RadioSwitch from '@verticeone/design-system/src/components/RadioSwitch';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

import { TaskFormControlLabel } from '../../shared/formFields/TaskFormControlLabel';
import { FormData } from '../schema';

export enum SecurityQuestionAnswer {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
}

type SecurityQuestionProps = {
  fieldName: FieldPath<FormData>;
  questionNumber: number;
  sx?: SxProps;
};

export const SecurityQuestion = ({ fieldName, questionNumber, sx }: SecurityQuestionProps) => {
  const { t } = useTranslation();
  const {
    field: { ref, value, ...field },
  } = useController<FormData>({ name: fieldName });

  const handleChange = (newValue: string) => field.onChange(newValue);

  return (
    <Stack sx={sx}>
      <Text variant="caption" size="XS" color="text2">
        {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q${questionNumber}`)}
      </Text>
      <RadioGroup ref={ref} value={value} onChange={(_event, newValue) => handleChange(newValue)} row={true}>
        <TaskFormControlLabel
          control={<RadioSwitch value={SecurityQuestionAnswer.YES} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
          label={t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${SecurityQuestionAnswer.YES}`)}
        />
        <TaskFormControlLabel
          control={<RadioSwitch value={SecurityQuestionAnswer.NO} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
          label={t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${SecurityQuestionAnswer.NO}`)}
        />
        <TaskFormControlLabel
          control={<RadioSwitch value={SecurityQuestionAnswer.UNKNOWN} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
          label={t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${SecurityQuestionAnswer.UNKNOWN}`)}
        />
      </RadioGroup>
    </Stack>
  );
};
