import { Resource } from './types';

export const COMING_SOON_TEMPLATES: Resource[] = [
  {
    name: 'NDA Agreement',
    category: 'Procurement',
    urn: 'nda_agreement',
    definition: {
      Description: 'Automatically send and manage NDA agreement fulfillment',
    },
    thumbnail: {
      Type: 'PREDEFINED',
      Id: 'nda-agreement',
    },
  },
  {
    name: 'IT Security Review',
    category: 'Procurement',
    urn: 'it_security_review',
    definition: {
      Description:
        'Manage surveying and collection of IT security information or integrate with your existing tools to bring external assessments into your Vertice procurement workflow',
    },
    thumbnail: {
      Type: 'PREDEFINED',
      Id: 'it-security-review-preview',
    },
  },
  {
    name: 'RF[x] Playbook',
    category: 'Procurement',
    urn: 'rfx_playbook',
    definition: {
      Description:
        'Build and manage your RFI/RFP/RFX playbooks. Automate and manage data collection, Vendor scoring and selection',
    },
    thumbnail: {
      Type: 'PREDEFINED',
      Id: 'rfx-playbook',
    },
  },
  {
    name: 'Hire External Contractor',
    category: 'Resourcing',
    urn: 'hire_external_contractor',
    definition: {
      Description:
        'Simplify, automate and manage your contractor resourcing process with Vertice Intelligent Workflows',
    },
    thumbnail: {
      Type: 'PREDEFINED',
      Id: 'hire-external-contractor',
    },
  },
];
