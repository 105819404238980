import { NavigationNode, useCloudAnalytics } from '../../../CloudAnalyticsContext';
import React from 'react';
import CloudAccountServicesStats from './CloudAccountServicesStats';
import CloudAccountServicesUsageStats from './CloudAccountServicesUsageStats';
import CloudAccountsStats from './CloudAccountsStats';
import { useParams } from 'react-router-dom';

const StatsByLastNode = () => {
  const { node, getLastNode } = useCloudAnalytics();
  const lastNode = getLastNode();
  const { activeTab } = useParams();
  const activeTabId = activeTab as keyof NavigationNode;

  switch (lastNode.type) {
    case 'account':
      return <CloudAccountServicesStats linkedAccountId={lastNode.id} />;
    case 'service':
      return (
        <CloudAccountServicesUsageStats
          linkedAccountId={node[activeTabId]?.children?.id || ''}
          productCode={lastNode.id}
        />
      );
    default:
      return <CloudAccountsStats />;
  }
};

export default StatsByLastNode;
