import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Stack } from '@mui/material';
import { UserForm } from '@vertice/components/src/UserForm';
import { AccountUser } from '@vertice/slices';
import { AppTypeContext } from '../contexts/AppTypeContext';
import { UpdatedItem } from './UserList';
import UserFormNotificationsTabContent, { LocationWithState, MODAL_TABS } from './UserFormNotificationsTabContent';
import useUserSettings from '@vertice/core/src/hooks/useUserSettings';
import { Dialog, DialogActions, DialogContent, DialogHeader } from '@verticeone/design-system/src/components/Dialog';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import { FormEntrySkeleton } from '@vertice/core/src/modules/forms/fields/FormEntry/FormEntrySkeleton';
import Button from '@verticeone/design-system/src/components/Button';

interface ModalSettings {
  open: boolean;
  editedUser?: AccountUser;
  isAddingUser?: boolean;
}

const DialogContentSkeleton = () => (
  <DialogContent>
    <Stack direction="row" gap={4}>
      <Stack gap={4} flex={1}>
        <FormEntrySkeleton />
        <FormEntrySkeleton />
        <FormEntrySkeleton />
        <FormEntrySkeleton />
        <FormEntrySkeleton />
      </Stack>
      <Stack gap={4} flex={1}>
        <Placeholder height={150} />
        <Placeholder height={150} />
        <Placeholder height={150} />
      </Stack>
    </Stack>
  </DialogContent>
);

const useAddEditInviteContactModal = () => {
  const { t } = useTranslation();
  const location = useLocation() as LocationWithState;
  const { isIAT } = useContext(AppTypeContext);
  const { enqueueSnackbar } = useSnackbar();

  const currentModalTab = location?.state?.currentTab || MODAL_TABS.TEAM_MEMBER;

  const [loading, setLoading] = useState(false);
  const [modalSettings, setModalSettings] = useState<ModalSettings>({ open: false });
  const [addedContact, setAddedContact] = useState<UpdatedItem | null>(null);
  const [updatedContact, setUpdatedContact] = useState<UpdatedItem | null>(null);
  const [, setInvitedContact] = useState<UpdatedItem | null>(null);

  const { settings: userSettings } = useUserSettings({
    userId: modalSettings?.editedUser?.userId,
    skip: !modalSettings.open || !isIAT,
  });

  const handleAddRequest = () => {
    setModalSettings({ open: true, isAddingUser: true });
  };
  const handleEditRequest = (user: AccountUser) => {
    setModalSettings({ open: true, editedUser: user });
  };
  const handleModalClose = () => {
    setModalSettings({ open: false });
  };
  const handleUserSaved = (newUser: AccountUser) => {
    if (newUser?.userId && modalSettings) {
      if (modalSettings.editedUser) {
        // Refresh updated user in the list
        setUpdatedContact({ itemId: modalSettings.editedUser.userId });
      } else {
        // Add user into the list
        setAddedContact({ itemId: newUser.userId, selected: true });
      }
    } else {
      // Do nothing, just fire announce
      // this will be changed once the specification is completed
      setInvitedContact(null);
      enqueueSnackbar('User invited', { variant: 'success' });
    }
    handleModalClose();
  };

  const formId = 'new-form';

  const userFormComponent = (
    <UserForm
      formId={formId}
      userData={modalSettings?.editedUser}
      userSettings={userSettings}
      showTimeZone={isIAT && !!modalSettings?.editedUser}
      onUserSaved={handleUserSaved}
      setRequestInProgress={setLoading}
    />
  );

  const modal = (
    <Dialog size="M" open={modalSettings?.open} width={970} setOpen={handleModalClose}>
      <DialogHeader>
        {modalSettings?.editedUser
          ? t('PREFERENCES.DIRECTORY.USER_FORM.EDIT_TEAM_MEMBER')
          : t('PREFERENCES.DIRECTORY.USER_FORM.ADD_NEW_TEAM_MEMBER')}
      </DialogHeader>
      {isIAT && !modalSettings.isAddingUser && !userSettings ? (
        <DialogContentSkeleton />
      ) : (
        <>
          <DialogContent>
            {isIAT && !!modalSettings?.editedUser ? (
              <UserFormNotificationsTabContent
                teamMemberComponent={userFormComponent}
                userId={modalSettings?.editedUser?.userId}
              />
            ) : (
              userFormComponent
            )}
          </DialogContent>
          <DialogActions>
            {(currentModalTab === MODAL_TABS.TEAM_MEMBER || currentModalTab === MODAL_TABS.DIRECTORY) && (
              <Button type="submit" form={formId} isLoading={loading} disabled={loading} variant="solid">
                {modalSettings?.editedUser ? t('PREFERENCES.NEW_USER.SAVE_CHANGES') : t('PREFERENCES.NEW_USER.ADD_NEW')}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );

  return {
    modal,
    handleAddRequest,
    handleEditRequest,
    addedContact,
    updatedContact,
  };
};

export default useAddEditInviteContactModal;
