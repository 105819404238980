import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import Loader from '@verticeone/design-system/src/components/Loader';
import DataGrid, { DataGridProps } from '@verticeone/design-system/src/components/DataGrid';
import AddItemFooter from './AddItemFooter';
import { getRowId, preProcessNonEmptyCell } from './utils';
import NoRows from './NoRows';
import { EditableNameCell } from './cells';
import { useVendorProductsContext } from './VendorProductsContextProvider';
import { ProductItem } from './types';
import EditableTierCell from './cells/EditableTierCell';
import TierCell from './cells/TierCell';
import NameCell from './cells/NameCell';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';

export interface ProductListProps<T extends GridValidRowModel> {
  extraColumns?: GridColDef<T>[];
  dataGridExtraProps?: Partial<DataGridProps>;
  columnVisibilityModel?: GridColumnVisibilityModel;
  readOnlyMode?: boolean;
  selectedProducts: T[];
}

const ProductList = <T extends ProductItem>({
  extraColumns = [],
  dataGridExtraProps = {},
  columnVisibilityModel = {},
  selectedProducts,
  readOnlyMode,
}: ProductListProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE' });
  const { vendorProducts } = useVendorProductsContext();

  const columnsToUse = useMemo(
    (): GridColDef<T>[] => [
      {
        field: 'productId',
        headerName: t('PRODUCT'),
        renderCell: (params) => <NameCell {...params} />,
        renderEditCell: (params) => <EditableNameCell {...params} selectedProducts={selectedProducts} />,
        preProcessEditCellProps: preProcessNonEmptyCell,
        disableColumnMenu: true,
        editable: true,
        flex: 1.3,
      },
      {
        field: 'licenseType',
        headerName: t('TIER'),
        renderCell: (params) => <TierCell {...params} />,
        renderEditCell: (params) => <EditableTierCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1.3,
      },
      ...extraColumns,
    ],
    [t, selectedProducts, extraColumns]
  );

  if (vendorProducts === undefined) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <DataGrid<T>
      rows={selectedProducts || []}
      columns={columnsToUse}
      getRowId={getRowId}
      slots={{
        footer: AddItemFooter,
        noRowsOverlay: () => <NoRows readOnlyMode={readOnlyMode} />,
      }}
      sortingMode="client"
      isCellEditable={() => false}
      disableRowSelectionOnClick
      hideFooter
      columnHeaderHeight={48}
      autoHeight={true}
      columnVisibilityModel={columnVisibilityModel}
      {...dataGridExtraProps}
    />
  );
};

export default ProductList;
