import React from 'react';
import { ApprovalTwoTone, RequestPageTwoTone, AccountTreeTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NavbarIcon } from './navbarIcon.svg';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';
import NavigationGroup from '@vertice/dashboard/src/layouts/Main/Sidebar/components/NavigationGroup';
import NavigationItem from '@vertice/dashboard/src/layouts/Main/Sidebar/components/NavigationItem';
import useCfaRoutes from '@vertice/dashboard/src/router/Routes';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { Can } from '@verticeone/auth/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const WorkflowsNavbarItems = () => {
  const { t } = useTranslation();
  const routes = useCfaRoutes();
  const { isOpen: isNavbarExpanded } = useMainLayout();
  const { isEnabled } = useFeatures();
  const { accountId } = useAccountContext();

  const isWorkflowsGroupVisible = isEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  if (!isWorkflowsGroupVisible) {
    return null;
  }

  return (
    <NavigationGroup
      isVisible={isWorkflowsGroupVisible}
      label={
        isNavbarExpanded
          ? t('INTELLIGENT_WORKFLOWS.NAVBAR.GROUP_TITLE')
          : t('INTELLIGENT_WORKFLOWS.NAVBAR.GROUP_TITLE_SHORT')
      }
      icon={NavbarIcon}
    >
      <NavigationItem
        to={routes.INTELLIGENT_WORKFLOWS.TASKS.ABSOLUTE_PATH}
        icon={ApprovalTwoTone}
        label={t('INTELLIGENT_WORKFLOWS.NAVBAR.TASKS')}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      />
      <NavigationItem
        to={routes.INTELLIGENT_WORKFLOWS.REQUESTS.ABSOLUTE_PATH}
        icon={RequestPageTwoTone}
        label={t('INTELLIGENT_WORKFLOWS.NAVBAR.REQUESTS')}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        match={[
          routes.INTELLIGENT_WORKFLOWS.REQUESTS.ABSOLUTE_PATH,
          routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL.ABSOLUTE_PATH,
        ]}
      />
      <Can do="workflow:List" on={`urn:verticeone:vertice:${accountId}:workflows:workflow/*`}>
        <NavigationItem
          to={routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.ABSOLUTE_PATH}
          icon={AccountTreeTwoTone}
          label={t('INTELLIGENT_WORKFLOWS.NAVBAR.WORKFLOWS')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          match={[
            routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.ABSOLUTE_PATH,
            routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.ABSOLUTE_PATH,
            routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL.ABSOLUTE_PATH,
            routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL.VERSION.EDITOR.ABSOLUTE_PATH,
          ]}
        />
      </Can>
    </NavigationGroup>
  );
};

export default WorkflowsNavbarItems;
