import { PredefinedFormDef } from '../shared/types';
import { SecurityAssessmentForm } from './SecurityAssessmentForm';
import { zodSchema, FormData } from './schema';
import { getQuestionnaireDefaultValues } from '../SecurityQuestionnaireForm/formDef';

export const getAssessmentDefaultValues: Required<PredefinedFormDef<FormData>>['getDefaultValues'] = (
  getValue,
  taskContext
) => {
  return {
    securityScore: getValue('securityScore'),
    ...getQuestionnaireDefaultValues(getValue, taskContext),
  };
};

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/securityReview\/assessment\/v\d+$/,
  component: SecurityAssessmentForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue, taskContext) => ({
    ...getAssessmentDefaultValues(getValue, taskContext),
  }),
};
