import React, { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useContracts } from '@vertice/core/src/modules/saas/contract/components/ContractList/useContracts';
import { ContractListColumn, FilterView } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import { GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useGetColumnBuilder } from '@vertice/core/src/modules/saas/contract/components/ContractList/useGetColumnBuilder';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractsTable } from '@vertice/core/src/modules/saas/contract/components/ContractList/ContractsTable';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import Tab from '@verticeone/design-system/src/components/Tab';
import StageIndicator from './StageIndicator';
import { ContractGridType, DASHBOARD_FILTERS, DashboardStage } from './types';
import { Box } from '@mui/material';
import Divider from '@verticeone/design-system/src/components/Divider';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { getFilterKey } from '@vertice/core/src/modules/saas/contract/components/ContractList/functions';

const filters = Object.values(DASHBOARD_FILTERS);
const defaultFilterView = DASHBOARD_FILTERS.IN_PROGRESS;

const Contracts: FC = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  const getContractPath = (contractId: string) => generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, { contractId });

  const [activeFilterView, setActiveFilterView] = useState<FilterView>(defaultFilterView);
  const { viewsOnContracts, isLoading } = useContracts({ filterViews: filters, activeFilterView: activeFilterView });

  const { buildColumnsFromView } = useGetColumnBuilder();
  const columns: GridColDef<ViewOnContract>[] = buildColumnsFromView?.(activeFilterView) ?? [];

  const filterType = useMemo(() => {
    for (const [type, filter] of Object.entries(DASHBOARD_FILTERS)) {
      if (getFilterKey(filter) === getFilterKey(activeFilterView)) {
        return type as DashboardStage;
      }
    }
  }, [activeFilterView]);

  const defaultSortModel: GridSortModel = useMemo(() => {
    switch (filterType) {
      case 'IN_PROGRESS':
      case 'UPCOMING':
        return [{ field: ContractListColumn.RENEWAL_DATE, sort: 'asc' }];
      default:
        return [{ field: ContractListColumn.VENDOR, sort: 'asc' }];
    }
  }, [filterType]);

  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(defaultSortModel);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel | undefined>({ pageSize: 25, page: 0 });

  return (
    <>
      <Box px={10}>
        <Tabs
          color="primary"
          value={filterType}
          onChange={(_: SyntheticEvent, newValue: DashboardStage) => setActiveFilterView(DASHBOARD_FILTERS[newValue])}
        >
          <Tab
            label={t(`DASHBOARD.TABS.IN_PROGRESS`)}
            value={ContractGridType.DashboardInProgress}
            icon={<StageIndicator stage="IN_PROGRESS" />}
            iconPosition="start"
          />
          <Tab
            label={t(`DASHBOARD.TABS.UPCOMING`)}
            value={ContractGridType.DashboardUpcoming}
            icon={<StageIndicator stage="UPCOMING" />}
            iconPosition="start"
          />
        </Tabs>
      </Box>

      <Box>
        <Divider />
        <ContractsTable
          rows={viewsOnContracts}
          isLoading={isLoading}
          columns={columns}
          getContractPath={getContractPath}
          sortModel={sortModel}
          setSortModel={setSortModel}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          noBorderRadius
          noBorder
        />
      </Box>
    </>
  );
};

export default Contracts;
