import React from 'react';
import Card, { CardHeader, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { useGetProductByIdQuery } from '@vertice/slices';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Divider from '@verticeone/design-system/src/components/Divider';
import { LinkSection } from './components/LinkSection';
import { InfoField } from './components/InfoField';

export type AboutProductCardProps = {
  vendorId: string;
  productId: string;
};

export const AboutProductCard = ({ vendorId, productId }: AboutProductCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PRODUCT.ABOUT_PRODUCT' });
  const { data: product, isLoading } = useGetProductByIdQuery({ vendorId, productId });
  const allCategories = product?.categories?.join(', ') || undefined;

  return (
    <Card minWidth={374}>
      <CardHeader size="S">
        <CardHeaderTitle text={t('TITLE')} />
      </CardHeader>
      <Stack direction="row">
        <Stack flexGrow={1} flexShrink={1} minWidth={0} p={6} gap={4}>
          <InfoField
            label={t('LABELS.DESCRIPTION')}
            value={product?.description}
            isLoading={isLoading}
            expandable={true}
          />
          <Stack direction="row" columnGap={6} rowGap={4} flexWrap="wrap">
            <InfoField label={t('LABELS.MAIN_CATEGORY')} value={product?.category} isLoading={isLoading} />
            <InfoField label={t('LABELS.ALL_CATEGORIES')} value={allCategories} isLoading={isLoading} />
          </Stack>
        </Stack>
        <Divider orientation="vertical" />
        <Stack flexShrink={0} flexBasis={232} p={6}>
          <LinkSection title={t('LABELS.LINKS')} links={product?.links} />
        </Stack>
      </Stack>
    </Card>
  );
};
