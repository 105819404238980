import { ListTagsApiArg, ListTagsApiResponse, Tag, useLazyListTagsQuery } from '@vertice/slices';
import { useAccountContext } from '../contexts/AccountContext';
import { useFetchPaginated } from './useFetchPaginated';
import { useEffect, useState } from 'react';
import { DEPARTMENT_TAG_PREFIX, isDefaultDepartmentTag } from '../modules/saas/contract/utils/tagUtils';
import { orderBy } from 'lodash';

type UseAccountDepartmentsProps = {
  skip?: boolean;
  overriddeAccountId?: string | null;
};

export type Department = {
  departmentId: string;
  name: string;
  code?: string;
};

export const useAccountDepartments = ({ skip, overriddeAccountId }: UseAccountDepartmentsProps | undefined = {}) => {
  const { accountId } = useAccountContext();
  const [fetchTags] = useLazyListTagsQuery();
  const [departments, setDepartments] = useState<Department[]>();

  const { items: departmentTags, isLoading: isLoadingDepartmentTags } = useFetchPaginated<
    ListTagsApiResponse,
    ListTagsApiArg,
    Tag
  >({
    fetchFn: fetchTags,
    getItemsFn: (data) => data.tags,
    getNextTokenFn: (data) => data.nextToken,
    fetchArgs: {
      accountId: overriddeAccountId || accountId!,
      tagIdPrefix: DEPARTMENT_TAG_PREFIX,
    },
    skip: skip,
  });

  const mapTagToDepartment = (tag: Tag) => {
    return {
      departmentId: tag.tagId,
      name: tag.label,
      code: isDefaultDepartmentTag(tag) ? 'DEFAULT' : undefined,
    };
  };

  useEffect(() => {
    if (departmentTags) {
      setDepartments(departmentTags.map(mapTagToDepartment));
    }
  }, [departmentTags]);

  if (skip) {
    return { data: undefined, isLoading: false };
  }

  return {
    data: orderBy(departments, (dep) => dep.name.toLowerCase()),
    isLoading: isLoadingDepartmentTags || !departments,
  };
};
