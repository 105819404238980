import { createTypedTaskFormEntry } from '../shared/formFields/TaskFormEntry';
import { FormData } from './schema';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@verticeone/design-system/src/components/Grid';
import { RequestOwnerSelectFormEntry } from '@vertice/core/src/modules/intelligentWorkflows/components/formFields/RequestOwnerSelectFormEntry';

export const AssignRequestOwnerFormEntry = createTypedTaskFormEntry<FormData>();

export const AssignRequestOwnerForm = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.TASK_FORMS.ASSIGN_REQUEST_OWNER' });

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <AssignRequestOwnerFormEntry
          name="requestOwner"
          label={t('LABELS.OWNER')}
          width={12}
          component={RequestOwnerSelectFormEntry}
          componentProps={{}}
        />
      </Grid>
    </Grid>
  );
};
