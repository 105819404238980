import React, { createContext, useCallback, useContext, useState } from 'react';
import type { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import type { SerializedError } from '@reduxjs/toolkit';

import { useInstanceList } from './InstanceListProvider';
import GetOfferingsHandlerProvider, {
  GetOfferingsHandler,
  OfferingsParserHandler,
} from './GetOfferingsHandlerProvider';
import type { RDSDataEnhanced } from '../../RDS/dataSource/useRDSData';
import type { EC2DataEnhanced } from '../../EC2/dataSource/useEC2Data';
import type { ChartSeriesDefinition, ReservationsData } from '../../../types';

export type InstanceData = RDSDataEnhanced | EC2DataEnhanced;

type ProviderProps<Item extends InstanceData> = {
  getReservations: (item: Item) => Promise<{
    data: Array<ReservationsData> | undefined;
    error: ErrorResponse | SerializedError | undefined;
  }>;
  children: React.ReactNode;
};

type InstanceItemProviderProps<Item extends InstanceData> = {
  item: Item;
  defaults: {
    isExpanded: boolean;
  };
  chartSeries: Array<ChartSeriesDefinition>;
  omittedColumns?: Array<string>;
} & ProviderProps<Item>;

type InstanceProviderProps<Item extends InstanceData> = {
  items?: Array<Item>;
  getOfferings: GetOfferingsHandler<Item>;
  offeringsParser: OfferingsParserHandler<Item>;
  chartSeries: Array<ChartSeriesDefinition>;
  omittedColumns?: Array<string>;
} & ProviderProps<Item>;

type Reservations = {
  data: Array<ReservationsData> | undefined;
  error: ErrorResponse | SerializedError | undefined;
  isUninitialized: boolean;
  isFetching: boolean;
};

type InstanceItemContextData = {
  item: InstanceData;
  buyDrawer: {
    isOpened: boolean;
    open: () => void;
    close: () => void;
  };
  managedByVertice: {
    isActive: boolean;
    toggle: () => void;
  };
  reservations: {
    fetch: () => void;
  } & Reservations;
  detail: {
    isExpanded: boolean;
    toggle: () => void;
  };
  chartSeries: Array<ChartSeriesDefinition>;
  omittedColumns?: Array<string>;
};

const InstanceItemContext = createContext<InstanceItemContextData>({} as InstanceItemContextData);

const InstanceItemProvider = <Item extends InstanceData>({
  item,
  defaults,
  getReservations,
  chartSeries,
  omittedColumns,
  children,
}: InstanceItemProviderProps<Item>) => {
  const [buyDrawerInstance, setBuyDrawerInstance] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(defaults.isExpanded);
  const [reservations, setReservations] = useState<Reservations>({
    data: undefined,
    error: undefined,
    isUninitialized: true,
    isFetching: false,
  });

  //prettier-ignore
  const fetchReservations = useCallback(() => {
    if (reservations.isUninitialized) {
      setReservations(prev => ({ ...prev, isUninitialized: false, isFetching: true }));
      void getReservations(item).then((response) => setReservations(prev => ({
        ...prev,
        ...response,
        isFetching: false,
      })));
    }
  }, [reservations, getReservations, item]);

  return (
    <InstanceItemContext.Provider
      key={item.id}
      value={{
        item,
        buyDrawer: {
          isOpened: buyDrawerInstance,
          close: () => setBuyDrawerInstance(false),
          open: () => setBuyDrawerInstance(true),
        },
        managedByVertice: {
          isActive,
          toggle: () => setIsActive((prev) => !prev),
        },
        detail: {
          isExpanded: isExpanded,
          toggle: () => setIsExpanded((prev) => !prev),
        },
        reservations: {
          fetch: fetchReservations,
          ...reservations,
        },
        chartSeries: chartSeries,
        omittedColumns: omittedColumns,
      }}
    >
      {children}
    </InstanceItemContext.Provider>
  );
};

const InstanceProvider = <Item extends InstanceData>({
  items,
  getReservations,
  getOfferings,
  offeringsParser,
  chartSeries,
  omittedColumns,
  children,
}: InstanceProviderProps<Item>) => {
  const { pagination } = useInstanceList();

  return (
    <GetOfferingsHandlerProvider
      getOfferings={getOfferings as GetOfferingsHandler<InstanceData>}
      offeringsParser={offeringsParser as OfferingsParserHandler<InstanceData>}
    >
      {items
        ?.sort((a, b) => b.savingsCostFrom - a.savingsCostFrom)
        ?.slice(0, pagination.visibleItems)
        .map((item) => (
          <InstanceItemProvider
            item={item}
            getReservations={getReservations}
            defaults={{ isExpanded: items.indexOf(item) === 0 }}
            chartSeries={chartSeries}
            omittedColumns={omittedColumns}
            key={item.id}
          >
            {children}
          </InstanceItemProvider>
        ))}
    </GetOfferingsHandlerProvider>
  );
};

export const useInstanceData = () => useContext(InstanceItemContext);

export default InstanceProvider;
