import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

export type UseBenchmarkingReturn = {
  benchmarkingStage0: boolean;
  isLoading: boolean;
};

const useBenchmarking = (): UseBenchmarkingReturn => {
  const { getFeature, isLoading } = useFeatures();
  const benchmarkFeature = getFeature(FEATURES.VENDOR_BENCHMARKING);
  const benchmarkStage0Enabled = benchmarkFeature?.enabled && benchmarkFeature?.properties?.STAGE_0;

  return {
    benchmarkingStage0: benchmarkStage0Enabled,
    isLoading,
  };
};

export default useBenchmarking;
