import { useAccountContext } from '../contexts/AccountContext';
import { useAccountUsersById } from './useAccountUsersById';
import { useVerticeUsersById } from './useVerticeUsersById';
import { useMemo } from 'react';
import { AccountUser, UserInfo } from '@vertice/slices';

export type UsersByIdReturn = Record<string, AccountUser | UserInfo>;

export const useRelevantUsersById = (options?: { skip: boolean }) => {
  const { accountId } = useAccountContext();
  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useAccountUsersById({
    skip: options?.skip || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION',
  });
  const { usersById: verticeUsersById, isLoadingUsers: isLoadingVerticeUsers } = useVerticeUsersById({
    skip: options?.skip || accountId !== 'VERTICE_WORKFLOW_ADMINISTRATION',
  });

  const usersById = useMemo(() => {
    return { ...accountUsersById, ...verticeUsersById };
  }, [accountUsersById, verticeUsersById]);

  const isLoadingUsers = useMemo(
    () => isLoadingAccountUsers || isLoadingVerticeUsers,
    [isLoadingAccountUsers, isLoadingVerticeUsers]
  );

  return {
    usersById,
    isLoadingUsers,
  };
};
