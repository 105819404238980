import { FC, PropsWithChildren } from 'react';
import { DRAWER_WIDTH } from '../WorkflowViewer/constants';
import { Stack, styled } from '@mui/material';

const StyledDrawer = styled(Stack)<{ $open: boolean }>(({ theme, $open }) => ({
  position: 'fixed',
  backgroundColor: theme.palette.background.default,
  height: '100%',
  top: 0,
  right: 0,
  width: DRAWER_WIDTH,
  transition: 'transform ease-in-out 300ms',
  transform: `translate(${$open ? 0 : DRAWER_WIDTH}px, 0)`,
  boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '80' }),
}));

type EditorDrawerProps = PropsWithChildren & {
  open: boolean;
};

export const EditorDrawer: FC<EditorDrawerProps> = ({ children, open }) => {
  return <StyledDrawer $open={open}>{children}</StyledDrawer>;
};
