import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTaskFormContext } from '../TaskFormContext';

type SavingsInsightsFormEntryProps<FormDataType extends FieldValues> = {
  name: FieldPath<FormDataType>;
};

export const SavingsInsightsFormEntry = <FormDataType extends FieldValues>({
  name,
}: SavingsInsightsFormEntryProps<FormDataType>) => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();

  return (
    <TaskFormEntry<any, typeof FormTextField>
      name={name}
      label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.SAVINGS_INSIGHTS')}
      component={FormTextField}
      width={12}
      componentProps={{
        multiline: true,
        rows: 4,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        placeholder: !readOnly ? t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.PLACEHOLDERS.SAVINGS_INSIGHTS') : undefined,
      }}
    />
  );
};
