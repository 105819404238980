import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeepPartial, FieldPath, FieldPathByValue, FieldValues, useFormContext } from 'react-hook-form';
import { useOfferCostModelMutation } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import { Offer, joinFormPath, offerSchema } from './predefinedForms/shared/schemas';
import { useTaskFormContext } from './predefinedForms/shared/TaskFormContext';

const COST_MODEL_RELATED_FIELDS: Set<string | number> = new Set<FieldPath<Offer>>([
  'endDate',
  'startDate',
  'rollingFrequency',
  'products',
]);

export const useOfferFormComputeCosts = <
  T extends FieldValues,
  P extends FieldPathByValue<T, Offer> = FieldPathByValue<T, Offer>
>(
  offerName: P
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isProcessing, setProcessing } = useTaskFormContext();
  const { setValue, watch } = useFormContext<T>();

  const [transformModel, { isLoading }] = useOfferCostModelMutation();
  const validateOffer = useIsOfferValid();

  const computeOfferCosts = useCallback(
    async (form: DeepPartial<T>) => {
      setProcessing?.(true);
      try {
        let computedCosts = 0;
        const { success, offers } = validateOffer(get(form, offerName));
        if (success) {
          const models = await transformModel({ body: offers });
          if ('data' in models) {
            computedCosts = models.data[0].model.annual_cost;
          }
        }

        setValue(joinFormPath<T>(offerName, 'computedCosts'), computedCosts as any, { shouldValidate: true });
      } finally {
        setProcessing?.(false);
      }
    },
    [offerName, setValue, transformModel, setProcessing, validateOffer]
  );

  useEffect(() => {
    const subscription = watch((form, { name }) => {
      const fieldName = name?.split('.').pop();

      if (fieldName && COST_MODEL_RELATED_FIELDS.has(fieldName)) {
        computeOfferCosts(form).catch(() =>
          enqueueSnackbar(t('SNACKBAR.ERRORS.FAILED_TO_COMPUTE_COST'), {
            variant: 'error',
          })
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, computeOfferCosts, enqueueSnackbar, t]);

  return { isProcessing: isProcessing || isLoading };
};

const useIsOfferValid = () => {
  return useCallback((offer: Offer | undefined) => {
    if (!offer) {
      return { success: false, offers: [] };
    }
    const { success, error } = offerSchema.safeParse(offer);

    return {
      success:
        success ||
        !error?.errors.find(({ path }) => {
          return path.find((v) => COST_MODEL_RELATED_FIELDS.has(v));
        }),
      offers: [offer],
    };
  }, []);
};
