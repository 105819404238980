import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Direction } from '../../types';

/**
 * Only horizontal variant with core3 color is implemented ATM
 */

const StyledDivider = styled('div')<{ $orientation?: Direction }>(({ theme, children, $orientation }) => ({
  display: 'flex',
  flexDirection: $orientation === 'vertical' ? 'column' : 'row',
  alignItems: 'center',
  gap: children ? 4 : 0,
  '&::before, &::after': {
    flex: '1',
    content: '""',
    padding: '0.5px',
    backgroundColor: theme.palette.core.color3,
  },
}));

type DividerProps = {
  children?: ReactNode;
  orientation?: Direction;
};

const Divider: FC<DividerProps> = ({ children, orientation }) => {
  return <StyledDivider $orientation={orientation}>{children}</StyledDivider>;
};

export default Divider;
