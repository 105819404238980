import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const intelligentWorkflowsRoutes = createPortableRoutes({
  INTELLIGENT_WORKFLOWS: {
    path: '/intelligent-workflows',
    children: {
      TASKS: {
        path: 'tasks',
      },
      WORKFLOWS: {
        path: 'workflows',
        children: {
          SERVICE: {
            path: 'service/:serviceId',
            children: {
              DETAIL: {
                path: 'workflow/:workflowId',
                children: {
                  VERSION: {
                    path: 'version/:versionId',
                    children: {
                      EDITOR: {
                        path: 'edit',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      REQUESTS: {
        path: 'requests',
        children: {
          DETAIL: {
            path: ':requestId',
          },
        },
      },
    },
  },
} as const);
