import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from './components/Layout';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

const RIOOverview = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();

  // @todo: to be implemented. Now we are navigating to the optimize page
  useEffect(() => {
    navigate(routes.CLOUD.RIO.RI.OPTIMIZE);
  }, [navigate, routes.CLOUD.RIO.RI.OPTIMIZE]);

  return (
    <Layout
      getCanBeViewed={({ rio, sprio }) => rio && sprio}
      breadcrumbs={[
        {
          label: t('NAVBAR.RIO_NEW.MAIN'),
        },
      ]}
      title={t('RIO_NEW.OVERVIEW.TITLE')}
    ></Layout>
  );
};
export default RIOOverview;
