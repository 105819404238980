import { useCloudAnalytics } from '../../../../CloudAnalyticsContext';
import React from 'react';
import CloudTagsServicesGraphData from './CloudTagsServicesGraphData';
import CloudTagsServicesUsageGraph from './CloudTagsServicesUsageGraph';

const GraphByLastNode = () => {
  const { getLastNode } = useCloudAnalytics();
  const lastNode = getLastNode();

  switch (lastNode.type) {
    case 'service':
      return <CloudTagsServicesUsageGraph productCode={lastNode.id} />;
    default:
      return <CloudTagsServicesGraphData />;
  }
};

export default GraphByLastNode;
