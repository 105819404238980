import React, { FC } from 'react';

import Text from '@verticeone/design-system/src/components/Text';

import {
  GenericDateIcon,
  GenericUpdateIcon,
} from '@vertice/core/src/modules/intelligentWorkflows/components/icons/Icons';
import { RequestStatusChip } from '@vertice/core/src/modules/intelligentWorkflows/request/components/RequestStatusChip';
import { PageCard } from './PageCard';
import { Request } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { CardLine, CardTooltip, CardValue } from './CardContentElements';
import { getDataForWidget } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/widgets/shared/utils';
import { DETAILS_WIDGET_URN_PATTERN } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/widgets/Details/widgetDef';

type RequestPageCardProps = { request: Request };

export const RequestPageCard: FC<RequestPageCardProps> = ({ request }) => {
  const formatDate = useFormatDate();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  const deadline =
    getDataForWidget(request, DETAILS_WIDGET_URN_PATTERN)?.deadline ?? request?.verticeReserved?.request?.deadline;

  return (
    <PageCard
      key={request.ref}
      path={generatePath(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL.ABSOLUTE_PATH, {
        requestId: parseRequestRef(request.ref).requestId,
      })}
    >
      <CardLine>
        <Text variant="body-bold" color="text1">
          {request.name}
        </Text>
        <RequestStatusChip status={request.status} size="XS" />
      </CardLine>
      <CardTooltip translationKey="ENTITIES.WORKFLOW_REQUEST.LABELS.CREATION_DATE">
        <CardLine>
          <GenericDateIcon size="S" />
          <CardValue>{formatDate(request.createdAt)}</CardValue>
        </CardLine>
      </CardTooltip>
      <CardTooltip translationKey="ENTITIES.WORKFLOW_REQUEST.LABELS.NEXT_KEY_DATE">
        <CardLine>
          <GenericUpdateIcon size="S" />
          <CardValue>{deadline ? formatDate(deadline) : '-'}</CardValue>
        </CardLine>
      </CardTooltip>
    </PageCard>
  );
};
