import { useCloudAnalytics } from '../../../CloudAnalyticsContext';
import React from 'react';
import CloudAccountServicesStats from './CloudAccountServicesStats';
import CloudAccountServicesUsageStats from './CloudAccountServicesUsageStats';

const StatsByLastNode = () => {
  const { getLastNode } = useCloudAnalytics();
  const lastNode = getLastNode();

  switch (lastNode.type) {
    case 'service':
      return <CloudAccountServicesUsageStats productCode={lastNode.id} />;
    default:
      return <CloudAccountServicesStats />;
  }
};

export default StatsByLastNode;
