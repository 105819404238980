import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';

import Grid from '@verticeone/design-system/src/components/Grid';
import Text from '@verticeone/design-system/src/components/Text';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import Divider from '@verticeone/design-system/src/components/Divider';
import { FormData } from '../../SecurityAssessmentForm/schema';

export const SecurityQuestionnaireOverview = () => {
  const { t } = useTranslation();
  const taskData = useWatch<FormData>();

  return (
    <Stack gap={6}>
      <Grid container columnSpacing={4} rowGap={6}>
        <Grid item xs={6} rowGap={2}>
          <Stack gap={6}>
            <Stack>
              <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_WEBSITE')} />
              <Text variant="body-regular" size="S">
                {taskData.vendorWebsite}
              </Text>
            </Stack>

            <Stack>
              <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_CONTACT_EMAIL')} />
              <Text variant="body-regular" size="S">
                {taskData.vendorContact?.email}
              </Text>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={6}>
            <Stack>
              <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_CONTACT')} />
              <Text variant="body-regular" size="S">
                {taskData.vendorContact?.name}
              </Text>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q1')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharedWithVendor}`)}
          </Text>
        </Grid>
      </Grid>

      <Divider />

      <Stack gap={6}>
        <Text variant={'caption'}>{t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.TITLE_1')}</Text>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q2')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerPiiData}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q3')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerAccountAuthData}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q4')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.employeeContacts}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q5')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.employeeSocialSecurityNumbers}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q6')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.employeeBankAccounts}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q7')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.acquisitionPlans}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q8')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.sourceCode}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q9')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.publishedResearch}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q10')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.pressReleases}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q11')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.accountingData}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.Q12')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerCreditCardData}`)}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="XS" label={t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_NOTES')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.dataSharingDetails?.customerMedicalRecords}`)}
          </Text>
        </Stack>
      </Stack>

      <Divider />

      <Stack gap={6}>
        <Stack>
          <TextFieldCaption size="S" label={t('INTAKE_FORM.SECURITY_ASSESSMENT.Q_INTEGRATION')} />
          <Text variant="body-regular" size="S">
            {t(`INTAKE_FORM.SECURITY_QUESTIONNAIRE.${taskData.additionalIntegration}`)}
          </Text>
        </Stack>
        <Stack>
          <TextFieldCaption size="XS" label={t('INTAKE_FORM.SECURITY_ASSESSMENT.OTHER_NOTES')} />
          <Text variant="body-regular" size="S">
            {taskData.additionalNotes}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
