import React, { FC } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from '@xyflow/react';
import { Stack, styled, useTheme } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { EDGE_BORDER_RADIUS } from '../../../sharedVisualStyle/edgeStyle';

const StyledEdgeLabel = styled(ChipButton)<{ $labelX: number; $labelY: number }>(({ $labelX, $labelY, theme }) => ({
  position: 'absolute',
  transform: `translate(-50%, -50%) translate(${$labelX}px,${$labelY}px)`,
  cursor: 'default',
  backgroundColor: theme.palette.global.color.coolGray['20'].main,
}));

export const LabelledEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
  markerEnd,
  style,
  selected,
}) => {
  const { palette } = useTheme();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: EDGE_BORDER_RADIUS,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          stroke: palette.core.color5,
        }}
      />
      {label && (
        <EdgeLabelRenderer>
          <StyledEdgeLabel $labelX={labelX} $labelY={labelY} variant="solid" size="M">
            <Stack alignItems="center" gap="1px">
              <Text variant="body-bold" size="M" color={palette.global.color.coolGray['60'].main} tag="span">
                {label}
              </Text>
            </Stack>
          </StyledEdgeLabel>
        </EdgeLabelRenderer>
      )}
    </>
  );
};
