import { Stack } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import {
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
  GridExpandMoreIcon,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';

import { RequestRow } from '../types';
import { ExpandLess } from '@mui/icons-material';
import { LegacyRequestNameCell } from '../LegacyRequestNameCell';
import Text from '@verticeone/design-system/src/components/Text';
import { RequestLogo } from './RequestLogo';
import { AvatarStack } from '@verticeone/design-system/src/components/Avatar';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import { useUsersContext } from '@vertice/core/src/contexts/UsersContext';

export const ParentGroupingCell: FC<GridRenderCellParams<RequestRow>> = ({ id, field, rowNode, row }) => {
  const isGroup = rowNode.type === 'group';
  const isChild = rowNode.depth !== 0;

  const marginLeft = !isGroup ? 6 : undefined;

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <ExpandIcon id={id} field={field} rowNode={rowNode} />
      <Stack direction="row" gap={2} alignItems="center" sx={{ marginLeft }}>
        <RequestIcon row={row} isChild={isChild} />
        <Stack direction="column">
          {row.isLegacy ? (
            <LegacyRequestNameCell name={row.requestName} />
          ) : (
            <Text variant="body-regular">{row.requestName}</Text>
          )}
          {row.requestType && (
            <Text variant="body-bold" size="XS" color="text3">
              {row.requestType}
            </Text>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const RequestIcon: FC<{ row: RequestRow; isChild: boolean }> = ({ row, isChild }) => {
  const { getFullName } = useUsersContext();

  const avatarItems = useMemo(() => {
    if (!row.ownerId || isChild) {
      return undefined;
    }

    return [
      {
        personId: row.ownerId,
        personName: getFullName(row.ownerId),
        color: 'neutral' as const,
      },
    ];
  }, [isChild, row.ownerId, getFullName]);

  return (
    <Stack
      width={50}
      height={50}
      direction="row"
      sx={{
        position: 'relative',
        justifyContent: isChild ? 'end' : undefined,
      }}
    >
      <RequestLogo vendor={row.vendor} isChild={isChild} />
      {avatarItems && (
        <Stack sx={{ position: 'absolute', right: 0, bottom: 0 }}>
          <AvatarStack items={avatarItems} size="XXS" />
        </Stack>
      )}
    </Stack>
  );
};

const ExpandIcon: FC<{ id: GridRowId; field: string; rowNode: GridTreeNodeWithRender }> = ({ id, field, rowNode }) => {
  const apiRef = useGridApiContext();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (rowNode.type !== 'group') {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    },
    [id, field, rowNode, apiRef]
  );

  if (rowNode.type !== 'group') {
    return null;
  }

  return (
    <IconButton
      onClick={handleClick}
      size="XXS"
      variant="plain"
      icon={rowNode.childrenExpanded ? ExpandLess : GridExpandMoreIcon}
    />
  );
};
