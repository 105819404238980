import React, { useState, useCallback } from 'react';
import { Definitions, TaskDefinition } from '../../definitionsTypes';
import { updateTaskInProcessDefinition } from '../../definitions/processDefinition';
import { EditUserTask } from './EditUserTask';
import { isProcessDefinition } from '../../pocWorkflowSchema';
import { useWorkflowViewerState } from '../WorkflowViewer/useViewerState';
import { useSimpleDialogContext } from '@verticeone/design-system/src/components/Dialog/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';

type WorkflowEditorProps = {
  workflowDefinitions?: Definitions;
  setDefinitions: (definitions: Definitions) => void;
};

export const WorkflowEditor = ({ workflowDefinitions, setDefinitions }: WorkflowEditorProps) => {
  const { t } = useTranslation();
  const [activeEditNode, setActiveEditNode] = useState<TaskDefinition | undefined>(undefined);
  const { getConfirmation } = useSimpleDialogContext();
  const [hasChange, setHasChange] = useState(false);

  const handleNodeSelected = useCallback(
    (nodeId?: string) => {
      const processDefinition = workflowDefinitions?.definitions.find(isProcessDefinition);
      const definitionTaskNode = processDefinition?.process.tasks?.find((taskDef) => taskDef.task.id === nodeId);
      if (definitionTaskNode?.task.taskType !== 'User') {
        setActiveEditNode(undefined);
        return;
      }
      setActiveEditNode(definitionTaskNode);
      setHasChange(false);
    },
    [workflowDefinitions?.definitions]
  );

  const checkChangeSelectionAllowed = async () => {
    if (!activeEditNode || !hasChange) {
      return true;
    }

    await getConfirmation({
      title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_TASK_CHANGES_DIALOG.HEADER'),
      okButton: {
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      },
      cancelButton: {
        hidden: true,
      },
    });

    return false;
  };

  const { unselectNodes } = useWorkflowViewerState({
    onNodeSelected: handleNodeSelected,
    checkChangeSelectionAllowed,
  });

  const handleCancelTaskChanges = useCallback(() => {
    setActiveEditNode(undefined);
    unselectNodes();
  }, [unselectNodes]);

  const handleSaveTask = useCallback(
    (task: TaskDefinition) => {
      if (!workflowDefinitions) {
        return;
      }

      const definition = updateTaskInProcessDefinition(workflowDefinitions, task);

      handleCancelTaskChanges();
      setDefinitions(definition);
    },
    [workflowDefinitions, setDefinitions, handleCancelTaskChanges]
  );

  return (
    <EditUserTask
      isOpen={!!activeEditNode}
      task={activeEditNode}
      saveTask={handleSaveTask}
      close={handleCancelTaskChanges}
      onDirty={() => setHasChange(true)}
    />
  );
};
