import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { Link } from 'react-router-dom';
import LoadableAWSPageWrapper, { LoadableAWSPageWrapperProps } from 'pages/Cloud/LoadableAWSPageWrapper';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import Tabs from '@verticeone/design-system/src/components/Tabs';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import Tab from '@verticeone/design-system/src/components/Tab';
import TabsBottomLineWrapper from '@verticeone/design-system/src/components/Tabs/TabsBottomLineWrapper';

type CloudRIOLayoutProps = React.PropsWithChildren & {
  title: string;
  breadcrumbs?: {
    label: string;
    to?: string;
  }[];
  activeTab?: string;
  tabs?: {
    value: string;
    label: string;
    onClick?: () => void;
  }[];
  getCanBeViewed: LoadableAWSPageWrapperProps['getCanBeViewed'];
};

const LayoutInner = ({ title, breadcrumbs, activeTab, tabs, children }: CloudRIOLayoutProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        {breadcrumbs && (
          <Breadcrumbs variant="slash" size="S">
            <BreadcrumbLink key="cloud">{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
            {breadcrumbs.map(({ label, to }) => {
              return to ? (
                <BreadcrumbLink key={label} component={Link} to={to}>
                  {label}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbLink key={label}>{label}</BreadcrumbLink>
              );
            })}
          </Breadcrumbs>
        )}
        <Text variant="heading" size="M" color={palette.text.color1} tag="h1">
          {title}
        </Text>
      </Stack>
      {tabs && (
        <TabsBottomLineWrapper paddingX={10}>
          <Tabs variant="outlined" value={activeTab} color={AWS_BRAND_COLOR}>
            {tabs.map(({ value, label, onClick }) => (
              <Tab key={value} value={value} label={label} onClick={onClick} />
            ))}
          </Tabs>
        </TabsBottomLineWrapper>
      )}
      <Box position="relative" px={10}>
        {children}
      </Box>
    </Stack>
  );
};

const Layout = (props: CloudRIOLayoutProps) => {
  const { getCanBeViewed } = props;
  return (
    <LoadableAWSPageWrapper getCanBeViewed={getCanBeViewed}>
      <LayoutInner {...props} />
    </LoadableAWSPageWrapper>
  );
};

export default Layout;
