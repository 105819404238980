import { api } from '../baseApi';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Alarm = {
  __typename?: 'Alarm';
  dataSourceName?: Maybe<Scalars['String']>;
  errorLog?: Maybe<ErrorLog>;
  providerName: Scalars['String'];
  status: AlarmStatus;
};

export type AlarmDataLastFetched = {
  __typename?: 'AlarmDataLastFetched';
  fetchedAt: Scalars['String'];
  providerName: Scalars['String'];
};

export type AlarmDay = {
  __typename?: 'AlarmDay';
  alarms?: Maybe<Array<Alarm>>;
  date: Scalars['String'];
};

export enum AlarmStatus {
  Failed = 'FAILED',
  Passed = 'PASSED',
  Undetermined = 'UNDETERMINED',
}

export type Alarms = {
  __typename?: 'Alarms';
  alarmsPerDay?: Maybe<Array<AlarmDay>>;
  builtAt: Scalars['String'];
  lastFetchedAccessLogs?: Maybe<Array<AlarmDataLastFetched>>;
};

export type Application = {
  __typename?: 'Application';
  accountId: Scalars['String'];
  children?: Maybe<Array<Maybe<Application>>>;
  childrenRecordIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataHealth?: Maybe<DataHealth>;
  dataHealthConfigs?: Maybe<Array<Maybe<DataHealthCheckConfig>>>;
  id: Scalars['String'];
  license?: Maybe<License>;
  master?: Maybe<Application>;
  masterRecordId?: Maybe<Scalars['String']>;
  matches?: Maybe<Array<Maybe<Match>>>;
  name: Scalars['String'];
  providerName?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  status: ApplicationStatus;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export type ApplicationMeasureReports = {
  __typename?: 'ApplicationMeasureReports';
  activityFrequencyLast56d?: Maybe<Array<Maybe<Measure>>>;
  generatedAt?: Maybe<Scalars['String']>;
  lastActivity56d?: Maybe<Scalars['String']>;
  status?: Maybe<MeasureReportsStatus>;
  totalUsers?: Maybe<Scalars['Int']>;
  uniqueUserAccessCountLast12mBin1mAuthEvent?: Maybe<Array<Maybe<Measure>>>;
  uniqueUserAccessCountLast12mBin1mLoginEvent?: Maybe<Array<Maybe<Measure>>>;
  uniqueUserAccessCountLast28d?: Maybe<Array<Maybe<Measure>>>;
  uniqueUserAccessCountLast56dBin7dAuthEvent?: Maybe<Array<Maybe<Measure>>>;
  uniqueUserAccessCountLast56dBin7dLoginEvent?: Maybe<Array<Maybe<Measure>>>;
  uniqueUserAccessCountPrev28d?: Maybe<Array<Maybe<Measure>>>;
};

export enum ApplicationStatus {
  Discovered = 'DISCOVERED',
  Ignored = 'IGNORED',
  MergedWithMaster = 'MERGED_WITH_MASTER',
  Undiscovered = 'UNDISCOVERED',
}

export type ApplicationWithMeasure = {
  __typename?: 'ApplicationWithMeasure';
  accountId: Scalars['String'];
  children?: Maybe<Array<Maybe<Application>>>;
  childrenRecordIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataCollectionStart?: Maybe<Scalars['String']>;
  dataHealth?: Maybe<DataHealth>;
  dataHealthConfigs?: Maybe<Array<Maybe<DataHealthCheckConfig>>>;
  id: Scalars['String'];
  license?: Maybe<License>;
  master?: Maybe<Application>;
  masterRecordId?: Maybe<Scalars['String']>;
  matches?: Maybe<Array<Maybe<Match>>>;
  measureReports?: Maybe<ApplicationMeasureReports>;
  name: Scalars['String'];
  providerName?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  status: ApplicationStatus;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export type ApplicationsPaginated = {
  __typename?: 'ApplicationsPaginated';
  items?: Maybe<Array<Application>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ApplicationsWithMeasurePaginated = {
  __typename?: 'ApplicationsWithMeasurePaginated';
  items?: Maybe<Array<ApplicationWithMeasure>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DataHealth = {
  __typename?: 'DataHealth';
  checks?: Maybe<Array<DataHealthCheck>>;
  status: DataHealthStatus;
};

export type DataHealthCheck = {
  __typename?: 'DataHealthCheck';
  code: Scalars['String'];
  priority: Scalars['Int'];
  severity: DataHealthCheckSeverity;
  status: DataHealthCheckStatus;
};

export type DataHealthCheckConfig = {
  __typename?: 'DataHealthCheckConfig';
  code: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
};

export enum DataHealthCheckSeverity {
  Info = 'INFO',
  Warning = 'WARNING',
}

export enum DataHealthCheckStatus {
  Failed = 'FAILED',
  Ignored = 'IGNORED',
  Passed = 'PASSED',
}

export enum DataHealthStatus {
  IssuesDetected = 'ISSUES_DETECTED',
  Passed = 'PASSED',
}

export type DataSource = {
  __typename?: 'DataSource';
  class: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  provider?: Maybe<IntegrationProvider>;
  status?: Maybe<DataSourceStatus>;
  version: Scalars['String'];
};

export enum DataSourceGroup {
  All = 'ALL',
  Usage = 'USAGE',
}

export enum DataSourceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type DataSourcesPaginated = {
  __typename?: 'DataSourcesPaginated';
  items?: Maybe<Array<DataSource>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DiscoverySource = {
  __typename?: 'DiscoverySource';
  accountId: Scalars['String'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kind: DiscoverySourceKind;
  mdm: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum DiscoverySourceKind {
  DataRecord = 'DATA_RECORD',
  Discovery = 'DISCOVERY',
  IntegrationProvider = 'INTEGRATION_PROVIDER',
  Unknown = 'UNKNOWN',
}

export type ErrorLog = {
  __typename?: 'ErrorLog';
  accountId: Scalars['String'];
  category: ErrorLogCategory;
  createdAt: Scalars['String'];
  dataSource?: Maybe<Scalars['String']>;
  errorCode: Scalars['String'];
  errorMessage: Scalars['String'];
  httpStatusCode?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  origin: Scalars['String'];
  originalError: Scalars['String'];
  taskId?: Maybe<Scalars['String']>;
};

export enum ErrorLogCategory {
  AuthenticationIssues = 'AUTHENTICATION_ISSUES',
  InvalidToken = 'INVALID_TOKEN',
  Other = 'OTHER',
  ThrottlingIssues = 'THROTTLING_ISSUES',
}

export type ErrorLogsPaginated = {
  __typename?: 'ErrorLogsPaginated';
  items?: Maybe<Array<ErrorLog>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type IntakeJob = {
  __typename?: 'IntakeJob';
  executionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: IntakeJobStatus;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum IntakeJobStatus {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
}

export type IntakeJobsPaginated = {
  __typename?: 'IntakeJobsPaginated';
  items?: Maybe<Array<IntakeJob>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type IntakeTask = {
  __typename?: 'IntakeTask';
  createdAt: Scalars['String'];
  dataSource: Scalars['String'];
  errorData?: Maybe<IntakeTaskErrorData>;
  id: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
  range?: Maybe<TimeSpan>;
  status: IntakeTaskStatus;
  type: Scalars['String'];
};

export type IntakeTaskErrorData = {
  __typename?: 'IntakeTaskErrorData';
  errorDetails?: Maybe<Scalars['String']>;
  remainingAttempts?: Maybe<Scalars['Int']>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export enum IntakeTaskStatus {
  Failed = 'FAILED',
  Fulfilled = 'FULFILLED',
  Open = 'OPEN',
  Processing = 'PROCESSING',
}

export type IntakeTasksPaginated = {
  __typename?: 'IntakeTasksPaginated';
  items?: Maybe<Array<IntakeTask>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type IntegrationProvider = {
  __typename?: 'IntegrationProvider';
  id: Scalars['String'];
  status: IntegrationProviderStatus;
};

export enum IntegrationProviderStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type IntegrationProvidersPaginated = {
  __typename?: 'IntegrationProvidersPaginated';
  items?: Maybe<Array<IntegrationProvider>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type License = {
  __typename?: 'License';
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type Match = {
  __typename?: 'Match';
  entityId: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  entityType: Scalars['String'];
};

export type Measure = {
  __typename?: 'Measure';
  binSize: Scalars['String'];
  binStart: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
  valueType: Scalars['String'];
};

export enum MeasureReportsStatus {
  Available = 'AVAILABLE',
  Generating = 'GENERATING',
  NotAvailable = 'NOT_AVAILABLE',
}

export type Mutation = {
  __typename?: 'Mutation';
  activateIntegrationProvider?: Maybe<IntegrationProvider>;
  deactivateIntegrationProvider?: Maybe<IntegrationProvider>;
};

export type MutationActivateIntegrationProviderArgs = {
  accountId: Scalars['String'];
  providerId: Scalars['String'];
};

export type MutationDeactivateIntegrationProviderArgs = {
  accountId: Scalars['String'];
  providerId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAlarms?: Maybe<Alarms>;
  getApplication?: Maybe<Application>;
  getApplicationWithMeasure?: Maybe<ApplicationWithMeasure>;
  getDataSource?: Maybe<DataSource>;
  getIntegrationProvider?: Maybe<IntegrationProvider>;
  listApplications?: Maybe<ApplicationsPaginated>;
  listApplicationsWithMeasure?: Maybe<ApplicationsWithMeasurePaginated>;
  listDataSources?: Maybe<DataSourcesPaginated>;
  listErrorLogs?: Maybe<ErrorLogsPaginated>;
  listIntakeJobs?: Maybe<IntakeJobsPaginated>;
  listIntakeTasks?: Maybe<IntakeTasksPaginated>;
  listIntegrationProviders?: Maybe<IntegrationProvidersPaginated>;
  listUsersWithMeasure?: Maybe<UsersWithMeasurePaginated>;
};

export type QueryGetAlarmsArgs = {
  accountId: Scalars['String'];
  dataSourceGroup?: InputMaybe<DataSourceGroup>;
};

export type QueryGetApplicationArgs = {
  accountId: Scalars['String'];
  applicationId: Scalars['String'];
};

export type QueryGetApplicationWithMeasureArgs = {
  accountId: Scalars['String'];
  applicationId: Scalars['String'];
};

export type QueryGetDataSourceArgs = {
  accountId: Scalars['String'];
  dataSourceCode: Scalars['String'];
};

export type QueryGetIntegrationProviderArgs = {
  accountId: Scalars['String'];
  providerId: Scalars['String'];
};

export type QueryListApplicationsArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<ApplicationStatus>>>;
};

export type QueryListApplicationsWithMeasureArgs = {
  accountId: Scalars['String'];
  applicationIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<ApplicationStatus>>>;
};

export type QueryListDataSourcesArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListErrorLogsArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListIntakeJobsArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListIntakeTasksArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListIntegrationProvidersArgs = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListUsersWithMeasureArgs = {
  accountId: Scalars['String'];
  applicationId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Suggestion = {
  __typename?: 'Suggestion';
  entityId: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  entityType: Scalars['String'];
};

export type TimeSpan = {
  __typename?: 'TimeSpan';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type UserMeasureReports = {
  __typename?: 'UserMeasureReports';
  activityFrequencyLast56d?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['String']>;
  lastActivity56d?: Maybe<Scalars['String']>;
  status?: Maybe<MeasureReportsStatus>;
};

export enum UserStatus {
  Discovered = 'DISCOVERED',
  Ignored = 'IGNORED',
  MergedWithMaster = 'MERGED_WITH_MASTER',
  Undiscovered = 'UNDISCOVERED',
}

export type UserWithMeasure = {
  __typename?: 'UserWithMeasure';
  accountId: Scalars['String'];
  childrenRecordIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['String'];
  identityKey?: Maybe<Scalars['String']>;
  masterRecordId?: Maybe<Scalars['String']>;
  matches?: Maybe<Array<Maybe<Match>>>;
  measureReports?: Maybe<UserMeasureReports>;
  name: Scalars['String'];
  sources?: Maybe<Array<Maybe<DiscoverySource>>>;
  status: UserStatus;
  suggestions?: Maybe<Array<Maybe<Array<Maybe<Suggestion>>>>>;
};

export type UsersWithMeasurePaginated = {
  __typename?: 'UsersWithMeasurePaginated';
  items?: Maybe<Array<UserWithMeasure>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ListErrorLogsQueryVariables = Exact<{
  accountId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ListErrorLogsQuery = {
  __typename?: 'Query';
  listErrorLogs?: {
    __typename?: 'ErrorLogsPaginated';
    nextToken?: string;
    items?: Array<{
      __typename?: 'ErrorLog';
      id: string;
      createdAt: string;
      origin: string;
      originalError: string;
      category: ErrorLogCategory;
      errorCode: string;
      errorMessage: string;
    }>;
  };
};

export type LastFetchedQueryVariables = Exact<{
  accountId: Scalars['String'];
  dataSourceGroup?: InputMaybe<DataSourceGroup>;
}>;

export type LastFetchedQuery = {
  __typename?: 'Query';
  getAlarms?: {
    __typename?: 'Alarms';
    lastFetchedAccessLogs?: Array<{ __typename?: 'AlarmDataLastFetched'; fetchedAt: string; providerName: string }>;
    alarmsPerDay?: Array<{
      __typename?: 'AlarmDay';
      date: string;
      alarms?: Array<{
        __typename?: 'Alarm';
        dataSourceName?: string;
        status: AlarmStatus;
        providerName: string;
        errorLog?: {
          __typename?: 'ErrorLog';
          taskId?: string;
          originalError: string;
          origin: string;
          id: string;
          httpStatusCode?: number;
          errorMessage: string;
          errorCode: string;
          dataSource?: string;
          createdAt: string;
          category: ErrorLogCategory;
          accountId: string;
        };
      }>;
    }>;
  };
};

export type ListApplicationsWithMeasureReportsQueryVariables = Exact<{
  accountId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<ApplicationStatus>> | InputMaybe<ApplicationStatus>>;
}>;

export type ListApplicationsWithMeasureReportsQuery = {
  __typename?: 'Query';
  listApplicationsWithMeasure?: {
    __typename?: 'ApplicationsWithMeasurePaginated';
    nextToken?: string;
    items?: Array<{
      __typename?: 'ApplicationWithMeasure';
      id: string;
      name: string;
      status: ApplicationStatus;
      children?: Array<{ __typename?: 'Application'; id: string }>;
      matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
      license?: { __typename?: 'License'; quantity?: number; type?: string };
      dataHealth?: {
        __typename?: 'DataHealth';
        status: DataHealthStatus;
        checks?: Array<{
          __typename?: 'DataHealthCheck';
          code: string;
          status: DataHealthCheckStatus;
          severity: DataHealthCheckSeverity;
          priority: number;
        }>;
      };
      master?: { __typename?: 'Application'; id: string; name: string };
      sources?: Array<{ __typename?: 'DiscoverySource'; id?: string }>;
      measureReports?: {
        __typename?: 'ApplicationMeasureReports';
        generatedAt?: string;
        lastActivity56d?: string;
        totalUsers?: number;
        uniqueUserAccessCountLast28d?: Array<{
          __typename?: 'Measure';
          binSize: string;
          binStart: string;
          id: string;
          value: string;
        }>;
        activityFrequencyLast56d?: Array<{
          __typename?: 'Measure';
          binSize: string;
          binStart: string;
          id: string;
          value: string;
        }>;
      };
    }>;
  };
};

export type ListApplicationsForDiscoveryQueryVariables = Exact<{
  accountId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<ApplicationStatus>> | InputMaybe<ApplicationStatus>>;
}>;

export type ListApplicationsForDiscoveryQuery = {
  __typename?: 'Query';
  listApplicationsWithMeasure?: {
    __typename?: 'ApplicationsWithMeasurePaginated';
    nextToken?: string;
    items?: Array<{
      __typename?: 'ApplicationWithMeasure';
      id: string;
      name: string;
      status: ApplicationStatus;
      children?: Array<{ __typename?: 'Application'; id: string }>;
      matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
      suggestions?: Array<
        Array<{ __typename?: 'Suggestion'; entityId: string; entityName?: string; entityType: string }>
      >;
      dataHealthConfigs?: Array<{ __typename?: 'DataHealthCheckConfig'; code: string; enabled?: boolean }>;
      license?: { __typename?: 'License'; quantity?: number; type?: string };
      master?: { __typename?: 'Application'; id: string; name: string };
      sources?: Array<{ __typename?: 'DiscoverySource'; id?: string }>;
      measureReports?: {
        __typename?: 'ApplicationMeasureReports';
        uniqueUserAccessCountLast28d?: Array<{
          __typename?: 'Measure';
          binSize: string;
          binStart: string;
          id: string;
          value: string;
        }>;
      };
    }>;
  };
};

export type GetApplicationWithMeasureReportsQueryVariables = Exact<{
  accountId: Scalars['String'];
  applicationId: Scalars['String'];
}>;

export type GetApplicationWithMeasureReportsQuery = {
  __typename?: 'Query';
  getApplicationWithMeasure?: {
    __typename?: 'ApplicationWithMeasure';
    id: string;
    name: string;
    status: ApplicationStatus;
    dataCollectionStart?: string;
    children?: Array<{
      __typename?: 'Application';
      id: string;
      sources?: Array<{ __typename?: 'DiscoverySource'; id?: string; entityId?: string }>;
    }>;
    matches?: Array<{ __typename?: 'Match'; entityId: string; entityName?: string; entityType: string }>;
    license?: { __typename?: 'License'; quantity?: number; type?: string };
    dataHealth?: {
      __typename?: 'DataHealth';
      status: DataHealthStatus;
      checks?: Array<{
        __typename?: 'DataHealthCheck';
        code: string;
        status: DataHealthCheckStatus;
        severity: DataHealthCheckSeverity;
        priority: number;
      }>;
    };
    sources?: Array<{ __typename?: 'DiscoverySource'; id?: string; entityId?: string }>;
    measureReports?: {
      __typename?: 'ApplicationMeasureReports';
      generatedAt?: string;
      lastActivity56d?: string;
      uniqueUserAccessCountLast28d?: Array<{
        __typename?: 'Measure';
        binSize: string;
        binStart: string;
        id: string;
        value: string;
      }>;
      uniqueUserAccessCountPrev28d?: Array<{
        __typename?: 'Measure';
        binSize: string;
        binStart: string;
        id: string;
        value: string;
      }>;
      activityFrequencyLast56d?: Array<{
        __typename?: 'Measure';
        binSize: string;
        binStart: string;
        id: string;
        value: string;
      }>;
      uniqueUserAccessCountLast56dBin7dLoginEvent?: Array<{
        __typename?: 'Measure';
        binSize: string;
        binStart: string;
        id: string;
        value: string;
      }>;
      uniqueUserAccessCountLast56dBin7dAuthEvent?: Array<{
        __typename?: 'Measure';
        binSize: string;
        binStart: string;
        id: string;
        value: string;
      }>;
      uniqueUserAccessCountLast12mBin1mLoginEvent?: Array<{
        __typename?: 'Measure';
        binSize: string;
        binStart: string;
        id: string;
        value: string;
      }>;
      uniqueUserAccessCountLast12mBin1mAuthEvent?: Array<{
        __typename?: 'Measure';
        binSize: string;
        binStart: string;
        id: string;
        value: string;
      }>;
    };
  };
};

export type ListApplicationUsersQueryVariables = Exact<{
  accountId: Scalars['String'];
  applicationId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ListApplicationUsersQuery = {
  __typename?: 'Query';
  listUsersWithMeasure?: {
    __typename?: 'UsersWithMeasurePaginated';
    nextToken?: string;
    items?: Array<{
      __typename?: 'UserWithMeasure';
      id: string;
      name: string;
      accountId: string;
      status: UserStatus;
      identityKey?: string;
      measureReports?: {
        __typename?: 'UserMeasureReports';
        generatedAt?: string;
        status?: MeasureReportsStatus;
        activityFrequencyLast56d?: string;
        lastActivity56d?: string;
      };
    }>;
  };
};

export const ListErrorLogsDocument = `
    query ListErrorLogs($accountId: String!, $nextToken: String, $limit: Int) {
  listErrorLogs(accountId: $accountId, nextToken: $nextToken, limit: $limit) {
    items {
      id
      createdAt
      origin
      originalError
      category
      errorCode
      errorMessage
    }
    nextToken
  }
}
    `;
export const LastFetchedDocument = `
    query LastFetched($accountId: String!, $dataSourceGroup: DataSourceGroup) {
  getAlarms(accountId: $accountId, dataSourceGroup: $dataSourceGroup) {
    lastFetchedAccessLogs {
      fetchedAt
      providerName
    }
    alarmsPerDay {
      alarms {
        errorLog {
          taskId
          originalError
          origin
          id
          httpStatusCode
          errorMessage
          errorCode
          dataSource
          createdAt
          category
          accountId
        }
        dataSourceName
        status
        providerName
      }
      date
    }
  }
}
    `;
export const ListApplicationsWithMeasureReportsDocument = `
    query listApplicationsWithMeasureReports($accountId: String!, $nextToken: String, $limit: Int, $statuses: [ApplicationStatus]) {
  listApplicationsWithMeasure(
    accountId: $accountId
    limit: $limit
    nextToken: $nextToken
    statuses: $statuses
  ) {
    nextToken
    items {
      id
      name
      status
      children {
        id
      }
      matches {
        entityId
        entityName
        entityType
      }
      license {
        quantity
        type
      }
      dataHealth {
        status
        checks {
          code
          status
          severity
          priority
        }
      }
      master {
        id
        name
      }
      sources {
        id
      }
      measureReports {
        generatedAt
        lastActivity56d
        totalUsers
        uniqueUserAccessCountLast28d {
          binSize
          binStart
          id
          value
        }
        activityFrequencyLast56d {
          binSize
          binStart
          id
          value
        }
      }
    }
  }
}
    `;
export const ListApplicationsForDiscoveryDocument = `
    query listApplicationsForDiscovery($accountId: String!, $nextToken: String, $limit: Int, $statuses: [ApplicationStatus]) {
  listApplicationsWithMeasure(
    accountId: $accountId
    limit: $limit
    nextToken: $nextToken
    statuses: $statuses
  ) {
    nextToken
    items {
      id
      name
      status
      children {
        id
      }
      matches {
        entityId
        entityName
        entityType
      }
      suggestions {
        entityId
        entityName
        entityType
      }
      dataHealthConfigs {
        code
        enabled
      }
      license {
        quantity
        type
      }
      master {
        id
        name
      }
      sources {
        id
      }
      measureReports {
        uniqueUserAccessCountLast28d {
          binSize
          binStart
          id
          value
        }
      }
    }
  }
}
    `;
export const GetApplicationWithMeasureReportsDocument = `
    query getApplicationWithMeasureReports($accountId: String!, $applicationId: String!) {
  getApplicationWithMeasure(accountId: $accountId, applicationId: $applicationId) {
    id
    name
    status
    children {
      id
      sources {
        id
        entityId
      }
    }
    matches {
      entityId
      entityName
      entityType
    }
    license {
      quantity
      type
    }
    dataHealth {
      status
      checks {
        code
        status
        severity
        priority
      }
    }
    sources {
      id
      entityId
    }
    dataCollectionStart
    measureReports {
      generatedAt
      lastActivity56d
      uniqueUserAccessCountLast28d {
        binSize
        binStart
        id
        value
      }
      uniqueUserAccessCountPrev28d {
        binSize
        binStart
        id
        value
      }
      activityFrequencyLast56d {
        binSize
        binStart
        id
        value
      }
      uniqueUserAccessCountLast56dBin7dLoginEvent {
        binSize
        binStart
        id
        value
      }
      uniqueUserAccessCountLast56dBin7dAuthEvent {
        binSize
        binStart
        id
        value
      }
      uniqueUserAccessCountLast12mBin1mLoginEvent {
        binSize
        binStart
        id
        value
      }
      uniqueUserAccessCountLast12mBin1mAuthEvent {
        binSize
        binStart
        id
        value
      }
    }
  }
}
    `;
export const ListApplicationUsersDocument = `
    query listApplicationUsers($accountId: String!, $applicationId: String!, $nextToken: String, $limit: Int) {
  listUsersWithMeasure(
    accountId: $accountId
    applicationId: $applicationId
    nextToken: $nextToken
    limit: $limit
  ) {
    items {
      id
      name
      accountId
      status
      identityKey
      measureReports {
        generatedAt
        status
        activityFrequencyLast56d
        lastActivity56d
      }
    }
    nextToken
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ListErrorLogs: build.query<ListErrorLogsQuery, ListErrorLogsQueryVariables>({
      query: (variables) => ({ document: ListErrorLogsDocument, variables }),
    }),
    LastFetched: build.query<LastFetchedQuery, LastFetchedQueryVariables>({
      query: (variables) => ({ document: LastFetchedDocument, variables }),
    }),
    listApplicationsWithMeasureReports: build.query<
      ListApplicationsWithMeasureReportsQuery,
      ListApplicationsWithMeasureReportsQueryVariables
    >({
      query: (variables) => ({ document: ListApplicationsWithMeasureReportsDocument, variables }),
    }),
    listApplicationsForDiscovery: build.query<
      ListApplicationsForDiscoveryQuery,
      ListApplicationsForDiscoveryQueryVariables
    >({
      query: (variables) => ({ document: ListApplicationsForDiscoveryDocument, variables }),
    }),
    getApplicationWithMeasureReports: build.query<
      GetApplicationWithMeasureReportsQuery,
      GetApplicationWithMeasureReportsQueryVariables
    >({
      query: (variables) => ({ document: GetApplicationWithMeasureReportsDocument, variables }),
    }),
    listApplicationUsers: build.query<ListApplicationUsersQuery, ListApplicationUsersQueryVariables>({
      query: (variables) => ({ document: ListApplicationUsersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useListErrorLogsQuery,
  useLazyListErrorLogsQuery,
  useLastFetchedQuery,
  useLazyLastFetchedQuery,
  useListApplicationsWithMeasureReportsQuery,
  useLazyListApplicationsWithMeasureReportsQuery,
  useListApplicationsForDiscoveryQuery,
  useLazyListApplicationsForDiscoveryQuery,
  useGetApplicationWithMeasureReportsQuery,
  useLazyGetApplicationWithMeasureReportsQuery,
  useListApplicationUsersQuery,
  useLazyListApplicationUsersQuery,
} = injectedRtkApi;
