import { Stack, styled } from '@mui/material';

/**
 * We need to use CSS styles to render vertical divider
 * Stack doesn't detect null children correctly when using divider props
 */
export const StackWithDivider = styled(Stack)(({ theme }) => ({
  '> *': {
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.core.color2}`,
  },
  '> *:last-child': {
    paddingBottom: 0,
    borderBottom: 'none',
  },
}));
