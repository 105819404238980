import { Definitions, Task, TaskDefinition } from '../../../../definitionsTypes';
import { isProcessDefinition } from '@vertice/core/src/modules/intelligentWorkflows/pocWorkflowSchema';
import { useTranslation } from 'react-i18next';
import { getFullName } from '../../../../../../utils/formatting/user';
import { parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';
import { isAssignmentConfiguration } from '../../../../definitions/taskDefinition';
import { EXPRESSION_ALIASES } from '../../../../task/ExpressionAliases';
import { isEqual } from 'lodash';

export const findTaskDefinition = (
  definitions: Definitions | undefined,
  taskId: string
): TaskDefinition | undefined => {
  if (!definitions) {
    return undefined;
  }

  const processDefinition = definitions.definitions.find(isProcessDefinition);

  if (!processDefinition) {
    return undefined;
  }

  return processDefinition.process.tasks?.find((task) => task.task.id.includes(taskId));
};

export const useTaskDefinitionAssigneeNames = () => {
  const { t } = useTranslation();
  const { usersById } = useWorkflowRendererContext();

  const getTaskDefinitionAssigneeNames = (task?: Task): string[] | null => {
    if (!task) {
      return null;
    }

    const assignmentConfiguration = task.configurations?.find(isAssignmentConfiguration);

    if (!assignmentConfiguration) {
      return null;
    }

    if (assignmentConfiguration.type === 'REQUEST_OWNER') {
      return [t('ENTITIES.CONTRACT.LABELS.REQUEST_OWNER')];
    }

    if (assignmentConfiguration.type === 'EXPRESSION') {
      const expressionLabel = Object.values(EXPRESSION_ALIASES).find(
        ({ assignment, expressionType }) =>
          isEqual(assignmentConfiguration.assignment, assignment) &&
          assignmentConfiguration.expression_type === expressionType
      )?.label;

      return expressionLabel ? [expressionLabel] : null;
    }

    if (!assignmentConfiguration.assignment) {
      return null;
    }

    return assignmentConfiguration.assignment.map(
      (assignee: string) => getFullName(usersById[parseUserRef(assignee).userId]) || assignee
    );
  };

  return { getTaskDefinitionAssigneeNames };
};
