import { PredefinedWidgetDef } from '../shared/types';
import { DetailsSection } from './DetailsSection';

export const DETAILS_WIDGET_URN_PATTERN = /^widget\/request\/overview\/v\d+$/;
export const widgetDef: PredefinedWidgetDef = {
  urnSuffix: DETAILS_WIDGET_URN_PATTERN,
  component: DetailsSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.REQUEST_DETAILS',
  renderWhenMissingData: true,
};
