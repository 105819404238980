import { useEffect } from 'react';

export const useConfirmPageExit = (enabled: boolean) => {
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      const e = event || window.event;

      if (enabled) {
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      }
    };
    if (!window) return;

    addEventListener('beforeunload', handler);

    return () => {
      if (!window) return;

      removeEventListener('beforeunload', handler);
    };
  }, [enabled]);
};
