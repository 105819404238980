import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import Highcharts, { OptionsStackingValue } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { cssObjectToString } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import getTextVariantStyle from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import { AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../../../modules/cloud/constants';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { getTickPositions } from './utils';
import { SeriesOptionsWithStates } from '@vertice/core/src/components/charts/highcharts-specific/types';

type DataColumns =
  | 'aws_infra_spend'
  | 'aws_marketplace_spend'
  | 'eligible_marketplace_spend'
  | 'non_eligible_marketplace_spend';

type BaseBreakdownChartProps = {
  data: Record<DataColumns, number>;
  thresholdLabel: string;
  thresholdValue: number;
};

type ThresholdEl = {
  label: Highcharts.SVGElement;
  stroke: Highcharts.SVGElement;
};

const useTransformDataToSeries = (data: Record<DataColumns, number>) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_SPEND_BREAKDOWN.GRAPH' });

  return useMemo(
    () =>
      [
        {
          id: 'aws_infra_spend',
          type: 'bar' as const,
          data: [data.aws_infra_spend],
          color: palette.visualization.divergent.primary['-30'],
          name: t('LABEL.INFRASTRUCTURE'),
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.divergent.primary['-50'],
            },
          },
        },
        {
          id: 'eligible_marketplace_spend',
          type: 'bar' as const,
          data: [data.eligible_marketplace_spend],
          color: palette.visualization.divergent.tertiary['-30'],
          states: {
            hover: {
              color: palette.visualization.divergent.tertiary['-50'],
            },
          },
          name: t('LABEL.ELIGIBLE_MARKET'),
        },
        {
          id: 'non_eligible_marketplace_spend',
          type: 'bar' as const,
          data: [data.non_eligible_marketplace_spend],
          color: palette.visualization.divergent.tertiary['-10'],
          states: {
            hover: {
              color: palette.visualization.divergent.tertiary['-30'],
            },
          },
          name: t('LABEL.NON_ELIGIBLE_MARKET'),
        },
      ].filter((column) => column.data.some((item) => item > 0)),
    [t, data, palette]
  );
};

const BreakdownChart = ({ data, thresholdLabel, thresholdValue }: BaseBreakdownChartProps) => {
  const { locale } = useLocaleContext();
  const thresholdEl = useRef<ThresholdEl | null>(null);
  const [chart, setChartRef] = useChartRef();
  const chartSeries = useTransformDataToSeries(data);
  const { palette } = useTheme();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyXAxisOffset = useXAxisOffset();
  const formatCurrency = useFormatCurrency();
  const tooltipFormatter = useMemo(
    () => currencyFormatter(locale, AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES),
    [locale]
  );

  const tickPositions = useMemo(
    () => getTickPositions(data.aws_infra_spend + data.aws_marketplace_spend, thresholdValue),
    [data.aws_infra_spend, data.aws_marketplace_spend, thresholdValue]
  );

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: {
            type: 'bar',
            height: 104,
            spacing: [0, 0, 0, 0],
            marginTop: 30,
            marginLeft: 35,
            marginRight: 50,
            events: {
              load: function () {
                thresholdEl.current = {
                  label: this.renderer
                    .label(thresholdLabel, 0, 0)
                    .css({
                      ...(getTextVariantStyle({ variant: 'caption', size: 'XS' }) as Highcharts.CSSObject),
                      color: palette.text.color2,
                    })
                    .add(),
                  stroke: this.renderer
                    .path([
                      ['M', 0, 25],
                      ['L', 0, this.chartHeight],
                    ])
                    .attr({
                      'stroke-width': 1,
                      stroke: palette.core.color5,
                      dashstyle: 'Solid',
                    })
                    .add(),
                };
              },
              render: function () {
                if (thresholdEl.current !== null && this.yAxis[0].ticks[thresholdValue]?.gridLine) {
                  const offset = 10;
                  const newX = this.yAxis[0].ticks[thresholdValue].gridLine?.getBBox().x || 0;
                  const labelWidth = thresholdEl.current.label.element.getBoundingClientRect().width;
                  const labelX = Math.max(
                    offset,
                    Math.min(newX + labelWidth / 2, this.chartWidth) - labelWidth - offset
                  );

                  thresholdEl.current.label.attr({ x: labelX });
                  thresholdEl.current.stroke.attr({
                    d: [
                      ['M', newX - 0.5, 25],
                      ['L', newX - 0.5, this.chartHeight],
                    ],
                  });
                }
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
            series: {
              stacking: 'normal' as OptionsStackingValue,
              dataLabels: {
                enabled: false,
              },
              borderWidth: 2,
              borderColor: palette.core.bg,
            },
          },
          xAxis: {
            labels: {
              enabled: false,
            },
          },
          yAxis: {
            startOnTick: false,
            tickPositions: tickPositions,
            plotLines: [],
            labels: {
              x: 0,
              y: 10,
              align: 'center',
              formatter: ({ value }: { value: number | string }) => {
                const formattedValue = formatCurrency(value as number, { maximumFractionDigits: -1, compact: true });
                const wrapperStyle = {
                  display: 'flex',
                  justifyContent: 'center',
                };

                const labelStyle = {
                  borderRadius: '8px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: palette.core.color3,
                  backgroundColor: palette.core.bg,
                  padding: '1px 7px',
                };

                return `<div style="${cssObjectToString(wrapperStyle)}"><span style="${cssObjectToString(
                  labelStyle
                )}">${formattedValue}</span></div>`;
              },
            },
          },
          series: chartSeries.toReversed(),
          tooltip: {
            enabled: true,
            animation: false,
            positioner: function (w, h, point) {
              const getNewWidth = (width: number) => {
                const pointCenter = this.chart.plotLeft + point.plotX - (point as any).h / 2;
                const xCenter = pointCenter - width / 2;

                return Math.max(Math.min(xCenter, this.chart.chartWidth - width), 0);
              };

              setTimeout(() => {
                const label = (this as any).label;
                label.div.style.left = `${getNewWidth(label?.box?.width ?? w)}px`;
              }, 0);

              return {
                x: getNewWidth(w),
                y: this.chart.plotTop - 20,
              };
            },
          },
        }),
      ]),
    [
      applyStyledHighcharts,
      applyXAxisOffset,
      palette.core.bg,
      palette.core.color5,
      palette.core.color3,
      palette.text.color2,
      tickPositions,
      chartSeries,
      thresholdLabel,
      thresholdValue,
      formatCurrency,
    ]
  );

  const legendItems = chartSeries.map((item) => ({
    color: item.states.hover.color,
    label: item.name,
    id: item.name,
  }));

  return (
    <Stack gap={1.5}>
      {legendItems.length > 0 && (
        <Box alignSelf="flex-end" px={6}>
          <Legend items={legendItems} />
        </Box>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ point }) => (
          <TooltipWrapper>
            <TooltipSeriesValuePair
              seriesName={point.series.name}
              seriesColor={(point.series.options as SeriesOptionsWithStates)?.states?.hover?.color}
              value={tooltipFormatter(point.y)}
              key={point.series.name}
            />
          </TooltipWrapper>
        )}
      </HighchartTooltip>
    </Stack>
  );
};

export default BreakdownChart;
