import { DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { createRequiredContext } from '../../utils/contexts';

type DataGridContextType = {
  size: DesignSystemSize;
  headerSize: DesignSystemSize;
  color: DesignSystemColor;
  component: string;
} & TestProps;

export const { DataGridContextProvider, useDataGridContext } = createRequiredContext<DataGridContextType, 'DataGrid'>(
  'DataGrid'
);
