import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { NotificationSettingsItem } from '@vertice/slices';
import Text from '@verticeone/design-system/src/components/Text';
import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { SlackPreviewCreation } from './SlackPreviewCreation';
import { ChannelSetupProps } from './ChannelSetupRenewal';
import Select from '@verticeone/design-system/src/components/Select';
import { SingleValue } from 'react-select';

type ChannelSetupCreationProps = ChannelSetupProps;

export const ChannelSetupCreation = ({
  channelSettings,
  channels,
  onSave,
  savingSection,
  loadingChannels,
}: ChannelSetupCreationProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<NotificationSettingsItem>(channelSettings);

  useEffect(() => {
    setFormData(channelSettings);
  }, [channelSettings]);

  const handleChannelChange = (newValue: SingleValue<NotificationSettingsItem>) => {
    setFormData((current) => ({
      ...current,
      channelId: newValue ? newValue.channelId : '',
    }));
    onSave({
      creation: {
        channelId: newValue ? newValue.channelId : '',
      },
    });
  };

  return (
    <Stack direction="row" gap={6} p={6}>
      <Stack flex={1} gap={8}>
        <Stack gap={2}>
          <TextFieldCaption
            id="creationChannelLabel"
            label={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNEL_RENEWAL.SECTION_CREATION.CHANNEL_LABEL')}
            size="S"
            tooltip={{
              content: t(
                'PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNEL_RENEWAL.CHANNEL_SELECTION_TITLE_TOOLTIP'
              ),
            }}
          />
          <Select<NotificationSettingsItem, false>
            value={channels.find((channel) => channel.channelId === formData?.channelId)}
            options={channels}
            isMulti={false}
            isClearable={true}
            variant="outlined"
            isLoading={savingSection === 'creation' || loadingChannels}
            isDisabled={!!savingSection}
            size="M"
            getOptionLabel={(option) => option.channelName || option.channelId}
            getOptionValue={(option) => option.channelId}
            onChange={handleChannelChange}
            aria-labelledby="creationChannelLabel"
            menuPosition="fixed"
            formatOptionLabel={(option) => {
              return (
                <Stack gap={1} direction="row" alignItems="center">
                  <IconWrapper icon={option.type === 'PUBLIC' ? TagOutlinedIcon : LockOutlinedIcon} size="M" />
                  {option.channelName}
                </Stack>
              );
            }}
          />
          {['ERROR', 'CHANNEL_NOT_FOUND'].includes(formData?.saveStatus ?? '') ? (
            <Text variant="body-regular" size="XS" color="error">
              {t(`PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.ERROR_${formData?.saveStatus}`)}
            </Text>
          ) : (
            <Text variant="body-regular" size="XS" color="text2">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNEL_RENEWAL.CHANNEL_SELECTION_HELPER_TEXT')}
            </Text>
          )}
        </Stack>
      </Stack>
      <Stack flex={1} gap={2}>
        <Text variant="caption" size="S" color="text2">
          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_PREVIEW_TITLE')}
        </Text>
        <SlackPreviewCreation />
      </Stack>
    </Stack>
  );
};
