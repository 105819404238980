import React from 'react';
import { NavLink } from 'react-router-dom';
import { Stack, styled, StackProps } from '@mui/material';
import type { StyledComponent } from 'styled-components';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';
import { Logo as VerticeIcon, VerticeVIcon as VerticeSmallIcon } from '@vertice/assets';

// logos are not positioned equally, this padding should fix it
const StyledVerticeIcon = styled(VerticeIcon)(({ theme }) => ({
  paddingTop: '1px',
  // this is working right now but in future we need to improve it with better svg
  'path[fill="#210058"]': {
    fill: theme.palette.text.color1,
  },
}));

const StyledVerticeSmallIcon = styled(VerticeSmallIcon)({
  paddingLeft: '1px',
});

const BaseLogo = (props: Partial<StackProps>) => {
  const { isOpen } = useMainLayout();

  return (
    <Stack {...props}>
      <NavLink to="/">
        {isOpen ? <StyledVerticeIcon height="32" width="106" /> : <StyledVerticeSmallIcon height="33" width="21" />}
      </NavLink>
    </Stack>
  );
};

const Logo = styled(BaseLogo)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '80px',
  marginTop: '16px',
  padding: '32px 24px',
  a: {
    height: '48px',
    padding: '8px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.neutral.color4,
    },
  },
})) as StyledComponent<typeof BaseLogo, Partial<StackProps>>;

export default Logo;
