import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  useConnectAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useInitiateOauth2AccountIntegrationMutation,
  useTestAccountIntegrationMutation,
  useUpdateSlackIntegrationConfigMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  DataSourceStatus,
  useUpdateDataSourceMutation,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { useActivateIntegrationAndProvider } from 'pages/Preferences/Integrations/util';
import {
  isValidIntegrationResponse,
  SLACK_DATA_SOURCE_CODES,
  SLACK_PROVIDER_ID,
  Step,
  VERTICE_PROVIDER_ID,
} from '../../common';

export const useActivateSlackIntegration = (setStep: (step: Step) => void) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isConnectionInitiated, setIsConnectionInitiated] = useState(false);
  const [connectionWindow, setConnectionWindow] = useState<Window | null>(null);
  const [pollingInterval, setPollingInterval] = useState(0);
  const [isConnectionExecuted, setIsConnectionExecuted] = useState(false);

  const { data: integrationData, isFetching: integrationDataLoading } = useGetAccountIntegrationQuery(
    {
      accountId,
      integrationId: SLACK_PROVIDER_ID,
    },
    { pollingInterval: pollingInterval > 0 ? pollingInterval : undefined }
  );
  const { activate: activateSlackProvider, isLoading: isLoadingActivateSlackProvider } =
    useActivateIntegrationAndProvider(SLACK_PROVIDER_ID, SLACK_PROVIDER_ID, accountId);
  const { activate: activateVerticeProvider, isLoading: isLoadingActivateVerticeProvider } =
    useActivateIntegrationAndProvider(VERTICE_PROVIDER_ID, VERTICE_PROVIDER_ID, accountId);

  const [updateDataSource, { isLoading: isLoadingUpdateDataSource }] = useUpdateDataSourceMutation();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [initiateOauth2AccountIntegration] = useInitiateOauth2AccountIntegrationMutation();
  const [toggleAutoConnection, { isLoading: isLoadingToggleAutoConnection }] =
    useUpdateSlackIntegrationConfigMutation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();

  const showErrorMessage = useCallback(() => {
    enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CONNECTION_FAILED_TITLE'), {
      description: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CONNECTION_FAILED_DESCRIPTION'),
      variant: 'error',
    });
  }, [enqueueSnackbar, t]);

  const activateSlackIntegration = async () => {
    const dataSourceCodeUpdates = SLACK_DATA_SOURCE_CODES.map((code) =>
      updateDataSource({
        accountId,
        dataSourceCode: code,
        input: {
          status: DataSourceStatus.Active,
        },
      })
    );

    await Promise.all(dataSourceCodeUpdates).then(() => connectIntegration());
  };

  const connectIntegration = async () => {
    await connectAccountIntegrationMutation({
      accountId,
      integrationId: SLACK_PROVIDER_ID,
      integrationConnectionInput: {
        parameters: {
          secret: {},
        },
      },
    });

    const response = await initiateOauth2AccountIntegration({
      accountId,
      integrationId: SLACK_PROVIDER_ID,
    });

    setIsConnectionInitiated(true);

    if (isValidIntegrationResponse(response)) {
      const windowReference = window.open(undefined, '_blank');

      if (windowReference) {
        windowReference.location = response.data.authorizationUrl ? response.data.authorizationUrl : '';
        setConnectionWindow(windowReference);
      } else {
        enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.OPENING_WINDOW_NOT_ALLOWED'), {
          variant: 'error',
        });
      }
    } else {
      showErrorMessage();
    }
  };

  const closeConnectionWindow = (window: Window | null) => {
    if (window) {
      window.close();
    }
  };

  useEffect(() => {
    const finishActivation = async () => {
      await activateVerticeProvider();
      await activateSlackProvider();
      await toggleAutoConnection({
        accountId,
        body: { automaticallyConnectMatchedUsers: true },
      });

      setStep(Step.CONNECTION);
      enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CONNECTION_SUCCESSFUL'), {
        variant: 'success',
      });
    };

    setPollingInterval(isConnectionInitiated ? 1000 : 0);

    if (isConnectionInitiated && !integrationDataLoading) {
      const auth2ConnectionStatus = integrationData?.parameters?.OAuth2Status;
      const connectionStatus = integrationData?.connection?.status;

      if (connectionStatus === 'OK') {
        void finishActivation();
        closeConnectionWindow(connectionWindow);
        setIsConnectionInitiated(false);
      }

      if (auth2ConnectionStatus === 'ERROR' || connectionStatus === 'FAILED') {
        showErrorMessage();
        closeConnectionWindow(connectionWindow);
        setIsConnectionInitiated(false);
      }

      if (auth2ConnectionStatus === 'AUTHORIZED' && !isConnectionExecuted) {
        void testAccountIntegrationMutation({
          accountId,
          integrationId: SLACK_PROVIDER_ID,
          testName: 'connection',
        });
        setIsConnectionExecuted(true);
        closeConnectionWindow(connectionWindow);
      }
    }
  }, [
    accountId,
    activateSlackProvider,
    activateVerticeProvider,
    connectionWindow,
    toggleAutoConnection,
    enqueueSnackbar,
    integrationData?.connection?.status,
    integrationData?.parameters?.OAuth2Status,
    isConnectionExecuted,
    isConnectionInitiated,
    setStep,
    t,
    testAccountIntegrationMutation,
    showErrorMessage,
    integrationDataLoading,
  ]);

  return {
    activateSlackIntegration,
    isLoadingActivateSlackIntegration:
      isLoadingActivateSlackProvider ||
      isLoadingActivateVerticeProvider ||
      isLoadingUpdateDataSource ||
      isLoadingToggleAutoConnection,
    isConnectionInitiated,
  };
};
