import { useMemo } from 'react';
import { useLoggedUser } from '@verticeone/auth/src';
import { useUserOwnerDepartments } from './useUserOwnerDepartments';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

const useIntakeForm = () => {
  const { userId } = useLoggedUser();

  const { isLoading: isLoadingAccountRoles, isUserAdmin, isUserPowerUser } = useLoggedUserAccountRoles();
  const isAdminOrPowerUser = isUserAdmin || isUserPowerUser;
  const { departments, isLoading: isLoadingDepartments } = useUserOwnerDepartments({
    userId: userId!,
    skip: isAdminOrPowerUser || isLoadingAccountRoles || !userId,
  });

  return useMemo(
    () => ({
      isLoading: isLoadingDepartments || isLoadingAccountRoles,
      userCanAddExistingContract: isAdminOrPowerUser || !!departments?.length,
    }),
    [departments, isAdminOrPowerUser, isLoadingAccountRoles, isLoadingDepartments]
  );
};

export default useIntakeForm;
