import { useCloudAnalytics } from '../../../../CloudAnalyticsContext';
import CloudTagsServicesTable from './CloudTagsServicesTable';
import CloudTagsServicesUsageTable from './CloudTagsServicesUsageTable';
import React from 'react';

const TableByLastNode = () => {
  const { getLastNode } = useCloudAnalytics();
  const lastNode = getLastNode();

  switch (lastNode.type) {
    case 'service':
      return <CloudTagsServicesUsageTable productCode={lastNode.id} />;
    default:
      return <CloudTagsServicesTable />;
  }
};

export default TableByLastNode;
