import React, { FC } from 'react';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import { PropsWithContract } from '../types';
import { Field } from './Field';
import { getStartedMonthsBetweenDates } from '@vertice/core/src/modules/saas/contract/computed';

export const ContractLength: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const startDate = contract.parts.contractual?.lifecycle?.startDate;
  const renewalDate = contract.parts.contractual?.lifecycle?.renewalDate;

  const contractLength = getStartedMonthsBetweenDates(startDate ?? undefined, renewalDate ?? undefined);

  return (
    <Field
      labelTranslationKey="ENTITIES.CONTRACT.LABELS.CONTRACT_LENGTH"
      value={
        isNil(contractLength) ? t('COMMON.NA') : `${contractLength} ${t('UNITS.MONTH', { count: contractLength ?? 0 })}`
      }
    />
  );
};
