import React from 'react';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import { DesignSystemDensity } from '../../types';
import { useDrawer } from './DrawerContext';
import { StackProps } from '@mui/material/Stack/Stack';
import Placeholder from '../Placeholder';

export const paddingDefinitions: Record<DesignSystemDensity, number> = {
  high: 16,
  medium: 24,
  low: 32,
};

const StyledStack = styled(Stack)<{ $density: DesignSystemDensity }>(({ $density }) => ({
  padding: `${paddingDefinitions[$density]}px`,
}));

type DrawerBodyProps = StackProps;

const DrawerBody = ({ children, ...otherProps }: DrawerBodyProps) => {
  const { density, isLoading, fullScrollbar } = useDrawer();
  return (
    <StyledStack
      $density={density}
      {...otherProps}
      height={isLoading ? '100%' : otherProps.height}
      overflow={!fullScrollbar ? 'auto' : otherProps.overflow}
    >
      {isLoading ? <Placeholder height="100%" /> : children}
    </StyledStack>
  );
};

export default DrawerBody;
