import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';

import CardGrid from '../../../../task/TaskList/CardGrid';
import { TaskRow } from '../../../../task/types';
import { useRequestTasks } from '../../useRequestTasks';
import { useRequestTaskColumns } from '../../useRequestTaskColumns';
import { OnRowClickEvent } from '../../../../task/TaskList/CardGrid/types';
import { TaskModal } from '../../../../task/TaskModal/TaskModal';
import { useRequestContext } from '../../RequestContext';

export const TasksSection = () => {
  const { requestId } = useRequestContext();
  const { t } = useTranslation();

  const { activeTasks, allTasks, isLoadingTasks, isFetchingTasks, refetchTasks } = useRequestTasks(requestId);
  const taskColumns = useRequestTaskColumns();

  const [modalTaskId, setModalTaskId] = useState<string | undefined>(undefined);
  const onOpenTask: OnRowClickEvent<TaskRow> = (params) => {
    setModalTaskId(params.row.id);
  };
  const modalTask = useMemo(() => {
    return allTasks.find((row) => row.id === modalTaskId);
  }, [allTasks, modalTaskId]);

  return (
    <>
      <CardGrid<TaskRow>
        columns={taskColumns}
        rows={activeTasks}
        hideHeaders
        loading={isLoadingTasks}
        loadingRowCount={1}
        onRowClick={onOpenTask}
        slots={{
          noRowsOverlay: () => (
            <Stack alignItems="flex-start" direction="column" gap={2}>
              <Text variant="body-regular">{t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.NO_TASKS')}</Text>
              <Button variant="outline" size="S" onClick={refetchTasks} isLoading={isFetchingTasks}>
                {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.REFRESH_TASKS')}
              </Button>
            </Stack>
          ),
        }}
        rowHeight={67}
        sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      />
      <TaskModal onClose={() => setModalTaskId(undefined)} taskRow={modalTask} />
    </>
  );
};
