import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import Highcharts from 'highcharts';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { MostCommonContractLengthData } from './useGetMostCommonContractLengthData';
import { getAxisTitleStyle } from '../shared/utils';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';

export type MostCommonContractLengthGraphProps = {
  data: MostCommonContractLengthData;
};

const axisTitleStyle = getAxisTitleStyle();

const MostCommonContractLengthGraph = ({ data }: MostCommonContractLengthGraphProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const applyStyledHighcharts = useStyledHighcharts();
  const applyXAxisOffset = useXAxisOffset(52, 16);

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        (opts) =>
          mergeOptions(
            {
              chart: {
                type: 'column',
                height: 232,
                spacingLeft: -27,
                spacingBottom: 6,
              },
              tooltip: { enabled: false },
              plotOptions: {
                series: {
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                },
                column: {
                  pointPadding: 0.03,
                  groupPadding: 0,
                },
              },
              xAxis: {
                labels: {
                  y: 33,
                },
                categories: data.categories,
                title: {
                  text: t('PRODUCT.MOST_COMMON_CONTRACT_LENGTH_CARD.X_AXIS_TITLE'),
                  y: 6,
                  style: {
                    color: palette.text.color2,
                    ...axisTitleStyle,
                  },
                },
              },
              yAxis: {
                tickAmount: 5,
                labels: {
                  enabled: false,
                },
                title: {
                  text: t('PRODUCT.MOST_COMMON_CONTRACT_LENGTH_CARD.Y_AXIS_TITLE'),
                  x: 43,
                  style: {
                    color: palette.text.color2,
                    ...axisTitleStyle,
                  },
                },
              },
              series: data.series.map((serie) => ({
                ...serie,
                color: palette.visualization.divergent.secondary['-10'],
                borderRadiusTopLeft: 8,
                borderRadiusTopRight: 8,
              })),
            },
            opts
          ),
      ]),
    [t, applyXAxisOffset, applyStyledHighcharts, palette, data]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default MostCommonContractLengthGraph;
