import React from 'react';
import { Stack } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

export const StatBarDivider = () => {
  const { palette } = useTheme();

  return <Stack width="1px" bgcolor={palette.core.color3} />;
};

export default StatBarDivider;
