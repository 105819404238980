import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import wildcardMatch from 'wildcard-match';

import { useAuthentication } from '../authentication/AuthenticationContext';
import { authorizerReducer, authRequestDataToItem, ReducerItem, AuthRequestProps } from './reducer';
import { usePostAuthBatch } from '../api/hooks/usePostAuthBatch';

type UseAuthorizerProps = {
  <ID extends string>(item: AuthRequestProps<ID>): ReducerItem<ID>;
  <ID extends string>(item: Array<AuthRequestProps<ID>>): Array<ReducerItem<ID>>;
};

type AuthorizerContextProps = {
  authorize: (authData: AuthRequestProps | Array<AuthRequestProps>) => void;
  authorizer: Array<ReducerItem<string>>;
  compareAuthItems: (item: AuthRequestProps, item2: AuthRequestProps) => boolean;
};

type AuthorizerContextProviderProps = {
  baseUrl: string;
  children: React.ReactNode;
};

const AuthorizerContext = createContext<AuthorizerContextProps>({} as AuthorizerContextProps);

const AuthorizerContextProvider = ({ baseUrl, children }: AuthorizerContextProviderProps) => {
  const { user } = useAuthentication();
  const [authorizer, dispatch] = useReducer(authorizerReducer, []);

  const accountId = useMemo(() => user?.accountId, [user]);

  const { mutate: postAuthBatchMutation } = usePostAuthBatch(accountId!, baseUrl, {
    mutation: {
      onSuccess: (data) => {
        dispatch({ type: 'VALIDATE_POLICIES', payload: data.responses });
      },
    },
  });

  const compareAuthItems = useCallback((item: AuthRequestProps, item2: AuthRequestProps) => {
    return item.object === item2.object && item.action === item2.action;
  }, []);

  const hasPermission = useCallback(
    (item: AuthRequestProps): boolean => {
      const isMatch = wildcardMatch(item.object.replace(/\/\*$/, '/**'));

      return authorizer
        .filter(({ metadata }) => item.action === metadata.action && isMatch(metadata.object))
        .some(({ isAllowed }) => isAllowed);
    },
    [authorizer]
  );

  const isInCache = useCallback(
    (item: AuthRequestProps): boolean => {
      return !!authorizer.find(({ metadata }) => compareAuthItems(metadata, item));
    },
    [authorizer, compareAuthItems]
  );

  const authorize = useCallback(
    (authData: AuthRequestProps | Array<AuthRequestProps>) => {
      const dataToCreate = [authData]
        .flat()
        .filter((item) => !isInCache(item))
        .filter((item) => !hasPermission(item));

      if (dataToCreate.length) {
        dispatch({ type: 'ADD_POLICIES', payload: dataToCreate });
      }
    },
    [isInCache, hasPermission]
  );

  useEffect(() => {
    if (authorizer.every(({ isLoading }) => !isLoading)) return;

    postAuthBatchMutation({
      requests: [authorizer.map(({ metadata }) => metadata)]
        .flat()
        .map(({ id, object, action }) => ({ id, obj: object, act: action })),
    });
  }, [authorizer, postAuthBatchMutation]);

  return (
    <AuthorizerContext.Provider value={{ authorizer, authorize, compareAuthItems }}>
      {children}
    </AuthorizerContext.Provider>
  );
};

export const useAuthorizer: UseAuthorizerProps = (data) => {
  const { authorize, authorizer, compareAuthItems } = useContext(AuthorizerContext);

  useEffect(() => {
    authorize(data);
  }, [authorize, data]);

  const dataToDisplay = useMemo(
    () => [
      ...[data]
        .flat()
        .filter((item) => !authorizer.some(({ metadata }) => compareAuthItems(item, metadata)))
        .map(authRequestDataToItem),
      ...authorizer.filter(({ metadata }) => [data].flat().some((item) => compareAuthItems(item, metadata))),
    ],
    [authorizer, compareAuthItems, data]
  );

  return useMemo(() => {
    return Array.isArray(data)
      ? (dataToDisplay as any)
      : (dataToDisplay.find((item) => item.metadata.id === data.id) as any);
  }, [data, dataToDisplay]);
};

export default AuthorizerContextProvider;
