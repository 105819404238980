import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_PRO_LICENSE_KEY } from '@vertice/utils';

import { store } from './redux/store';
import App from './App';

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
