import { accountsApi as api } from '../../api/accountsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<GetAccountsApiResponse, GetAccountsApiArg>({
      query: (queryArg) => ({ url: `/accounts`, params: { offset: queryArg.offset, limit: queryArg.limit } }),
    }),
    getSsoConfiguration: build.query<GetSsoConfigurationApiResponse, GetSsoConfigurationApiArg>({
      query: (queryArg) => ({ url: `/accounts/sso`, params: { domain: queryArg.domain } }),
    }),
    getSsoConfigurationV2: build.query<GetSsoConfigurationV2ApiResponse, GetSsoConfigurationV2ApiArg>({
      query: (queryArg) => ({ url: `/accounts/sso/configuration`, params: { domain: queryArg.domain } }),
    }),
    getAccount: build.query<GetAccountApiResponse, GetAccountApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}` }),
    }),
    getPublicAccountInformation: build.query<GetPublicAccountInformationApiResponse, GetPublicAccountInformationApiArg>(
      {
        query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/public` }),
      }
    ),
    updatePublicAccountInformation: build.mutation<
      UpdatePublicAccountInformationApiResponse,
      UpdatePublicAccountInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/public`,
        method: 'PUT',
        body: queryArg.companyInformation,
      }),
    }),
    getAccountSettings: build.query<GetAccountSettingsApiResponse, GetAccountSettingsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/settings` }),
    }),
    updateAccountSettings: build.mutation<UpdateAccountSettingsApiResponse, UpdateAccountSettingsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/settings`,
        method: 'PUT',
        body: queryArg.updateAccountSettings,
      }),
    }),
    migrateUserSso: build.mutation<MigrateUserSsoApiResponse, MigrateUserSsoApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}/initiateMigration`,
        method: 'POST',
        body: queryArg.userSsoMigration,
      }),
    }),
    getAccountWorkflows: build.query<GetAccountWorkflowsApiResponse, GetAccountWorkflowsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/workflows` }),
    }),
    updateAccountWorkflows: build.mutation<UpdateAccountWorkflowsApiResponse, UpdateAccountWorkflowsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/workflows`, method: 'PUT', body: queryArg.body }),
    }),
    listAccountUsers: build.query<ListAccountUsersApiResponse, ListAccountUsersApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users` }),
    }),
    createNewAccountUser: build.mutation<CreateNewAccountUserApiResponse, CreateNewAccountUserApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users`, method: 'POST', body: queryArg.newUser }),
    }),
    createNewAccountUsersInBulk: build.mutation<
      CreateNewAccountUsersInBulkApiResponse,
      CreateNewAccountUsersInBulkApiArg
    >({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/usersBulk`, method: 'POST', body: queryArg.body }),
    }),
    getAccountUser: build.query<GetAccountUserApiResponse, GetAccountUserApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}` }),
    }),
    updateAccountUser: build.mutation<UpdateAccountUserApiResponse, UpdateAccountUserApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    removeUserRole: build.mutation<RemoveUserRoleApiResponse, RemoveUserRoleApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}`, method: 'DELETE' }),
    }),
    refreshUserInvitation: build.mutation<RefreshUserInvitationApiResponse, RefreshUserInvitationApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}/refreshInvitation`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getAccountUserWorkflowRoles: build.query<GetAccountUserWorkflowRolesApiResponse, GetAccountUserWorkflowRolesApiArg>(
      {
        query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}/workflows/roles` }),
      }
    ),
    getUserServiceAlias: build.query<GetUserServiceAliasApiResponse, GetUserServiceAliasApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}/service/${queryArg.serviceId}/alias`,
      }),
    }),
    getAccountUserSsoInvitationStatus: build.query<
      GetAccountUserSsoInvitationStatusApiResponse,
      GetAccountUserSsoInvitationStatusApiArg
    >({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users/email/${queryArg.userEmail}/sso/status` }),
    }),
    getSsoDetails: build.query<GetSsoDetailsApiResponse, GetSsoDetailsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/sso` }),
    }),
    saveSsoSamlConfig: build.mutation<SaveSsoSamlConfigApiResponse, SaveSsoSamlConfigApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sso`,
        method: 'POST',
        body: queryArg.accountSsoSamlConfig,
      }),
    }),
    initiateSso: build.mutation<InitiateSsoApiResponse, InitiateSsoApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/sso/initiate`, method: 'POST' }),
    }),
    addSsoDomain: build.mutation<AddSsoDomainApiResponse, AddSsoDomainApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sso/domains`,
        method: 'PUT',
        body: queryArg.accountSsoDomain,
      }),
    }),
    removeSsoDomain: build.mutation<RemoveSsoDomainApiResponse, RemoveSsoDomainApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sso/domains`,
        method: 'DELETE',
        params: { domain: queryArg.domain },
      }),
    }),
    issueIdentityTokenForRealmForSelf: build.mutation<
      IssueIdentityTokenForRealmForSelfApiResponse,
      IssueIdentityTokenForRealmForSelfApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sts/${queryArg.realm}/idtoken/issue`,
        method: 'POST',
      }),
    }),
    issueIdentityTokenForRealmForAnyUser: build.mutation<
      IssueIdentityTokenForRealmForAnyUserApiResponse,
      IssueIdentityTokenForRealmForAnyUserApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sts/${queryArg.realm}/idtoken/user/${queryArg.userId}/issue`,
        method: 'POST',
      }),
    }),
    issueTokenGrantingTicket: build.mutation<IssueTokenGrantingTicketApiResponse, IssueTokenGrantingTicketApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sts/tokens/tgt/issue`,
        method: 'POST',
        body: queryArg.body,
        params: { expireInSeconds: queryArg.expireInSeconds },
      }),
    }),
    issueIdentityTokenForTgt: build.mutation<IssueIdentityTokenForTgtApiResponse, IssueIdentityTokenForTgtApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sts/tokens/idtoken/redeem`,
        method: 'POST',
        params: { token: queryArg.token, expireInSeconds: queryArg.expireInSeconds },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accountAPICodegen };
export type GetAccountsApiResponse = /** status 200 Provides list of retrieved accounts
 */ Account[];
export type GetAccountsApiArg = {
  offset?: number;
  limit?: number;
};
export type GetSsoConfigurationApiResponse = /** status 200 Provides SSO configuration for account
 */ AccountSsoConfiguration;
export type GetSsoConfigurationApiArg = {
  domain: string;
};
export type GetSsoConfigurationV2ApiResponse = /** status 200 Provides SSO configuration for account
 */ AccountSsoConfiguration;
export type GetSsoConfigurationV2ApiArg = {
  domain: string;
};
export type GetAccountApiResponse =
  /** status 200 Provides information about account
   */
  | Account
  | /** status 202 Some resources cannot be created immediately and require asynchronous processing.
They are represented by unique job that is tasked to process all necessary steps.
Task represents promise to requested resource that is currently processed and it
might take unspecified time before it's fully ready.
If you want to get information about progress, check the task resource.
 */ {
      task?: ResourceRef;
    };
export type GetAccountApiArg = {
  accountId: UuidIdentifier;
};
export type GetPublicAccountInformationApiResponse =
  /** status 200 Provides publicly accessible information about company */ CompanyInformation;
export type GetPublicAccountInformationApiArg = {
  accountId: UuidIdentifier;
};
export type UpdatePublicAccountInformationApiResponse = unknown;
export type UpdatePublicAccountInformationApiArg = {
  accountId: UuidIdentifier;
  companyInformation: CompanyInformation;
};
export type GetAccountSettingsApiResponse = /** status 200 Provides all settings */ AccountSettings;
export type GetAccountSettingsApiArg = {
  accountId: UuidIdentifier;
};
export type UpdateAccountSettingsApiResponse =
  /** status 201 Provides information about account Settings and Preferences
   */ AccountSettings;
export type UpdateAccountSettingsApiArg = {
  accountId: UuidIdentifier;
  updateAccountSettings: UpdateAccountSettings;
};
export type MigrateUserSsoApiResponse = /** status 201 User successfully migrated */ void;
export type MigrateUserSsoApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
  /** User SSO migration
   */
  userSsoMigration: UserSsoMigration;
};
export type GetAccountWorkflowsApiResponse = /** status 200 Defines notification workflow
 */ {
  workflow?: Workflow;
};
export type GetAccountWorkflowsApiArg = {
  accountId: UuidIdentifier;
};
export type UpdateAccountWorkflowsApiResponse = /** status 201 Defines notification workflow
 */ {
  workflow?: Workflow;
};
export type UpdateAccountWorkflowsApiArg = {
  accountId: UuidIdentifier;
  /** A JSON object containing new Workflow settings */
  body: {
    workflow: Workflow;
  };
};
export type ListAccountUsersApiResponse = /** status 200 Provides list of users associated with account
 */ {
  users?: AccountUser[];
};
export type ListAccountUsersApiArg = {
  accountId: UuidIdentifier;
};
export type CreateNewAccountUserApiResponse = /** status 201 New user
 */ {
  userId?: Identifier;
};
export type CreateNewAccountUserApiArg = {
  accountId: UuidIdentifier;
  newUser: NewUser;
};
export type CreateNewAccountUsersInBulkApiResponse = /** status 201 New users bulk
 */ {
  SuccessfulUsers?: Email[];
  FailedUsers?: {
    email?: Email;
    reason?: string;
  }[];
};
export type CreateNewAccountUsersInBulkApiArg = {
  accountId: UuidIdentifier;
  body: NewUser[];
};
export type GetAccountUserApiResponse = /** status 200 User identity
 */ AccountUser;
export type GetAccountUserApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
};
export type UpdateAccountUserApiResponse = /** status 200 New user
 */ {
  userId?: Identifier;
};
export type UpdateAccountUserApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
  body: {
    role?: 'user:power' | 'user:restricted' | 'admin' | 'proxy';
    /** Optional arbitrary data which will be stored together with the user role for given account */
    accountUserProperties?: {
      jobTitle?: string;
    };
  };
};
export type RemoveUserRoleApiResponse = /** status 204 Resource has been deleted
 */ void;
export type RemoveUserRoleApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
};
export type RefreshUserInvitationApiResponse = /** status 201 User invitation successfully refreshed */ void;
export type RefreshUserInvitationApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
  /** Generic user invitation
   */
  body: object;
};
export type GetAccountUserWorkflowRolesApiResponse = /** status 200 User workflow roles
 */ UserWorkflowRoles;
export type GetAccountUserWorkflowRolesApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
};
export type GetUserServiceAliasApiResponse = /** status 200 User alias for given service
 */ UserAlias;
export type GetUserServiceAliasApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
  serviceId: Identifier;
};
export type GetAccountUserSsoInvitationStatusApiResponse = /** status 200 User SSO status
 */ UserSsoStatus;
export type GetAccountUserSsoInvitationStatusApiArg = {
  accountId: UuidIdentifier;
  userEmail: Email;
};
export type GetSsoDetailsApiResponse = /** status 200 Provides sso details for account
 */ AccountSsoDetails;
export type GetSsoDetailsApiArg = {
  accountId: UuidIdentifier;
};
export type SaveSsoSamlConfigApiResponse = /** status 200 Provides SSO saml configuration for account
 */ AccountSsoSamlConfig;
export type SaveSsoSamlConfigApiArg = {
  accountId: UuidIdentifier;
  /** SSO saml config
   */
  accountSsoSamlConfig: AccountSsoSamlConfig;
};
export type InitiateSsoApiResponse = /** status 200 Provides sso details for account
 */ AccountSsoDetails;
export type InitiateSsoApiArg = {
  accountId: UuidIdentifier;
};
export type AddSsoDomainApiResponse = /** status 201 Domain added */ void;
export type AddSsoDomainApiArg = {
  accountId: UuidIdentifier;
  /** SSO domain
   */
  accountSsoDomain: AccountSsoDomain;
};
export type RemoveSsoDomainApiResponse = /** status 204 Resource has been deleted
 */ void;
export type RemoveSsoDomainApiArg = {
  accountId: UuidIdentifier;
  domain: string;
};
export type IssueIdentityTokenForRealmForSelfApiResponse =
  /** status 200 IssueIdentityTokenForRealmResponse response */ {
    /** Identity token */
    identityToken: string;
  };
export type IssueIdentityTokenForRealmForSelfApiArg = {
  accountId: UuidIdentifier;
  realm: string;
};
export type IssueIdentityTokenForRealmForAnyUserApiResponse =
  /** status 200 IssueIdentityTokenForRealmResponse response */ {
    /** Identity token */
    identityToken: string;
  };
export type IssueIdentityTokenForRealmForAnyUserApiArg = {
  accountId: UuidIdentifier;
  realm: string;
  userId: Identifier;
};
export type IssueTokenGrantingTicketApiResponse = /** status 200 IssueTokenGrantingTicket response */ {
  /** Token granting ticket */
  tokenGrantingTicket: string;
};
export type IssueTokenGrantingTicketApiArg = {
  accountId: UuidIdentifier;
  expireInSeconds?: number;
  /** Token granting ticket access definition
   */
  body: {
    sessionType: 'GroupBased';
    groups: (
      | 'role:account:user:simple'
      | 'role:account:user:workflow'
      | 'role:account:user:approver'
      | 'role:account:user:power'
      | 'role:account:user:restricted'
      | 'role:account:admin'
    )[];
  };
};
export type IssueIdentityTokenForTgtApiResponse = /** status 200 IssueIdentityTokenForRealmResponse response */ {
  /** Identity token */
  identityToken: string;
};
export type IssueIdentityTokenForTgtApiArg = {
  token?: string;
  accountId: UuidIdentifier;
  expireInSeconds?: number;
};
export type Identifier = string;
export type CompanyInformation = {
  companyName?: string;
  country?: string;
  registrationNumber?: string;
  registeredAddress?: string;
  website?: string;
};
export type DealInformation = {
  estimatedSaasSpendLocal?: {
    amount?: number | null;
    currency?: string | null;
  };
  estimatedSaasSpendUsd?: {
    amount?: number | null;
  };
} | null;
export type Account = {
  accountId?: Identifier;
  name?: string;
  associatedManager?: Identifier;
  associatedSuccessManager?: Identifier;
  companyInformation?: CompanyInformation;
  dealInformation?: DealInformation;
  memo?: string;
  /** This field is set to DEMO if the account is a demo account, it is undefined otherwise. */
  accountType?: 'DEMO' | 'NORMAL';
};
export type AccountSsoConfiguration = {
  /** flag which indicates if SSO is enabled for given domain */
  enabled?: boolean;
  /** Client ID for SSO */
  clientId?: string;
  /** User pool ID for SSO */
  userPoolId?: string;
  /** Identity provider name for SSO */
  identityProviderName?: string;
  /** OAuth domain for SSO */
  oAuthDomain?: string;
};
export type Url = string;
export type Attachments = {
  files?: {
    fileName?: string;
    fileVersion?: string;
  }[];
};
export type ContractVendorProduct = {
  vendorName?: string;
  productName?: string;
  numberOfLicenses: number;
  annualCost?: number | null;
  currency?: string | null;
  vendorProductId: Identifier;
};
export type ContractVendorProducts = ContractVendorProduct[];
export type ResourceRef = {
  id?: Identifier;
  href?: Url;
  attachments?: Attachments;
  products?: ContractVendorProducts;
  /** Calculated earliest date to act on the contract. Used for ordering in the grid view. Based on the status/stage of this contract or its renewal. It is calculated from renewalDate, autorenewalDeadline, userDeadline (either from this contract or its renewal). */
  deadline?: string | null;
  /** Provides information how the deadline was calculated. This value is computed and is provided only on views/lists */
  deadlineMeta?: {
    sourceVersion: number;
    /** One of userDeadline (User Deadline Date), renewalDate (Renewal Date), autorenewalDeadline (Auto Renewal Deadline Date), c_nv_userDeadline (User Deadline Date of the renewal) */
    source: 'userDeadline' | 'renewalDate' | 'autorenewalDeadline' | 'c_nv_userDeadline';
  };
};
export type UuidIdentifier = string;
export type AccountNotificationWorkflowSettings = {
  role: 'owner' | 'watcher';
  contractRenewal: {
    enabled?: boolean;
    timeThreshold?: string;
  };
  pipelineUpdates: {
    enabled?: boolean;
  };
  proposalsAwaitingApproval: {
    enabled?: boolean;
  };
  newContractsAdded: {
    enabled?: boolean;
  };
};
export type AccountSettings = {
  preferredCurrency?: string;
  verticeManagedContractThreshold?: number | null;
  /** Marks whether account uses workflows or not */
  useWorkflows?: boolean;
  /** Deprecated. Historically used for notification preferences for proxy users
    Notification preferences for proxy users per role when the custom workflow is enabled
     */
  workflowNotificationSettings?: {
    subscriptions?: AccountNotificationWorkflowSettings[];
  };
};
export type UpdateAccountSettings = {
  preferredCurrency?: string;
  /** Marks whether account uses workflows or not */
  useWorkflows?: boolean;
  /** Deprecated. Historically used for notification preferences for proxy users
    Notification preferences for proxy users per role when the custom workflow is enabled
     */
  workflowNotificationSettings?: {
    subscriptions?: AccountNotificationWorkflowSettings[];
  };
};
export type UserSsoMigration = {
  strategy: 'migrateOnFirstSignIn';
};
export type ParticipantRoleCondition = {
  field?: string;
  /** maxLength is ~JSON.stringify array of 100 uuids */
  value?: string;
  /** - *equalsConstantString* - Example: "string" === "string" => true
    - *notEqualsConstantString* - Example: "string" !== "string" => false
    - *greaterThanConstantNumber - Example: 10 > 5 => true, 10 > 10 => false, 10 > 15 => false
    - *greaterOrEqualToConstantNumber - Example: 10 > 5 => true, 10 >= 10 => true, 10 > 15 => false
    - *lessThanConstantNumber - Example: 5 < 10 => true, 10 < 10 => false, 15 < 10 => false
    - *lessOrEqualToConstantNumber - Example: 5 < 10 => true, 10 <= 10 => true, 15 < 10 => false
    - *stringInArray* - value has to be `JSON.stringify` array of strings; Example: ["id1", "id2"].includes("id1) => true
     */
  operator?:
    | 'equalsConstantString'
    | 'notEqualsConstantString'
    | 'greaterThanConstantNumber'
    | 'greaterOrEqualToConstantNumber'
    | 'lessThanConstantNumber'
    | 'lessOrEqualToConstantNumber'
    | 'stringInArray';
};
export type WorkflowParticipant = {
  /** Name of the participant */
  name: string;
  /** Role of the participant */
  role: 'owner' | 'approver' | 'watcher';
  userId: Identifier;
  /** Conditions can narrow down how role is assigned to the participant.
    If no conditions are defined, then the participant is assigned the role by default.
    If conditions are defined then all conditions are evaluated and if all conditions are met then
    the participant is assigned the role.
     */
  conditions?: ParticipantRoleCondition[];
};
export type ContactListSubscriptionCondition = {
  field?: string;
  /** maxLength is ~JSON.stringify array of 25 uuids */
  value?: string;
  /** - *equalsConstantString* - Example: "string" === "string" => true
    - *notEqualsConstantString* - Example: "string" !== "string" => false
    - *greaterThanConstantNumber - Example: 10 > 5 => true, 10 > 10 => false, 10 > 15 => false
    - *greaterOrEqualToConstantNumber - Example: 10 > 5 => true, 10 >= 10 => true, 10 > 15 => false
    - *lessThanConstantNumber - Example: 5 < 10 => true, 10 < 10 => false, 15 < 10 => false
    - *lessOrEqualToConstantNumber - Example: 5 < 10 => true, 10 <= 10 => true, 15 < 10 => false
    - *stringInArray* - value has to be `JSON.stringify` array of strings; Example: ["id1", "id2"].includes("id1) => true
     */
  operator?:
    | 'equalsConstantString'
    | 'notEqualsConstantString'
    | 'greaterThanConstantNumber'
    | 'greaterOrEqualToConstantNumber'
    | 'lessThanConstantNumber'
    | 'lessOrEqualToConstantNumber'
    | 'stringInArray';
};
export type ContactListSubscription = {
  /** - *Contract:Created:Existing* - Event triggered when registering existing contract
    - *Contract:Created:ExistingRenewal* - Event triggered when registering contract renewed outside of Vertice
    - *Contract:Created:Renewal* - Event triggered when renewal of contract happened
    - *Contract:Created:Purchase* - Event triggered when new purchase of contract happened
    - *Contract:ApprovalRequired* - Event triggered when contract is awaiting approval in renewal and purchase workflow
    - *Contract:Approved* - Event triggered when task in renewal or purchase workflow is approved
    - *Contract:DeadlineP1M* - Event triggered when deadline (contractDetail.computed.deadline) is less then 1 month from now
    - *Contract:DeadlineP2M* - Event triggered when deadline (contractDetail.computed.deadline) is less then 2 months from now
    - *Contract:DeadlineP3M* - Event triggered when deadline (contractDetail.computed.deadline) is less then 3 months from now
    - *Contract:DeadlineP4M* - Event triggered when deadline (contractDetail.computed.deadline) is less then 4 months from now
    - *Contract:DeadlineP5M* - Event triggered when deadline (contractDetail.computed.deadline) is less then 5 months from now
    - *Contract:Expired* - Event triggered when contract state changes to expired
    - *Contract:Negotiating* - Event triggered when account manager starts negotiate with vendor in renewal or purchase process
    - *Contract:Renewed* - Event triggered when renewal process is completed
    - *Contract:Purchased* - Event triggered when purchase process is completed
    deadline - computed field from one of:
        - userDeadline (User Deadline Date)
        - renewalDate (Renewal Date)
        - autoRenewalDeadline (Auto Renewal Deadline Date)
        - followingVersionUserDeadline (User Deadline Date of the renewal)
     */
  event:
    | 'Contract:Created:Existing'
    | 'Contract:Created:ExistingRenewal'
    | 'Contract:Created:Renewal'
    | 'Contract:Created:Purchase'
    | 'Contract:ApprovalRequired'
    | 'Contract:Approved'
    | 'Contract:DeadlineP1M'
    | 'Contract:DeadlineP2M'
    | 'Contract:DeadlineP3M'
    | 'Contract:DeadlineP4M'
    | 'Contract:DeadlineP5M'
    | 'Contract:Expired'
    | 'Contract:Negotiating'
    | 'Contract:Renewed'
    | 'Contract:Purchased';
  conditions?: ContactListSubscriptionCondition[];
  properties?: {
    sendOneTimeActions?: boolean;
  };
};
export type ContactListMember = {
  /** Name of the contact */
  name?: string;
  /** Channel of the contact, which specifies how the contact is reached */
  channel?: 'email';
  /** Contact information, which is used to reach the contact */
  contact?: string;
  /** Credentials required for given channel. Only applies for certain channels. */
  credentials?: {
    type?: string;
    [key: string]: any;
  };
  /** List of subscriptions for the contact. */
  subscriptions?: ContactListSubscription[];
  userId?: Identifier;
  identityId?: Identifier;
};
export type ContactList = {
  /** Name of the contact list */
  name: string;
  /** List of members in the contact list */
  members: ContactListMember[];
};
export type Workflow = {
  /** Name of the workflow */
  name: string;
  /** enable/disable workflow feature for account scope */
  enabled: boolean;
  participants: WorkflowParticipant[];
  /** List of contact list definitions */
  contactLists: ContactList[];
  /** Allows API users to store arbitrary properties on the workflows.
    This property bag is limited to 200KB in size.
     */
  misc?: {
    [key: string]: any;
  };
};
export type AccountUser = {
  userId: Identifier;
  userName: string;
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  userRoles?: string[];
  userStatus?: 'DISABLED' | 'ACTIVE' | 'PENDING';
  directoryStatus?:
    | 'NOT_APPLICABLE'
    | 'INACTIVE'
    | 'INVITED_MANAGED'
    | 'INVITED_SSO'
    | 'INVITED_SSO_MIGRATION'
    | 'ACTIVE_MANAGED'
    | 'ACTIVE_SSO'
    | 'ACTIVE_VERTICE_EMPLOYEE';
  userPoolType?: 'SSO' | 'VERTICE';
  invitationStatus?: string;
  invitationType?: string;
  /** Arbitrary data associated with the user and account */
  accountUserProperties?: {
    jobTitle?: string;
  };
};
export type PhoneNumber = string;
export type Timestamp = string | null;
export type NewUser = {
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber?: PhoneNumber;
  retentionDate?: Timestamp;
  role: 'user:power' | 'user:restricted' | 'admin' | 'proxy';
  /** Optional arbitrary data which will be stored together with the user role for given account */
  accountUserProperties?: {
    jobTitle?: string;
  };
};
export type Email = string;
export type AccountContractRef = {
  accountId: Identifier;
  contractId?: Identifier;
  contractVersion?: number;
};
export type UserWorkflowRole = {
  objectType: 'Account' | 'Contract';
  ref: AccountContractRef;
  role: 'owner' | 'approver' | 'watcher';
};
export type UserWorkflowRoles = {
  roles: UserWorkflowRole[];
};
export type UserAlias = {
  alias?: Identifier;
  accountId?: Identifier;
  userId?: Identifier;
  serviceId?: string;
  createdAt?: string;
};
export type UserSsoStatus = {
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'ERROR';
};
export type AccountSsoDetails = {
  status?: 'INACTIVE' | 'ACTIVE' | 'PROVISIONING' | 'ERROR' | 'WAITING_FOR_MANIFEST' | 'WAITING_FOR_DOMAINS';
  properties?: {
    registeredDomains?: string[];
    saml?: {
      metadata?: {
        url?: string;
        /** SAML metadata content in XML format
         */
        file?: string;
      };
      callbackUrl?: string;
      /** SAML configuration (as JSON) which should be used by account admin to configure SAML in their IdP
       */
      configuration?: string;
    };
  };
};
export type AccountSsoSamlConfig = {
  saml?: {
    metadata: {
      url?: Url;
      file?: string;
    };
  };
};
export type AccountSsoDomain = {
  domain?: string;
};
export const {
  useGetAccountsQuery,
  useLazyGetAccountsQuery,
  useGetSsoConfigurationQuery,
  useLazyGetSsoConfigurationQuery,
  useGetSsoConfigurationV2Query,
  useLazyGetSsoConfigurationV2Query,
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useGetPublicAccountInformationQuery,
  useLazyGetPublicAccountInformationQuery,
  useUpdatePublicAccountInformationMutation,
  useGetAccountSettingsQuery,
  useLazyGetAccountSettingsQuery,
  useUpdateAccountSettingsMutation,
  useMigrateUserSsoMutation,
  useGetAccountWorkflowsQuery,
  useLazyGetAccountWorkflowsQuery,
  useUpdateAccountWorkflowsMutation,
  useListAccountUsersQuery,
  useLazyListAccountUsersQuery,
  useCreateNewAccountUserMutation,
  useCreateNewAccountUsersInBulkMutation,
  useGetAccountUserQuery,
  useLazyGetAccountUserQuery,
  useUpdateAccountUserMutation,
  useRemoveUserRoleMutation,
  useRefreshUserInvitationMutation,
  useGetAccountUserWorkflowRolesQuery,
  useLazyGetAccountUserWorkflowRolesQuery,
  useGetUserServiceAliasQuery,
  useLazyGetUserServiceAliasQuery,
  useGetAccountUserSsoInvitationStatusQuery,
  useLazyGetAccountUserSsoInvitationStatusQuery,
  useGetSsoDetailsQuery,
  useLazyGetSsoDetailsQuery,
  useSaveSsoSamlConfigMutation,
  useInitiateSsoMutation,
  useAddSsoDomainMutation,
  useRemoveSsoDomainMutation,
  useIssueIdentityTokenForRealmForSelfMutation,
  useIssueIdentityTokenForRealmForAnyUserMutation,
  useIssueTokenGrantingTicketMutation,
  useIssueIdentityTokenForTgtMutation,
} = injectedRtkApi;
