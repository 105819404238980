import BaseCloudAnalyticsGraph from '../../../BaseCloudTab/Graph/BaseCloudAnalyticsGraph';
import React from 'react';
import useTagsServicesUsageGraphData from '../useTagsServicesUsageGraphData';

type CloudAccountsServicesGraphProps = {
  productCode: string;
};

const CloudTagsServicesUsageGraph = ({ productCode }: CloudAccountsServicesGraphProps) => {
  const { data: graphData, ...loadingStatus } = useTagsServicesUsageGraphData({
    productCode,
  });

  return <BaseCloudAnalyticsGraph data={graphData!} {...loadingStatus} />;
};

export default CloudTagsServicesUsageGraph;
