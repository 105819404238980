import { useGetAccountSettingsQuery } from '@vertice/slices';
import { useAccountContext } from '../contexts/AccountContext';
import { WORKFLOW_ADMIN_ACCOUNT_ID } from '../modules/intelligentWorkflows/constants';

export const useAccountSettings = (overriddenAccountId?: string) => {
  const { accountId } = useAccountContext();

  const effectiveAccountId = overriddenAccountId ?? accountId;

  return useGetAccountSettingsQuery(
    { accountId: effectiveAccountId! },
    { skip: !effectiveAccountId || effectiveAccountId === WORKFLOW_ADMIN_ACCOUNT_ID }
  );
};
