import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  vendorApi,
  userApi,
  accountSlice,
  authSlice,
  contractApi,
  eventsApi,
  analyticsApi,
  enhancedIntegrationsAPI,
  enhancedAccountAPI,
  enhancedInsightGraphQL,
  enhancedUsageGraphQL,
  enhancedCoreAppGraphQL,
  cloudOptimizationGraphQL,
  notificationsSlice,
  accountsAdminApi,
  featuresApi,
  storageApi,
  conversationsAPI,
  legalApi,
  enhancedTaggingAPI,
  notifWebsocketsAPI,
} from '@vertice/slices';
import { enhancedContractWorkflowsV2API } from '@vertice/slices/src/api/enhanceContractWorkflowsV2API';
import { enhancedBffeSaasAPI } from '@vertice/slices/src/api/enhancedBffeSaasAPI';
import { errorHandlerMiddleware } from '@vertice/slices/src/middlewares/errorHandlerMiddleware';
import { enhancedWorkflowsV2API } from '@vertice/slices/src/api/enhancedWorkflowsV2API';
import { scimAPI } from '@vertice/slices/src/api/scimAPI';
import { enhancedUserAPI } from '@vertice/slices/src/api/enhancedUserAPI';
import getCfaJwtToken from '../modules/auth/getCfaJwtToken';
import { identity } from 'lodash';
import ApiMiddlewareExtras from '@vertice/slices/src/types/ApiMiddlewareExtras';
import { enhancedBffeWorkflowsAPI } from '@vertice/slices/src/api/enhancedBffeWorkflowsAPI';
import { enhancedServicesAPI } from '@vertice/slices/src/api/enhancedServicesAPI';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    account: accountSlice.reducer,
    notifications: notificationsSlice.reducer,
    [vendorApi.reducerPath]: vendorApi.reducer,
    [contractApi.reducerPath]: contractApi.reducer,
    [enhancedWorkflowsV2API.reducerPath]: enhancedWorkflowsV2API.reducer,
    [enhancedContractWorkflowsV2API.reducerPath]: enhancedContractWorkflowsV2API.reducer,
    [enhancedBffeSaasAPI.reducerPath]: enhancedBffeSaasAPI.reducer,
    [enhancedTaggingAPI.reducerPath]: enhancedTaggingAPI.reducer,
    [enhancedUserAPI.reducerPath]: enhancedUserAPI.reducer,
    [enhancedAccountAPI.reducerPath]: enhancedAccountAPI.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [featuresApi.reducerPath]: featuresApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [enhancedIntegrationsAPI.reducerPath]: enhancedIntegrationsAPI.reducer,
    [accountsAdminApi.reducerPath]: accountsAdminApi.reducer,
    [enhancedInsightGraphQL.reducerPath]: enhancedInsightGraphQL.reducer,
    [enhancedUsageGraphQL.reducerPath]: enhancedUsageGraphQL.reducer,
    [enhancedCoreAppGraphQL.reducerPath]: enhancedCoreAppGraphQL.reducer,
    [cloudOptimizationGraphQL.api.reducerPath]: cloudOptimizationGraphQL.api.reducer,
    [storageApi.reducerPath]: storageApi.reducer,
    [legalApi.reducerPath]: legalApi.reducer,
    [scimAPI.reducerPath]: scimAPI.reducer,
    [enhancedBffeWorkflowsAPI.reducerPath]: enhancedBffeWorkflowsAPI.reducer,
    [enhancedServicesAPI.reducerPath]: enhancedServicesAPI.reducer,
    [conversationsAPI.reducerPath]: conversationsAPI.reducer,
    [notifWebsocketsAPI.reducerPath]: notifWebsocketsAPI.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: identity<ApiMiddlewareExtras>({
          getJwtToken: getCfaJwtToken,
        }),
      },
    }).concat([
      errorHandlerMiddleware,
      vendorApi.middleware,
      contractApi.middleware,
      accountsAdminApi.middleware,
      enhancedContractWorkflowsV2API.middleware,
      enhancedWorkflowsV2API.middleware,
      enhancedBffeSaasAPI.middleware,
      enhancedTaggingAPI.middleware,
      userApi.middleware,
      enhancedAccountAPI.middleware,
      eventsApi.middleware,
      featuresApi.middleware,
      enhancedIntegrationsAPI.middleware,
      analyticsApi.middleware,
      enhancedInsightGraphQL.middleware,
      enhancedUsageGraphQL.middleware,
      enhancedCoreAppGraphQL.middleware,
      cloudOptimizationGraphQL.api.middleware,
      storageApi.middleware,
      legalApi.middleware,
      scimAPI.middleware,
      enhancedBffeWorkflowsAPI.middleware,
      enhancedServicesAPI.middleware,
      conversationsAPI.middleware,
      notifWebsocketsAPI.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
