import { Stack, styled } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Tooltip, { TooltipInfoTrigger } from '@verticeone/design-system/src/components/Tooltip';
import { useTranslation } from 'react-i18next';
import { offerSectionPropsMap } from './helpers/itemMapping';
import { OfferSubSectionProps } from './types';

const GridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(190px, 1fr))',
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  backgroundColor: theme.palette.core.color1,
  borderRadius: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.core.color3}`,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const OfferSubSection = ({ variant, name, items }: OfferSubSectionProps) => {
  const { t } = useTranslation();

  const sectionMap = offerSectionPropsMap(t);
  const Icon = sectionMap[variant].icon;

  if (items.length === 0) return null;

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1}>
        <Icon />
        <Text marginLeft={1} variant="body-regular" size="M" color="text2">
          {sectionMap[variant].title}:
        </Text>
        <Text variant="body-bold" size="M" color="text1">
          {name}
        </Text>
      </Stack>
      <GridContainer>
        {variant !== 'attachments' &&
          items
            .filter((item) => 'value' in item && !!item.value)
            .map((item, index) => (
              <Stack key={['offer', item.name, index].join()} gap={1} minWidth={0}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Text variant="caption" size="XS" color="text2">
                    {item.name}
                  </Text>
                  {item.info && (
                    <Tooltip content={item.info} placement="top" size="M" title={item.name}>
                      <TooltipInfoTrigger />
                    </Tooltip>
                  )}
                </Stack>
                <Text variant="body-regular" size="M" color="text1">
                  {item.value}
                </Text>
              </Stack>
            ))}
      </GridContainer>
    </Stack>
  );
};
