import { z } from 'zod';

import { SecurityScoreSchema } from '../shared/schemas';
import { zodSchema as SecurityQuestionnaireFormSchema } from '../SecurityQuestionnaireForm/schema';

export const zodSchema = z
  .object({
    securityScore: SecurityScoreSchema,
  })
  .merge(SecurityQuestionnaireFormSchema);

export type FormData = z.infer<typeof zodSchema>;
