import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatBar, StatBarItem, StatBarDivider } from '@verticeone/design-system/src/components/StatBar';
import { useEDPTotalEligibleSpend } from './useSummaryData';
import { LoadableComponent } from '@verticeone/design-system/src/components/Loader';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';

type SummaryProps = {
  totalCommitment: number;
  avgDiscount: number;
};

const Summary = ({ avgDiscount, totalCommitment }: SummaryProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_EDP.CONTRACT.SUMMARY' });
  const { data, isFetching } = useEDPTotalEligibleSpend();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  return (
    <Stack p={4} pt={0}>
      <StatBar>
        <LoadableComponent isLoading={isFetching}>
          <StatBarItem
            title={t('TOTAL_COMMITMENT')}
            value={formatCurrency(totalCommitment, { maximumFractionDigits: 1, compact: true })}
          />
          <StatBarDivider />
          <StatBarItem
            title={t('SPEND_IN_TERM')}
            value={[
              formatCurrency(data?.spendInTerm ?? 0, { maximumFractionDigits: 1, compact: true }),
              formatCurrency(data?.totalSpend ?? 0, { maximumFractionDigits: 1, compact: true }),
            ].join(' / ')}
          />
          <StatBarDivider />
          <StatBarItem title={t('AVG_SAVED_DISCOUNT')} value={formatPercentage(avgDiscount)} />
          <StatBarDivider />
          <StatBarItem
            title={t('AVG_MONTHLY_EDP_SAVINGS')}
            value={formatCurrency(data?.avgMonthlySaving ?? 0, { maximumFractionDigits: 1, compact: true })}
            color="success"
          />
        </LoadableComponent>
      </StatBar>
    </Stack>
  );
};

export default Summary;
