import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Text from '@verticeone/design-system/src/components/Text';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import IconButton from '@verticeone/design-system/src/components/IconButton';

import { TaskStatusChip } from '../TaskStatusChip';
import { useFormatDate } from '@vertice/core/src/utils/formatting/date';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import {
  createServiceIdentifier,
  parseRequestRef,
  parseWorkflowVersionRef,
} from '@vertice/core/src/hooks/workflows/refUtils';
import { TaskAssignees } from './TaskAssignees';
import { useTaskContext } from './TaskContext';
import AssigneeSelect from '../../components/AssigneeSelect';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useAssignUserTaskMutation, UserTask } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';
import {
  GenericDateIcon,
  GenericRequestIcon,
  GenericUserIcon,
  GenericWorkflowIcon,
} from '../../components/icons/Icons';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { isSaaSApprovalTask } from './TaskModalForm/predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { Can } from '@verticeone/auth/src';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

type DetailRowProps = {
  icon: FC;
  label: ReactNode;
  linkTo?: string;
  testId?: string;
};

const DetailRow: FC<DetailRowProps> = ({ icon: Icon, label, linkTo, testId }) => {
  return (
    <Stack direction="row" alignItems="center" height={32} {...testProps(testId, 'detailRow')}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Icon />
        <Text variant="body-regular" size="M" color="text1">
          {label}
        </Text>
      </Stack>
      {linkTo && (
        <IconButton
          icon={OpenInNew}
          size="M"
          variant="plain"
          color="transparent"
          component={Link}
          to={linkTo}
          sx={{ marginLeft: -1 }}
        />
      )}
    </Stack>
  );
};

const canReassignTask = (task: UserTask, useCanReassignTask: boolean) =>
  useCanReassignTask && !Array<UserTask['status']>('COMPLETED', 'TERMINATED').includes(task.status);

export const TaskModalContent = () => {
  const { t } = useTranslation();
  const { isUserAdmin } = useLoggedUserAccountRoles();
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();
  const { taskOverview, task } = useTaskContext();

  const formatDate = useFormatDate();
  const daysActive = dayjs().diff(task.createdAt, 'day');
  const createdAt = formatDate(task.createdAt);

  const workflowRef = taskOverview?.workflow?.ref;
  const workflowLink =
    workflowRef && taskOverview?.request?.serviceRef
      ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL, {
          workflowId: parseWorkflowVersionRef(workflowRef).workflowId,
          serviceId: createServiceIdentifier(taskOverview?.request?.serviceRef),
        })
      : undefined;

  const { accountId } = useAccountContext();
  const [assignUserMutation, { isLoading: isAssigningUserTask }] = useAssignUserTaskMutation();
  const handleAssignmentChange = (assignment: string[]) => {
    void assignUserMutation({
      accountId,
      taskId: task.id,
      assignUserTask: {
        assignments: assignment,
      },
    });
  };

  return (
    <Stack gap={4} paddingX={4} {...testProps('taskModalContent')}>
      <Stack>
        <Stack gap={2} direction="row" alignItems="center">
          <Text variant="heading" size="M" {...testProps('taskName')}>
            {task.name}
          </Text>
          <TaskStatusChip status={task.status} />
        </Stack>
      </Stack>
      <Stack flexBasis="60%">
        <Stack gap={2}>
          <DetailRow
            label={
              taskOverview?.task ? (
                canReassignTask(taskOverview.task, isUserAdmin) ? (
                  <AssigneeSelect
                    task={taskOverview.task}
                    onChange={handleAssignmentChange}
                    isLoading={isAssigningUserTask}
                    size="S"
                    color="secondary"
                    variant="solid"
                  />
                ) : (
                  <TaskAssignees assignees={task.assignees} />
                )
              ) : (
                <Placeholder width={150} />
              )
            }
            testId="assignees"
            icon={GenericUserIcon}
          />

          <DetailRow
            label={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.ACTIVE_FOR', { daysActive, createdAt })}
            icon={GenericDateIcon}
            testId="activeFor"
          />
          <RequestDetailRow taskOverview={taskOverview} />
          <Can passThrough do="workflow:List" on={`urn:verticeone:vertice:${accountId}:workflows:workflow/*`}>
            {(isAllowed) => (
              <DetailRow
                label={taskOverview?.workflow?.name || '–'}
                icon={GenericWorkflowIcon}
                linkTo={isAllowed ? workflowLink : undefined}
                testId="workflowName"
              />
            )}
          </Can>
        </Stack>
      </Stack>
    </Stack>
  );
};

const RequestDetailRow: FC<{ taskOverview?: TaskOverview }> = ({ taskOverview }) => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();

  if (isSaaSApprovalTask(taskOverview)) {
    const contractLink = taskOverview?.task.input?.contractId
      ? generatePathForRoute(routes.CONTRACTS.DETAIL, {
          contractId: taskOverview?.task.input?.contractId,
        })
      : undefined;

    return (
      <DetailRow
        label={taskOverview?.workflow?.instanceName || '–'}
        icon={GenericRequestIcon}
        linkTo={contractLink}
        testId="requestName"
      />
    );
  }

  const requestRef = taskOverview?.request?.ref;
  const requestLink = requestRef
    ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
        requestId: parseRequestRef(requestRef).requestId,
      })
    : undefined;

  return (
    <DetailRow
      label={taskOverview?.request?.name || '–'}
      icon={GenericRequestIcon}
      linkTo={requestLink}
      testId="requestName"
    />
  );
};
