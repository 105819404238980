import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import ProductDetailPageHeader from './ProductDetailPageHeader';
import useBenchmarking from '../../modules/saas/vendor/hooks/useBenchmarking';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import PricingBenchmarkCard from '../../modules/saas/product/cards/PricingBenchmarkCard/PricingBenchmarkCard';
import EffectiveBenchmarkingCard from '../../modules/saas/product/cards/EffectiveBenchmarkingCard/EffectiveBenchmarkingCard';
import MostCommonContractLengthCard from '../../modules/saas/product/cards/MostCommonContractLengthCard/MostCommonContractLengthCard';
import { AboutProductCard } from '../../modules/saas/product/cards/AboutProductCard/AboutProductCard';

const ProductDetail = () => {
  const { id: vendorId, productId } = useParams();
  const { benchmarkingStage0, isLoading } = useBenchmarking();

  if (!vendorId || !productId) {
    return null;
  }

  if (!isLoading && !benchmarkingStage0) {
    return <PageNotFound />;
  }

  return (
    <Stack>
      <ProductDetailPageHeader vendorId={vendorId} productId={productId} />
      <Stack px={8} pb={6} gap={4}>
        <Box flexShrink={0}>
          <AboutProductCard vendorId={vendorId} productId={productId} />
        </Box>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
          <Box minWidth={0} flexGrow={1}>
            <PricingBenchmarkCard vendorId={vendorId} productId={productId} />
          </Box>
          <Box flexBasis={{ xs: 1, md: 377 }}>
            <EffectiveBenchmarkingCard />
          </Box>
        </Stack>
        <MostCommonContractLengthCard vendorId={vendorId} productId={productId} />
      </Stack>
    </Stack>
  );
};

export default ProductDetail;
