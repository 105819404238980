import { featuresApi as api } from '../../api/featuresAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listFeatures: build.query<ListFeaturesApiResponse, ListFeaturesApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/features` }),
    }),
    enableDisableFeatures: build.mutation<EnableDisableFeaturesApiResponse, EnableDisableFeaturesApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/features`,
        method: 'PUT',
        body: queryArg.approachableFeatures,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as featuresAPICodegen };
export type ListFeaturesApiResponse = /** status 200 Provides list of features
 */ {
  features?: {
    [key: string]: Feature;
  };
};
export type ListFeaturesApiArg = {
  accountId: UuidIdentifier | 'VERTICE_WORKFLOW_ADMINISTRATION';
};
export type EnableDisableFeaturesApiResponse =
  /** status 201 Provides list of features that could be enabled or disabled by user
   */ ApproachableFeatures;
export type EnableDisableFeaturesApiArg = {
  accountId: UuidIdentifier | 'VERTICE_WORKFLOW_ADMINISTRATION';
  approachableFeatures: ApproachableFeatures;
};
export type Feature = {
  /** Code of the feature */
  code: string;
  /** Name of the feature */
  name: string;
  /** Version of the feature */
  version?: string;
  /** Whether the feature is enabled or disabled */
  enabled: boolean;
  /** Properties of the feature.
    For example, if the feature is CUSTOMER_SUCCESS, then the properties
    would be the list of products that are enabled for the account.
     */
  properties?: {
    [key: string]: any;
  };
};
export type UuidIdentifier = string;
export type ApproachableFeatures = {
  features?: {
    AWS_COST_INSIGHT?: {
      /** Whether the AWS Cost Insight feature is available for account. */
      enabled: boolean;
      properties?: {
        /** Whether the spend audit is active for account. */
        activeSpendAudit?: boolean;
        /** Use demo data and configure automatically */
        activateIntegration?: boolean;
        /** Demo data configuration (optional) */
        intakeParameters?: string;
        /** Comma separated list of tests (optional). Empty value means all tests are visible. */
        visibleTests?: string;
        [key: string]: any;
      };
    };
    AWS_RIO?: {
      /** Whether the RIO feature is available for account. */
      enabled: boolean;
    };
    AWS_SPRIO?: {
      enabled: boolean;
    };
    AWS_SPRIO_COMPUTE_SP?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_SPRIO_EC2_SP?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_SPRIO_SAGEMAKER_SP?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_SPRIO_EC2_RI?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_SPRIO_RDS_RI?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_SPRIO_OPENSEARCH_RI?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_SPRIO_ELASTICACHE_RI?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_SPRIO_REDSHIFT_RI?: {
      enabled: boolean;
      properties?: {
        purchaseUseDryRun?: boolean;
        buyingJourney?: boolean;
        purchaseDelay?: number;
      };
    };
    AWS_ANALYTICS?: {
      /** This feature drives the visibility of the Analytics */
      enabled: boolean;
    };
    CUSTOM_WORKFLOWS?: {
      /** Whether the Custom Workflows feature is available for account. */
      enabled: boolean;
    };
    CONTRACTS_V2?: {
      /** Whether the CONTRACTS_V2 feature is available for account. */
      enabled: boolean;
    };
    WORKFLOWS_V2?: {
      /** Whether the WORKFLOWS_V2 feature is available for account. */
      enabled: boolean;
    };
    INSIGHT_ACCOUNTING?: {
      /** Whether the Insight Accounting feature is available for account. */
      enabled: boolean;
      properties?: {
        /** Whether the spend insights feature is enabled for account. */
        spendInsightsEnabled?: boolean;
        /** Whether the simulator is active for account. */
        simulatorActive?: boolean;
        /** Simulator configuration for account. */
        simulatorConfig?: string;
        [key: string]: any;
      };
    };
    INSIGHTS?: {
      /** Whether the Insights feature is available for account. */
      enabled: boolean;
    };
    INTEGRATION_GOOGLE?: {
      /** Whether the Integration Google feature is available for account. */
      enabled: boolean;
    };
    INTEGRATION_MERGE?: {
      /** Whether the Integration Merge feature is available for account. */
      enabled: boolean;
      properties: {
        /** If set to true, user is able to configure accounting integration via Merge.dev */
        accounting: boolean;
      };
    };
    INTEGRATION_NETSUITE?: {
      /** Whether the Integration Netsuite feature is available for account. */
      enabled: boolean;
      properties: {
        /** If set to true, NetSuite integration is allowed directly without any integration service. */
        standalone: boolean;
      };
    };
    INTEGRATION_QUICKBOOKS?: {
      /** Whether the Integration Quickbooks feature is available for account. */
      enabled: boolean;
      properties: {
        /** If set to true, Quickbooks integration is allowed directly without any integration service. */
        standalone: boolean;
      };
    };
    INTEGRATION_XERO?: {
      /** Whether the Integration Xero feature is available for account. */
      enabled: boolean;
      properties: {
        /** If set to true, Xero integration is allowed directly without any integration service. */
        standalone: boolean;
      };
    };
    INTEGRATION_MS_DYNAMICS?: {
      /** Whether the Integration MS Dynamics feature is available for account. */
      enabled: boolean;
      properties: {
        /** If set to true, MS Dynamics integration is allowed directly without any integration service. */
        standalone: boolean;
      };
    };
    INTEGRATIONS?: {
      /** Whether the Integrations feature is available for account. */
      enabled: boolean;
    };
    SAVINGS_DASHBOARD?: {
      /** Whether the Savings Dashboard feature is available for account. */
      enabled: boolean;
    };
    SSO?: {
      /** Whether the SSO feature is available for account. */
      enabled: boolean;
    };
    USAGE_ANALYTICS?: {
      /** Whether the Usage Analytics feature is available for account. */
      enabled: boolean;
      properties: {
        /** Whether usage data simulator should be active. */
        simulatorActive: boolean;
        /** JSON describes data simulator configuration, specify only if simulatorActive enabled */
        simulatorConfig?: string;
      };
    };
    VENDOR_BENCHMARKING?: {
      /** This feature allows users to use vendor benchmarking */
      enabled: boolean;
      properties: {
        STAGE_0: boolean;
      };
    };
    VENDOR_LEGAL?: {
      /** Whether the Vendor Legal feature is available for account. */
      enabled: boolean;
      properties: {
        /** Whether the Vendor Legal feature is subscribed for the account. */
        isSubscribed: boolean;
      };
    };
  };
};
export const { useListFeaturesQuery, useLazyListFeaturesQuery, useEnableDisableFeaturesMutation } = injectedRtkApi;
