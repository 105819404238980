import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

import IconButton from '@verticeone/design-system/src/components/IconButton';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { WorkflowVersionRow } from '../types';
import { WorkflowPreviewDialog } from './WorkflowPreviewDialog';

type PreviewButtonProps = {
  row: WorkflowVersionRow;
};

export const PreviewButton: FC<PreviewButtonProps> = ({ row }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={t('ENTITIES.WORKFLOW_VERSION.ACTIONS.PREVIEW')} minified size="S">
        <IconButton
          icon={RemoveRedEyeOutlined}
          variant="outline"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      {open && <WorkflowPreviewDialog previewRow={row} onClose={() => setOpen(false)} />}
    </>
  );
};
