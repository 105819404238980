import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';

import { DEFAULT_OFFER_LIMIT, FormData } from './schema';
import Button from '@verticeone/design-system/src/components/Button';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../shared/TaskFormContext';
import Accordion, { AccordionDetails, AccordionSummary } from '@verticeone/design-system/src/components/Accordion';
import { Divider } from '@vertice/core/src/components/Dialog/Components';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import { OfferFormEntry } from '../shared/formFields/OfferFormEntry';

export const NegotiationOfferForm = () => {
  const { readOnly } = useTaskFormContext();
  const { t } = useTranslation();

  const maxOffers = useWatch<FormData, 'maxOffers'>({ name: 'maxOffers' });
  const baselineOffer = useWatch<FormData, 'baselineOffer'>({ name: 'baselineOffer' });
  const { fields, append, remove } = useFieldArray<FormData, 'negotiationOffers.instances'>({
    name: 'negotiationOffers.instances',
  });

  const addOffer = () =>
    append({
      products: [],
      concessions: [],
      overrideEffectiveCost: false,
      purchasedFromReseller: false,
      baseCurrency: baselineOffer.baseCurrency,
      vendor: baselineOffer.vendor,
    } as any);

  return (
    <Stack gap={4}>
      {fields.map(({ id }, i) => (
        <Accordion key={id} defaultExpanded variant="label">
          <AccordionSummary
            title={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.OFFER_SUMMARY', { count: i + 1 })}
            adornment={!readOnly && <IconButton icon={DeleteIcon} variant="ghost" onClick={() => remove(i)} />}
          />
          <AccordionDetails>
            <OfferFormEntry<FormData> name={`negotiationOffers.instances.${i}`} editName />
          </AccordionDetails>
        </Accordion>
      ))}

      {!readOnly && (
        <>
          <Divider />
          <Button
            variant="ghost"
            onClick={addOffer}
            disabled={fields.length >= (maxOffers || DEFAULT_OFFER_LIMIT)}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.ACTIONS.ADD_OFFER')}
          </Button>
        </>
      )}
    </Stack>
  );
};
