import { TaskAssignmentConfiguration } from '../../../../definitionsTypes';
import { EXPRESSION_ALIASES } from '../../../../task/ExpressionAliases';
import isEqual from 'lodash/isEqual';
import { DEFINITION_VERSION } from '../../../../definitions/constants';
import { formatUserRef, parseUserRef } from '../../../../../../hooks/workflows/refUtils';

export const getAssigneesFromAssignmentConfiguration = (
  assignmentConfiguration: TaskAssignmentConfiguration
): string[] => {
  if (assignmentConfiguration.type === 'STATIC') {
    return assignmentConfiguration.assignment?.map((a) => parseUserRef(a).userId) || [];
  } else if (assignmentConfiguration.type === 'REQUEST_OWNER') {
    return ['REQUEST_OWNER'];
  } else if (
    assignmentConfiguration.type === 'EXPRESSION' &&
    isEqual(assignmentConfiguration.assignment, EXPRESSION_ALIASES.WorkflowSettings.assignment) &&
    assignmentConfiguration.expression_type === EXPRESSION_ALIASES.WorkflowSettings.expressionType
  ) {
    return ['WORKFLOWS_CONFIG'];
  }

  return [];
};

export const getAssignmentConfigFromAssignees = (assignees: string[]): TaskAssignmentConfiguration => {
  if (assignees.includes('REQUEST_OWNER')) {
    return {
      kind: 'Tasks:Assignment',
      version: DEFINITION_VERSION,
      type: 'REQUEST_OWNER',
    };
  }

  if (assignees.includes('WORKFLOWS_CONFIG')) {
    return {
      kind: 'Tasks:Assignment',
      version: DEFINITION_VERSION,
      type: 'EXPRESSION',
      expression_type: EXPRESSION_ALIASES.WorkflowSettings.expressionType,
      assignment: EXPRESSION_ALIASES.WorkflowSettings.assignment,
    };
  }

  return {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'STATIC',
    assignment: assignees.map(formatUserRef),
  };
};
