import React from 'react';
import { DesignSystemSize } from '../../types';
import { styled } from '@mui/material';
import { getTextVariantStyle } from '../Text';

type StyledTextProps = {
  $color: string;
  $size: DesignSystemSize;
};

const translateYBySize = {
  XXS: '0.3em',
  XS: '0.35em',
  S: '0.4em',
  M: '0.45em',
  L: '0.5em',
  XL: '0.5em',
};

const StyledText = styled('g')<StyledTextProps>(({ theme, $color, $size }) => ({
  fill: $color,
  transform: `translateY(${translateYBySize[$size]})`,

  text: {
    ...getTextVariantStyle({
      variant: 'button',
      size: $size,
    }),
    textAnchor: 'middle',
  },
}));

export type GaugeLabelProps = {
  label: string | number;
  color: string;
  size: DesignSystemSize;
};

const GaugeLabel = ({ label, color, size }: GaugeLabelProps) => {
  return (
    <StyledText $color={color} $size={size}>
      <text x="50%" y="50%">
        {label}
      </text>
    </StyledText>
  );
};

export default GaugeLabel;
