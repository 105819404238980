import { createRequiredContext } from '@verticeone/design-system/src/utils/contexts';
import { UsersByIdReturn } from '../../../../hooks/useRelevantUsersById';
import { Definitions } from '../../definitionsTypes';

export type WorkflowRendererContextType = {
  isEditor?: boolean;
  usersById: UsersByIdReturn;
  loggedUserId: string;
  accountId: string;
  onOpenTask?: (taskId: string) => void;
  allowVerticeServiceNavigation?: boolean;
  workflowDefinitions?: Definitions;
};

const { WorkflowRendererContextProvider, useWorkflowRendererContext } = createRequiredContext<
  WorkflowRendererContextType,
  'WorkflowRenderer'
>('WorkflowRenderer');

export { useWorkflowRendererContext, WorkflowRendererContextProvider };
