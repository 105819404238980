import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../translate';

// WARNING:
// This file is copied from @vertice/core/src/utils/formatting.
// Don't change this file without updating source file as well.
// This file is going to be deleted as soon as we move the formatters into the shared @vertice/utils package.

const useFormattedTimeAgo = () => {
  const { t } = useTranslation(undefined, { i18n });

  const getFormattedTimeAgo = useCallback(
    (date: Date) => {
      const now = new Date();
      const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

      const seconds = diffInSeconds;
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(days / 365);

      if (years > 0) {
        return `${years}${t('COMMENTS.YEAR_AGO')}`;
      } else if (months > 0) {
        return `${months}${t('COMMENTS.MONTH_AGO')}`;
      } else if (days > 0) {
        return `${days}${t('COMMENTS.DAY_AGO')}`;
      } else if (hours > 0) {
        return `${hours}${t('COMMENTS.HOUR_AGO')}`;
      } else if (minutes > 0) {
        return `${minutes}${t('COMMENTS.MINUTE_AGO')}`;
      } else {
        return `${t('COMMENTS.NOW')}`;
      }
    },
    [t]
  );

  return { getFormattedTimeAgo };
};

export default useFormattedTimeAgo;
