import React from 'react';
import BaseCloudAnalyticsStats from '../../BaseCloudTab/Stats/BaseCloudAnalyticsStats';
import useTagsServicesUsageData from '../Table/useTagsServicesUsageData';

type CloudAccountServicesUsageStatsProps = {
  productCode: string;
};

const CloudAccountServicesUsageStats = ({ productCode }: CloudAccountServicesUsageStatsProps) => {
  const { data, isLoading } = useTagsServicesUsageData({
    productCode,
  });
  return <BaseCloudAnalyticsStats data={data?.stats} isLoading={isLoading} />;
};

export default CloudAccountServicesUsageStats;
