import { useMemo } from 'react';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetRequestJournalQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { getRequestJournalItems } from './getRequestJournalItems';
import { RequestJournalItem } from './types';
import { useNotificationWebSocketSubscription } from '../../../../../../contexts/NotificationWebSocketContext';
import { isRequestRef, isTaskRef } from '../../../../../../hooks/workflows/refUtils';

type UseRequestJournalReturn = {
  isLoadingJournal: boolean;
  journalItems: RequestJournalItem[];
};

const shouldRefreshJournalItems = (requestId: string) => (message: string) =>
  isTaskRef(message) || (isRequestRef(message) && message.includes(requestId));

export const useRequestJournal = (requestId?: string): UseRequestJournalReturn => {
  const { accountId } = useAccountContext();

  const {
    data: journalData,
    isLoading: isLoadingJournal,
    refetch,
  } = useGetRequestJournalQuery({ accountId, requestId: requestId! }, { skip: !requestId });

  useNotificationWebSocketSubscription({
    filter: shouldRefreshJournalItems(requestId!),
    callback: refetch,
  });

  const supportedJournalItems = useMemo(
    () =>
      journalData?.items.filter((item) =>
        item.intents.some((intent) => intent.startsWith('Services') || intent.startsWith('Workflows'))
      ) || [],
    [journalData?.items]
  );

  return useMemo(() => {
    if (journalData === undefined || isLoadingJournal) {
      return { isLoadingJournal, journalItems: [] };
    }

    return { isLoadingJournal, journalItems: getRequestJournalItems(supportedJournalItems.reverse()) };
  }, [isLoadingJournal, journalData, supportedJournalItems]);
};
