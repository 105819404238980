import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { enhancedBffeWorkflowsAPI, WORKFLOW_BFFE_VERSIONS } from '@vertice/slices';

export const useInvalidateWorkflowVersions = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_VERSIONS])),
    [dispatch]
  );
};
