import React, { FC, useEffect, useMemo } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import { DataGridColDef } from '@verticeone/design-system/src/components/DataGrid';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import NoRows from './NoRows';

type ContractsTableProps = {
  rows: ViewOnContract[];
  isLoading: boolean;
  columns: DataGridColDef<ViewOnContract>[];
  sortModel?: GridSortModel;
  setSortModel: (newSortModel?: GridSortModel) => void;
  paginationModel?: GridPaginationModel;
  setPaginationModel: (newPaginationModel?: GridPaginationModel) => void;
  getContractPath: (contractId: string) => string;
  pageSize?: number;
} & Pick<React.ComponentProps<typeof DataGrid>, 'noBorder' | 'noBorderRadius'>;

const ColumnLink = styled(Link)(() => {
  return {
    display: 'block',
    width: 'calc(100% + 32px)',
    textDecoration: 'unset',
    color: 'unset',
    marginLeft: -16,
    padding: 16,
    marginRight: -16,
  };
});

const defaultPageSize = 50;

export const ContractsTable: FC<ContractsTableProps> = ({
  sortModel,
  setSortModel,
  paginationModel,
  setPaginationModel,
  columns,
  getContractPath,
  isLoading,
  ...props
}) => {
  const clickableColumns = columns.map<DataGridColDef<ViewOnContract>>(({ renderCell, ...rest }) => {
    return {
      ...rest,
      renderCell: (params) => {
        return (
          <ColumnLink to={getContractPath(params.row.contract.record.contractId)}>{renderCell?.(params)}</ColumnLink>
        );
      },
    };
  });

  const pageSize = useMemo(() => paginationModel?.pageSize ?? defaultPageSize, [paginationModel]);
  const maxPageIndex = useMemo(
    () => Math.max(0, Math.floor((props.rows.length - 1) / pageSize)),
    [props.rows.length, pageSize]
  );

  useEffect(() => {
    if (!paginationModel) {
      // Set initial pagination model
      setPaginationModel({ pageSize: defaultPageSize, page: 0 });
    } else if (!isLoading && paginationModel?.page && paginationModel.page > maxPageIndex) {
      // Adjust current page index if it is out of available range
      setPaginationModel({ pageSize: paginationModel.pageSize, page: maxPageIndex });
    }
  }, [isLoading, paginationModel, maxPageIndex, setPaginationModel]);

  return (
    <DataGrid<ViewOnContract>
      {...props}
      columns={clickableColumns}
      getRowId={(row) => row.contract.record.contractId}
      sortingMode="client"
      disableRowSelectionOnClick
      getRowHeight={() => 60}
      noRowsOverlayHeight={200}
      sortingOrder={['asc', 'desc']}
      autoHeight
      rowSelection={false}
      loading={isLoading}
      loadingStyle="skeleton"
      slots={{
        noRowsOverlay: () => <NoRows />,
      }}
      pagination
      sortModel={sortModel ?? []}
      onSortModelChange={(newSortModel) => {
        // We do not want to override saved sort model if rows are loading
        if (!isLoading) {
          setSortModel(newSortModel);
        }
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={(newPaginationModel) => {
        // We do not want to override saved pagination model if rows are loading
        if (!isLoading) {
          setPaginationModel(newPaginationModel);
        }
      }}
    />
  );
};
